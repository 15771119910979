module.exports = {
  importImages: (type = 'station') => {
    switch (type) {
      case 'boxes':
        return require.context('../../assets/images/boxes');
      case 'dustBags':
        return require.context('../../assets/images/dust_bags');
      case 'watchBoxes':
        return require.context('../../assets/images/watch_boxes');
      default:
        return require.context('../../assets/images/dpticons');
    }
  },
};
