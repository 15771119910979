import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoButton: {
    width: 'fit-content',
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  h6: {
    lineHeight: 1.2,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '15px',
  },
});

export default useStyles;
