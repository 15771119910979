import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { TextField, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import {
  DivStyled,
  FlexContainerRow,
  Title,
} from '../../../reusables/StyledComponent';
import InputButton from '../../../reusables/inputs/InputButton';
import { useStateValue } from '../../../../providers/StateProvider';
import ExceptionCodeSelector from './ExceptionCodeSelector';
import UploadSelector from '../../../reusables/inputs/InputFiles';
import stringIsNullOrEmpty from '../../../../utils/stringIsNullOrEmpty';
import { getStationById } from '../../../../utils/stationsInfo';
import { COLORS, EXCEPTION_MAXIMUM_CHARACTERS } from '../../../../constants';
import InputSelect from '../../../reusables/inputs/InputSelect';
import usePsnadAutomation from '../../QualityControl/hooks/usePsnadAutomation';
import useStorageLocation from '../hooks/useStorageLocation';
import useImsLocations from '../hooks/useImsLocations';
import Loading from '../../../reusables/loading';
import {
  formatStorageLocation,
  isLocationEmpty,
  isValidLocation,
} from '../../../../utils/locationUtils';
import LabelledLocationInput from '../../../reusables/LabelledLocationInput/index';

const ExceptionPopup = (props) => {
  const { handleClose, handleSubmit, stationId, interceptedValue } = props;
  const { t } = useTranslation();
  const [
    { flagTypeAndValue, stationList, currentStationId, selectedSnadIds },
    dispatch,
  ] = useStateValue();
  const [formData, setFormData] = useState({
    details: '',
    selectExceptionCode: null,
    value: interceptedValue,
    location: '',
  });
  const { isPsnadAutomatedHub } = usePsnadAutomation();

  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedIdentifier, setSelectedIdentifier] = useState('');
  const [exceptionReasonsConfigured, setExceptionReasonsConfigured] =
    useState(false);
  const [error, setError] = useState('');
  const [lpnError, setLpnError] = useState('');
  const [confirmButtonState, setConfirmButtonState] = useState(false);
  const currentStationName = getStationById(stationList, currentStationId);
  const [isIntercepted, setIsIntercepted] = useState(false);
  const now = () => {
    const newDate = new Date();
    return newDate.toLocaleDateString();
  };

  const isDockReceive = () => currentStationName.value === 'DockReceive';
  const isConfirmReceive = () => currentStationName.value === 'ConfirmReceive';

  const confirmReceiveIdentifierOptions = [
    { id: '-1', value: t('ExceptionPopup.LabelTextField.SelectIdentifier') },
    { id: '1', value: t('ExceptionPopup.LabelTextField.Evtn') },
    { id: '2', value: t('ExceptionPopup.LabelTextField.Lpn') },
    { id: '3', value: t('ExceptionPopup.LabelTextField.CertNumber') },
    { id: '4', value: t('ExceptionPopup.LabelTextField.Other') },
  ];

  const setIdentifier = (id) => {
    const identifier = confirmReceiveIdentifierOptions.find(
      (opt) => opt.id === id
    )?.value;
    setSelectedIdentifier(identifier);
  };

  const partnerLocationId = JSON.parse(
    sessionStorage.getItem('partnerLocation')
  )?.id;

  const { imsEnabled } = useImsLocations();
  const hubIdentifier = partnerLocationId?.padStart(3, '0');
  const [location, setLocation] = useState('');
  const { zoneCategory, notFound, isFetching } = useStorageLocation(
    hubIdentifier,
    location
  );

  const isDetailsNotEmpty = (details = formData.details) => {
    if (stringIsNullOrEmpty(details)) {
      setError(t('ExceptionPopup.Error.NoMessage'));
      return false;
    }

    setError('');
    return true;
  };

  const isValueNotEmptyOrNotTooLong = (lpn = formData.value) => {
    if (!flagTypeAndValue.value || isConfirmReceive()) {
      if (stringIsNullOrEmpty(lpn)) {
        setLpnError(t('ExceptionPopup.Error.NoLpn'));
        return false;
      }
      if (lpn.length > EXCEPTION_MAXIMUM_CHARACTERS) {
        setLpnError(
          t('ExceptionPopup.Error.LpnTooLong', {
            characterLimit: EXCEPTION_MAXIMUM_CHARACTERS,
          })
        );
        return false;
      }
    }

    setLpnError('');
    return true;
  };

  const openConfirmation = () => {
    const isDetail = isDetailsNotEmpty();
    const isValue = isValueNotEmptyOrNotTooLong();
    if (isDetail && isValue) setShowConfirmPopup(true);
  };

  const setSelectedCodeId = (selectedCodeOption) =>
    setFormData(() => ({
      ...formData,
      selectExceptionCode: selectedCodeOption?.id || null,
    }));

  const handleDetailsChange = (e) => {
    setFormData({ ...formData, details: e.target.value });
    isDetailsNotEmpty(e.target.value);
  };

  const handleValueChange = (e) => {
    setFormData({ ...formData, value: e.target.value });
    isValueNotEmptyOrNotTooLong(e.target.value);
  };

  const handleIMSLocationChange = (locationValue) => {
    setLocation(locationValue);
  };

  const handleLocationChange = (e) => {
    setFormData({ ...formData, location: e.target.value });
  };

  // TODO maybe add a success message before handle close
  const onSubmit = async (baseData) => {
    let flagType = flagTypeAndValue.type
      ? flagTypeAndValue.type
      : currentStationName.value;
    let flagValue = flagTypeAndValue.value
      ? flagTypeAndValue.value
      : formData.value;
    if (isDockReceive()) {
      flagType = currentStationName.value;
      flagValue = formData.value;
    }
    if (isConfirmReceive()) {
      flagType = selectedIdentifier.toLowerCase();
      flagValue = formData.value;
    }

    const prepValues = {
      variables: {
        args: {
          exceptionCodeId: baseData.selectExceptionCode,
          stationId,
          type: flagType.trim(),
          value: flagValue.trim(),
          notes: baseData.details,
          location: baseData.location,
          partnerLocationId,
          files: baseData.files || null,
          defaultFlagValue: flagTypeAndValue?.value,
        },
      },
    };

    if (isPsnadAutomatedHub()) {
      prepValues.variables.args.snadInputIds = selectedSnadIds;
    }

    await handleSubmit(prepValues);
    handleClose();
  };
  const setFiles = (files) => {
    setSelectedFiles(files);
    setFormData((data) => ({ ...data, files: files.map((f) => f.file) }));
  };

  const confirmButton = () => {
    setConfirmButtonState(true);
    if (imsEnabled && isValidLocation(location) && !notFound) {
      const formattedLocation = formatStorageLocation(location);
      const formDataWithLocation = { ...formData, location: formattedLocation };
      onSubmit(formDataWithLocation);
    } else {
      onSubmit(formData);
    }
  };

  const labelTitle = () => {
    if (isDockReceive()) {
      return '';
    }
    if (isConfirmReceive()) {
      return t(`ExceptionPopup.LabelTitle.ItemOrder`);
    }
    if (flagTypeAndValue.type) {
      return t(`ExceptionPopup.LabelTitle.${flagTypeAndValue.type}`, {
        value: flagTypeAndValue.value,
      });
    }

    return t('ExceptionPopup.LabelTitle.Unknown');
  };

  const labelTextField = () => {
    if (isDockReceive()) {
      return t('ExceptionPopup.LabelTextField.EvtnTracking');
    }
    if (isConfirmReceive()) {
      return t('ExceptionPopup.LabelTextField.Identifier');
    }
    return t('ExceptionPopup.LabelTextField.Lpn');
  };

  useEffect(() => {
    if (interceptedValue) {
      setIsIntercepted(true);
      dispatch({
        type: 'openException',
        scannedString: '',
      });
    }
  }, [interceptedValue]);

  const getLocationError = () => {
    if (!isLocationEmpty(location)) {
      if (!isValidLocation(location)) {
        return (
          <Typography variant="subtitle2" color="error">
            {t('ExceptionPopup.Error.BadLocation')}
          </Typography>
        );
      }
      if (notFound) {
        return (
          <Typography variant="subtitle2" color="error">
            {t('ExceptionPopup.Error.NotFound')}
          </Typography>
        );
      }
      return null;
    }
    return null;
  };

  if (showConfirmPopup) {
    return (
      // eslint-disable-next-line react/jsx-boolean-value
      <Dialog open={true} onClose={handleClose} maxWidth="xs" margin="normal">
        <FlexContainerRow
          justCont="space-between"
          alignItems="center"
          bgColor={COLORS.red}
          width="100%"
          minHeight="40px"
        >
          <Title
            margin="0 1em 0 1em"
            color="#fff"
            overflow="hidden"
            textOverflow="ellipsis"
            wordWrapHover
          >
            {labelTitle()}
          </Title>
          <Title margin="0 1em 0 0" color="#fff">
            {now()}
          </Title>
        </FlexContainerRow>
        <DivStyled padding="0 1em">
          <DialogTitle id="form-dialog-title" />
          <DivStyled margin="0 0 1.3em" textCenter>
            <InputButton
              type="error"
              id="confirmReportExceptionId"
              renderAs="button"
              onClick={confirmButton}
              disabled={confirmButtonState}
              content={t('ButtonContent.ConfirmReportException')}
              fullWidth
            />
            <InputButton
              id="confirmReportExceptionCancelId"
              margin="1em"
              type="cancel"
              content={t('ButtonContent.Cancel')}
              onClick={handleClose}
            />
          </DivStyled>
        </DivStyled>
      </Dialog>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-boolean-value
    <Dialog open={true} onClose={handleClose} maxWidth="xs" margin="normal">
      <FlexContainerRow
        justCont="space-between"
        alignItems="center"
        bgColor={COLORS.red}
        width="100%"
        minHeight="40px"
      >
        <Title
          margin="0 1em 0 1em"
          color={COLORS.white}
          overflow="hidden"
          textOverflow="ellipsis"
          wordWrapHover
        >
          {labelTitle()}
        </Title>
        <Title margin="0 1em 0 0" color={COLORS.white}>
          {now()}
        </Title>
      </FlexContainerRow>
      <DivStyled padding="1em">
        <div>
          {(!flagTypeAndValue.value || isDockReceive()) &&
            !isConfirmReceive() && (
              <TextField
                id="evtnLpnTrackingId"
                variant="outlined"
                margin="normal"
                value={formData.value}
                error={!!lpnError}
                helperText={lpnError}
                onChange={handleValueChange}
                label={labelTextField()}
                rows={1}
                name="details"
                fullWidth
              />
            )}
          {isConfirmReceive() && (
            <>
              <InputSelect
                margin="none"
                name="selectIdentifierType"
                label={t('ExceptionPopup.SelectIdentifierType')}
                setValue={setIdentifier}
                options={isConfirmReceive() && confirmReceiveIdentifierOptions}
                defaultValue={confirmReceiveIdentifierOptions[0]?.id}
                fullWidth
              />
              <TextField
                id="evtnLpnTrackingId"
                variant="outlined"
                margin="normal"
                value={formData.value}
                error={!!lpnError}
                helperText={lpnError}
                onChange={handleValueChange}
                label={labelTextField()}
                rows={1}
                name="details"
                fullWidth
              />
            </>
          )}
          {imsEnabled ? (
            <>
              <LabelledLocationInput
                onChange={handleIMSLocationChange}
                error={getLocationError()}
              />
              <Stack direction="row" spacing={2} p={1}>
                <Typography variant="subTitle1">
                  {t('ExceptionPopup.LabelZone')}
                </Typography>
                {isFetching ? (
                  <Loading width="20px" height="20px" />
                ) : (
                  <b>{isValidLocation(location) && zoneCategory?.name}</b>
                )}
              </Stack>
            </>
          ) : (
            <TextField
              id="locationId"
              variant="outlined"
              margin="normal"
              onChange={handleLocationChange}
              label={t('ExceptionPopup.LabelLocation')}
              rows={1}
              name="location"
              fullWidth
            />
          )}
          <ExceptionCodeSelector
            setSelectedCodeId={setSelectedCodeId}
            setExceptionReasonsConfigured={setExceptionReasonsConfigured}
            isIntercepted={isIntercepted}
          />
          {exceptionReasonsConfigured && (
            <>
              <TextField
                id="detailsId"
                variant="outlined"
                margin="normal"
                error={!!error}
                helperText={error}
                onChange={handleDetailsChange}
                label={t('ExceptionPopup.Details')}
                multiline
                rows={4}
                name="details"
                fullWidth
              />
              <UploadSelector files={selectedFiles} setFiles={setFiles} />
            </>
          )}
          <DivStyled margin="1em 0 0" textCenter>
            {exceptionReasonsConfigured && (
              <InputButton
                id="reportExceptionId"
                type="error"
                content={t('ButtonContent.ReportException')}
                renderAs="button"
                onClick={openConfirmation}
                fullWidth
              />
            )}
            <InputButton
              id="reportExceptionCancelId"
              margin="1em"
              type="cancel"
              content={t('ButtonContent.Cancel')}
              onClick={handleClose}
            />
          </DivStyled>
        </div>
      </DivStyled>
    </Dialog>
  );
};
export default ExceptionPopup;

ExceptionPopup.defaultProps = {
  interceptedValue: '',
};

ExceptionPopup.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  stationId: PropTypes.string.isRequired,
  interceptedValue: PropTypes.string,
};
