/* eslint-disable react/prop-types */
import React from 'react';
import { ImageWrapper } from './ImageContainer.styles';

const defaultProps = {
  width: '',
  height: '',
  alt: '',
  margin: '',
  src: '',
  srcSet: '',
  href: '',
};

const ImageContainer = ({ width, height, alt, margin, src, srcSet, href }) => {
  if (href) {
    return (
      <a href={href}>
        <ImageWrapper
          width={width}
          height={height}
          alt={alt}
          margin={margin}
          src={src}
          srcSet={srcSet}
        />
      </a>
    );
  }

  return (
    <ImageWrapper
      width={width}
      height={height}
      alt={alt}
      margin={margin}
      src={src}
      srcSet={srcSet}
    />
  );
};

ImageContainer.defaultProps = defaultProps;

export default ImageContainer;
