/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StepNumber, StepWrapper } from './StepContainer.styles';

const StepContainer = ({ stepNumber, children }) => {
  const { t } = useTranslation();

  return (
    <div>
      <StepNumber>
        {t('account.security.2fa.common.step')} {stepNumber}
      </StepNumber>
      <StepWrapper>{children}</StepWrapper>
    </div>
  );
};

export default StepContainer;
