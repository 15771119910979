import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import React from 'react';
import PhotoUpload from '../../../ginzaV2/reusables/photoUpload';
import AccordionToggleList from '../../../ginzaV2/reusables/accordionToggleList';

const PsnadSubmissionForm = ({
  psnadForm,
  itemMetadata,
  createNotificationBanner,
  handleUpdateListItems,
  setShouldBeDisabled,
}) => {
  const { objectExceptionId } = useParams();

  return (
    <>
      {itemMetadata.itemId && (
        <PhotoUpload
          uploadParams={{
            itemId: itemMetadata.itemId,
            objectExceptionId,
            uploadToZoomApi: false,
            type: 'PSNAD',
          }}
          onFailure={createNotificationBanner}
        />
      )}
      <div id="psnad-matrix-form" data-testid="psnad-matrix-form">
        {psnadForm.map((psnad, index) => (
          <AccordionToggleList
            key={`accordion-toggle-${psnad.title}`}
            title={psnad.title}
            listItems={psnad.childInputs}
            onUpdateListItems={handleUpdateListItems(psnad.title)}
            checkType="fail"
            setShouldBeDisabled={setShouldBeDisabled}
          />
        ))}
      </div>
    </>
  );
};

const psnadFormShape = {
  inputId: PropTypes.string,
  parentId: PropTypes.string,
  hasPsnadValue: PropTypes.bool,
  snadCode: PropTypes.string,
  inputType: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  isPhotoRequired: PropTypes.bool,
  isChecked: PropTypes.bool,
};
psnadFormShape.childInputs = PropTypes.arrayOf(PropTypes.shape(psnadFormShape));

PsnadSubmissionForm.propTypes = {
  psnadForm: PropTypes.arrayOf(PropTypes.shape(psnadFormShape)).isRequired,
  itemMetadata: PropTypes.shape({
    itemId: PropTypes.string,
    lpn: PropTypes.string,
    evtn: PropTypes.string,
    title: PropTypes.string,
    condition: PropTypes.string,
    url: PropTypes.string,
    attributes: PropTypes.arrayOf(
      PropTypes.shape({ name: PropTypes.string, value: PropTypes.string })
    ),
    imageUrls: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  createNotificationBanner: PropTypes.func.isRequired,
  handleUpdateListItems: PropTypes.func.isRequired,
  setShouldBeDisabled: PropTypes.func.isRequired,
};

export default PsnadSubmissionForm;
