import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import Verification from './Verification';
import Security from './Security';
import { withMFAContext } from '../../../context/MFAContext';

import { useApolloContext } from '../../apolloProvider/context/ApolloContext';
import {
  RESPONSE_HEADERS,
  ACCES_DENIED_MESSAGE_VALUES,
} from '../../apolloProvider/constants';
import { AUTHENTICATION_ROUTES, ROUTES } from '../constants';
import { usePingAdminStatusQuery } from '../hooks/usePingAdminStatus';

const Authentication = () => {
  const { loading, status } = usePingAdminStatusQuery();
  const { response } = useApolloContext();
  const history = useHistory();

  if (status === 200) {
    history.push(`${ROUTES.administration}`);
  }

  if (!loading && response) {
    const { statusCode, headers } = response;

    if (statusCode && statusCode > 400) {
      const admHeader = headers
        ? headers.find(
            (element) => element.name === RESPONSE_HEADERS.ACCES_DENIED_MESSAGE
          )
        : null;

      if (admHeader) {
        const { value } = admHeader;

        if (value === ACCES_DENIED_MESSAGE_VALUES.OTP_VERIFICATION_REQUIRED) {
          return (
            <Switch>
              <Route path="/" render={() => <Verification />} />
              <Route
                path={AUTHENTICATION_ROUTES.verification}
                render={() => <Verification />}
              />
            </Switch>
          );
        }

        if (value === ACCES_DENIED_MESSAGE_VALUES.OTP_SETUP_NOT_FINISHED) {
          return (
            <Switch>
              <Route path="/" render={() => <Security />} />
              <Route
                path={AUTHENTICATION_ROUTES.security}
                render={() => <Security />}
              />
            </Switch>
          );
        }
      }

      return <></>;
    }
  }

  return <></>;
};

export default withMFAContext(Authentication);
