import { getRequest } from './httpClient';
import mapUser from '../mapper/mapUser';
import { getAPIUri } from '../../components/apolloProvider';

async function getUserInfo() {
  const response = await getRequest(`${getAPIUri()}/api/ims/token_info`);

  return {
    data: response.data
      ? mapUser(response.data)
      : {
          firstName: '',
          name: '',
          hubs: [],
        },
  };
}

export default getUserInfo;
