import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, InputAdornment, IconButton } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { PredefinedNotes } from '@codeboxxtechschool/ginza_shared_components';
import { usePredefinedNotes } from './PredefinedNotesContext';
import InputTextBase from '../inputs/inputTextBase';

const getFilteredNoteList = (notes, noteValue = '') => {
  if (!noteValue) {
    return notes;
  }
  return notes.filter((note) => {
    const { node } = note;
    const { description } = node;
    return description.toLowerCase().includes(noteValue.toLowerCase());
  }, []);
};

const PredefinedNotesList = ({ onAdd, disabled, autofocus }) => {
  const [predefinedNotes, setPredefinedNotes] = useState([]);
  const { filters, setFilters, fullNoteList } = usePredefinedNotes();
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    setFilters({
      ...filters,
      search: searchInput,
    });
  }, [searchInput]);

  useEffect(() => {
    if (fullNoteList.length > 0) {
      const filteredNoteList = getFilteredNoteList(fullNoteList, searchInput);
      setPredefinedNotes(filteredNoteList);
    }
  }, [searchInput, fullNoteList]);

  const handleSubmit = (text) => (e) => {
    e.preventDefault();
    onAdd(text.trim());
    setSearchInput('');
  };
  const formatPredefinedNotes = () => {
    return predefinedNotes.map((predifnedNote) => {
      return predifnedNote.node.description;
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(searchInput)}>
          <InputTextBase
            name="predefinedNotesSearch"
            value={searchInput}
            onChange={({ target }) => setSearchInput(target.value)}
            fullWidth
            autofocus={autofocus}
            placeholder="Enter note"
            disabled={disabled}
            dataTestId="set-search-input"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleSubmit(searchInput)}
                  disabled={disabled}
                  data-testid="submit-note"
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </form>
        <PredefinedNotes
          height="120px"
          notes={formatPredefinedNotes()}
          onClickNote={(note) => onAdd(note)}
        />
      </Grid>
    </Grid>
  );
};

PredefinedNotesList.propTypes = {
  onAdd: PropTypes.func.isRequired,
  autofocus: PropTypes.bool,
  disabled: PropTypes.bool,
};

PredefinedNotesList.defaultProps = {
  autofocus: true,
  disabled: false,
};

export default PredefinedNotesList;
