import { timeZoneFormat } from '../../../../utils/timeZoneFormat';

const getTimeOutDate = (item, stationItem) => {
  const isLocked =
    ['2', '3', '4'].includes(stationItem?.timelineStationStatus) ||
    stationItem?.item?.order?.status.toLowerCase() === 'shipped';

  return isLocked && stationItem?.completedAt
    ? timeZoneFormat(stationItem?.completedAt, item)
    : 'N/A';
};

export default getTimeOutDate;
