import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import { CloudUpload } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { COLORS } from '@codeboxxtechschool/ginza_shared_components';
import { makeStyles } from '@material-ui/core/styles';
import NotificationBanner from '../notificationBanner';

const dropzoneColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
};
const useStyle = makeStyles({
  loading: {
    color: COLORS.black,
    fontWeight: 'bolder',
    fontStyle: 'italic',
    fontSize: 16,
  },
  promptContainer: {
    marginBottom: '24px',
    textAlign: 'center',
    color: COLORS.black,
  },
  promptSubtitle: {
    color: COLORS.black,
  },
  uploadIcon: {
    fontSize: 45,
    color: COLORS.blue500,
  },
  dropzoneContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderStyle: 'dashed',
    color: '#bdbdbd',
    outline: 'none',
    minHeight: '120px',
    borderColor: (props) => dropzoneColor(props),
  },
});

const DropZoneContent = (props) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { isLoading, isDisabled, disableNotice, children } = props;

  if (isLoading)
    return (
      <>
        <Typography variant="h6" className={classes.loading}>
          {t('GinzaV2.Dropzone.Loading')}
        </Typography>
        <LinearProgress style={{ width: '100%' }} />
      </>
    );

  return isDisabled ? (
    <> {disableNotice} </>
  ) : (
    <>
      <IconButton style={{ padding: 0 }} disabled>
        <CloudUpload className={classes.uploadIcon} />
      </IconButton>
      {children}
      <div className={classes.promptContainer}>
        <Typography variant="body1">{t('GinzaV2.Dropzone.Prompt')}</Typography>
        <Typography variant="body1">
          {t('GinzaV2.Dropzone.BrowseImages')}
        </Typography>
      </div>
      <Typography className={classes.promptSubtitle} variant="subtitle1">
        {t('GinzaV2.Dropzone.AcceptableFileTypes')}
      </Typography>
      <Typography className={classes.promptSubtitle} variant="subtitle1">
        {t('GinzaV2.Dropzone.SizeLimit')}
      </Typography>
    </>
  );
};

DropZoneContent.defaultProps = {
  isDisabled: false,
  children: <></>,
  disableNotice: <></>,
};

DropZoneContent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  children: PropTypes.node,
  disableNotice: PropTypes.node,
};

const Dropzone = (props) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const { onDrop, isLoading, isDisabled, disableNotice, maxPhoto } = props;
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: { 'image/jpeg': [], 'image/jpg': [], 'image/png': [] },
    disabled: isDisabled,
    maxFiles: maxPhoto,
    maxSize: 12582912, // 12mb in bytes
  });
  const errorMessage = (errorCode) => {
    switch (errorCode) {
      case 'too-many-files':
        return t('PSNAD.PhotoUpload.MaxPhotoReached');
      case 'file-too-large':
        return t('PSNAD.PhotoUpload.FileTooLarge');
      default:
        return t('PSNAD.PhotoUpload.UnableToUpload');
    }
  };

  const rejectionErrors = fileRejections.reduce((output, currentValue) => {
    const errorCodes = currentValue.errors.map((error) => error.code);
    const errorCodesToAdd = [];
    errorCodes.forEach((errorCode) => {
      const exists = output.includes(errorCode);
      if (!exists) {
        errorCodesToAdd.push(errorCode);
      }
    });

    return output.concat(errorCodesToAdd);
  }, []);

  return (
    <div
      className={classes.dropzoneContainer}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...getRootProps({ isFocused, isDragAccept, isDragReject })}
    >
      <input
        data-testid="dropzone-input"
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...getInputProps()}
      />
      <DropZoneContent
        isDisabled={isDisabled}
        disableNotice={disableNotice}
        isLoading={isLoading}
      >
        {rejectionErrors.map((errorCode) => (
          <div style={{ padding: '8px 0px' }}>
            <NotificationBanner
              type="error"
              message={errorMessage(errorCode)}
            />
          </div>
        ))}
      </DropZoneContent>
    </div>
  );
};

Dropzone.defaultProps = {
  isDisabled: false,
  disableNotice: <></>,
  maxPhoto: 0,
  rejections: [],
};

Dropzone.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onDrop: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  disableNotice: PropTypes.node,
  maxPhoto: PropTypes.number,
  rejections: PropTypes.arrayOf(PropTypes.string),
};

export default Dropzone;
