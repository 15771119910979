import React from 'react';
import { useTranslation } from 'react-i18next';
import DownloadAndInstall from '../../steps/DownloadAndInstall';
import QRCodeAddAccount from '../../steps/AddAccount';
import DeviceVerification from '../../steps/DeviceVerification';
import { StyledRow, StyledColumn, SetupPageContainer } from './index.styles';
import PageHeader from './PageHeader';
import CenteredContainer from '../../../reusables/centeredContainer';

export const GASetupPage = () => {
  const { t } = useTranslation();

  return (
    <SetupPageContainer>
      <CenteredContainer>
        <StyledRow>
          <PageHeader
            title={t('account.security.2fa.setupPage.title')}
            content={t('account.security.2fa.setupPage.description')}
          />
        </StyledRow>
      </CenteredContainer>
      <CenteredContainer>
        <StyledRow>
          <StyledColumn>
            <DownloadAndInstall />
          </StyledColumn>
          <StyledColumn>
            <QRCodeAddAccount />
          </StyledColumn>
          <StyledColumn>
            <DeviceVerification />
          </StyledColumn>
        </StyledRow>
      </CenteredContainer>
    </SetupPageContainer>
  );
};

export default GASetupPage;
