import { makeStyles } from '@material-ui/core';
import { COLORS as SHARED_COLORS } from '@codeboxxtechschool/ginza_shared_components/dist/theme';
import { COLORS } from '@codeboxxtechschool/ginza_shared_components';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    marginTop: '5px',
  },
  predefinedNoteTextArea: {
    borderBottom: `1px ${SHARED_COLORS.blue500} solid`,
    borderRadius: '4px',
  },
  textArea: {
    resize: 'both',
  },
  buttonsContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    columnGap: '24px',
  },
  dropdownSelect: {
    padding: '12px 12px',
    border: `1px ${COLORS.gray100} solid`,
  },
  dropdownLabel: {
    paddingLeft: '16px',
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: '100',
    top: '8px',
  },
});

export default useStyles;
