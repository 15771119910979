import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { PsnadAutomationService } from '../services/PsnadAutomationService';
import useDescriptionList from '../../../ginzaV2/reusables/descriptionList/useDescriptionList';
import SectionContainer from '../../../ginzaV2/reusables/sectionContainer';
import PhotoPreviewer from '../../../ginzaV2/reusables/photoPreviewer';
import MultiLevelList from '../../../ginzaV2/reusables/multiLevelList';
import DescriptionList from '../../../ginzaV2/reusables/descriptionList';
import useQueryItemImages from '../hooks/useQueryItemImages';

const PsnadReadForm = ({ objectExceptionId, psnadForm, exception }) => {
  const services = PsnadAutomationService(exception);
  const { transformObjectArrayToList } = useDescriptionList();
  const { t } = useTranslation();
  const { images } = useQueryItemImages({
    objectExceptionId,
  });

  const inboundShipmentKey = {
    carrier: t('PSNAD.InboundShipment.Carrier'),
    trackingNumber: t('PSNAD.InboundShipment.TrackingNumber'),
  };
  const inboundShipment = transformObjectArrayToList(
    exception.order.trackings,
    inboundShipmentKey
  );

  const storageDetailKey = {
    identifier: t('PSNAD.Storage.Location'),
  };
  const storageDetails = transformObjectArrayToList(
    exception.actualLocations,
    storageDetailKey
  );
  storageDetails.unshift({ name: t('PSNAD.Storage.Zone'), value: 'PSNAD' });

  const autheDetails = [
    {
      name: t('PSNAD.AuthDetails.AuthenticationResult'),
      value: exception.order.authenticationDetailStatus,
    },
    {
      name: t('PSNAD.AuthDetails.Authenticator'),
      value: services.itemException.qcStationItem.userIdentification,
    },
    {
      name: t('PSNAD.AuthDetails.Brand'),
      value: services.itemException.itemBrand,
    },
    {
      name: t('PSNAD.AuthDetails.Model'),
      value: services.itemException.itemModel,
    },
    {
      name: t('PSNAD.AuthDetails.EntrupyId'),
      value: services.itemException.itemIdentifier,
    },
    {
      name: t('PSNAD.AuthDetails.ItemStyle'),
      value: services.itemException.itemStyle,
    },
    {
      name: t('PSNAD.AuthDetails.TagId'),
      value: exception.order.nfcTag?.tagId,
    },
    {
      name: t('PSNAD.AuthDetails.TagUrl'),
      value: exception.order.nfcTag?.tagUrl,
    },
  ];

  return (
    <>
      <SectionContainer title={t('PSNAD.PsnadMatrix')}>
        {images.length > 0 && <PhotoPreviewer photos={images} readOnly />}
        <MultiLevelList list={psnadForm} />
      </SectionContainer>
      <DescriptionList title={t('PSNAD.Storage.Title')} list={storageDetails} />
      <DescriptionList
        title={t('PSNAD.InboundShipment.Title')}
        list={inboundShipment}
      />
      <DescriptionList
        title={t('PSNAD.AuthDetails.Title')}
        list={autheDetails}
      />
    </>
  );
};

const psnadFormShape = {
  inputId: PropTypes.string,
  parentId: PropTypes.string,
  hasPsnadValue: PropTypes.bool,
  snadCode: PropTypes.string,
  inputType: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  isPhotoRequired: PropTypes.bool,
  isChecked: PropTypes.bool,
};
psnadFormShape.childInputs = PropTypes.arrayOf(PropTypes.shape(psnadFormShape));

PsnadReadForm.propTypes = {
  objectExceptionId: PropTypes.string.isRequired,
  psnadForm: PropTypes.arrayOf(PropTypes.shape(psnadFormShape)).isRequired,
  exception: PropTypes.shape({
    actualLocations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        identifier: PropTypes.string,
      })
    ),
    order: PropTypes.shape({
      authenticationDetailStatus: PropTypes.string,
      trackings: PropTypes.arrayOf(
        PropTypes.shape({
          carrier: PropTypes.string,
          trackingNumber: PropTypes.string,
        })
      ),
      nfcTag: PropTypes.shape({
        tagId: PropTypes.string,
        tagUrl: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default PsnadReadForm;
