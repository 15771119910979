import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  photoUploadContainer: {
    backgroundColor: 'rgb(239, 242, 245)',
    padding: '0 24px',
  },
  photoUploadCtaContainer: {
    height: '80px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '5px',
  },
  photoUploadBtn: {
    marginLeft: 'auto',
    border: '1px dashed black',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    padding: '0 8px',
    cursor: 'pointer',
  },
  photoUploadText: {
    fontWeight: 'bold',
    margin: '8px 0',
  },
});

export default useStyles;
