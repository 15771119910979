import React, { useEffect, useState } from 'react';
import { Grid, Button, Paper, Typography } from '@material-ui/core';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { COLORS } from '@codeboxxtechschool/ginza_shared_components';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import NotificationBanner from '../notificationBanner';

const StationContainer = ({ children, header, subHeader }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.backgroundColor = COLORS.gray100;
  }, []);

  useEffect(
    () => () => {
      document.body.style.backgroundColor = COLORS.white;
    },
    []
  );
  const history = useHistory();
  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div id="station-container" className={classes.flexCol}>
      <div
        id="header-container"
        className={`${classes.flexRow} ${classes.headerContainer}`}
      >
        <div
          id="back-btn"
          className={`${classes.flexRow} ${classes.backBtnContainer}`}
        >
          <Button
            className={classes.backBtn}
            variant="text"
            disableRipple
            onClick={handleGoBack}
          >
            <ArrowBackIosNewIcon style={{ fontSize: '12px' }} />
            {t('Button.Back')}
          </Button>
        </div>
        <div
          id="header-main-container"
          className={`${classes.flexCol} ${classes.headerMainContainer}`}
        >
          <div className={classes.headerTitle}>{header}</div>
          <div>{subHeader}</div>
        </div>
      </div>
      {children}
    </div>
  );
};

StationContainer.defaultProps = {
  children: <></>,
  header: <></>,
  subHeader: <></>,
};

StationContainer.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  subHeader: PropTypes.node,
};

const NOTIFICATION_DISPLAY_TIME = 5000; // in ms
const RightStationFooter = ({ children, notification, setNotification }) => {
  const [showBanner, setShowBanner] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (!notification) return;

    setShowBanner(true);

    setTimeout(() => {
      // clear & hide notification after 5 seconds
      setShowBanner(false);
      setNotification(null);
    }, NOTIFICATION_DISPLAY_TIME);
  }, [notification]);

  return (
    <div className={classes.footerMainContainer}>
      <Grid container direction="row" spacing={4} justifyContent="flex-end">
        <Grid
          style={{ padding: '0 32px 0 16px' }}
          container
          item
          xs={6}
          sm={6}
          justifyContent="center"
        >
          {showBanner && (
            <NotificationBanner
              type={notification.type}
              message={notification.message}
              fullWidth
            />
          )}
          <Paper className={classes.footerBtnContainer} elevation={3}>
            {children}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

RightStationFooter.defaultProps = {
  children: <></>,
  notification: null,
  setNotification: null,
};

RightStationFooter.propTypes = {
  children: PropTypes.node,
  notification: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
  setNotification: PropTypes.func,
};

const Station = ({
  header,
  subHeader,
  leftColumn,
  rightColumn,
  footerButtons,
  notification,
  setNotification,
}) => {
  const classes = useStyles();

  return (
    <StationContainer header={header} subHeader={subHeader}>
      <Grid
        className={classes.mainContainer}
        container
        direction="row"
        spacing={4}
      >
        <Grid container item xs={6} sm={6} justifyContent="center">
          <Paper className={classes.leftContainer} elevation={3}>
            {leftColumn}
          </Paper>
        </Grid>
        <Grid container item xs={6} sm={6} justifyContent="center">
          <Paper className={classes.rightContainer} elevation={3}>
            {rightColumn}
          </Paper>
        </Grid>
      </Grid>
      <RightStationFooter
        notification={notification}
        setNotification={setNotification}
      >
        {footerButtons}
      </RightStationFooter>
    </StationContainer>
  );
};

Station.defaultProps = {
  header: <></>,
  subHeader: <></>,
  leftColumn: <></>,
  rightColumn: <></>,
  footerButtons: <></>,
  notification: null,
  setNotification: null,
};

Station.propTypes = {
  header: PropTypes.node,
  subHeader: PropTypes.node,
  leftColumn: PropTypes.node,
  rightColumn: PropTypes.node,
  footerButtons: PropTypes.node,
  notification: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
  setNotification: PropTypes.func,
};

Station.Footer = RightStationFooter;
Station.Container = StationContainer;
Station.TwoColumns = Station;

export default Station;
