import mapStorageDetails from './mapStorageDetails';

const mapStorage = (storagePayloadArray) => {
  return storagePayloadArray.map((storagePayload) => {
    return {
      exceptionId: storagePayload.exceptionId.toString(),
      item:
        storagePayload.storagePayloads.map((storage) =>
          mapStorageDetails(storage)
        )?.[0] || null,
    };
  });
};

export default mapStorage;
