import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';

const darkGrey = '#585757';
const orange = '#ffb404';

const StyledListItem = withStyles({
  root: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '14px',
    color: darkGrey,
    '& .MuiListItemIcon-root': {
      '& img': {
        filter: 'opacity(0.6)',
      },
    },
    '&:first-child': {
      marginTop: '10px',
    },
    '&:visited': {
      color: darkGrey,
    },
    '&:active': {
      backgroundColor: orange,
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white',
        '& img': {
          filter: 'invert(1)',
        },
      },
    },
    '&:hover': {
      backgroundColor: orange,
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white',
        '& img': {
          filter: 'invert(1)',
        },
      },
    },
    '&$selected': {
      backgroundColor: orange,
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white',
        '& img': {
          filter: 'invert(1)',
        },
      },
    },
    '&$selected:hover': {
      backgroundColor: orange,
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white',
        '& img': {
          filter: 'invert(1)',
        },
      },
    },
  },
  selected: {},
  gutters: {
    paddingLeft: '0px',
    paddingRight: '4px',
  },
})(ListItem);

const StyledListItemIcon = withStyles({
  root: {
    paddingLeft: '16px',
    minWidth: '24px',
  },
})(ListItemIcon);

const StyledListItemText = withStyles({
  root: {
    fontWeight: 'bold',
    paddingLeft: '16px',
  },
})(ListItemText);

export { StyledListItem, StyledListItemIcon, StyledListItemText };
