import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  mainContainer: {
    height: '-webkit-fill-available',
    width: '92vw',
    marginTop: '20px',
  },
  leftContainer: { width: '100%', padding: '35px', paddingTop: 0 },
  rightContainer: { width: '100%', padding: '35px' },
  footerBtnContainer: {
    height: '70px',
    width: '100%',
    padding: '16px 16px 8px',
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerContainer: {
    width: '92vw',
    position: 'relative',
  },
  headerMainContainer: {
    flexGrow: '1',
  },
  backBtnContainer: {
    flexShrink: '0',
    position: 'absolute',
    zIndex: '100',
    top: '35px',
    left: '0',
  },
  headerTitle: {
    paddingTop: '40px',
  },
  backBtn: {
    textTransform: 'none',
    color: '#52535a',
    backgroundColor: 'transparent',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  footerMainContainer: {
    width: '92vw',
    backgroundColor: 'transparent',
    textAlign: 'center',
    position: 'sticky',
    bottom: '16px',
  },
});

export default useStyles;
