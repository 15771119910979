const useDescriptionList = () => {
  const stringToKey = (string) => {
    return string.toLowerCase().replace(/ /g, '-');
  };

  const transformObjectArrayToList = (objectArray, customKey = {}) => {
    const includeList = Object.keys(customKey);
    const hasCustomKey = includeList.length > 0;

    return objectArray.flatMap((object) =>
      Object.entries(object)
        .filter(([key]) => (hasCustomKey ? includeList.includes(key) : key))
        .map(([key, value]) => ({
          key: hasCustomKey ? stringToKey(customKey[key]) : stringToKey(key),
          name: hasCustomKey ? customKey[key] : key,
          value,
        }))
    );
  };

  return {
    stringToKey,
    transformObjectArrayToList,
  };
};

export default useDescriptionList;
