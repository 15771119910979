import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PopupModal from '../popupModal';
import useStyles from './useStyles';
import conditionDefinitions from './hooks/conditionDefinitions';

const ConditionDefinitionModal = ({ category, isOpen, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const definitions = conditionDefinitions(category);

  const renderDefinition = (color, title, description) => (
    <div className={classes.container}>
      <div style={{ marginBottom: 'auto' }}>
        <CircleIcon style={{ color }} />
      </div>
      <div style={{ marginLeft: '10px' }}>
        <Typography variant="h6" className={classes.h6} style={{ margin: 0 }}>
          {title}
        </Typography>
        <Typography variant="body1" style={{ margin: 0 }}>
          {description}
        </Typography>
      </div>
    </div>
  );

  if (!definitions) return <></>;

  return (
    <PopupModal
      title={t('GinzaV2.ConditionInfoModal.Title')}
      openModal={isOpen}
      onClose={onClose}
    >
      {definitions.map(({ color, title, definition }) =>
        renderDefinition(color, title, definition)
      )}
    </PopupModal>
  );
};

export default ConditionDefinitionModal;

ConditionDefinitionModal.defaultProps = {
  isOpen: false,
  onClose: null,
};

ConditionDefinitionModal.propTypes = {
  category: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
