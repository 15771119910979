export const getStationById = (stationList = [], stationId = '') => {
  return (
    stationId && stationList?.find((sta) => stationId === sta?.id?.toString())
  );
};

export const getStationByName = (stationList = [], stationName = '') => {
  return stationName && stationList?.find((sta) => stationName === sta?.value);
};

export const getStationIdByName = (stationList = [], stationName = '') => {
  return getStationByName(stationList, stationName)?.id?.toString();
};
