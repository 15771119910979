import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getStorageLocation } from '../../../../api/rest/storageLocation';
import { NOT_FOUND } from '../../../../api/rest/errorsCode';
import { isValidLocation } from '../../../../utils/locationUtils';

export default function useStorageLocation(hubIdentifier, locationString) {
  const [zoneCategory, setZoneCategory] = useState(null);
  const [zoneStatuses, setZoneStatuses] = useState([]);
  const [notFound, setNotFound] = useState(false);

  const enabled =
    !!hubIdentifier &&
    (locationString === '' || isValidLocation(locationString));

  const { isLoading, isSuccess, data, isFetching } = useQuery({
    queryKey: ['storage-location', hubIdentifier, locationString],
    queryFn: () => getStorageLocation({ hubIdentifier, locationString }),
    cacheTime: 0,
    enabled,
  });

  useEffect(() => {
    if (!isLoading && isSuccess) {
      if (data?.data?.storageInformation) {
        setZoneCategory(data.data.storageInformation.zoneCategory);
        setZoneStatuses(data.data.storageInformation.zoneStatuses);
      } else {
        setZoneCategory(null);
        setZoneStatuses([]);
      }

      setNotFound(data?.error?.code === NOT_FOUND);
    }
  }, [isLoading, isSuccess, data, isFetching]);

  return { zoneStatuses, zoneCategory, notFound, isFetching };
}
