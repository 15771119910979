import React from 'react';
import PropTypes from 'prop-types';
import InputButton from '../inputs/InputButton';
import useMediaQuery from '../mediaQuery';

const Button = ({
  onClick,
  type,
  content,
  id,
  show,
  disabled,
  hasMargin,
  customMargin,
  fontSize,
  minwidth,
  customPadding,
  height,
  width,
}) => {
  const isTabletPortraitMin = useMediaQuery('(min-width: 768px)');
  const isTabletPortraitMax = useMediaQuery('(max-width: 1024px)');

  const inputButtonMargin = () => {
    if (hasMargin) {
      if (isTabletPortraitMin && isTabletPortraitMax) {
        return '0 0.5em';
      }
      return '0 1em';
    }
    return '0';
  };

  return (
    <InputButton
      key={id}
      id={id}
      content={content}
      onClick={onClick}
      type={type}
      display={show ? 'block' : 'none'}
      margin={customMargin || inputButtonMargin()}
      padding={customPadding}
      className="footerButton"
      disabled={disabled}
      fontSize={fontSize}
      minwidth={minwidth}
      height={height}
      width={width}
    />
  );
};

Button.defaultProps = {
  show: true,
  disabled: false,
  hasMargin: true,
  customMargin: null,
  customPadding: null,
  minwidth: null,
  fontSize: null,
  height: null,
  width: null,
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  show: PropTypes.bool,
  disabled: PropTypes.bool,
  hasMargin: PropTypes.bool,
  customMargin: PropTypes.string,
  customPadding: PropTypes.string,
  minwidth: PropTypes.string,
  fontSize: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Button;
