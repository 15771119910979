import React from 'react';
import {
  COLORS,
  ContentContainer,
} from '@codeboxxtechschool/ginza_shared_components';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

// eslint-disable-next-line react/prop-types
const CenterForm = ({ children, description, title }) => {
  return (
    <>
      <ContentContainer fitContent padding="50px 60px">
        <Grid
          sx={{
            backgroundColor: COLORS.white,
            minWidth: '330px',
            minHeight: '280px',
            textAlign: 'center',
          }}
        >
          <div>
            <h2 style={{ textAlign: 'center' }} fontWeight="bold">
              {title}
            </h2>
          </div>
          <div>
            <p style={{ maxWidth: '330px', textAlign: 'center' }}>
              {description}
            </p>
          </div>
          {children}
        </Grid>
      </ContentContainer>
    </>
  );
};

CenterForm.defaultProps = {
  title: '',
  description: '',
};

CenterForm.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default CenterForm;
