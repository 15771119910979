import { Fab, ButtonGroup, Box } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import {
  ButtonLink,
  Popover,
  NoteList,
} from '@codeboxxtechschool/ginza_shared_components';
import { CancelPresentation, PhotoCamera } from '@material-ui/icons';
import { CREATE_PRIVATE_NOTES } from '../../graphql/mutations';
import { useStateValue } from '../../providers/StateProvider';
import { QUERY_NOTABLE_NOTES } from '../../graphql/queries';
import { TextFieldFillHeight } from '../components.styles';
import InputButton from '../reusables/inputs/InputButton';
import stringIsNullOrEmpty from '../../utils/stringIsNullOrEmpty';
import makeError from '../../utils/makeError';
import useMediaQuery from '../reusables/mediaQuery/index';
import PopoverExceptionNotes from '../reusables/notes/PopoverExceptionNotes';
import formatNotes from '../../utils/formatNotes';
import PredefinedNotes from '../reusables/predefinedNotes';
import usePredefinedNotesHooks from '../../hooks/usePredefinedNotesHooks';
import { getStationIdByName } from '../../utils/stationsInfo';
import Notes from '../reusables/notes/Notes';
import { POLL_INTERVALS } from '../../constants';

const PrivateNote = (props) => {
  const {
    readOnly,
    timeZone,
    isLiveEventNote,
    isNavbarNote,
    isFooterNote,
    openNoteSection,
    setOpenNoteSection,
  } = props;
  const { t } = useTranslation();
  const [{ orderId, itemId, currentStationId, stationList }, dispatch] =
    useStateValue();
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const isTabletLandscape = useMediaQuery('(max-width: 1080px)');
  const [isTakingNote, setIsTakingNote] = useState(false);
  const [newUserNote, setNewUserNote] = useState('');
  const [notes, setNotes] = useState([]);
  const [notesNumber, setNotesNumber] = useState(0);
  const [createPrivateNotes, { loading: loadingCreatePrivateNotes }] =
    useMutation(CREATE_PRIVATE_NOTES);

  const reset = () => {
    setIsTakingNote(false);
    setNewUserNote('');
  };

  const isLiveEventIntakeStation =
    currentStationId === getStationIdByName(stationList, 'LiveEventIntake');

  const isLiveEvent = !!(isLiveEventNote && isLiveEventIntakeStation && notes);

  const isCollectAllLevelNotes = isNavbarNote || isFooterNote;

  const queryVariables = () => {
    const commonParams = {
      visibilities: ['UserNotes'],
    };

    if (isCollectAllLevelNotes) {
      return {
        notableId: orderId || '',
        notableType: 'Order',
        level: 'all',
        ...commonParams,
      };
    }
    return {
      notableId: itemId || orderId || '',
      notableType: itemId ? 'Item' : 'Order',
      level: itemId && isLiveEventNote ? null : 'all',
      ...commonParams,
    };
  };

  const { data, refetch, error, errors } = useQuery(QUERY_NOTABLE_NOTES, {
    fetchPolicy: 'no-cache',
    pollInterval: POLL_INTERVALS.notes,
    variables: queryVariables(),
  });

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (data?.queryNotableNotes) {
      setNotes(data.queryNotableNotes);
      scrollToBottom();
    }
  }, [data]);

  const { handleOnAddPredefinedNote } = usePredefinedNotesHooks();

  const saveNote = async () => {
    if (!stringIsNullOrEmpty(newUserNote)) {
      try {
        const response = await createPrivateNotes({
          variables: {
            note: newUserNote,
            orderId,
            itemId: isCollectAllLevelNotes ? null : itemId,
            stationId: currentStationId || '',
          },
        });
        if (
          isLiveEventNote &&
          isLiveEventIntakeStation &&
          response?.data?.createPrivateNotes
        ) {
          setOpenNoteSection(false);
        }
        reset();
        await refetch();
      } catch (err) {
        makeError(err, dispatch, t);
      }
    } else {
      dispatch({
        type: 'changePopup',
        newPopup: {
          type: 'error',
          title: t(`PopupContent.Title.EmptyNote`),
          message: t(`PopupContent.Error.NoNoteEntered`),
          btnMessage: t('ButtonContent.Close'),
        },
      });
    }
  };

  useEffect(
    () => (error || errors) && makeError(error || errors, dispatch, t),
    [error || errors]
  );

  const changeViewState = () => {
    setIsTakingNote((prev) => !prev);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  const handleOnOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  useEffect(() => {
    if (loadingCreatePrivateNotes)
      dispatch({ type: 'changeLoading', newLoading: true });
    else dispatch({ type: 'changeLoading', newLoading: false });
  }, [loadingCreatePrivateNotes]);

  useEffect(() => {
    if (notesNumber) {
      refetch();
    }
  }, [notesNumber, setNotesNumber]);

  return orderId ? (
    <div>
      {(openNoteSection || isTakingNote) && isLiveEvent ? (
        <NoteSection
          isLiveEventNote={isLiveEventNote}
          isNavbarNote={isNavbarNote}
        >
          <NoteHeader isLiveEventNote={isLiveEventNote}>
            <h2>{t('Notes.Title', { count: notes?.length })}</h2>
            <Button
              id="private-note-close-button"
              onClick={() => setIsTakingNote(false)}
            >
              <CancelPresentation fontSize="large" />
            </Button>
          </NoteHeader>
          <Notes
            itemId={isCollectAllLevelNotes ? null : itemId}
            notes={notes}
            orderId={orderId}
            setIsTakingNote={setIsTakingNote}
            setOpenNoteSection={setOpenNoteSection}
            setNotesNumber={setNotesNumber}
            title=""
          />
        </NoteSection>
      ) : null}
      {(openNoteSection || isTakingNote) && !isLiveEvent ? (
        <>
          <NoteSection
            isLiveEventNote={isLiveEventNote}
            isNavbarNote={isNavbarNote}
          >
            <NoteHeader>
              <h2>{t('PrivateNote.AddPrivateNote')}</h2>

              <Button
                id="private-note-close-button"
                onClick={() => setIsTakingNote(false)}
              >
                <CancelPresentation fontSize="large" />
              </Button>
            </NoteHeader>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <>
                <b>
                  {t('History.ExceptionNotes')} ({notes?.length})
                </b>
              </>
              <ButtonLink
                id="see-all-notes-button-link"
                text={t('History.SeeAll')}
                onClick={handleOnOpen}
              />
              <Popover anchorEl={anchorEl}>
                <PopoverExceptionNotes
                  onClose={handleOnClose}
                  notes={formatNotes(notes, timeZone)}
                />
              </Popover>
            </Box>
            <Box sx={{ marginBottom: '10px' }}>
              <NoteList
                notes={formatNotes(notes, timeZone)}
                icon={<PhotoCamera />}
              />
            </Box>
            <PredefinedNotes
              onAdd={(note) =>
                handleOnAddPredefinedNote(note, newUserNote, setNewUserNote)
              }
              disabled={readOnly}
            />
            <div>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'stretch',
                  justifyContent: 'space-between',
                  component: 'span',
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <TextFieldFillHeight
                    id="add-note-textfield"
                    name="note"
                    fullWidth
                    variant="outlined"
                    label={t('Exception.Notes')}
                    onChange={({ target }) => setNewUserNote(target.value)}
                    value={newUserNote}
                    disabled={readOnly}
                    multiline
                    minRows={3}
                    maxRows={20}
                  />
                </Box>
                <Box
                  sx={{
                    whiteSpace: 'nowrap',
                    marginLeft: '5px',
                    marginTop: 'auto',
                  }}
                >
                  <InputButton
                    id="add-private-note-button"
                    dataTestId="add-private-note-button"
                    type="CRPrintLPN"
                    content={t('PrivateNote.AddNote')}
                    fullWidth
                    onClick={saveNote}
                    disabled={stringIsNullOrEmpty(newUserNote) || readOnly}
                  />
                </Box>
              </Box>
            </div>
          </NoteSection>
        </>
      ) : null}
      <ButtonGroup>
        {isDesktop && !isTabletLandscape && (
          <InputButton
            id="add-a-note-button"
            dataTestId="add-a-note-button"
            width="inherit"
            type="note"
            content={t('PrivateNote.AddANoteButton')}
            onClick={changeViewState}
          />
        )}
        <NoteIconButton id="add-a-note-icon-button" onClick={changeViewState}>
          <StyledBadge
            badgeContent={notes?.length || 0}
            color="primary"
            max={999}
          >
            <PostAddIcon fontSize="large" />
          </StyledBadge>
        </NoteIconButton>
      </ButtonGroup>
    </div>
  ) : null;
};

PrivateNote.defaultProps = {
  readOnly: false,
  timeZone: null,
  isLiveEventNote: false,
  openNoteSection: false,
  setOpenNoteSection: undefined,
  isNavbarNote: false,
  isFooterNote: false,
};
PrivateNote.propTypes = {
  readOnly: PropTypes.bool,
  timeZone: PropTypes.string,
  isLiveEventNote: PropTypes.bool,
  openNoteSection: PropTypes.bool,
  setOpenNoteSection: PropTypes.func,
  isNavbarNote: PropTypes.bool,
  isFooterNote: PropTypes.bool,
};

export default PrivateNote;

const NoteSection = styled.div`
  z-index: ${(props) => (props.isLiveEventNote ? '100' : 'auto')};
  border: 1px solid lightgray;
  border-top: ${(props) => {
    if (props.isLiveEventNote) return '';
    return '0.5em solid #333;';
  }}
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  position: absolute;
  ${(props) => {
    if (props.isLiveEventNote) return 'left: 75%;';
    return 'right: 1em;';
  }}
  ${(props) => {
    if (props.isLiveEventNote) return 'top: -50px;';
    if (props.isNavbarNote) return 'top: 100%;';
    return 'bottom: 90px;';
  }}
  margin: auto;
  padding: 20px;
  padding-top: 0px;
  width: ${(props) => {
    if (props.isLiveEventNote) return 'max-content';
    if (props.isNavbarNote) return '20%';
    return '33%';
  }};
  height: ${(props) =>
    props.isLiveEventNote ? 'fit-content;' : 'calc(0.5 * 100vh);'}
  background-color: #fff;
  gap: ${(props) => (props.isLiveEventNote ? '0px;' : '10px;')}
  flex-direction: column;
  ${(props) => {
    if (props.isLiveEventNote)
      return 'max-height: calc(100% + 105px) !important;';
    return 'max-height: calc(100vh - 200px) !important;';
  }}
  
  overflow-y: auto;
  & > .noteInput > * {
    font-size: 1.25em;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 75%;
  }
  @media only screen and (max-width: 768px) {
  ${(props) => {
    if (props.isLiveEventNote) return 'left: auto; right: 0px; max-width: 100%';
    return '';
  }}
    
  }
`;

const NoteHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CloseBTN = styled(Fab)`
  align-self: center;
  background-color: #fff !important;
  border: 1px solid lightgray !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  margin-left: 1em !important;
`;

const NoteIconButton = styled(Button)`
  border: 2px solid black !important;
  background-color: black !important;
  color: #fff !important;
  border-left: 0 !important;
  border-radius: 0 !important;
`;

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: 8,
    top: 10,
    'font-weight': '800',
  },
  '& .MuiBadge-colorPrimary': {
    'background-color': '#E53238',
  },
}));

export { NoteHeader, NoteSection, CloseBTN, NoteIconButton };
