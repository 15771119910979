import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  itemConditionPill: {
    width: 'fit-content',
    padding: '0 15px',
    borderRadius: '10px',
    fontWeight: 'bolder',
  },
});

export default useStyles;
