import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NoteForm } from '@codeboxxtechschool/ginza_shared_components';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import stringIsNullOrEmpty from '../../../utils/stringIsNullOrEmpty';
import makeError from '../../../utils/makeError';
import { CREATE_PRIVATE_NOTES } from '../../../graphql/mutations';
import { useStateValue } from '../../../providers/StateProvider';
import predefinedNotesConnectionGql from '../../../graphql/hooks/predefinedNotes/predefinedNotesConnectionGql';

const Notes = (props) => {
  const {
    itemId,
    notes,
    orderId,
    setIsTakingNote,
    setOpenNoteSection,
    setNotesNumber,
    title,
  } = props;
  const { t } = useTranslation();
  const [{ currentStationId, partnerLocation }, dispatch] = useStateValue();
  const [description, setDescription] = useState('');
  const [predefinedNoteList, setPredefinedNoteList] = useState([]);
  const [createPrivateNotes, { loading: loadingCreatePrivateNotes }] =
    useMutation(CREATE_PRIVATE_NOTES);

  const reset = () => {
    setDescription('');
  };

  const notesForNoteForm = notes.map((note) => {
    return {
      ...note,
      user: {
        firstName: note.firstName,
        lastName: note.lastName,
      },
    };
  });

  const { data, loading } = useQuery(predefinedNotesConnectionGql, {
    fetchPolicy: 'network-only',
    variables: {
      after: null,
      before: null,
      filters: {
        categoryId: partnerLocation.categoryId,
        partnerLocationId: partnerLocation.id,
        search: '',
      },
      first: 50,
      last: null,
      stationId: currentStationId || '',
    },
    onError: (error) => makeError(error, dispatch, t),
  });

  useEffect(() => {
    if (data?.predefinedNotes?.edges?.length > 0) {
      let list = [];
      list = data?.predefinedNotes.edges.map((predefinedNote) => {
        return predefinedNote?.node?.description;
      });
      setPredefinedNoteList(list);
    }
  }, [data, loading]);

  const handleAddNote = async () => {
    if (!stringIsNullOrEmpty(description)) {
      try {
        const response = await createPrivateNotes({
          variables: {
            itemId,
            note: description,
            orderId,
            stationId: currentStationId || '',
          },
        });
        if (response?.data?.createPrivateNotes) {
          setNotesNumber(notes?.length + 1);
          setIsTakingNote(false);
          setOpenNoteSection(false);
        }
        reset();
      } catch (err) {
        makeError(err, dispatch, t);
      }
    } else {
      dispatch({
        type: 'changePopup',
        newPopup: {
          type: 'error',
          title: t(`PopupContent.Title.EmptyNote`),
          message: t(`PopupContent.Error.NoNoteEntered`),
          btnMessage: t('ButtonContent.Close'),
        },
      });
    }
  };

  useEffect(() => {
    if (loadingCreatePrivateNotes)
      dispatch({ type: 'changeLoading', newLoading: true });
    else dispatch({ type: 'changeLoading', newLoading: false });
  }, [loadingCreatePrivateNotes]);

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleCancelNote = () => {
    setDescription('');
  };

  return (
    <NoteForm
      buttons={{
        add: {
          label: t('ButtonContent.Notes.AddButton'),
          onClick: handleAddNote,
          disabled: description.trim().length <= 0,
        },
        cancel: {
          label: t('ButtonContent.Notes.CancelButton'),
          onClick: handleCancelNote,
        },
      }}
      labels={{
        title,
      }}
      noteInput={{
        onChange: handleChangeDescription,
        label: t('Notes.EnterNote'),
      }}
      notes={notesForNoteForm}
      predefinedNotes={{
        notes: predefinedNoteList,
        onClickNote: (val) => setDescription(val),
        label: t('Notes.PredefinedNote'),
      }}
      value={description}
    />
  );
};

Notes.defaultProps = {
  itemId: null,
  notes: [],
  orderId: null,
  setIsTakingNote: undefined,
  setOpenNoteSection: undefined,
  setNotesNumber: undefined,
  title: '',
};

Notes.propTypes = {
  itemId: PropTypes.string,
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  orderId: PropTypes.string,
  setIsTakingNote: PropTypes.func,
  setOpenNoteSection: PropTypes.func,
  setNotesNumber: PropTypes.func,
  title: PropTypes.string,
};

export default Notes;
