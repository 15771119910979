import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckIcon from '@mui/icons-material/Check';
import { COLORS } from '@codeboxxtechschool/ginza_shared_components';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  bannerContainer: {
    height: '30px',
    borderRadius: '5px',
    border: (props) => `1px solid ${props.borderColor}`,
    backgroundColor: (props) => props.bgColor,
    display: 'flex',
    flexDirection: 'row',
    color: COLORS.black,
    padding: '8px 8px',
    alignItems: 'center',
    width: (props) => props.fullWidth && '100%',
  },
});

const NotificationBanner = ({ type, message, fullWidth }) => {
  const initBanner = (_type) => {
    switch (_type) {
      case 'error':
        return {
          icon: (
            <ErrorOutlineIcon
              fontSize="small"
              style={{
                color: COLORS.red400,
                marginRight: '8px',
                alignItems: 'center',
              }}
            />
          ),
          color: COLORS.red400,
          backgroundColor: COLORS.red100,
        };
      case 'success':
        return {
          icon: (
            <CheckIcon
              fontSize="small"
              style={{
                color: COLORS.green500,
                marginRight: '8px',
                alignItems: 'center',
              }}
            />
          ),
          color: COLORS.green500,
          backgroundColor: COLORS.green100,
        };
      default:
        return {
          icon: <></>,
          color: COLORS.black,
          backgroundColor: COLORS.white,
        };
    }
  };

  const banner = initBanner(type);
  const classes = useStyles({
    borderColor: banner.color,
    bgColor: banner.backgroundColor,
    fullWidth,
  });

  return (
    <div className={classes.bannerContainer}>
      {banner.icon}
      <Typography variant="body1">{message}</Typography>
    </div>
  );
};

export default NotificationBanner;

NotificationBanner.defaultProps = {
  type: '',
  message: '',
  fullWidth: false,
};

NotificationBanner.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  fullWidth: PropTypes.bool,
};
