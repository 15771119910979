import { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';

const PING_ADMIN = gql`
  query PingAdminStatus {
    ping {
      status
    }
  }
`;

export const usePingAdminStatusQuery = () => {
  const [status, setStatus] = useState(401);

  const { loading, data } = useQuery(PING_ADMIN, {
    context: {
      useApolloNetworkStatus: true,
      clientName: 'administration',
      response: true,
    },
  });

  useEffect(() => {
    if (loading || data === undefined || isEmpty(data) || !data?.ping?.status)
      return;
    setStatus(data.ping.status);
  }, [loading, data]);

  return {
    loading,
    status,
  };
};

export default usePingAdminStatusQuery;
