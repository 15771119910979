import React, { useEffect, Suspense, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core';
import { COLORS } from '@codeboxxtechschool/ginza_shared_components';
import NavBar from '../components/NavBar';
import BuildStation from '../components/BuildStation';
import { useStateValue } from '../providers/StateProvider';
import {
  CURRENT_PARTNER_LOCATION_CONFIGS,
  CURRENT_USER,
  QUERY_STATIONS,
} from '../graphql/queries';
import removeSpace from '../utils/removeSpace';
import StationSelectionContainer from '../components/reusables/selectors/StationSelectionContainer';
import Authentication from '../components/twoFactorAuthentication/authentication';
import PartnerLocationSelectionContainer from '../components/reusables/selectors/PartnerLocationSelectionContainer';
import PsnadDetailsPage from '../components/stations/PSNAD/PsnadDetailsPage';
import makeError from '../utils/makeError';

const useGinzaV2Styles = makeStyles({
  container: {
    overflow: 'inherit',
    position: 'relative',
    top: '80px',
    height: 'initial',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: COLORS.gray100,
  },
});

const ginzaV2Theme = createTheme({
  typography: {
    fontFamily: "'PT Sans', sans-serif",
    subtitle1: {
      fontStyle: 'italic',
      fontSize: 14,
      fontWeight: 400,
    },
    body1: {
      fontWeight: 400,
    },
  },
});

const Routes = () => {
  const [{ userStationList, authToken, partnerLocation }, dispatch] =
    useStateValue();
  const [statusCode, setStatusCode] = useState(0);
  const [partnerLocations, setPartnerLocations] = useState([]);
  const [resetStation, setResetStation] = useState(() => () => null);

  const [fetchCurrentPartnerLocationConfigs] = useLazyQuery(
    CURRENT_PARTNER_LOCATION_CONFIGS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ currentPartnerLocationConfigs }) => {
        sessionStorage.setItem(
          'partnerLocationConfigs',
          JSON.stringify({
            fingerprintMethods:
              currentPartnerLocationConfigs.fingerprintMethods,
            isTitleHeaderConfigOn:
              currentPartnerLocationConfigs.isTitleHeaderConfigOn,
            authenticityCardType:
              currentPartnerLocationConfigs.authenticityCardType,
            snadCardType:
              currentPartnerLocationConfigs.snadCardType,
          })
        );
        dispatch({
          type: 'changeCurrentPartnerLocationConfigs',
          currentPartnerLocationConfigs: {
            fingerprintMethods:
              currentPartnerLocationConfigs.fingerprintMethods,
            isTitleHeaderConfigOn:
              currentPartnerLocationConfigs.isTitleHeaderConfigOn,
            authenticityCardType:
              currentPartnerLocationConfigs.authenticityCardType,
            snadCardType:
              currentPartnerLocationConfigs.snadCardType,
          },
        });
      },
      onError: (error) => makeError(error, dispatch, t),
    }
  );

  const classes = useGinzaV2Styles();
  const { loading, error, data, refetch } = useQuery(CURRENT_USER, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  const {
    loading: loadingStations,
    error: errorStations,
    data: dataStationList,
  } = useQuery(QUERY_STATIONS);

  const removeAuthToken = () => {
    sessionStorage.clear();
    dispatch({ type: 'changeAuthToken', newAuthToken: null });
    dispatch({
      type: 'changeCurrentPartnerLocationConfigs',
      currentPartnerLocationConfigs: {},
    });
    dispatch({
      type: 'changePartnerLocation',
      newPartnerLocation: {
        id: null,
        name: null,
        lpnType: null,
      },
    });
  };

  useEffect(() => {
    if (partnerLocation?.id)
      fetchCurrentPartnerLocationConfigs({
        variables: { partnerLocationId: partnerLocation.id },
      });
  }, [partnerLocation?.id])

  useEffect(() => {
    if (data || error) refetch();
  }, [authToken]);

  useEffect(() => {
    if (statusCode) {
      // Unauthorized
      if (statusCode === 401 || statusCode === 500) {
        removeAuthToken();
      }
    }
  }, [statusCode]);

  useEffect(() => {
    if (data) {
      if (data.currentUser?.id) {
        dispatch({
          type: 'changeCurrentUserId',
          newCurrentUserId: data.currentUser.id,
        });
      }

      const roles = data?.currentUser?.roles.map((role) => role.name) || [];
      dispatch({
        type: 'changeRoleList',
        newRoleList: roles,
      });

      if (data?.currentUser?.partnerLocations?.length > 0) {
        setPartnerLocations(data.currentUser.partnerLocations);
        dispatch({
          type: 'changePartnerLocations',
          newPartnerLocations: data.currentUser.partnerLocations,
        });
      }

      // TODO: Put this section in a reusable

      const userPartnerStations =
        partnerLocation.userPartnerLocationStations || [];
      dispatch({
        type: 'changeUserStationList',
        newUserStationList: userPartnerStations.map((station) => ({
          id: station.id,
          name: removeSpace(station.name),
          customEndpoint: station.customEndpoint,
          isExternalUrl: station.isExternalUrl,
        })),
      });

      if (data?.currentUser?.categories?.length > 0) {
        dispatch({
          type: 'changeCategories',
          newCategories: data.currentUser.categories,
        });
      }

      if (data.currentUser?.userIdentification) {
        dispatch({
          type: 'changeUserIdentification',
          newUserIdentification: data.currentUser.userIdentification,
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (dataStationList?.queryStations) {
      const stations = dataStationList?.queryStations || [];
      dispatch({
        type: 'changeStationList',
        newStationList: stations.map((station) => ({
          id: station.id,
          value: removeSpace(station.name),
          displayOrder: station.displayOrder,
        })),
      });
    }
  }, [dataStationList]);

  useEffect(() => {
    if (error || errorStations)
      if (error?.networkError?.statusCode !== undefined)
        setStatusCode(error.networkError.statusCode);
      else if (errorStations?.networkError?.statusCode !== undefined)
        setStatusCode(errorStations.networkError.statusCode);
  }, [error]);

  if (loading || loadingStations) return <LinearProgress />;
  if (userStationList !== null)
    return (
      <Router>
        <NavBar
          partnerLocations={partnerLocations}
          resetStation={resetStation}
        />

        <Switch>
          <Route path="/authentication" render={() => <Authentication />} />
          <Route
            path="/stations/:id/"
            exact
            render={() => (
              <BuildStation
                resetStation={resetStation}
                setResetStation={setResetStation}
              />
            )}
          />
          <Route
            path="/stations/:id/psnad/:objectExceptionId"
            exact
            render={() => (
              <ThemeProvider theme={ginzaV2Theme}>
                <Suspense fallback={<LinearProgress />}>
                  <div className={classes.container}>
                    <PsnadDetailsPage />
                  </div>
                </Suspense>
              </ThemeProvider>
            )}
          />
          <Route>
            {partnerLocation.id && partnerLocation.name ? (
              <StationSelectionContainer />
            ) : (
              <PartnerLocationSelectionContainer
                partnerLocations={partnerLocations}
                fetchCurrentPartnerLocationConfigs={
                  fetchCurrentPartnerLocationConfigs
                }
              />
            )}
          </Route>
        </Switch>
      </Router>
    );
  return null;
};

export default Routes;
