import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { StyledRow, ImageBox } from './index.styles';
import PageHeader from './PageHeader';
import ImageContainer from '../../steps/DownloadAndInstall/ImageContainer';
import CenteredContainer from '../../../reusables/centeredContainer';

import applicationImage1 from './img/group-3.png';
import applicationImage2 from './img/group-3@2x.png';
import applicationImage3 from './img/group-3@3x.png';
import { ROUTES } from "../../constants";

const ConfirmPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const onResetCodesBtnClick = () => {
    // eslint-disable-next-line no-console
    console.log('2FA reset codes button is clicked!');
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.push(
        `${ROUTES.administration}`
      );
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div spacing={1}>
      <CenteredContainer>
        <StyledRow>
          <ImageBox>
            <ImageContainer
              width="-webkit-fill-available"
              height="284px"
              alt="confirm-ga-success"
              src={applicationImage1}
              srcSet={`${applicationImage2} 2x, ${applicationImage3} 3x`}
            />
          </ImageBox>
        </StyledRow>
        <StyledRow>
          <PageHeader
            title={t('account.security.2fa.confirmPage.title')}
            content={t('account.security.2fa.confirmPage.description')}
          />
        </StyledRow>
        {/* <ButtonContainer> */}
        {/*  <ResetCodesButton onClick={onResetCodesBtnClick} width="260px" /> */}
        {/* </ButtonContainer> */}
      </CenteredContainer>
    </div>
  );
};

export default ConfirmPage;
