import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const InputSelect = (props) => {
  const {
    defaultOption,
    defaultDisabled,
    name,
    label,
    options,
    defaultValue,
    updateValue,
    fullWidth,
    setValue,
    value,
    size,
    margin,
    error,
    dataTestId,
    isRequired,
  } = props;
  const [refValue, setRefValue] = useState(defaultValue || '');
  const selectRef = useRef();

  const handleChange = () => {
    setRefValue(selectRef.current.value);
    updateValue((prev) => {
      const newValue = { ...prev };
      newValue[name] = selectRef.current.value || null;
      return newValue;
    });
    setValue(selectRef.current.value);
  };

  useEffect(() => handleChange(), []);
  useEffect(() => handleChange(), [options]);
  useEffect(() => value && setRefValue(value), [value]);
  useEffect(() => setRefValue(defaultValue), [defaultValue]);

  return (
    <TextField
      data-testid={dataTestId}
      size={size}
      select
      SelectProps={{
        native: true,
        value: refValue,
        style: { maxHeight: '50px' },
      }}
      inputRef={selectRef}
      margin={margin}
      label={label || name}
      variant="outlined"
      error={!!error}
      helperText={!!error && error}
      name={name}
      id={`${name}-select-input`.replace(/\s/g, '')}
      onChange={handleChange}
      fullWidth={fullWidth}
      required={isRequired}
      InputLabelProps={{ shrink: true }}
    >
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      {defaultOption && <option value="" disabled={defaultDisabled} />}
      {options.map((opt) => (
        <option data-testid="select-option" value={opt.id} key={opt.id}>
          {opt.value}
        </option>
      ))}
    </TextField>
  );
};

export default InputSelect;

InputSelect.defaultProps = {
  label: null,
  fullWidth: false,
  defaultValue: '',
  value: null,
  size: 'medium',
  margin: 'none',
  defaultOption: false,
  defaultDisabled: false,
  updateValue: () => null,
  setValue: () => null,
  error: '',
  dataTestId: '',
  isRequired: false,
};

InputSelect.propTypes = {
  defaultOption: PropTypes.bool,
  defaultDisabled: PropTypes.bool,
  margin: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  updateValue: PropTypes.func,
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  error: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  dataTestId: PropTypes.string,
  isRequired: PropTypes.bool,
};
