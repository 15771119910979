import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Popper from '@material-ui/core/Popper';

export const StyledGrid = styled(Grid)`
  position: relative;
  height: 100%;
`;
StyledGrid.displayName = 'StyledGrid';

export const LabelGrid = styled(Grid)`
  color: white;
  @media only screen and (max-width: 1669px) {
    display: none;
  }
  @media only screen and (min-width: 1670px) {
    font-size: 8px;
  }
  @media only screen and (min-width: 1920px) {
    font-size: 9px;
  }
`;
LabelGrid.displayName = 'LabelGrid';

export const Container = styled.div`
  background-color: ${({ bgcolor }) => bgcolor} !important;
  align-items: center;
  width: 100%;
  height: 45px;
  border: none;
  border: solid 2px white;
  border-radius: 0 200px 200px 0 !important;
  justify-content: space-between;
`;
Container.displayName = 'Container';

export const StationNameGrid = styled(Grid)`
  display: flex;
  align-items: center;
  height: 24px;
  font-size: 9px;
  font-weight: bold;
  text-transform: uppercase;
  @media only screen and (min-width: 800px) {
    font-size: 8px;
  }
  @media only screen and (min-width: 1670px) {
    font-size: 9px;
  }
  @media only screen and (min-width: 1920px) {
    font-size: 10px;
  }
`;
StationNameGrid.displayName = 'StationNameGrid';

export const IconGrid = styled(Grid)`
  position: relative;
  .wrapperWarning {
    width: 30px;
    height: 30px;
    top: 50%;
    right: 8px;
    position: absolute;
    transform: translate(0, -50%);
  }
  .wrapperWarning .exceptionCounter {
    position: absolute;
    width: 1.3em !important;
    text-align: center;
    right: 14px;
    top: 4px;
    transform: translate(50%, -50%);
    z-index: 10;
    opacity: 90%;
    border-radius: 50%;
    font-size: 0.9em;
    font-weight: bold;
    color: white;
    background-color: #d60000;
  }
  .wrapperWarning .svg12 {
    right: 10px;
  }
  .wrapperWarning .svg1 {
    right: -18px;
  }
  svg {
    display: block !important;
    right: 8px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 30px !important;
    height: 30px !important;
    fill: white !important;
    background-color: transparent !important;
  }
`;
IconGrid.displayName = 'IconGrid';

export const StyledPopper = styled(Popper)`
  padding: 8px;
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  color: white;
  opacity: 0.7;
  width: fit-content;
`;
StyledPopper.displayName = 'StyledPopper';
