import { useQuery } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QUERY_STATION } from '../../../../graphql/queries';
import makeError from '../../../../utils/makeError';
import { useStateValue } from '../../../../providers/StateProvider';
import { QualityControlContext } from '../QualityControlContext';
import { getStationIdByName } from '../../../../utils/stationsInfo';

/**
 * @param { withInputValues }
 * @returns {{data: any, refetch: (variables?: Partial<OperationVariables>) => Promise<ApolloQueryResult<any>>, loading: boolean, error: ApolloError}}
 */
const useQueryStation = (
  { withInputValues = true } = { withInputValues: true }
) => {
  const [{ stationList }, d] = useStateValue();
  const { t } = useTranslation();
  const { reinitialize } = useContext(QualityControlContext);
  const currentPartnerLocation = JSON.parse(
    sessionStorage.getItem('partnerLocation')
  );
  const stationId = parseInt(sessionStorage.getItem('stationId'), 10);
  const psnadStationId = parseInt(getStationIdByName(stationList, 'PSNAD'), 10);
  const qcStationId = parseInt(
    getStationIdByName(stationList, 'QualityControl'),
    10
  );

  const { loading, data, error, refetch } = useQuery(QUERY_STATION, {
    variables: {
      id: stationId === psnadStationId ? qcStationId : stationId,
      forQcAuthStation: true,
      forPackStation: false,
      partnerLocationId: currentPartnerLocation?.id,
      categoryId: currentPartnerLocation?.categoryId,
      isActive: true,
      withInputValues,
      withoutInputValues: !withInputValues,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (error) {
      makeError(error, d, t);

      // useQueryStation is also used outside of QualityControlContext: PsnadDetailsPage
      if (reinitialize) reinitialize();
    }
  }, [error]);

  return { loading, data, error, refetch };
};

export default useQueryStation;
