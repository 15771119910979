import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useStateValue } from '../../../../providers/StateProvider';
import { IMS_ENABLED_FLAG } from '../../../../graphql/queries';

export default function useImsLocations() {
  const [{ partnerLocation }] = useStateValue();
  const [imsEnabled, setImsEnabled] = useState(false);

  const { loading, data, error } = useQuery(IMS_ENABLED_FLAG, {
    variables: { partnerLocationId: partnerLocation.id },
  });

  useEffect(() => {
    if (!loading && !error && data) {
      setImsEnabled(data.imsEnabledFlag);
    }
  }, [loading, data, error]);

  return { loading, imsEnabled };
}
