import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import predefinedNotesConnectionGql from './predefinedNotesConnectionGql';
import makeError from '../../../utils/makeError';
import { useStateValue } from '../../../providers/StateProvider';

const usePredefinedNotesConnection = ({ filters }) => {
  const dispatch = useStateValue()[1];
  const { t } = useTranslation();
  const [stationId] = useState(sessionStorage.getItem('stationId'));

  const [fetch, { data, loading }] = useLazyQuery(
    predefinedNotesConnectionGql,
    {
      fetchPolicy: 'network-only',
      variables: {
        first: filters.first,
        last: filters.last,
        after: filters.after,
        before: filters.before,
        filters: {
          categoryId: filters.categoryId === '-1' ? null : filters.categoryId,
          partnerLocationId:
            filters.partnerLocationId === '-1'
              ? null
              : filters.partnerLocationId,
          search: filters.search,
        },
        stationId: stationId || '',
      },
      onError: (error) => makeError(error, dispatch, t),
    }
  );

  useEffect(() => {
    if (data === undefined) {
      fetch();
    }
  }, [data]);

  return { data, loading, fetch };
};

export default usePredefinedNotesConnection;
