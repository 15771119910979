import React from 'react';
import { useSearchParams } from '../hooks/useSearchParams';
import CenteredContainer from '../../reusables/centeredContainer';
import SetupPage from '../googleAuthenticator/SetupPage';
import ConfirmPage from '../googleAuthenticator/ConfirmPage';

const Security = () => {
  const searchParams = useSearchParams();
  const { redirectUrl } = searchParams;

  return (
    <CenteredContainer>
      <div>
        {redirectUrl === 'pSuccess' && <ConfirmPage />}
        {!redirectUrl && <SetupPage />}
      </div>
    </CenteredContainer>
  );
};

export default Security;
