import ReactLoading from 'react-loading';
import React from 'react';
import PropTypes from 'prop-types';
import { ReactLoadingWrapper } from './index.styles';

const Loading = ({ color, className, width, height }) => {
  return (
    <ReactLoadingWrapper data-testid="spin-loading">
      <ReactLoading
        type="spin"
        color={color}
        width={width}
        height={height}
        className={className}
      />
    </ReactLoadingWrapper>
  );
};

Loading.defaultProps = {
  color: '#868686',
  className: '',
  width: '64px',
  height: '64px',
};

Loading.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default Loading;
