import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './useStyles';

const SectionContainer = ({ title, children }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.descriptionTitleContainer}>
        <Typography variant="h6" className={classes.descriptionTitle}>
          {title}
        </Typography>
      </div>
      <div className={classes.contentContainer}>{children}</div>
    </>
  );
};

export default SectionContainer;

SectionContainer.defaultProps = {
  title: '',
  children: <></>,
};

SectionContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};
