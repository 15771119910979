import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useStyles from './useStyles';

const MultiLevelList = ({ list }) => {
  const classes = useStyles();

  const renderSubList = (listItem, index, isTopList) => {
    if (!listItem) return null;

    return (
      <li
        id={listItem.id}
        key={listItem.id}
        className={classes.li}
        data-testid={listItem.id}
      >
        <div className={isTopList ? classes.topList : classes.childList}>
          <Typography
            className={isTopList ? classes.topListText : ''}
            variant="body1"
          >
            {listItem.name}
          </Typography>
        </div>
        {listItem.subList && listItem.subList.length > 0 && (
          <ul className={classes.ul}>
            {listItem.subList.map((subItem, i) =>
              renderSubList(subItem, i, false)
            )}
          </ul>
        )}
      </li>
    );
  };

  return (
    <ul className={classes.ul} style={{ padding: '0' }}>
      {list.map((el, index) => renderSubList(el, index, true))}
    </ul>
  );
};

export default MultiLevelList;

const listShape = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

listShape.subList = PropTypes.arrayOf(PropTypes.shape(listShape));

MultiLevelList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape(listShape)).isRequired,
};
