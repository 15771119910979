export const ApiParameters = {
  hubIdentifier: ':hubIdentifier',
  exceptionId: ':exceptionId',
  evtn: ':evtn',
};

export const IMS_API_PATH = process.env.ImsApi;
export const STORAGE_LOCATION_PATH = `${IMS_API_PATH}/storage`;
export const STORAGE_PATH = `${IMS_API_PATH}/storage/exceptions`;
export const REMOVE_LOCATIONS_PATH = `${IMS_API_PATH}/remove-locations/${ApiParameters.exceptionId}`;
export const CREATE_SHELL_ITEM_PATH = `${IMS_API_PATH}/shell-item/${ApiParameters.hubIdentifier}`;
export const ADVANCED_FILTERS_PATH = `${IMS_API_PATH}/advanced-filters`;
export const FULFILLMENT_ORDERS_PATH = `${IMS_API_PATH}/fulfillment-orders/seller/${ApiParameters.evtn}`;
export const GET_PENDING_PICKS_PATH = `${IMS_API_PATH}/fulfillment-orders/pending-picks`;
