import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  descriptionTitleContainer: {
    height: '48px',
    color: 'white',
    backgroundColor: '#7C858E',
    borderRadius: '5px',
  },
  descriptionTitle: {
    marginLeft: '16px',
    lineHeight: '2.5',
  },
  contentContainer: {
    padding: '0 16px',
  },
});

export default useStyles;
