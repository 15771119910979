import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '@codeboxxtechschool/ginza_shared_components';

const useStyles = makeStyles({
  topList: {
    borderBottom: `3px ${COLORS.gray100} solid`,
    padding: '4px',
  },
  topListText: {
    fontWeight: 'bolder',
  },
  childList: {
    borderBottom: `1px ${COLORS.gray100} solid`,
    padding: '4px 0',
  },
  listItem: {
    padding: '4px 0',
  },
  ul: {
    padding: '8px 16px',
    marginTop: '0',
  },
  li: {
    listStyleType: 'none',
  },
});

export default useStyles;
