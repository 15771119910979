import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import IconCard from './IconCard';

import ginzaLogo from '../../../assets/images/ginza_logo.svg';

const IconSelection = (props) => {
  const {
    logo,
    title,
    onClick,
    itemList,
    iconSize,
    noShadow,
    selectedId,
    justCont,
    sortAlphabetically,
  } = props;

  if (itemList.length > 0)
    return (
      <IconSelectionContainerStyled>
        {logo ? (
          <GinzaAuthLogo
            src={ginzaLogo}
            alt="ginza logo"
            data-testid="ginzaLogoStation"
          />
        ) : null}
        {title !== '' ? <TitleHeader>{title}</TitleHeader> : null}
        <IconItemsContainer justCont={justCont}>
          {itemList
            .sort(
              sortAlphabetically
                ? (a, b) => a.name.localeCompare(b.name)
                : () => 0
            )
            .map((item) => (
              <IconCard
                selected={selectedId === item.id}
                noShadow={noShadow}
                iconSize={iconSize}
                onClick={selectedId === item.id ? () => null : onClick}
                key={item.id}
                item={item}
              />
            ))}
        </IconItemsContainer>
      </IconSelectionContainerStyled>
    );
  return null;
};

export default IconSelection;

IconSelection.defaultProps = {
  iconSize: 6,
  logo: false,
  title: '',
  onClick: null,
  noShadow: false,
  selectedId: '',
  justCont: 'center',
  sortAlphabetically: false,
};

IconSelection.propTypes = {
  sortAlphabetically: PropTypes.bool,
  selectedId: PropTypes.string,
  noShadow: PropTypes.bool,
  iconSize: PropTypes.number,
  logo: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
  justCont: PropTypes.string,
  itemList: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      alert: PropTypes.bool,
    })
  ).isRequired,
};

// Style
const IconSelectionContainerStyled = styled.div`
  overflow: auto;
  height: fit-content;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconItemsContainer = styled.div`
  display: grid;
  justify-content: center;
  place-items: center;
  grid-template-columns: repeat(6, 1fr);
  @media only screen and (max-width: 992px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const GinzaAuthLogo = styled.img`
  margin: 1em;
  margin-top: 2em;
  max-width: 280px;
  height: auto;
`;

export const TitleHeader = styled.p`
  text-align: center;
  margin-top: 0.5em;
  margin-bottom: 0;
  font-size: 1.7em;
  @media only screen and (max-width: 442px) {
    font-size: 1.25em;
  }
`;
