import { PSNAD_STATUSES, PSNAD_RESOLVE_TYPE } from '../../../../constants';

const initItemData = {
  itemId: '',
  orderId: '',
  lpn: '',
  evtn: '',
  title: '',
  condition: '',
  url: '',
  attributes: [],
  imageUrls: [],
};

const PsnadAutomationService = (
  exceptionData,
  stationData,
  imsLocationIdentifier
) => {
  const flagValue = exceptionData?.flagValue;
  const exceptionOrder = exceptionData?.order;
  const exception = exceptionOrder?.items?.find(
    (item) => item.lpn.lpn === flagValue
  );

  // PSNAD METADATA
  const psnad = () => [
    {
      name: 'PSNAD.PsnadDetails.ExceptionReason',
      value: exceptionData?.exceptionCode?.displayName,
    },
    {
      name: 'PSNAD.PsnadDetails.ExceptionNote',
      value: exceptionData?.notes[0]?.description,
    },
    {
      name: 'PSNAD.PsnadDetails.PsnadStatus',
      value: exceptionData?.psnadException.status,
    },
    {
      name: 'PSNAD.PsnadDetails.DaysSinceReported',
      value: exceptionData?.psnadException.daysSinceReported.toString(),
    },
    {
      name: 'PSNAD.PsnadDetails.ReportedTime',
      value: exceptionData?.psnadException.createdAt,
    },
    {
      name: 'PSNAD.PsnadDetails.ReportedBy',
      value: exceptionData?.createdBy?.userIdentification,
    },
    {
      name: 'PSNAD.PsnadDetails.UserRespondedTime',
      value: exceptionData?.psnadException.respondedAt,
    },
    {
      name: 'PSNAD.PsnadDetails.RespondedBy',
      value: exceptionData?.psnadException.respondedBy,
    },
    {
      name: 'PSNAD.PsnadDetails.SubmittedTime',
      value: exceptionData?.psnadException.submittedAt,
    },
    {
      name: 'PSNAD.PsnadDetails.SubmittedBy',
      value: exceptionData?.psnadException.submittedBy,
    },
    {
      name: imsLocationIdentifier
        ? 'PSNAD.PsnadDetails.StorageLocation'
        : 'PSNAD.PsnadDetails.Location',
      value:
        imsLocationIdentifier || exceptionData?.actualLocations[0]?.identifier,
    },
  ];

  const item = () => {
    if (exception) {
      const { evtn } = exceptionOrder;

      const { lpn } = exception.lpn;
      const itemId = exception.id;
      const { orderId } = exception;
      const {
        itemAttributes: attributes,
        itemUrl: url,
        title,
        itemCondition: condition,
      } = exception.ebayItem;
      const imageUrls = exception.allItemImages
        .filter((img) => !img.isPsnad)
        .map((img) => img.imageUrl);

      return {
        itemId,
        lpn,
        evtn,
        title,
        condition,
        url,
        attributes,
        imageUrls,
        orderId,
      };
    }
    return initItemData;
  };

  // PSNAD FORM FUNCTIONALITY
  const getSelectedInputIds = (inputs) => {
    return inputs.reduce((output, input) => {
      if (input.isChecked && input.inputType === 'psnad') {
        output.push(input.inputId);
      } else if (input.childInputs.length > 0) {
        const childInputs = getSelectedInputIds(input.childInputs);
        if (childInputs.length > 0) output.push(...childInputs);
      }
      return output;
    }, []);
  };

  const selectOptionsByInputIds = (allPsnad, inputIds) => {
    return allPsnad.map((_psnad) => {
      if (inputIds.includes(_psnad.inputId)) {
        return { ..._psnad, isChecked: true };
      }
      if (_psnad.childInputs) {
        const hasSelectedChild = _psnad.childInputs.some((el) =>
          inputIds.includes(el.inputId)
        );

        return {
          ..._psnad,
          isChecked: hasSelectedChild,
          childInputs: selectOptionsByInputIds(_psnad.childInputs, inputIds),
        };
      }
      return _psnad;
    });
  };

  const selectOptionsBySnadCodes = (allInputs, snadCodes) => {
    return allInputs.map((snad) => ({
      ...snad,
      childInputs: snad.childInputs.map((childInput) => {
        if (!childInput.snadCode) return { ...childInput };
        return {
          ...childInput,
          isChecked: snadCodes.includes(childInput.snadCode),
        };
      }),
    }));
  };

  const selectOptionsBySnadTitle = (allInputs, titles) => {
    return allInputs.map((snad) => ({
      ...snad,
      childInputs: snad.childInputs.map((childInput) => {
        if (childInput.snadCode) return { ...childInput };
        return {
          ...childInput,
          isChecked: titles.includes(childInput.title),
        };
      }),
    }));
  };

  const transformToFormList = (output, snad) => {
    const filterSecondLevel = snad.hasPsnadValue;
    const filterTopLevel = !snad.parentId;

    // Refers to SNAD with 1 PSNAD matching in title and/or snad-code
    const isOneToOneSnadPsnad =
      snad.childInputs.length === 1 &&
      snad.childInputs[0].inputType === 'psnad' &&
      snad.inputType === 'snad' &&
      (snad.childInputs[0].title === snad.title ||
        snad.childInputs[0].snadCode === snad.snadCode);

    const isPsnadPhotoRequired = snad.childInputs[0]?.isPhotoRequired;

    if (filterSecondLevel || filterTopLevel) {
      // When relationship is 1-to-1 between SNAD & PSNAD, we use the PSNAD as parent without childInputs
      // when relationship is 1-to-Many between SNAD & PSNADs, we use the SNAD as the parent, and PSNAD as the childInputs
      const psnadInput = isOneToOneSnadPsnad ? snad.childInputs[0] : snad;

      return output.concat({
        inputId: psnadInput.id,
        parentId: psnadInput.parentId,
        hasPsnadValue: psnadInput.hasPsnadValue,
        snadCode: psnadInput.snadCode,
        inputType: psnadInput.inputType,
        title: psnadInput.title,
        label: psnadInput.title,
        isPhotoRequired: isPsnadPhotoRequired,
        childInputs: isOneToOneSnadPsnad
          ? []
          : snad.childInputs.reduce(transformToFormList, []),
        isChecked: false,
      });
    }

    return output;
  };

  const parseQueryStationInputs = (inputs) => {
    // Logic to parse queryStation response into snad-psnad submission form
    return inputs
      .reduce(transformToFormList, [])
      .filter(
        (topLevel) =>
          topLevel.inputType === 'snad' && topLevel.childInputs.length > 0
      );
  };

  const getPsnadList = () => {
    const initialList = parseQueryStationInputs(stationData.inputs);

    const psnadStationItemAnswer =
      exception.psnadStationItem?.stationItemAnswers;
    const qcStationItemAnswer = exception.qcStationItem?.stationItemAnswers;

    if (psnadStationItemAnswer) {
      // autofill list with psnadStationItemAnswer
      const failedPsnadInputIds = psnadStationItemAnswer.map(
        (sia) => sia.input.id
      );
      return selectOptionsByInputIds(initialList, failedPsnadInputIds);
    }

    if (qcStationItemAnswer) {
      // autofill list with qcStationItemAnswer
      // For Customized SNADs without a snadCode
      const titles = qcStationItemAnswer
        .filter((sia) => sia.grade === 'fail' && !sia.input.snadCode)
        .map((sia) => sia.input.title);

      // For all other SNADs with a snadCode
      const snadCodes = qcStationItemAnswer
        .filter((sia) => sia.grade === 'fail')
        .map((sia) => sia.input.snadCode)
        .filter((snadCode) => snadCode); // remove top_level inputs with no snad_codes

      const checkedTitleList = selectOptionsBySnadTitle(initialList, titles);
      return selectOptionsBySnadCodes(checkedTitleList, snadCodes);
    }

    return initialList;
  };

  const getSelectedPsnadList = () => {
    const psnadForm = getPsnadList();

    const subList = (inputs) =>
      inputs
        .filter((input) => input.isChecked)
        .map((input) => ({
          id: input.inputId,
          name: input.title,
          subList: subList(input.childInputs),
        }));

    return psnadForm
      .map((topLevel) => ({
        id: topLevel.inputId,
        name: topLevel.title,
        subList: subList(topLevel.childInputs),
      }))
      .filter((topLevel) => topLevel.subList.length > 0);
  };

  // PSNAD RESOLVER FUNCTIONALITY
  const getPredefinedNote = (psnadStatus) => {
    const predefinedNotes = {
      cancelled: ['Cancelled order'],
      accepted: ['Item accepted, please pass authentication'],
      rejected: ['Item rejected, please SNAD'],
      none: ['No action required'],
    };

    return predefinedNotes[psnadStatus];
  };

  const getResolverBtnText = (psnadStatus) => {
    const btnText = {
      cancelled: 'Submit',
      accepted: 'Submit',
      rejected: 'Submit',
      none: 'SendToQc',
    };

    return btnText[psnadStatus];
  };

  const getResolutionAction = (psnadStatus) => {
    const resolutionActions = {
      cancelled: {
        key: PSNAD_RESOLVE_TYPE.cancelled,
        value: 'SendToPackAndShip',
      },
      accepted: { key: PSNAD_RESOLVE_TYPE.accepted, value: 'SendToQc' },
      rejected: { key: PSNAD_RESOLVE_TYPE.rejected, value: 'SendToQc' },
      none: { key: PSNAD_RESOLVE_TYPE.noActionRequired, value: 'SendToQc' },
    };

    return resolutionActions[psnadStatus];
  };

  const resolver = (resolveType) => {
    // NOT_ELIGIBLE will be equivalent to CANCELLED
    const key =
      resolveType === PSNAD_STATUSES.notEligible
        ? PSNAD_STATUSES.cancelled.toLowerCase()
        : resolveType.toLowerCase();

    const titleText = {
      cancelled: 'CancelledOrder',
      accepted: 'ResolvePsnad',
      rejected: 'ResolvePsnad',
      none: 'SendToQc',
    };

    return {
      title: titleText[key],
      resolverBtnTxt: getResolverBtnText(key),
      resolutionOption: getResolutionAction(key),
      predefinedNotes: getPredefinedNote(key),
    };
  };

  return {
    form: {
      getSelectedInputIds,
      getPsnadList,
      getSelectedPsnadList,
    },
    metadata: { psnad, item },
    itemException: exception,
    resolver,
  };
};

export { initItemData, PsnadAutomationService };
