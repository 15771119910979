import React from 'react';
import { formatInTimeZone } from 'date-fns-tz';

const formatNotes = (exceptionNotes, timeZone) => {
  let notesToSet = [...exceptionNotes];
  const session = JSON.parse(sessionStorage.getItem('partnerLocation'));
  notesToSet = notesToSet.map((noteToSet) => {
    return {
      ...noteToSet,
      createdAt: formatInTimeZone(
        noteToSet.createdAt,
        timeZone ?? session.timeZone,
        'yyyy-MM-dd HH:mm:ss'
      ),
      user: { firstName: noteToSet.firstName, lastName: noteToSet.lastName },
      hasAttachments: noteToSet.filesInfo.length > 0,
      attachments:
        noteToSet.filesInfo.length > 0
          ? noteToSet.filesInfo.map((files) => {
              return {
                file: (
                  <a href={files.url} target="_blank" rel="noreferrer">
                    {files.filename}
                  </a>
                ),
              };
            })
          : false,
    };
  });
  return notesToSet;
};
export default formatNotes;
