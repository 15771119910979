import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getUserInfo from '../api/rest/userInfo';
import { useStateValue } from '../providers/StateProvider';

/**
 * Fulfillment enabled is true if the user has access to Fulfillment Pick station for the current hub
 */
export default function useFulfillmentEnabled() {
  const [fulfillmentEnabled, setFulfillmentEnabled] = useState(false);
  const [{ partnerLocation }] = useStateValue();

  const result = useQuery({
    queryKey: ['user'],
    queryFn: () => getUserInfo(),
  });

  useEffect(() => {
    if (!result.isLoading && result.isSuccess) {
      const {
        data: { data },
      } = result;

      if (data) {
        const currentHub = data.hubs.find(
          (hub) => hub.hubId === partnerLocation.hubId
        );
        setFulfillmentEnabled(
          currentHub ? currentHub.stations.includes('Fulfillment Pick') : false
        );
      }
    }
  }, [result, partnerLocation.hubId]);

  return { fulfillmentEnabled };
}
