import styled from 'styled-components';
import { COLORS } from '@codeboxxtechschool/ginza_shared_components';

export const StepNumber = styled('div')({
  width: '60px',
  padding: '13px 35px 12px',
  borderRadius: '23px',
  border: `solid 1px ${COLORS.mediumGray}`,
  backgroundColor: COLORS.blue,
  color: 'white',
  textAlign: 'center',
  margin: 'auto',
  position: 'relative',
});

export const StepWrapper = styled('div')({
  maxWidth: '485px',
  height: '425px',
  margin: '-22.4px 30px 0 0',
  padding: '45px 25px 71px',
  borderRadius: '4px',
  border: `solid 1px ${COLORS.mediumGray}`,
});
