/* eslint-disable react/prop-types */
import React from 'react';
import {
  SecretCodeWrapper,
  SecretCodeValue,
  SecretCodeTitle,
} from './SecretCodeContainer.styles';

const defaultProps = {
  title: '',
  value: '',
};

const SecretCodeContainer = ({ title, value }) => {
  return (
    <SecretCodeWrapper>
      <SecretCodeValue>
        <SecretCodeTitle>{title}:</SecretCodeTitle>
        {value}
      </SecretCodeValue>
    </SecretCodeWrapper>
  );
};

SecretCodeContainer.defaultProps = defaultProps;

export default SecretCodeContainer;
