import styled from 'styled-components';

export const PageHeaderContent = styled('span')({
  width: '100%',
  fontSize: '18px',
  margin: '0 0 27.2px',
  textAlign: 'center',
  display: 'inline-block',
});

export const PageHeaderTitle = styled('span')({
  fontSize: '24px',
  fontWeight: 'bold',
});
