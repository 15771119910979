import React from 'react';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import { CloseIcon } from '../StyledComponent';
import {
  WrapperBodyContainer,
  WrapperHeaderContainer,
  WrapperSlideContainer,
  StyledIconButton,
} from './index.styles';
import ImageCarousel from './ImageCarousel';

const ImageCarouselLightbox = ({
  images,
  onLightboxClose,
  openLightbox,
  selectedImage,
  setSelectedImage,
}) => {
  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: 'rgba(0,0,0,0.9)',
        },
      }}
      open={openLightbox}
      fullScreen
    >
      <WrapperHeaderContainer>
        <StyledIconButton
          id="close-button-icon"
          edge="start"
          color="inherit"
          onClick={() => onLightboxClose()}
          aria-label="close"
          size="small"
        >
          <CloseIcon />
        </StyledIconButton>
      </WrapperHeaderContainer>
      <WrapperBodyContainer>
        <WrapperSlideContainer>
          <ImageCarousel
            images={images}
            isLightbox
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
          />
        </WrapperSlideContainer>
      </WrapperBodyContainer>
    </Dialog>
  );
};

ImageCarouselLightbox.defaultProps = {
  images: [{ id: '', src: '', title: '', isAlitheon: false }],
  openLightbox: false,
  onLightboxClose: () => {},
  selectedImage: 0,
  setSelectedImage: () => {},
};

ImageCarouselLightbox.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      src: PropTypes.node.isRequired,
      title: PropTypes.string,
      isAlitheon: PropTypes.bool,
    })
  ),
  openLightbox: PropTypes.bool,
  onLightboxClose: PropTypes.func,
  selectedImage: PropTypes.number,
  setSelectedImage: PropTypes.func,
};

export default ImageCarouselLightbox;
