import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../constants';

const themes = {
  alert: { font: COLORS.red, background: COLORS.white, border: 'biggerRed' },
  hasActionablePsnad: { font: COLORS.white, background: COLORS.darkRed },
  noActionablePsnad: {
    font: COLORS.darkGray,
    background: COLORS.white,
    border: 'true',
    borderColor: COLORS.darkGray,
  },
  white: { font: COLORS.black, background: COLORS.white, border: 'false' },
  'white&border': {
    font: COLORS.black,
    background: COLORS.white,
    border: 'true',
  },
  transparent: { font: COLORS.black, background: 'none', border: 'true' },
};

const InputButton = (props) => {
  const {
    content,
    type,
    onClick,
    onKeyPress,
    onKeyDown,
    fullWidth,
    margin,
    renderAs,
    disabled,
    selected,
    shadow,
    display,
    width,
    height,
    fontSize,
    padding,
    href,
    dataTestId,
    minwidth,
    borderradius,
    id,
    ...otherProps
  } = props;
  const { t } = useTranslation();
  const [buttonContent, setButtonContent] = useState(() => {
    if (content === 'submit' || !content) return t('ButtonContent.Submit');
    return content;
  });
  const [theme, setTheme] = useState({
    font: COLORS.white,
    background: COLORS.blue,
    border: 'false',
  });

  useEffect(() => {
    if (content === 'submit' || !content)
      setButtonContent(t('ButtonContent.Submit'));
    else setButtonContent(content);
  }, [content]);

  useEffect(() => {
    if (themes[type]) setTheme(themes[type]);
    else if (type === 'success' || type === 'finish')
      setTheme({
        font: COLORS.white,
        background: disabled ? COLORS.lightGray : COLORS.darkGreen,
        border: 'false',
      });
    else if (type === 'successWarning')
      setTheme({
        font: COLORS.black,
        background: disabled ? COLORS.lightGray : COLORS.yellow,
        border: 'false',
      });
    else if (type === 'ship' || type === 'upload')
      setTheme({
        font: COLORS.white,
        background: disabled ? COLORS.lightGray : COLORS.green,
        border: 'false',
      });
    else if (type === 'associate')
      setTheme({
        font: COLORS.white,
        background: disabled ? COLORS.lightGray : COLORS.black,
        border: 'false',
      });
    else if (type === 'select')
      setTheme({
        font: COLORS.white,
        background: selected ? COLORS.blue : COLORS.gray,
        border: 'false',
      });
    else if (type === 'save')
      setTheme({
        font: COLORS.white,
        background: COLORS.blue,
        border: 'false',
      });
    else if (type === 'printLPN')
      setTheme({
        font: COLORS.white,
        background: selected ? COLORS.lightGray : COLORS.darkGray,
        border: 'false',
      });
    else if (
      type === 'finishAuth' ||
      type === 'uploadImageButton' ||
      type === 'CRPrintLPN' ||
      type === 'print' ||
      type === 'resolverException'
    )
      setTheme({
        font: COLORS.white,
        background: disabled ? COLORS.lightGray : COLORS.blue,
        border: 'false',
        borderradius: type === 'resolverException' ? '5px' : null,
      });
    else if (type === 'warning')
      setTheme({
        font: COLORS.white,
        background: disabled ? COLORS.lightGray : COLORS.orange,
        border: 'false',
      });
    else if (type === 'error' || type === 'discardException')
      setTheme({
        font: COLORS.white,
        background: disabled ? COLORS.lightGray : COLORS.red,
        border: 'false',
        borderradius: type === 'discardException' ? '5px' : null,
      });
    else if (type === 'note')
      setTheme({
        font: disabled ? COLORS.lightGray : COLORS.black,
        background: COLORS.white,
        border: 'note',
      });
    else if (type === 'skip')
      setTheme({
        font: COLORS.white,
        background: disabled ? COLORS.lightGray : COLORS.black,
      });
    else if (type === 'saveImageUpload' || type === 'cropImage')
      setTheme({
        font: COLORS.white,
        background: disabled ? '#BFD9F7' : COLORS.blue,
        border: 'false',
        borderradius: '5px',
      });
    else if (
      type === 'cancelImageUpload' ||
      type === 'cancelResolverException' ||
      type === 'cancelAssociateShipment' ||
      type === 'cancelNotes' ||
      type === 'cancel2'
    )
      setTheme({
        font: COLORS.black,
        background: COLORS.white,
        border: 'true',
        borderradius: '5px',
      });
    else if (type === 'imageSlotDialog')
      setTheme({
        font: COLORS.blue,
        background: COLORS.white,
        border: 'true',
        borderradius: '5px',
      });
    else if (type === 'exceptionResolver')
      setTheme({
        font: disabled ? COLORS.gray : COLORS.blue,
        background: COLORS.white,
        border: disabled ? 'exceptionBorderDisabled' : 'exceptionBorder',
        borderradius: '5px',
      });
    else if (type === 'missing')
      setTheme({
        font: COLORS.white,
        background: disabled ? COLORS.lightGray : COLORS.orange,
        border: 'false',
      });
  }, [type, disabled, selected]);

  return type !== 'cancel' ? (
    <StyleInputButton
      id={id}
      data-id={otherProps['data-id']}
      data-testid={dataTestId}
      type={renderAs}
      fontcolor={theme.font}
      bgcolor={theme.background}
      border={theme.border}
      borderradius={theme.borderradius}
      margin={margin}
      variant="outlined"
      fullWidth={fullWidth}
      maxwidth={fullWidth.toString()}
      minwidth={minwidth}
      onClick={onClick}
      onKeyPress={onKeyPress}
      onKeyDown={onKeyDown}
      disabled={disabled}
      shadow={shadow}
      width={width}
      height={height}
      fontSize={fontSize}
      padding={padding}
      display={display}
      href={href || null}
      target={href && '_blank'}
    >
      {buttonContent}
    </StyleInputButton>
  ) : (
    <CancelButton
      id={id}
      type={renderAs}
      maxwidth={fullWidth.toString()}
      onClick={onClick}
      onKeyPress={onKeyPress}
      onKeyDown={onKeyDown}
      margin={margin}
      width={width}
    >
      {buttonContent}
    </CancelButton>
  );
};

export default InputButton;

InputButton.defaultProps = {
  id: '',
  'data-id': 'input-button',
  dataTestId: '',
  renderAs: 'submit',
  content: 'submit',
  type: 'default',
  display: 'inline-flex',
  onClick: null,
  onKeyPress: () => null,
  onKeyDown: () => null,
  fullWidth: false,
  margin: null,
  shadow: null,
  disabled: false,
  selected: false,
  width: null,
  minwidth: null,
  borderradius: null,
  height: null,
  fontSize: null,
  padding: null,
  href: null,
};

InputButton.propTypes = {
  id: PropTypes.string,
  'data-id': PropTypes.string,
  dataTestId: PropTypes.string,
  shadow: PropTypes.string,
  selected: PropTypes.bool,
  renderAs: PropTypes.string,
  content: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  fullWidth: PropTypes.bool,
  width: PropTypes.string,
  minwidth: PropTypes.string,
  borderradius: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
  fontSize: PropTypes.string,
  padding: PropTypes.string,
  display: PropTypes.string,
  href: PropTypes.string,
};

const CancelButton = styled.button`
  max-width: ${(props) =>
    props.maxwidth === 'true' ? 'none' : '380px'} !important;
  margin: ${(props) => (props.margin ? props.margin : '0')} !important;
  text-decoration: underline !important;
  font-size: 1em !important;
  border: 0 !important;
  background-color: transparent !important;
  &:hover {
    cursor: pointer !important;
  }
`;

const StyleInputButton = styled(Button)`
  border-radius: ${(props) =>
    props.borderradius ? props.borderradius : '0 !important'};
  border: 0 !important;
  display: ${(props) => props.display} !important;
  height: ${(props) => (props.height ? props.height : 'auto')};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '85px')};
  ${(props) => props.width && !props.fullWidth && `width: ${props.width};`}
  min-width: ${(props) =>
    props.minwidth ? props.minwidth : '110px'} !important;
  max-width: ${(props) =>
    props.maxwidth === 'true' ? 'none' : '380px'} !important;
  padding: ${(props) =>
    props.padding ? props.padding : '12px 20px'} !important;
  font-weight: 600 !important;
  color: ${(props) => props.fontcolor} !important;
  background-color: ${(props) => props.bgcolor} !important;
  ${(props) =>
    props.border === 'true' ? 'border: 1.5px solid black !important;' : null}
  ${(props) =>
    props.border === 'biggerRed'
      ? 'border: 2px solid #D01110 !important;'
      : null}
  ${(props) =>
    props.border === 'warning' ? 'border: 2px solid #F7B945 !important;' : null}
  ${(props) =>
    props.border === 'note' ? 'border: 2px solid black !important;' : null}
  ${(props) =>
    props.border === 'exceptionBorder'
      ? `border: 2px solid ${COLORS.blue} !important;`
      : null}
  ${(props) =>
    props.border === 'exceptionBorderDisabled'
      ? `border: 2px solid ${COLORS.gray} !important;`
      : null}
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : 'initial'} !important;
  margin: ${(props) => (props.margin ? props.margin : '0')} !important;
  box-shadow: ${(props) => (props.shadow ? props.shadow : '0')} !important;
`;
