export default function mapStorageDetails({
  itemId,
  sellerId = null,
  fulfillmentLpn = null,
  zoneCategoryPayload,
  locationPayload,
  statusPayload,
  gcxTickets,
  lpn,
}) {
  const zoneCategory = zoneCategoryPayload
    ? {
        id: zoneCategoryPayload.id,
        name: zoneCategoryPayload.name,
        handle: zoneCategoryPayload.handle,
      }
    : null;

  const location = locationPayload
    ? {
        row: locationPayload.row,
        section: locationPayload.section,
        level: locationPayload.level,
        bin: locationPayload.bin,
        identifier: locationPayload.identifier,
      }
    : null;

  const status = statusPayload
    ? {
        id: statusPayload.id,
        name: statusPayload.name,
        handle: statusPayload.handle,
      }
    : null;

  const tickets = gcxTickets
    ? gcxTickets.map((ticket) => {
        return {
          url: ticket.url,
          ticketNumber: ticket.ticketNumber,
          claimOutcomeName: ticket.claimOutcomeName,
          claimOutcomeHandle: ticket.claimOutcomeHandle,
          updatedAt: ticket.updatedAt,
        };
      })
    : null;

  return {
    itemId,
    zoneCategory,
    location,
    status,
    gcxTickets: tickets,
    fulfillmentLpn,
    sellerId,
    lpn,
  };
}
