export default function mapUser(user) {
  return {
    name: user.name,
    firstName: user.firstName,
    hubs: user.hubs
      ? user.hubs.map((element) => ({
          hubId: element.handle,
          stations: element.stations ? element.stations : [],
        }))
      : [],
  };
}
