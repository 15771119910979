import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import ImageCarouselLightbox from './ImageCarouselLightbox';
import ImageCarousel from './ImageCarousel';

export const useImageViewerCarousel = () => {
  const { t } = useTranslation();
  const mapItemImageData = (item) => {
    // to get images for stations that handle things at the item-level (e.g. QC)
    // When order does not have imageUrl, it will be: []
    let imageUrls = item?.allItemImages;

    // to get images for stations that handle things at the order-level (e.g. Exception)
    if (!imageUrls?.length) {
      let allOrderItemImages = item?.order?.items
        ?.map((i) => i.allItemImages)
        ?.flat(2);

      if (!allOrderItemImages?.length) {
        // For orders that have not been associated with items yet (e.g. In Exception from CR)
        // When order does not have imageUrl, it will be: [undefined]
        allOrderItemImages = item?.order?.imageUrls?.map((imgUrl) => {
          return { imageUrl: imgUrl };
        });
      }

      const uniqueImageUrls = Array.from(
        new Set(allOrderItemImages?.map((img) => img?.imageUrl))
      ); // 'Set' used to create unique array since duplicate values are removed when creating 'set'

      imageUrls = uniqueImageUrls
        ?.filter((imgUrl) => !!imgUrl) // removing falsy value such as [undefined]
        .map((imgUrl) =>
          allOrderItemImages.find((img) => img?.imageUrl === imgUrl)
        );
    }

    // Early return for when there is no image available at both item / order level
    if (!imageUrls?.length) return [];

    return imageUrls.map((data, index) => ({
      id: `image${index + 1}Id`,
      src: data.imageUrl || data,
      title: data.title || data.caption || '',
      imagePill: data.imagePill || null,
    }));
  };

  // the argument for the parameter 'lpn' is derived from useGetLpn hook.
  const mapExceptionItemImageData = (orderException, lpn) => {
    if (lpn === t('Common.NA')) return mapItemImageData(orderException);

    const itemException =
      orderException?.order?.items?.find((i) => i?.lpn?.lpn === lpn) ||
      (orderException?.item?.lpn?.lpn === lpn && orderException?.item);

    return itemException
      ? mapItemImageData(itemException)
      : mapItemImageData(orderException);
  };

  return { mapItemImageData, mapExceptionItemImageData };
};

export const NoImageViewer = () => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('HistoryStation.ImageNotFound')}>
      <ImageNotSupportedIcon
        sx={{ width: '100%', height: '400px' }}
        data-testid="image-not-found-icon"
      />
    </Tooltip>
  );
};

export const ImageViewerCarousel = ({ images }) => {
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  useEffect(() => {
    setOpenLightbox(false);
  }, []);

  const handleOpenLightbox = () => {
    setOpenLightbox(true);
  };

  const handleCloseLightbox = () => {
    setOpenLightbox(false);
  };

  if (!images.length) return <NoImageViewer />;

  return (
    <>
      <ImageCarousel
        images={images}
        onOpenLightbox={handleOpenLightbox}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
      />
      {openLightbox && (
        <ImageCarouselLightbox
          openLightbox={openLightbox}
          onLightboxClose={handleCloseLightbox}
          images={images}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
        />
      )}
    </>
  );
};

ImageViewerCarousel.defaultProps = {
  images: [],
};

ImageViewerCarousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      title: PropTypes.string,
      isAlitheon: PropTypes.bool,
    })
  ),
};
