import React from 'react';
import { InputLabel, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { StyledErrorText, StyledInputBase } from '../inputs.styles';
import { StyledMenuItem } from './index.styles';

const useStyles = makeStyles({
  select: {
    borderRadius: 0,
  },
});

const SelectBase = ({
  name,
  options,
  fullWidth,
  value,
  error,
  onChange,
  disabled,
  label,
  inputStyled,
  marginBottom,
  dataTestId,
  id,
  labelStyle,
}) => {
  const classes = useStyles();

  return (
    <FormControl
      variant={inputStyled === 'underline' ? 'filled' : null}
      style={{ width: '100%', marginBottom }}
    >
      {label && (
        <InputLabel
          style={{ ...labelStyle, paddingBottom: '0.3em' }}
          id="label"
        >
          {label}
        </InputLabel>
      )}
      <Select
        labelId="label"
        value={value}
        name={name}
        id={id}
        input={
          inputStyled === 'underline' ? null : (
            <StyledInputBase error={!!error} fullWidth={fullWidth} />
          )
        }
        onChange={onChange}
        MenuProps={{ classes: { paper: classes.select } }}
        disabled={disabled}
        data-testid={dataTestId}
      >
        {options.map((option) => {
          return (
            <StyledMenuItem
              id={`${name}-${option.value}`}
              key={option.value}
              value={option.value}
            >
              {option.text}
            </StyledMenuItem>
          );
        })}
      </Select>
      {error && <StyledErrorText>{error}</StyledErrorText>}
    </FormControl>
  );
};

SelectBase.defaultProps = {
  fullWidth: false,
  error: null,
  disabled: false,
  label: null,
  inputStyled: '',
  marginBottom: '',
  dataTestId: undefined,
  id: undefined,
  labelStyle: {},
};

SelectBase.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, text: PropTypes.string })
  ).isRequired,
  labelStyle: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  label: PropTypes.string,
  inputStyled: PropTypes.string,
  marginBottom: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
  id: PropTypes.string,
};

export default SelectBase;
