import {
  Badge,
  Box,
  Button,
  Grid,
  makeStyles,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import PrintIcon from '@material-ui/icons/Print';
import PropTypes from 'prop-types';
import { useStateValue } from '../../../../providers/StateProvider';
import { COLORS } from '../../../../constants';
import SelectBase from '../../inputs/selectBase';
import { PRINTER_LABELS } from '../../../../utils/printing/zebra/utils/labelGenerator';

const useStyles = makeStyles({
  printerBtn: {
    '& .MuiBadge-anchorOriginTopRightRectangular': {
      top: '8px',
      left: '55%',
    },
    '& *': {
      color: (props) => props.color,
    },
  },
});

const SelectPrinterIconButton = ({
  printerType,
  onPrinterChange,
  onLabelChange,
  onLabelCurrentPrinterModelChange,
  printers,
  labels,
}) => {
  const { t } = useTranslation();
  const [{ selectedPrintersId, selectedLabels }] = useStateValue();
  const classes = useStyles({
    color: selectedPrintersId[printerType]?.id ? COLORS.green : COLORS.black,
  });
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Tooltip
        data-testid="printer-infos"
        title={
          selectedPrintersId[printerType]?.id ? (
            <Box>
              <Typography color="inherit">
                {selectedPrintersId[printerType].printerName}
              </Typography>
              <Typography color="inherit">
                {selectedPrintersId[printerType].ipAddress}
              </Typography>
            </Box>
          ) : (
            <Typography color="inherit">
              {t('PrinterSelectionContainer.SelectAPrinter')}
            </Typography>
          )
        }
      >
        <Button
          id={`select-printer-for-type-${printerType}-button`}
          data-testid={`select-printer-for-type-${printerType}-button`}
          onClick={handleOpen}
          aria-controls={`select-printer-for-type-${printerType}`}
          variant="outlined"
          className={classes.printerBtn}
        >
          <Badge
            badgeContent={selectedPrintersId[printerType]?.id ? '1' : 0}
            overlap="rectangular"
          >
            <Box
              component={Grid}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <PrintIcon />
              <Typography variant="caption">{printerType}</Typography>
            </Box>
          </Badge>
        </Button>
      </Tooltip>
      <Popover
        open={!!anchorEl}
        onClose={handleClose}
        anchorEl={anchorEl}
        data-testid="select-printer-popover"
      >
        <Box
          display="flex"
          flexDirection="column"
          gridGap="1em"
          p="1em"
          minWidth="150px"
          data-testid="printer-dropdowns-container"
        >
          <Box maxWidth="200px">
            <SelectBase
              fullWidth
              onChange={(e) =>
                onPrinterChange(printers.find((p) => p.id === e.target.value))
              }
              dataTestId={`select-printer-for-type-${printerType}`}
              name={`select-printer-for-type-${printerType}`}
              value={selectedPrintersId[printerType]?.id || ''}
              options={printers.map(({ id, printerName }) => ({
                value: id,
                text: printerName,
              }))}
              label={t('PrinterSelectionContainer.SelectPrinterType', {
                type: printerType.toUpperCase(),
              })}
            />
          </Box>
          {labels.length > 0 &&
          selectedPrintersId[printerType]?.id &&
          selectedPrintersId[printerType]?.canSelectLabel ? (
            <Box maxWidth="200px">
              <SelectBase
                fullWidth
                onChange={(e) =>
                  onLabelChange(labels.find((l) => l.value === e.target.value))
                }
                dataTestId={`select-printer-label-for-type-${printerType}`}
                name={`select-printer-label-for-type-${printerType}`}
                value={selectedLabels[printerType]?.value || ''}
                options={PRINTER_LABELS[printerType].map(
                  ({ label, value }) => ({
                    value,
                    text: label,
                  })
                )}
                label={t('PrinterSelectionContainer.SelectLabelType')}
              />
            </Box>
          ) : (
            <></>
          )}
          {labels.length > 0 &&
          selectedLabels[printerType]?.printerModels.length > 0 ? (
            <Box maxWidth="200px">
              <SelectBase
                fullWidth
                onChange={(e) =>
                  onLabelCurrentPrinterModelChange(
                    e.target.value,
                    selectedLabels[printerType]
                  )
                }
                dataTestId={`select-printer-model-for-type-${printerType}`}
                name={`select-printer-model-for-type-${printerType}`}
                value={selectedLabels[printerType]?.currentPrinterModel || ''}
                options={selectedLabels[printerType].printerModels.map(
                  ({ label, value }) => ({
                    value,
                    text: label,
                  })
                )}
                label={t('PrinterSelectionContainer.SelectPrinterModel')}
              />
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default SelectPrinterIconButton;

SelectPrinterIconButton.defaultProps = {
  labels: [],
};

SelectPrinterIconButton.propTypes = {
  printerType: PropTypes.string.isRequired,
  onPrinterChange: PropTypes.func.isRequired,
  onLabelChange: PropTypes.func.isRequired,
  onLabelCurrentPrinterModelChange: PropTypes.func.isRequired,
  printers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      printerName: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};
