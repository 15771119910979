import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import {
  StyledListItem,
  StyledListItemIcon,
  StyledListItemText,
} from './MenuElement.styles';

export const POSITION = {
  left: 'left',
  right: 'right',
};

const MenuElement = ({
  button,
  children,
  closeIcon,
  icon,
  id,
  onClick,
  open,
  openIcon,
  selected,
  text,
  to,
  statusIconPosition,
  dataTestId,
}) => {
  const renderStatusIcon = () => {
    if (!openIcon || !closeIcon) return null;

    return <Box display="flex">{open ? closeIcon : openIcon}</Box>;
  };

  return (
    <StyledListItem
      button={button}
      component={RouterLink}
      id={id}
      onClick={onClick}
      selected={selected}
      to={to}
      text={text}
      data-testid={dataTestId}
    >
      {statusIconPosition === POSITION.left && renderStatusIcon()}
      {icon && <StyledListItemIcon>{icon}</StyledListItemIcon>}
      <StyledListItemText disableTypography primary={text} />
      {statusIconPosition === POSITION.right && renderStatusIcon()}
      {children}
    </StyledListItem>
  );
};

MenuElement.defaultProps = {
  button: false,
  children: undefined,
  closeIcon: undefined,
  icon: undefined,
  open: false,
  openIcon: undefined,
  selected: false,
  to: undefined,
  dataTestId: undefined,
  onClick: () => {},
  statusIconPosition: POSITION.right,
};

MenuElement.propTypes = {
  button: PropTypes.bool,
  children: PropTypes.node,
  closeIcon: PropTypes.node,
  icon: PropTypes.node,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  open: PropTypes.bool,
  openIcon: PropTypes.node,
  selected: PropTypes.bool,
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
  dataTestId: PropTypes.string,
  statusIconPosition: PropTypes.oneOf([POSITION.left, POSITION.right]),
};

export default MenuElement;
