import { makeStyles } from '@material-ui/core';
import { COLORS } from '@codeboxxtechschool/ginza_shared_components';

const useStyles = makeStyles({
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkboxContainer: {
    backgroundColor: '#F0F2F5',
    width: '100%',
    marginTop: '12px',
    borderRadius: '2px',
    padding: '12px 24px',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    margin: '5px 0',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  accordionItem: {
    width: '100%',
    justifyContent: 'flex-start',
  },
  warning: {
    marginLeft: 'auto',
    fontStyle: 'italic',
    color: COLORS.gray800,
    marginTop: 0,
    marginBottom: 0,
  },
});

export default useStyles;
