import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { useTranslation } from 'react-i18next';
import StationStatus from '../components/stationStatus';
import { RelativeGrid, StyledGrid } from './StationsStatusSection.styles';
import { getStationsToDisplay } from '../utils/stationDisplayOptions';
import { COLORS, EXCEPTION_STATUS } from '../../../../constants';
import { itemShape } from '../../../../utils/propTypesShape/itemShape';
import getTimeOutDate from '../utils/getTimeOutDate';
import getTimeInDate from '../utils/getTimeInDate';
import collectAllExceptions from '../../../../utils/collectAllExceptions';
import { timeZoneFormat } from '../../../../utils/timeZoneFormat';

const isExceptionStation = (station) => {
  return station?.name.toLowerCase() === 'exception';
};

const warningIcon = (color, count) => {
  return (
    <>
      <div className="wrapperWarning">
        {(count || count === 0) && (
          <>
            <svg
              width="20"
              height="20"
              viewBox="0 0 48 39"
              xmlns="http://www.w3.org/2000/svg"
              className="svg12"
              data-testid="resolved-icon"
            >
              <g fill="none" fillRule="evenodd">
                <circle cx="20" cy="20" r="20" fill="#FFF" />
                <path d="M8 6h24v24H8z" />
                <path
                  d="m20 11.652 7.648 13.034H12.352L20 11.652m0-3.997L8.828 26.69h22.344L20 7.655zm1.016 14.026h-2.032v2.003h2.032v-2.003zm0-6.011h-2.032v4.007h2.032V15.67z"
                  fill={color}
                  fillRule="nonzero"
                />
              </g>
            </svg>
            {count !== 0 && (
              <div className="exceptionCounter" data-testid="resolved-counter">
                {count}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

const isWarningIcon = (station, item, count) => {
  const exceptions = collectAllExceptions(item);

  if (exceptions) {
    const stationExceptions = exceptions?.filter(
      (s) => s.stationId === station.id
    );

    if (
      stationExceptions.find(
        (s) => s.exceptionStatus === EXCEPTION_STATUS.Unsolved
      )
    ) {
      return warningIcon('#C01', count);
    }
    if (
      stationExceptions.find(
        (s) => s.exceptionStatus === EXCEPTION_STATUS.Resolved
      )
    ) {
      return warningIcon(COLORS.gray, count);
    }
  }
  return null;
};

const exceptionTimeIn = (station, item) => {
  const exceptions = collectAllExceptions(item);

  if (exceptions) {
    const stationExceptions = exceptions?.filter(
      (s) => s.stationId === station.id
    );

    if (
      stationExceptions.find(
        (s) => s.exceptionStatus === EXCEPTION_STATUS.Unsolved
      )
    ) {
      return timeZoneFormat(
        stationExceptions.find(
          (s) => s.exceptionStatus === EXCEPTION_STATUS.Unsolved
        ).createdAt,
        item
      );
    }
  }
  return null;
};

const getBackgroundColorAndIcon = (item, station, stationItem) => {
  const exceptions = collectAllExceptions(item);

  // timeline station status:  0 - NOTHING, 1 - OPENED, 2 - PAUSED, 3 - COMPLETED, 4 - LOCKED
  const objectException = exceptions?.filter(
    (exception) =>
      exception.exceptionStatus === EXCEPTION_STATUS.Resolved &&
      exception.stationId === station.id
  ).length;

  // RED BACKGROUND + PlayCircle ICON: ONLY FOR EXCEPTION STATION! Item/Order is in EXCEPTION
  if (isExceptionStation(station)) {
    if (item?.order?.status.toLowerCase() === 'exception') {
      return { background: COLORS.red, icon: <PlayCircleFilledIcon /> };
    }
    // GREY BACKGROUND + RemoveCircle ICON: when the Item/Order is not yet at the station => NO STATION ITEM EXISTS
    return {
      background: COLORS.gray,
      icon: <RemoveCircleIcon />,
      exceptionResolvedIcon: isWarningIcon(station, item, objectException),
    };
  }

  if (stationItem) {
    // ORANGE BACKGROUND + PauseCircle ICON: Timeline Station Status = 2 (PAUSED) => Item/Order is in EXCEPTION
    if (stationItem?.timelineStationStatus === '2') {
      return {
        background: COLORS.orange,
        icon: <PauseCircleFilledIcon />,
        exceptionResolvedIcon: isWarningIcon(station, item, objectException),
      };
    }

    // ORANGE BACKGROUND + PlayCircle ICON: Timeline Station Status = 1 (OPENED) => Item/Order is ENTERED THE STATION
    if (stationItem?.timelineStationStatus === '1') {
      return {
        background: COLORS.orange,
        icon: <PlayCircleFilledIcon />,
        exceptionResolvedIcon: isWarningIcon(station, item, objectException),
      };
    }

    // GREEN BACKGROUND + CheckCircle ICON: Timeline Station Status = 3 || 4 (COMPLETED || LOCKED) => Item/Order is COMPLETED THE STATION
    if (
      stationItem?.timelineStationStatus === '3' ||
      stationItem?.timelineStationStatus === '4' ||
      item?.order?.status.toLowerCase() === 'shipped' ||
      item?.order?.status.toLowerCase() === 'dispatched'
    ) {
      return {
        background: COLORS.green,
        icon: <CheckCircleIcon />,
        exceptionResolvedIcon: isWarningIcon(station, item, objectException),
      };
    }
  }

  if (!stationItem) {
    // GREEN BACKGROUND + CheckCircle ICON: when the Item/Order skipped station but SHIPPED (CANCELLED ITEMS, N0 SHIPPING REQUIRED ITEM/ORDER) => NO STATION ITEM EXISTS
    if (
      (station.name === 'Packaging' || station.name === 'Shipping') &&
      (item?.order?.status.toLowerCase() === 'shipped' ||
        item?.isNoShippingRequired)
    ) {
      return { background: COLORS.green, icon: <CheckCircleIcon /> };
    }
    if (parseInt(station?.id, 10) === parseInt(item?.nextStation?.id, 10)) {
      // ORANGE BACKGROUND + RemoveCircle ICON: when the next station for the Item/Order is not yet started
      return {
        background: COLORS.orange,
        icon: <RemoveCircleIcon />,
      };
    }

    // GREY BACKGROUND + RemoveCircle ICON: when the Item/Order is not yet at the station => NO STATION ITEM EXISTS
    return {
      background: COLORS.gray,
      icon: <RemoveCircleIcon />,
    };
  }
  // GREY BACKGROUND + RemoveCircle ICON: when the Item/Order is not yet at the station => NO STATION ITEM EXISTS
  return {
    background: COLORS.gray,
    icon: <RemoveCircleIcon />,
  };
};

const getNameLabel = (stationItem) =>
  stationItem ? stationItem?.userIdentification : 'N/A';

const getStationItem = (station, selectedItemStationItems) => {
  const stationItem = find(selectedItemStationItems, {
    station: { id: station.id },
  });

  if (stationItem) {
    return stationItem;
  }
  if (['Packaging', 'Shipping'].includes(station.name)) {
    return find(selectedItemStationItems, {
      station: { name: 'Packaging And Shipping' },
    });
  }

  if (station.name === 'Quality Control') {
    return find(selectedItemStationItems, {
      station: { name: 'QualityControlAndAuthentication' },
    });
  }

  return null;
};

const StationsStatusSection = ({ item, stations, id, dataTestId }) => {
  const { t } = useTranslation();
  // TODO: If we want to display Pack&Ship go to for logic in this file: app/javascript/components/stations/History/sections/detailsSection/accordions/StationStatusesTable.jsx
  const stationsToDisplay = getStationsToDisplay(stations, item);
  return (
    <StyledGrid data-testid={dataTestId} id={id} container>
      {stationsToDisplay.map((station, index) => {
        const stationItem = getStationItem(station, item?.stationItems);
        return (
          <RelativeGrid
            item
            xs
            zindex={stations.length - index}
            key={station?.id}
          >
            <StationStatus
              stationItem={stationItem}
              item={item}
              stationId={station?.id}
              isExceptionStation={isExceptionStation(station)}
              className="statusCard"
              name={t(`StationsNames.${station?.name.replace(/ /g, '')}`)}
              topLabel={getTimeInDate(item, station, stationItem)}
              middleLabel={
                isExceptionStation(station)
                  ? 'N/A'
                  : getTimeOutDate(item, stationItem)
              }
              exceptionLabel={exceptionTimeIn(station, item)}
              bottomLabel={
                isExceptionStation(station) ? 'N/A' : getNameLabel(stationItem)
              }
              backgroundColor={
                getBackgroundColorAndIcon(item, station, stationItem).background
              }
              icon={getBackgroundColorAndIcon(item, station, stationItem).icon}
              resolvedIcon={
                getBackgroundColorAndIcon(item, station, stationItem)
                  .exceptionResolvedIcon
              }
            />
          </RelativeGrid>
        );
      })}
    </StyledGrid>
  );
};

StationsStatusSection.defaultProps = {
  id: null,
  dataTestId: 'StationsStatusSection',
};

StationsStatusSection.propTypes = {
  stations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  item: PropTypes.shape(itemShape).isRequired,
  id: PropTypes.string,
  dataTestId: PropTypes.string,
};

export default StationsStatusSection;
