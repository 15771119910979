import PropTypes from 'prop-types';

export const notePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  userIdentification: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  description: PropTypes.string,
  filesInfo: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      filename: PropTypes.string.isRequired,
    })
  ).isRequired,
});

export default notePropTypes;
