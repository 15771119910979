import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';

/**
 * Hoc that will handle cookie authRedirectUrl.
 */

const WithRedirect = ({ children }) => {
  useEffect(() => {
    const cookies = new Cookies();
    const redirectUrl = new URL(document.location).searchParams.get(
      'redirect_url'
    );
    if (redirectUrl) {
      cookies.set('authRedirectUrl', redirectUrl);
    }
  }, []);

  return children;
};

export default WithRedirect;

WithRedirect.propTypes = {
  children: PropTypes.node.isRequired,
};
