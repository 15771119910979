import axios from 'axios';
import Cookies from 'universal-cookie';

const handleApiResponse = (response) => {
  return { data: response.data };
};

const handleApiError = (axiosError) => {
  const statusCode = axiosError.response?.status || 0;

  return {
    data: null,
    error: {
      statusCode,
      errorResponse: axiosError.response,
      message: axiosError.message,
    },
  };
};

const getAxiosClient = () => {
  const cookies = new Cookies();
  const token = sessionStorage.getItem('authToken') || cookies.get('identity');

  return axios.create({
    headers: {
      'Content-type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  });
};

export const getRequest = async (url) => {
  try {
    const response = await getAxiosClient().get(url);
    return handleApiResponse(response);
  } catch (error) {
    return handleApiError(error);
  }
};

export const postRequest = async (url, data) => {
  try {
    const response = await getAxiosClient().post(url, data);
    return handleApiResponse(response);
  } catch (error) {
    return handleApiError(error);
  }
};

export const putRequest = async (url, data) => {
  try {
    const response = await getAxiosClient().put(url, data);
    return handleApiResponse(response);
  } catch (error) {
    return handleApiError(error);
  }
};

export const removeRequest = async (url) => {
  try {
    const response = await getAxiosClient().delete(url);
    return handleApiResponse(response);
  } catch (error) {
    return handleApiError(error);
  }
};
