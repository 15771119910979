/**
 * Validate that at least 3 parts of the location is there
 *
 * Example:
 *
 *  D--- is not valid
 *
 *  D-01-- is not valid
 *
 *  D-01--01 is valid
 */
export const isValidLocation = (location) => {
  return (
    (location || ' - - - ')
      .split('-')
      .map((element) => element.trim())
      .reduce((acc, next) => (next !== '' ? acc + 1 : acc), 0) >= 3
  );
};

export const isLocationEmpty = (location) => {
  return !location || location.replace(/\s/g, '') === '---';
};

/**
 * Add a 0 in front of single digit numbers for section, level, bin
 * Example: A-1-1-1 becomes A-01-01-01
 * @param input location string
 */
export const formatStorageLocation = (input) => {
  if (!input) return '';

  const parts = input.split('-').map((part) => part.trim());

  for (let i = 1; i < parts.length; i += 1) {
    if (!Number.isNaN(Number(parts[i])) && parts[i].length === 1) {
      parts[i] = `0${parts[i]}`;
    }
  }
  return parts.join('-');
};
