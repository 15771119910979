export default (title, message, dispatch, errorCode, scannedString) =>
  dispatch({
    type: 'changePopup',
    newPopup: {
      type: 'error',
      title,
      message,
      errorCode,
      scannedString,
    },
  });
