import styled from 'styled-components';
import { InputBase } from '@material-ui/core';

export const StyledInputBase = styled(InputBase)`
  font-family: inherit !important;
  background-color: white;
  border: 1px solid #cacaca;
  padding: 0.5em !important;
  border-radius: ${({ rounded }) => (rounded === 'true' ? '20px' : '4px')};
  ${({ error }) => error && `border-color: #f44336;`};
  height: 50px !important;
  color: ${(props) => (props.disabled ? '#a1a1a1' : '#000')} !important;
  &:hover {
    border-color: #000 !important;
  }
  &:focus-within {
    border-color: ${({ error }) => (error ? `#f44336` : '#3f51b5')} !important;
    border-width: 2px !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
`;
StyledInputBase.displayName = 'StyledInputBase';

export const StyledErrorText = styled.p`
  font-size: 0.75rem;
  font-weight: 400 !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  text-align: left !important;
  letter-spacing: 0.03333em !important;
  line-height: 1.66 !important;
  color: #f44336;
  background-color: transparent !important;
  margin-top: 3px !important;
  margin-right: 14px !important;
  margin-left: 14px !important;
  margin-bottom: 0px !important;
`;

export default StyledInputBase;
