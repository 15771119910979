import axios from 'axios';

const axiosClient = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
});

axiosClient.defaults.timeout = 3000;

export function postRequest(URL, _payload) {
  const payload = _payload || '';
  axiosClient.defaults.headers.post.Authorization = `Bearer ${sessionStorage.getItem(
    'authToken'
  )}`;
  return axiosClient.post(`${URL}`, payload, {
    data: JSON.stringify(payload),
  });
}

export default axiosClient;
