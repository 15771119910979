import React from 'react';
import ReactLoading from 'react-loading';
import { Modal } from '@material-ui/core';
import { FlexContainerRow } from '../reusables/StyledComponent';

const LoadingAnimation = () => {
  return (
    <Modal open>
      <FlexContainerRow justCont="center" alignItems="center">
        <ReactLoading type="spin" color="#fff" />
      </FlexContainerRow>
    </Modal>
  );
};

export default LoadingAnimation;
