/* eslint-disable react/prop-types */
import React from 'react';
import { StepContent, StepTitle } from './StepHeader.styles';

const StepHeader = ({ title, content }) => {
  return (
    <StepContent>
      <StepTitle>{title}</StepTitle>
      <br />
      <br />
      {content}
    </StepContent>
  );
};

export default StepHeader;
