import React, { useState, useEffect, Suspense } from 'react';
import { Box, Button, IconButton } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import PersonIcon from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';
import axios from 'axios';

import { useQuery } from '@apollo/client';
import { SideNavText, CloseIcon } from './reusables/StyledComponent';
import { useStateValue } from '../providers/StateProvider';

// component
import LogOut from './LogOut';
import StationSelectionContainer from './reusables/selectors/StationSelectionContainer';
import ExceptionNav from './ExceptionNav';
// image import
import ginzaLogo from '../../assets/images/ginza_logo.svg';
import stationNameConverter from '../utils/stationNameConverter';
import { getStationIdByName, getStationById } from '../utils/stationsInfo';

import HubSwitch from './HubSwitch';
import MultiPrinterSelectionContainer from './reusables/selectors/MultiPrinterSelectionContainer';
import { NAVBAR_HEIGHT, COLORS } from '../constants';
import LiveEventSwitch from './LiveEventSwitch';
import PrivateNote from './modals/PrivateNote';
import InputButton from './reusables/inputs/InputButton';
import { QUERY_ACTIONABLE_PSNAD_COUNT } from '../graphql/queries';

// Uncomment LanguageSelector to show, not a MVP requirement
// import LanguageSelector from './reusables/selectors/LanguageSelector';

const IDLE_TIME_LIMIT = 5; // will kick out users in idle state while 5 mins

let idleTime = 0;

const PsnadButton = ({ partnerLocationId, psnadStationId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { data } = useQuery(QUERY_ACTIONABLE_PSNAD_COUNT, {
    variables: {
      partnerLocationId,
    },
    fetchPolicy: 'no-cache',
  });

  if (!data) return null;

  return (
    <InputButton
      width="inherit"
      type={
        data.actionablePsnadCount > 0
          ? 'hasActionablePsnad'
          : 'noActionablePsnad'
      }
      id="actionable-psnad-button"
      dataTestId="actionable-psnad-button"
      content={t('ButtonContent.psnadActionItems', {
        actionItemCount: data.actionablePsnadCount,
      })}
      onClick={() => {
        history.push(`/stations/${psnadStationId}`);
      }}
    />
  );
};

PsnadButton.propTypes = {
  partnerLocationId: PropTypes.string.isRequired,
  psnadStationId: PropTypes.string.isRequired,
};

const NavBar = ({ partnerLocations, resetStation }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [contextVariables, dispatch] = useStateValue();

  const {
    currentStationId,
    stationList,
    partnerLocation,
    currentPartnerLocationConfigs,
    userIdentification,
    roleList,
    tokenExpiryTime,
    refreshToken,
    isLiveEventShippingMain,
  } = contextVariables;
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => setOpen(!open);

  const history = useHistory();

  const currentStationName = getStationById(stationList, currentStationId);
  const displayLiveEventSwitch =
    currentStationName?.value === 'LiveEventShipping' &&
    !isLiveEventShippingMain;
  const stationName = currentStationName && currentStationName.value;

  const goToNavigation = () => {
    sessionStorage.setItem('stationId', null);
    dispatch({
      type: 'changeCurrentStationId',
      newCurrentStationId: null,
    });
    history.push('/');
  };

  const isShowingHubSwitch = () => {
    return (
      currentStationId && !!partnerLocation?.id && partnerLocations?.length > 1
    );
  };
  const isShowingHubSwitchNoStationId = () => {
    return (
      !currentStationId && !!partnerLocation?.id && partnerLocations?.length > 1
    );
  };

  const showError = (error) => {
    let message = t('PopupContent.Error.DefaultError');
    if (error.response.status === 400)
      message = t('PopupContent.Error.NotConnected');
    else if (error.response.status === 429)
      message = t('PopupContent.Error.TooManyRequests');
    else if (error.response.status === 403)
      message = t('PopupContent.Error.ExpiredPassword');
    else if (error.response.status === 423)
      message = t('PopupContent.Error.AccountLocked');
    else if (error.response.status === 401)
      message = t('PopupContent.Error.Unauthorized');
    dispatch({
      type: 'changePopup',
      newPopup: {
        type: 'error',
        title: `${error.response.status} ${error.response.statusText}`,
        message,
        btnMessage: t('ButtonContent.Close'),
      },
    });
  };

  const goToLogIn = () => {
    sessionStorage.clear();
    dispatch({ type: 'changeAuthToken', newAuthToken: null });
    dispatch({
      type: 'changeCurrentPartnerLocationConfigs',
      currentPartnerLocationConfigs: {},
    });
    dispatch({
      type: 'changePartnerLocation',
      newPartnerLocation: {
        id: null,
        name: null,
        lpnType: null,
      },
    });
    history.push('/');
  };

  const stayOnLoggedIn = async () => {
    try {
      const grantType = 'refresh_token';
      const { data } = await axios({
        method: 'post',
        url: '/api/sessions',
        data: { grantType, refreshToken },
      });
      const {
        access_token: newAccessToken,
        refresh_token: newRefreshToken,
        expires_in: tokenExpiresIn,
        created_at: tokenCreatedAt,
      } = data;
      const newTokenExpiryTime = tokenExpiresIn + tokenCreatedAt;

      sessionStorage.setItem('authToken', newAccessToken);
      dispatch({ type: 'changeAuthToken', newAuthToken: newAccessToken });
      sessionStorage.setItem('refreshToken', newRefreshToken);
      dispatch({ type: 'changeRefreshToken', newRefreshToken });
      sessionStorage.setItem('tokenExpiryTime', newTokenExpiryTime);
      dispatch({
        type: 'changeTokenExpiryTime',
        newTokenExpiryTime,
      });
    } catch (error) {
      showError(error);
    }
  };

  const timerIncrement = () => {
    const currentTimeStamp = Math.floor(Date.now() / 1000);

    idleTime += 1;

    if (currentTimeStamp < parseInt(tokenExpiryTime, 10)) {
      return;
    }

    if (idleTime > IDLE_TIME_LIMIT - 1) {
      goToLogIn();
    } else {
      stayOnLoggedIn();
    }
  };

  const resetIdleTime = () => {
    idleTime = 0;
  };

  const userHasPsnadAccess = () => {
    return partnerLocation
      ? partnerLocation.userPartnerLocationStations
          .map((station) => station.name)
          .includes('PSNAD')
      : null;
  };

  const psnadStationId = getStationIdByName(stationList, 'PSNAD');

  useEffect(() => {
    // Increase the idle time counter every minute.
    const idleInterval = setInterval(timerIncrement, 60000);
    const domEvents = [
      'mousedown', // touchscreen presses
      'mousemove',
      'keydown',
      'scroll',
      'click', // touchpad clicks
    ];

    domEvents.forEach((name) => {
      document.addEventListener(name, resetIdleTime, true);
    });

    return () => {
      clearInterval(idleInterval);
      domEvents.forEach((name) => {
        document.removeEventListener(name, resetIdleTime, true);
      });
    };
  }, [tokenExpiryTime, refreshToken]);

  const isShowingExceptionButton = ![
    'Admin',
    'Exception',
    'ExceptionGCX',
    'History',
    'Reports',
    'DispatchScan',
    'LiveEventIntake',
    'LiveEventShipping',
    'PSNAD',
  ].includes(stationName);

  const isUsingGinza2Style = ['PSNAD'].includes(stationName);

  const rightBorder = {
    borderRight: `solid ${COLORS.borderGray} 1px`,
    paddingRight: '22px',
  };

  const isShowingAddNoteButton = ['LiveEventIntake'].includes(stationName);

  const hubNameColor = () => {
    return partnerLocation.name.toLowerCase().includes('graded')
      ? COLORS.lightBlue
      : COLORS.midGreen;
  };

  return (
    <Suspense>
      <NavigationBar
        className={currentStationId ? 'gridThree' : ''}
        ginza2style={isUsingGinza2Style.toString()}
      >
        <div style={isUsingGinza2Style ? rightBorder : {}}>
          <Box display="flex" alignItems="center">
            <IconButton
              edge="start"
              onClick={() => setOpen(true)}
              color="inherit"
              aria-label="open drawer"
              id="sidenav-menu"
              data-testid="sidenav-menu"
            >
              <MenuIcon />
            </IconButton>
            {currentStationId !== null &&
            currentStationId !== getStationIdByName(stationList, 'Admin') ? (
              <GinzaLogoContainer flex={1}>
                <GinzaLogo
                  onClick={goToNavigation}
                  src={ginzaLogo}
                  alt="ginza logo"
                  data-testid="ginzaLogo"
                />
                {partnerLocation &&
                currentPartnerLocationConfigs &&
                currentPartnerLocationConfigs.isTitleHeaderConfigOn ? (
                  <HubNameDiv hubNameColor={hubNameColor()}>
                    {partnerLocation.name}
                  </HubNameDiv>
                ) : null}
              </GinzaLogoContainer>
            ) : null}
          </Box>
          <SwipeableDrawerStyled
            anchor="left"
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
          >
            <div>
              <Box textAlign="left" p={2}>
                <Button
                  id="close-navbar-button"
                  startIcon={<CloseIcon />}
                  onClick={() => setOpen(false)}
                >
                  {t('Close')}
                </Button>
              </Box>

              <Divider />
              {partnerLocation.id != null ? (
                <StationSelectionContainer border toggleDrawer={toggleDrawer} />
              ) : null}
              <Divider />
              <Divider />
              {isShowingHubSwitch() && (
                <HubSwitch
                  partnerLocations={partnerLocations}
                  toggleDrawer={toggleDrawer}
                />
              )}
              <List disablePadding>
                <ListItem data-testid="nav-logout" p={1} m={1}>
                  <LogOut padding="0" />
                </ListItem>
                <ListItem data-testid="nav-user">
                  <SideNavText>
                    <PersonIcon />
                    {userIdentification}
                  </SideNavText>
                </ListItem>
              </List>
            </div>
          </SwipeableDrawerStyled>
        </div>
        {/*  TODO partnerLocation.name needs styling */}
        {stationName && (
          <StationHeader
            stationName
            partnerLocations={partnerLocation.categoryName}
            roleList
            ginza2style={isUsingGinza2Style.toString()}
          >
            {t(
              `StationsNames.${stationNameConverter(
                stationName,
                partnerLocation.categoryName,
                roleList
              )}`
            )}
          </StationHeader>
        )}
        {/*  TODO partnerLocation.name needs styling */}

        <RightNavItem>
          {currentStationId && <MultiPrinterSelectionContainer />}
          {isShowingAddNoteButton && currentStationId && (
            <PrivateNote isNavbarNote />
          )}

          {/* Uncomment LanguageSelector to show, not a MVP requirement */}
          {/* <LanguageSelector /> */}
          {/* <Box p={1} m={1}>
              <LogOut padding="11px 15px" />
            </Box>

            <Title>{userIdentification}</Title> */}
          {displayLiveEventSwitch && <LiveEventSwitch />}
          {isShowingExceptionButton && currentStationId && (
            <ExceptionNav resetStation={resetStation} />
          )}
          {stationName === 'Exception' &&
            userHasPsnadAccess() &&
            partnerLocation.hasPsnadStation &&
            currentStationId && (
              <PsnadButton
                psnadStationId={psnadStationId}
                partnerLocationId={partnerLocation.id}
              />
            )}
        </RightNavItem>
        {/* Uncomment LanguageSelector to show, not a MVP requirement */}
        {/* <LanguageSelector /> */}
        {isShowingHubSwitchNoStationId() &&
          !location.pathname.includes('psnad') && (
            <HubSwitch partnerLocations={partnerLocations} />
          )}
      </NavigationBar>
    </Suspense>
  );
};

export default NavBar;

NavBar.propTypes = {
  partnerLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  resetStation: PropTypes.func.isRequired,
};

const GinzaLogo = styled.img`
  height: auto;
  width: 100px;
  &:hover {
    cursor: pointer;
  }
`;

const GinzaLogoContainer = styled(Box)`
  display: none;
  @media only screen and (min-width: 769px) {
    display: inherit;
  }
`;

export const HubNameDiv = styled.div`
  margin-left: 15px;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: ${(props) => props.hubNameColor};
  padding: 6px;
`;

const SwipeableDrawerStyled = styled(SwipeableDrawer)`
  .MuiPaper-root {
    width: 295px;
    * {
      text-transform: uppercase;
      font-family: 'Montserrat';
    }
    ul:nth-of-type(2) li:first-of-type {
      cursor: pointer;
    }
  }
  ul li > * {
    font-size: 14px !important;
  }
  p {
    display: flex;
    align-items: center;
  }
  p svg {
    margin-right: 3px;
  }
`;
const NavigationBar = styled(Box)`
  z-index: 1000;
  height: ${NAVBAR_HEIGHT};
  padding: 0 1rem;
  position: fixed;
  background-color: #fff;
  top: 0;
  right: 0;
  left: 0;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  justify-content: space-between;
  -webkit-box-shadow: 0px 2px 9px -3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 2px 9px -3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 9px -3px rgba(0, 0, 0, 0.3);
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  &.gridThree {
    grid-template-columns: ${(props) =>
      props.ginza2style === 'true' ? 'repeat(50, auto)' : '1fr auto 1fr'};
    @media only screen and (max-width: 1366px) {
      grid-template-columns: ${(props) =>
        props.ginza2style === 'true' ? 'repeat(50, auto)' : 'repeat(3, auto)'};
    }
  }
`;

const StationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  align-self: center;
  margin: 0.2em 0;
  color: #333;
  font-size: ${(props) => (props.ginza2style === 'true' ? '1.2em' : '2.2em')};
  font-weight: 500;
  text-align: center;
  @media only screen and (max-width: 992px) {
    font-size: 20px;
  }
`;
const RightNavItem = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  align-items: center;
  align-items: center;
  @media only screen and (max-width: 600px) {
    width: 100%;
    justify-content: space-between;
  }
`;
