import React from 'react';
import { useTranslation } from 'react-i18next';
import StepModel from '../stepModel';
import ImageContainer from './ImageContainer';

import applicationImage1 from './img/group-8.png';
import applicationImage2 from './img/group-8@2x.png';
import applicationImage3 from './img/group-8@3x.png';
import appleStoreImage1 from './img/group.png';
import appleStoreImage2 from './img/group@2x.png';
import appleStoreImage3 from './img/group@3x.png';
import googlePlayImage1 from './img/group-2.png';
import googlePlayImage2 from './img/group-2@2x.png';
import googlePlayImage3 from './img/group-2@3x.png';

const googleAuth = {
  googlePlayHref:
    'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
  appleStoreHref:
    'https://apps.apple.com/us/app/google-authenticator/id388497605',
};

const DownloadAndInstall = () => {
  const { t } = useTranslation();

  return (
    <div spacing={2}>
      <StepModel
        stepNumber="1"
        title={t('account.security.2fa.step1.title')}
        content={t('account.security.2fa.step1.description')}
      >
        <div>
          <div>
            <ImageContainer
              width="-webkit-fill-available"
              height="199.9px"
              alt="google-authenticator"
              src={applicationImage1}
              srcSet={`${applicationImage2} 2x, ${applicationImage3} 3x`}
            />
          </div>
          <div
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <ImageContainer
              width="100%"
              height="55.1px"
              alt="google-store"
              src={googlePlayImage1}
              srcSet={`${googlePlayImage2} 2x, ${googlePlayImage3} 3x`}
              href={googleAuth.googlePlayHref}
            />
            <ImageContainer
              width="100%"
              height="55.1px"
              alt="apple-store"
              src={appleStoreImage1}
              srcSet={`${appleStoreImage2} 2x, ${appleStoreImage3} 3x`}
              href={googleAuth.appleStoreHref}
            />
          </div>
        </div>
      </StepModel>
    </div>
  );
};

export default DownloadAndInstall;
