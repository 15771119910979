import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import moment from 'moment';
import enTranslations from './enTranslations.json';
import frTranslations from './frTranslations.json';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: enTranslations,
      },
      fr: {
        translations: frTranslations,
      },
    },
    fallbackLng: 'en',

    debug: process.env.i18nDebug,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format(value, format, lng) {
        if (value instanceof Date) return moment(value).format(format);
        return value;
      },
    },

    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
