import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStateValue } from '../providers/StateProvider';

// component
import Routes from '../routes/Routes';
import Popup from './modals/Popup';
import LoadingAnimation from './modals/LoadingAnimation';
import PublicRoutes from '../routes/PublicRoutes';

const Root = () => {
  const [{ authToken, popup, loading }, dispatch] = useStateValue();
  const { t } = useTranslation();

  const clearError = () => dispatch({ type: 'changePopup', newPopup: false });

  const onPopupClick = () => {
    if (popup.errorCode === 'InterceptedOrder') {
      dispatch({
        type: 'openException',
        lpnOrTrackingEvtn: popup.scannedString,
        selectedException: popup.errorCode,
      });
    }
    clearError();
  };

  const [isNewPopup, setIsNewPopup] = useState();

  useEffect(() => {
    setIsNewPopup(true);
  }, [popup]);

  const PopupAlert = () =>
    popup ? (
      <Popup
        type={popup.type}
        title={popup.title}
        message={popup.message}
        messages={popup.messages}
        isNewPopUp={isNewPopup}
        setIsNewPopup={setIsNewPopup}
        isScrollable={popup.isScrollable}
        buttons={[
          {
            key: 'closeError',
            type: 'white',
            content: popup?.btnMessage || t('ButtonContent.Close'),
            onClick: onPopupClick,
          },
        ]}
        component={popup.component || null}
      />
    ) : null;

  return (
    <>
      {loading && <LoadingAnimation />}
      <PopupAlert />
      {authToken ? <Routes /> : <PublicRoutes />}
    </>
  );
};

export default Root;
