const stationsToDisplay = [
  'DockReceive',
  'ConfirmReceive',
  'QualityControl',
  'Authentication',
  'Packaging',
  'Shipping',
  'PackagingAndShipping',
  'DispatchScan',
];

const excludeStationsFromStationList = (stationList, stationsToExclude) => {
  return stationList?.filter(
    (station) => !stationsToExclude.includes(station?.name.replace(/ /g, ''))
  );
};

export const getStationsToDisplay = (stations, item) => {
  const isItemUsePackAndShip = item?.stationItems?.some(
    (stationItem) => stationItem.station.name === 'Packaging And Shipping'
  );

  const relevantStations = () => {
    if (isItemUsePackAndShip) {
      const stationsToFilterOut = ['Packaging', 'Shipping'];
      return stationsToDisplay.filter(
        (station) => !stationsToFilterOut.includes(station)
      );
    }

    const stationsToFilterOut = ['PackagingAndShipping'];
    return stationsToDisplay.filter(
      (station) => !stationsToFilterOut.includes(station)
    );
  };

  const displayedStations = stations?.filter((station) => {
    return relevantStations().includes(station?.name.replace(/\s/g, ''));
  });

  if (displayedStations) {
    displayedStations.sort((a, b) => a.displayOrder - b.displayOrder);
  }

  if (
    (item?.category?.name === 'HANDBAG' ||
      item?.category?.name === null ||
      item?.category?.name === undefined) &&
    item?.stationItems?.filter((stationItem) => {
      return stationItem?.station?.name === 'Authentication';
    }).length > 0
  ) {
    return displayedStations;
  }

  // hide the authentication station for Watches and Trading card in the status bar
  return excludeStationsFromStationList(displayedStations, [
    'Authentication',
  ]).map((station) => {
    if (
      item?.category?.name === 'WATCH' &&
      (station?.name === 'QualityControl' ||
        station?.name === 'Quality Control')
    ) {
      return { ...station, name: 'QualityControlAndAuthentication' };
    }
    return station;
  });
};

export default getStationsToDisplay;
