import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_ITEM_IMAGES } from '../../../../graphql/queries';

const useQueryItemImages = ({ objectExceptionId = null, itemId = null }) => {
  const [images, setImages] = useState([]);
  const { data: itemImagesData } = useQuery(QUERY_ITEM_IMAGES, {
    variables: {
      objectExceptionId,
      itemId,
    },
    fetchPolicy: 'no-cache',
  });

  const psnadItemImages = (itemImages) => {
    return itemImages
      .filter((itemImage) => itemImage.objectExceptionId === objectExceptionId)
      .filter((itemImage) => itemImage.isPsnad)
      .map((itemImage) => ({
        itemImageId: itemImage.itemImageId,
        title: itemImage.title,
        imageUrl: itemImage.imageUrl,
      }));
  };

  useEffect(() => {
    if (!itemImagesData) return;
    const imgs = psnadItemImages(itemImagesData.itemImages);
    setImages(imgs);
  }, [itemImagesData]);

  return { images, psnadItemImages };
};

export default useQueryItemImages;
