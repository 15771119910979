import React, { createContext, useMemo, useState } from 'react';
import useApolloResponse from '../hooks/useApolloResponse';

export const ApolloContext = createContext({});

// eslint-disable-next-line react/prop-types
const ApolloContextProvider = ({ children, useApolloNetworkStatus }) => {
  const [error, setError] = useState();
  const [hasPendingCalls, setHasPendingCalls] = useState(false);
  const { response, setResponse } = useApolloResponse(useApolloNetworkStatus);

  const value = useMemo(
    () => ({
      error,
      hasPendingCalls,
      setHasPendingCalls,
      setError,
      response,
      setResponse,
    }),
    [response, error, hasPendingCalls]
  );

  return (
    <ApolloContext.Provider value={value}>{children}</ApolloContext.Provider>
  );
};

function useApolloContext() {
  const context = React.useContext(ApolloContext);
  if (context === undefined) {
    throw new Error('useApolloContext must be used within a ApolloProvider');
  }
  return context;
}

export { ApolloContextProvider, useApolloContext };
