import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const StyledGrid = styled(Grid)`
  width: calc(100% - 4px) !important;
  height: 50px;
  padding-right: 16px;
  padding-bottom: 16px;
`;
StyledGrid.displayName = 'StyledGrid';

export const RelativeGrid = styled(Grid)`
  position: relative;
  margin-bottom: 5px;
  width: calc(100% - 4px);
  .statusCard {
    position: absolute;
    width: calc(100% + 4px);
    z-index: ${({ zindex }) => zindex};
  }
`;

RelativeGrid.displayName = 'RelativeGrid';
