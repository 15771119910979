import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Fab, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import CloseIcon from '@material-ui/icons/Close';
import NotesIcon from '@material-ui/icons/Notes';
import styled from 'styled-components';
import validator from 'validator/es';
import {
  FloatingNotesContainer,
  FloatingNotesButton,
  FloatingNotesComponent,
  IconContainter,
  NotesContainer,
  BoldTypography,
} from './index.styles';
import close from '../../../../assets/images/close.svg';
import InputButton from '../inputs/InputButton';
import stringIsNullOrEmpty from '../../../utils/stringIsNullOrEmpty';
import { NoteHeader } from '../../modals/PrivateNote';
import { useStateValue } from '../../../providers/StateProvider';
import { CREATE_PRIVATE_NOTES } from '../../../graphql/mutations';
import { QUERY_NOTABLE_NOTES } from '../../../graphql/queries';
import makeError from '../../../utils/makeError';
import { TextFieldFillHeight } from '../../components.styles';
import { timeZoneFormat } from '../../../utils/timeZoneFormat';
import { COLORS, INPUT_VALIDATION, POLL_INTERVALS } from '../../../constants';

const FloatingNotes = (props) => {
  const { readOnly, top, overWriteOrderId, overWriteItemId } = props;
  const [isShown, setIsShown] = useState(false);
  const [showAddNotes, setShowAddNotes] = useState(false);
  const [newUserNote, setNewUserNote] = useState('');
  const [noteInvalid, setNoteInvalid] = useState(false);
  const { t } = useTranslation();
  const [{ orderId, itemId, currentStationId }, dispatch] = useStateValue();
  const [createPrivateNotes, { loading: loadingCreatePrivateNotes }] =
    useMutation(CREATE_PRIVATE_NOTES);
  const reset = () => {
    setNewUserNote('');
  };

  const queryVariables = () => {
    const commonParams = {
      visibilities: ['UserNotes'],
      level: 'all',
    };
    return {
      notableId: overWriteItemId || itemId || overWriteOrderId || orderId || '',
      notableType: overWriteItemId || itemId ? 'Item' : 'Order',
      ...commonParams,
    };
  };

  const { data, refetch, error, errors } = useQuery(QUERY_NOTABLE_NOTES, {
    fetchPolicy: 'no-cache',
    pollInterval: POLL_INTERVALS.notes,
    variables: queryVariables(),
  });

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (data) scrollToBottom();
  }, [data]);

  const saveNote = async () => {
    if (stringIsNullOrEmpty(newUserNote)) {
      dispatch({
        type: 'changePopup',
        newPopup: {
          type: 'error',
          title: t(`PopupContent.Title.EmptyNote`),
          message: t(`PopupContent.Error.NoNoteEntered`),
          btnMessage: t('ButtonContent.Close'),
        },
      });
    }

    // replaces line breaks
    const sanitizedNote = newUserNote.replace(/\r?\n|\r/g, ' ');
    const isValid = validator.isAlphanumeric(sanitizedNote, 'en-US', {
      ignore: INPUT_VALIDATION.specialChar.floatingNote,
    });

    if (isValid) {
      try {
        await createPrivateNotes({
          variables: {
            note: newUserNote,
            orderId: overWriteOrderId || orderId,
            itemId: overWriteItemId || itemId,
            stationId: currentStationId || '',
          },
        });
        reset();
        await refetch();
      } catch (err) {
        makeError(err, dispatch, t);
      }
    } else {
      setNoteInvalid(true);
    }
  };

  useEffect(
    () => (error || errors) && makeError(error || errors, dispatch, t),
    [error || errors]
  );

  useEffect(() => {
    if (noteInvalid) {
      setTimeout(() => setNoteInvalid(false), 5000);
    }
  }, [noteInvalid]);

  useEffect(() => {
    if (loadingCreatePrivateNotes)
      dispatch({ type: 'changeLoading', newLoading: true });
    else dispatch({ type: 'changeLoading', newLoading: false });
  }, [loadingCreatePrivateNotes]);

  return (
    <>
      <FloatingNotesContainer width="450px" right="50px" isShown={isShown}>
        <FloatingNotesComponent
          top={`${top - 25}px`}
          marginTop={`${top}px`}
          width="450px"
          isShown={isShown}
        >
          <div>
            {data ? (
              <>
                <NotesContainer>
                  <NoteHeader>
                    <BoldTypography variant="h6">
                      {t('PrivateNote.NotesTitle', {
                        notesLength: data.queryNotableNotes.length,
                      })}
                    </BoldTypography>

                    <CloseBTN
                      id="note-close-button"
                      onClick={() => setIsShown(false)}
                      size="small"
                    >
                      <CloseIcon src={close} alt="close IMG Icon" />
                    </CloseBTN>
                  </NoteHeader>
                  <Box
                    sx={{
                      marginTop: '25px',
                      marginBottom: '25px',
                    }}
                  >
                    <Divider variant="fullWidth" />
                  </Box>
                  {showAddNotes ? (
                    <div>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          alignItems: 'stretch',
                          justifyContent: 'space-between',
                          component: 'span',
                        }}
                      >
                        <Box sx={{ width: '100%' }}>
                          <TextFieldFillHeight
                            erorr={noteInvalid}
                            FormHelperTextProps={{
                              style: {
                                color: COLORS.red,
                              },
                            }}
                            helperText={
                              noteInvalid
                                ? t(
                                    'GinzaV2.Error.Validation.AlphanumericSpecialChars',
                                    {
                                      specialChars:
                                        INPUT_VALIDATION.specialChar.floatingNote.trim(),
                                    }
                                  )
                                : ''
                            }
                            id="add-note-textfield"
                            data-testid="add-note-textfield"
                            name="note"
                            fullWidth
                            variant="outlined"
                            label={t('Exception.Notes')}
                            onChange={({ target }) =>
                              setNewUserNote(target.value)
                            }
                            value={newUserNote}
                            disabled={readOnly}
                            multiline
                            minRows={3}
                            maxRows={20}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          alignItems: 'stretch',
                          justifyContent: 'space-between',
                          gap: '15px',
                          component: 'span',
                          marginTop: '5px',
                        }}
                      >
                        <InputButton
                          id="add-note-button"
                          data-testid="add-note-button"
                          type="CRPrintLPN"
                          content={t('PrivateNote.AddNote')}
                          fullWidth
                          onClick={saveNote}
                          disabled={
                            stringIsNullOrEmpty(newUserNote) || readOnly
                          }
                        />
                        <InputButton
                          id="cancel-note-button"
                          data-testid="cancel-note-button"
                          type="cancelNotes"
                          content={t('ButtonContent.Cancel')}
                          fullWidth
                          onClick={() => setShowAddNotes(false)}
                        />
                      </Box>
                    </div>
                  ) : (
                    <Box
                      id="show-note-form-button"
                      data-testid="show-note-form-button"
                      sx={{
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: '600',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'flex-row',
                        justifyContent: 'center',
                      }}
                      onClick={() => setShowAddNotes(true)}
                    >
                      <CircleBlue>+</CircleBlue>
                      <Typography variant="h6">
                        {t('ButtonContent.AddNotes')}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    sx={{
                      marginTop: '25px',
                      height: 'calc(100vh - 300px)',
                      overflow: 'hidden',
                      overflowY: 'scroll',
                    }}
                  >
                    <Divider variant="fullWidth" />
                    {data?.queryNotableNotes?.map((note) => (
                      <div
                        key={`floating-note-id-${note.id}`}
                        data-testid="floating-note"
                      >
                        <Box
                          sx={{
                            marginTop: '15px',
                            marginBottom: '15px',
                          }}
                        >
                          <Box
                            sx={{
                              marginBottom: '5px',
                            }}
                          >
                            <Typography variant="body1">
                              {note.description}
                            </Typography>
                          </Box>
                          <Box fontWeight="600">
                            <BoldTypography variant="body1">
                              {note.firstName} {note.lastName} -{' '}
                              {timeZoneFormat(
                                note.createdAt,
                                null,
                                'hh:mm a - yyyy/MM/dd'
                              )}
                            </BoldTypography>
                          </Box>
                        </Box>
                        <Divider
                          sx={{ marginTop: '10px', marginBottom: '10px' }}
                        />
                      </div>
                    ))}
                  </Box>
                </NotesContainer>
              </>
            ) : null}
          </div>
        </FloatingNotesComponent>
      </FloatingNotesContainer>
      <FloatingNotesContainer width="50px" right="0px" isShown>
        <FloatingNotesButton
          data-testid="FloatingNotesButton"
          top={`${top}px`}
          marginTop={`${top + 25}px`}
          width="50px"
          onClick={() => setIsShown(!isShown)}
          isShown={isShown}
        >
          <IconContainter isShown={isShown}>
            <NotesIcon />
          </IconContainter>
        </FloatingNotesButton>
      </FloatingNotesContainer>
    </>
  );
};

FloatingNotes.defaultProps = {
  overWriteOrderId: null,
  overWriteItemId: null,
  readOnly: false,
  top: 0,
};

FloatingNotes.propTypes = {
  overWriteOrderId: PropTypes.string,
  overWriteItemId: PropTypes.string,
  top: PropTypes.number,
  readOnly: PropTypes.bool,
};

export default FloatingNotes;

const CloseBTN = styled(Fab)`
  align-self: center;
  background-color: #fff !important;
  border: 0px;
  border-radius: 0 !important;
  box-shadow: none !important;
  margin-left: 1em !important;
`;

const CircleBlue = styled.span`
  display: inline-block;
  color: ${COLORS.white};
  align-self: center;
  background-color: ${COLORS.blue} !important;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 20px;
  margin-right: 10px;
`;
