import styled from 'styled-components';

export const BadgeWrapper = styled.div`
  position: relative;
  z-index: 1;
  left: -30px;
  margin-right: -30px;
  top: -15px;
  transform: scale(0.7);
`;

export default BadgeWrapper;
