import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  descriptionTitleContainer: {
    height: '48px',
    color: 'white',
    backgroundColor: '#7C858E',
    borderRadius: '5px',
  },
  descriptionContainer: {
    marginBottom: '15px',
  },
  daysSinceReported: {
    width: 'fit-content',
    height: '25px',
    padding: '1px 5px',
    backgroundColor: '#EFF2F5',
    border: 'solid #E1E3E6 1px',
    borderRadius: '5px',
    minWidth: '12px',
    textAlign: 'center',
  },
});

export default useStyles;
