import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ButtonContext = createContext({});

export const ButtonProvider = ({ children }) => {
  const [save, setSaveState] = useState({ open: false });
  const [fail, setFailState] = useState({ open: false });
  const [finish, setFinishState] = useState({ open: false });
  const [passed, setPassedState] = useState({ open: false });
  const [showOnEbay, setShowOnEbayState] = useState({ open: false });
  const [miscategorized, setMiscategorizedState] = useState({ open: false });

  const setState = (setMethod, { show, disabled, value }) => {
    setMethod((prev) => {
      return {
        disabled: disabled ?? prev.disabled,
        show: show ?? prev.show,
        value: value ?? prev.value,
      };
    });
  };

  const setSaveButton = ({ show }) => {
    setState(setSaveState, { show });
  };

  const setFailButton = ({ show, disabled }) => {
    setState(setFailState, { show, disabled });
  };

  const setFinishButton = ({ show, onClick, disabled }) => {
    setState(setFinishState, { show, onClick, disabled });
  };

  const setPassedButton = ({ disabled, show }) => {
    setState(setPassedState, { show, disabled });
  };

  const setShowOnEbayButton = ({ show }) => {
    setState(setShowOnEbayState, { show });
  };

  const setMiscategorizedButton = ({ show, value }) => {
    setState(setMiscategorizedState, { show, value });
  };

  return (
    <ButtonContext.Provider
      value={{
        save,
        fail,
        finish,
        passed,
        showOnEbay,
        miscategorized,
        setSaveButton,
        setFailButton,
        setFinishButton,
        setPassedButton,
        setShowOnEbayButton,
        setMiscategorizedButton,
      }}
    >
      {children}
    </ButtonContext.Provider>
  );
};

ButtonProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
