import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  carouselContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 100,
  },
  linkContainer: {
    zIndex: 100,
    position: 'absolute',
    top: '4%',
    alignSelf: 'flex-end',
  },
  rightContainer: {
    display: 'flex',
    gap: '16px',
    flexDirection: 'column',
  },
  rightFooterButtonsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: '20px',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
  },

  //  Image Slider Modal ---->

  modalBackground: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0%',
    left: '0%',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#eff2f5e0',
    zIndex: 1700,
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    height: '80%',
    maxHeight: '800px',
    minWidth: '900px',
    overflow: 'hidden',
    borderRadius: '4px',
    boxShadow: '0 0 17px -5px rgba(0, 0, 0, 0.35)',
    border: 'solid 1px #e1e3e6',
    backgroundColor: '#fff',
  },
  modalTop: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    flex: '0 0 5%',
    height: '2.5%',
    padding: '10px',
  },
  modalCenter: {
    display: 'flex',
    flexDirection: 'row',
    flex: '0 0 90%',
    overflow: 'auto',
  },
  modalBottom: {
    width: '100%',
    backgroundColor: 'white',
  },
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    padding: '20px',
  },
  centerImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: '#eff2f5',
    overflow: 'hidden',
  },
  leftArrowContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '10%',
    height: '100%',
  },
  rightArrowContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '10%',
    height: '100%',
  },
  bottomImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    marginTop: '3%',
    backgroundColor: 'white',
  },
  arrowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60px',
    width: '60px',
    padding: '0px',
  },
  arrow: {
    transform: 'scale(1.5)',
  },
  mainImage: {
    padding: '30px',
    overflow: 'auto',
    maxWidth: '600px',
  },
  selectImageContainer: {
    display: 'flex',
    position: 'relative',
    minWidth: '70px',
    minHeight: '70px',
    marginRight: '5px',
    backgroundColor: 'white',
    border: 'solid 1px blue',
    objectFit: 'contain',
  },
  unselectImageContainer: {
    display: 'flex',
    position: 'relative',
    minWidth: '70px',
    minHeight: '70px',
    marginRight: '5px',
    backgroundColor: 'white',
    border: 'solid 1px #e1e3e6',
    objectFit: 'contain',
    opacity: '0.3',
  },

  imageThumb: {
    flexGrow: '1',
    margin: 'auto',
    width: '100%',
    maxWidth: '65px',
    maxHeight: '65px',
  },
  psnadTag: {
    position: 'absolute',
    display: 'flex',
    alignSelf: 'flex-start',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    width: '69px',
    height: '25px',
    backgroundColor: '#c01',
  },
  pTag: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    width: '15px',
    height: '15px',
    fontSize: '12px',
    backgroundColor: '#c01',
  },
  unselectedThumb: {
    opacity: '0.3',
  },
  selectedThumb: {
    border: 'solid 1px blue',
  },
});

export default useStyles;
