import React from 'react';
import { useForm } from 'react-hook-form';
import { Container } from '@material-ui/core';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ginzaLogo from '../../assets/images/ginza_logo.svg';

// provider
import { useStateValue } from '../providers/StateProvider';
import InputText from './reusables/inputs/InputText';
import { FlexContainerColumn } from './reusables/StyledComponent';
import InputButton from './reusables/inputs/InputButton';
import Button from './reusables/button/Button';

// Uncomment LanguageSelector to show, not a MVP requirement
// import LanguageSelector from './reusables/selectors/LanguageSelector';

const SignIn = () => {
  const { t } = useTranslation();
  const dispatch = useStateValue()[1];
  const { handleSubmit, control, errors: fieldsErrors } = useForm();
  const history = useHistory();

  const showError = (error) => {
    let title = null;
    let message;

    switch (error.response.status) {
      case 400:
        message = t('PopupContent.Error.NotConnected');
        break;
      case 429:
        message = t('PopupContent.Error.TooManyRequests');
        break;
      case 403:
        message = t('PopupContent.Error.ExpiredPassword');
        break;
      case 423:
        message = t('PopupContent.Error.AccountLocked');
        break;
      case 406:
        title = t('PopupContent.Error.IncorrectLogin');
        message = t('PopupContent.Error.TooManyAttempts');
        break;
      case 401:
        message = t('PopupContent.Error.Unauthorized');
        break;
      default:
        message = t('PopupContent.Error.DefaultError');
    }

    dispatch({
      type: 'changePopup',
      newPopup: {
        type: 'error',
        title: title || `${error.response.status} ${error.response.statusText}`,
        message,
        btnMessage: t('ButtonContent.Close'),
      },
    });
  };
  const getRestToken = async ({ password, username }) => {
    dispatch({ type: 'changeLoading', newLoading: true });
    try {
      const grantType = 'password';
      const { data } = await axios({
        method: 'post',
        url: '/api/sessions',
        data: { username, password, grantType },
      });
      const {
        access_token: initToken,
        refresh_token: refreshToken,
        expires_in: tokenExpiresIn,
        created_at: tokenCreatedAt,
      } = data;
      const tokenExpiryTime = tokenExpiresIn + tokenCreatedAt;

      sessionStorage.setItem('authToken', initToken);
      dispatch({ type: 'changeAuthToken', newAuthToken: initToken });
      sessionStorage.setItem('refreshToken', refreshToken);
      dispatch({ type: 'changeRefreshToken', newRefreshToken: refreshToken });
      sessionStorage.setItem('tokenExpiryTime', tokenExpiryTime);
      dispatch({
        type: 'changeTokenExpiryTime',
        newTokenExpiryTime: tokenExpiryTime,
      });
    } catch (error) {
      showError(error);
    }
    dispatch({ type: 'changeLoading', newLoading: false });
  };

  const handleForgotPassword = () => {
    history.push('/resetPassword');
  };

  return (
    <Container component="main" maxWidth="xs">
      <FlexContainerColumn padding="4em 0 0 0">
        <img
          src={ginzaLogo}
          alt="ginza logo"
          data-testid="ginzaLogoSignIn"
          style={{ width: '280px' }}
        />
        <br />
        <form noValidate onSubmit={handleSubmit(getRestToken)}>
          <InputText
            type="email"
            name="username"
            control={control}
            label={t('SignIn.UsernameEmail.Label')}
            placeholder={t('SignIn.UsernameEmail.Placeholder')}
            fieldsErrors={fieldsErrors}
            required
          />
          <InputText
            type="password"
            name="password"
            control={control}
            label={t('SignIn.Password.Label')}
            placeholder={t('SignIn.Password.Placeholder')}
            fieldsErrors={fieldsErrors}
            required
          />
          <br />
          <br />
          <InputButton
            id="login-button"
            dataTestId="login-button"
            fullWidth
            content={t('SignIn.SignIn')}
          />
        </form>
        <br />
        <Button
          data-testid="forgot-password-btn"
          content={t('SignIn.Password.ForgotPassword')}
          type="cancel"
          onClick={handleForgotPassword}
        />
        {/* Uncomment LanguageSelector to show, not a MVP requirement */}
        {/* <LanguageSelector /> */}
      </FlexContainerColumn>
    </Container>
  );
};

export default SignIn;
