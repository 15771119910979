import React from 'react';
import { Grid, ListItem, Typography } from '@material-ui/core';
import { List } from '@codeboxxtechschool/ginza_shared_components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useStyles from './useStyles';
import useDescriptionList from './useDescriptionList';

const DescriptionList = ({
  title,
  list,
  withTitleContainer,
  customListItem,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { stringToKey } = useDescriptionList();

  return (
    <>
      <div
        data-testid={`description-container-${title}`}
        className={classes.descriptionContainer}
      >
        <div
          className={
            withTitleContainer ? `${classes.descriptionTitleContainer}` : ''
          }
        >
          <Typography
            variant="h6"
            style={{
              marginLeft: withTitleContainer ? '16px' : '0',
              lineHeight: '2.5',
            }}
          >
            {title}
          </Typography>
        </div>
        <div
          id="description-list-item"
          data-testid="description-list-item"
          style={{ padding: withTitleContainer ? '0px 16px' : '0' }}
        >
          <List
            color="#2e3f52"
            dividerColor="#E1E3E6"
            bottomDivider
            topDivider={false}
            width="100%"
          >
            {customListItem}
            {list && list.length > 0 ? (
              list.map(({ key, name, value }) => {
                const identifier = `description-list-${
                  key || stringToKey(name)
                }`;
                const isDaysSinceReported =
                  (key || stringToKey(name)).replace(/\./g, '-') ===
                  'psnad-psnaddetails-dayssincereported';

                return (
                  <ListItem
                    key={identifier}
                    id={identifier}
                    data-testid={identifier}
                    style={{ paddingLeft: 0 }}
                  >
                    <Grid container item xs={12} sm={12} direction="row">
                      <Grid item xs={4} sm={4}>
                        <Typography
                          data-testid="list-item-name"
                          variant="body1"
                        >
                          <strong>{t(name)}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8}>
                        <div
                          className={
                            isDaysSinceReported
                              ? `${classes.daysSinceReported}`
                              : ''
                          }
                        >
                          <Typography
                            data-testid="list-item-value"
                            variant="body1"
                          >
                            {value || '- -'}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })
            ) : (
              <Typography variant="body1" style={{ fontStyle: 'italic' }}>
                {t('GinzaV2.NoInformationAtThisTime')}
              </Typography>
            )}
          </List>
        </div>
      </div>
    </>
  );
};

export default DescriptionList;

DescriptionList.defaultProps = {
  title: '',
  withTitleContainer: true,
  customListItem: <></>,
  list: [],
};

DescriptionList.propTypes = {
  title: PropTypes.string,
  withTitleContainer: PropTypes.bool,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  customListItem: PropTypes.node,
};
