/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormButton from '../FormButton';

const defaultProps = {
  disabled: false,
  width: '100%',
};

const CancelButton = ({ onClick, disabled, width }) => {
  const { t } = useTranslation();
  return (
    <FormButton
      id="cancel-button"
      disabled={disabled}
      onClick={onClick}
      width={width}
      divWith="50%"
    >
      <span>{t('account.scanForm.button.cancel')}</span>
    </FormButton>
  );
};

CancelButton.defaultProps = defaultProps;

export default CancelButton;
