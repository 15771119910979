import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${({ borderColor }) => borderColor};
  padding: 0.5em 0.5em 0.5em 0.5em;
`;
Container.displayName = 'Container';

export const Label = styled.label`
  font-weight: bold;
`;
Label.displayName = 'Label';

// change for cherry pick
