import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useErrorNotification } from '@codeboxxtechschool/ginza_shared_components';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import GlobalLoadingIndicator from './GlobalLoadingIndicator';

export function QueryProvider({ children }) {
  const [axiosError, setAxiosError] = useState();
  const { create } = useErrorNotification();

  const [queryClient] = useState(
    () =>
      new QueryClient({
        mutationCache: new MutationCache({
          onError: (error) => {
            if (error.isAxiosError) setAxiosError(error);
          },
        }),
        queryCache: new QueryCache({
          onError: (error) => {
            if (error.isAxiosError) setAxiosError(error);
          },
        }),
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: 22000,
          },
        },
      })
  );

  useEffect(() => {
    if (axiosError) {
      const { response, stack } = axiosError;
      const { data, status } = response;
      if (status > 499 || status < 200) {
        create(
          axiosError.message,
          JSON.stringify(isEmpty(data) ? stack : data)
        );
      }

      setAxiosError(null);
    }
  }, [axiosError]);

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalLoadingIndicator />
      {children}
    </QueryClientProvider>
  );
}

QueryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default QueryProvider;
