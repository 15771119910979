/* eslint-disable react/prop-types */
import React from 'react';
import { PageHeaderContent, PageHeaderTitle } from './PageHeader.styles';

const PageHeader = ({ title, content }) => {
  return (
    <PageHeaderContent>
      <PageHeaderTitle>{title}</PageHeaderTitle>
      <br />
      {content}
    </PageHeaderContent>
  );
};

export default PageHeader;
