import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { ImageList, ImageListItem, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  CloseIcon,
  ModalCardContent,
  PBiggerFont,
  TextCenter,
} from '../reusables/StyledComponent';
import InputButton from '../reusables/inputs/InputButton';
import InputText from '../reusables/inputs/InputText';
import { COLORS } from '../../constants';
import BeepTest from '../../../assets/audio/beepTest.mp3';
import { StyledIconButton } from '../reusables/modal/index.styles';

const errorSound = new Audio(BeepTest);

const useStyles = makeStyles({
  popupHeader: {
    position: 'absolute',
    bottom: '100%',
    left: '0px',
    height: '20px',
    width: '100%',
    backgroundColor: COLORS.yellow,
  },
  popupMinWidth: {
    width: '800px',
    maxWidth: '90%',
  },
});

const Popup = (props) => {
  const {
    title,
    subtitle,
    message,
    messages,
    buttons,
    list,
    type,
    onClickAway,
    textField,
    onSubmit,
    size,
    component,
    reValidate,
    open,
    isNewPopUp,
    setIsNewPopup,
    setCapsLock,
    capsLock,
    isScrollable,
    status,
    note,
    children,
    onClickCloseButton,
    popupMinWidth,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    if (type === 'error' && open && isNewPopUp) {
      errorSound
        .play()
        .then()
        .catch(() => console.error('play() promise rejected'));
      setIsNewPopup(false);
    }
  }, [open]);

  const [theme] = useState(() => {
    if (type === 'error') {
      return { font: COLORS.white, background: COLORS.red, lineHeight: '175%' };
    }
    if (type === 'success')
      return {
        font: COLORS.white,
        background: COLORS.darkGreen,
        lineHeight: '120%',
      };
    if (type === 'warning')
      return {
        font: COLORS.white,
        background: COLORS.orange,
        lineHeight: '120%',
      };
    return { font: COLORS.black, background: COLORS.white, lineHeight: '120%' };
  });

  const {
    control,
    handleSubmit,
    errors: fieldsErrors,
  } = useForm({
    reValidateMode: reValidate,
  });

  return (
    <ModalCardContent
      onClose={type === 'error' ? null : onClickAway}
      open={open}
    >
      <PopupContent
        size={size}
        bgColor={`${theme.background}`}
        fontColor={`${theme.font}`}
        popupMinWidth={popupMinWidth}
      >
        {onClickCloseButton !== null && (
          <StyledIconButton
            id="close-button-icon"
            edge="start"
            color="inherit"
            onClick={onClickCloseButton}
            aria-label="close"
            size="small"
            top="35px"
            right="25px"
          >
            <CloseIcon />
          </StyledIconButton>
        )}
        {type === 'successWarning' && (
          <div className={classes.popupHeader}>{subtitle}</div>
        )}
        <h2
          style={{
            textAlign: onClickCloseButton !== null ? 'left' : 'inherit',
            width: onClickCloseButton !== null ? 'calc(100% - 30px)' : 'auto',
            paddingRight: onClickCloseButton !== null ? '30px' : '0px',
          }}
        >
          {title}
        </h2>
        {subtitle !== null && <h4>{subtitle}</h4>}
        {component !== null && component()}
        {message !== null && (
          <PBiggerFont lineHeight={`${theme.lineHeight}`} wordWrap>
            {message}
          </PBiggerFont>
        )}
        {status !== null && <TextCenter>{status}</TextCenter>}
        {note !== null && <p>{note}</p>}
        {messages !== null && (
          <Box
            display="flex"
            sx={{
              display: isScrollable ? 'flex' : 'bloc',
              overflow: isScrollable ? 'auto' : 'visible',
              justifyContent: isScrollable ? 'center' : '',
              maxHeight: isScrollable ? '50vh' : 'none',
            }}
          >
            <Box
              sx={{
                width: isScrollable ? '90%' : 'auto',
              }}
            >
              <ImageList rowHeight="auto">
                {messages.map((msg) => (
                  <ImageListItem key={msg.i} style={{ width: '100%' }}>
                    <p
                      id={`popup-message-${msg.i}`}
                      data-id="popup-message"
                      style={{ margin: '0 1em 0.5em' }}
                    >
                      {msg.text}
                    </p>
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
          </Box>
        )}
        {children}

        {list !== null && (
          <ul
            style={{
              textAlign: 'left',
              paddingBottom: '10px',
            }}
          >
            {list.map((listItem) => (
              <li key={listItem.key}>{listItem.content}</li>
            ))}
          </ul>
        )}
        <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
          {textField !== null ? (
            <InputText
              required={!!textField?.required}
              validation={textField?.validation ? textField.validation : {}}
              control={control}
              fieldsErrors={fieldsErrors}
              name={textField.name}
              label={textField.label}
              rows={textField.rows ? textField.rows : 0}
              setCapsLock={setCapsLock}
              capsLock={capsLock}
            />
          ) : null}
          {buttons.map((btn) => (
            <InputButton
              id={btn.key}
              renderAs={btn.renderAs || 'button'}
              margin="0.4em 0 0 0"
              key={btn.key}
              onClick={btn.onClick || null}
              type={btn.type || 'default'}
              content={btn.content}
              fullWidth
              disabled={
                btn.buttonDisabled
                  ? btn.buttonDisabled
                  : capsLock.isCapsLock && capsLock.isEnabled
              }
            />
          ))}
        </form>
      </PopupContent>
    </ModalCardContent>
  );
};

export default Popup;

Popup.defaultProps = {
  size: 'large',
  type: 'white',
  onClickAway: null,
  onSubmit: null,
  textField: null,
  component: null,
  message: null,
  messages: null,
  reValidate: 'onChange',
  open: true,
  isNewPopUp: false,
  setIsNewPopup: null,
  setCapsLock: () => {},
  capsLock: { isCapsLock: false, isEnabled: false },
  list: [],
  subtitle: null,
  isScrollable: false,
  status: null,
  note: null,
  children: null,
  onClickCloseButton: null,
  popupMinWidth: false,
};

Popup.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  onClickAway: PropTypes.func,
  type: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      renderAs: PropTypes.string,
      onClick: PropTypes.func,
      type: PropTypes.string,
      content: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  ),
  onSubmit: PropTypes.func,
  textField: PropTypes.shape({
    required: PropTypes.bool,
    validation: PropTypes.shape({}),
    multiline: PropTypes.bool,
    name: PropTypes.string,
    rows: PropTypes.number,
    label: PropTypes.string.isRequired,
  }),
  size: PropTypes.string,
  component: PropTypes.func,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      i: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  reValidate: PropTypes.string,
  open: PropTypes.bool,
  isNewPopUp: PropTypes.bool,
  setIsNewPopup: PropTypes.func,
  setCapsLock: PropTypes.func,
  capsLock: PropTypes.shape({
    isCapsLock: PropTypes.bool,
    isEnabled: PropTypes.bool,
  }),
  subtitle: PropTypes.string,
  isScrollable: PropTypes.bool,
  status: PropTypes.string,
  note: PropTypes.string,
  children: PropTypes.node,
  onClickCloseButton: PropTypes.func,
  popupMinWidth: PropTypes.bool,
};

const PopupContent = styled.div`
  position: relative;
  text-align: center;
  background-color: ${(props) => props.bgColor} !important;
  color: ${(props) => props.fontColor} !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  min-width: 350px;
  padding: 16px 20px;
  max-width: ${(props) => (props.size === 'large' ? '35em' : '20em')};
  // max-height: 15em;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  &:focus {
    outline: 0;
  }
  ${(props) => (props.popupMinWidth ? 'width: 675px; max-width: 90%;' : null)}
`;
