import React from 'react';
import CenteredContainer from '../../reusables/centeredContainer';
import CodeVerification from '../googleAuthenticator/CodeVerification';

const Verification = () => {
  return (
    <CenteredContainer>
      <div>
        <CodeVerification />
      </div>
    </CenteredContainer>
  );
};

export default Verification;
