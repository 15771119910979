import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useStyles from './useStyles';

const ItemConditionPill = ({ backgroundColor, condition }) => {
  const classes = useStyles();
  return (
    <div className={classes.itemConditionPill} style={{ backgroundColor }}>
      <Typography variant="body1">
        <strong>{condition}</strong>
      </Typography>
    </div>
  );
};

export default ItemConditionPill;

ItemConditionPill.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  condition: PropTypes.string.isRequired,
};
