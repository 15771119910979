import { Fade } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { StyledPopper } from './index.styles';
import { COLORS } from '../../../constants';

const Popper = ({ anchorEl, backgroundColor, children, color, id, open }) => {
  return (
    <StyledPopper
      anchorEl={anchorEl}
      backgroundcolor={backgroundColor}
      color={color}
      id={id}
      open={open}
      placement="bottom-start"
      transition
    >
      {({ TransitionProps }) => (
        <Fade
          in={TransitionProps.in}
          onEnter={TransitionProps.onEnter}
          onExited={TransitionProps.onExited}
          timeout={150}
        >
          <Box>{children}</Box>
        </Fade>
      )}
    </StyledPopper>
  );
};

Popper.defaultProps = {
  backgroundColor: COLORS.darkGray,
  color: COLORS.white,
  anchorEl: null,
};

Popper.propTypes = {
  anchorEl: PropTypes.instanceOf(Element),
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
};

export default Popper;
