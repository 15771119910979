/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormButton from '../FormButton';

const defaultProps = {
  disabled: false,
  width: '100%',
};

const VerifyButton = ({ onClick, disabled, width }) => {
  const { t } = useTranslation();
  return (
    <FormButton id="verify-button" disabled={disabled} onClick={onClick} width={width}>
      <span>{t('account.scanForm.button.verify')}</span>
    </FormButton>
  );
};

VerifyButton.defaultProps = defaultProps;

export default VerifyButton;
