const usePredefinedNotesHooks = () => {
  const handleOnAddPredefinedNote = (n, message, setter) => {
    if (message.length === 0) {
      setter(n);
    } else {
      const appendedNote = `${message}\n${n}`;
      setter(appendedNote);
    }
  };

  return { handleOnAddPredefinedNote };
};

export default usePredefinedNotesHooks;
