import React, { useState, useEffect } from 'react';
import { Icon } from '@codeboxxtechschool/ginza_shared_components';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import UploadModal from './UploadModal';
import PhotoPreviewer from '../photoPreviewer';
import {
  DELETE_ITEM_IMAGE,
  UPLOAD_ITEM_IMAGES,
} from '../../../../graphql/mutations';
import useQueryItemImages from '../../../stations/PSNAD/hooks/useQueryItemImages';
import useStyles from './useStyles';

const PhotoUpload = ({ uploadParams, onFailure }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [uploadedPhotos, setUploadedPhotos] = useState([]);

  const [uploadItemImages] = useMutation(UPLOAD_ITEM_IMAGES);
  const [deleteItemImage] = useMutation(DELETE_ITEM_IMAGE);
  const { images, psnadItemImages } = useQueryItemImages({
    objectExceptionId: uploadParams.objectExceptionId,
  });

  const deletePhoto = (photoToDelete) => {
    // Update photoPreview UI to display correct photos
    const remainingPhotos = [...uploadedPhotos].filter(
      (photo) => photo.itemImageId !== photoToDelete.itemImageId
    );
    setUploadedPhotos(remainingPhotos);

    if (photoToDelete.itemImageId) {
      deleteItemImage({
        variables: {
          itemImageId: photoToDelete.itemImageId,
        },
      })
        .then((res) => {
          const updatedPhotos = res.data.deleteItemImage?.allItemImages;
          setUploadedPhotos(psnadItemImages(updatedPhotos));
        })
        .catch(() => {
          onFailure({
            type: 'error',
            message: t('PSNAD.Notification.Error.UnableToDeletePhoto'),
          });
        });
    }
  };
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleUploadPhoto = (photoBlobs, resetModal) => {
    uploadItemImages({
      variables: {
        ...uploadParams,
        attachments: photoBlobs,
      },
    })
      .then((res) => {
        const itemImages = res.data.uploadItemImages.allItemImages;
        const psnadPhotos = psnadItemImages(itemImages);
        setUploadedPhotos(psnadPhotos);
      })
      .catch(() =>
        onFailure({
          type: 'error',
          message: t('PSNAD.Notification.Error.UnableToUploadPhoto'),
        })
      )
      .finally(() => {
        handleCloseModal();
        resetModal();
      });
  };

  useEffect(() => {
    if (!images) return;
    setUploadedPhotos(images);
  }, [images]);

  return (
    <>
      {openModal && (
        <UploadModal
          open={openModal}
          onClose={handleCloseModal}
          onUpload={handleUploadPhoto}
          uploadedPhotos={uploadedPhotos}
        />
      )}
      <div className={classes.photoUploadContainer}>
        <div className={classes.photoUploadCtaContainer}>
          <Typography variant="subtitle1">
            {t('PSNAD.PhotoUpload.Prompt')}
          </Typography>
          <button
            type="button"
            className={classes.photoUploadBtn}
            onClick={handleOpenModal}
          >
            <Icon
              backgroundColor="transparent"
              color="black"
              name="addPhoto"
              size="xSmall"
            />
            <p className="photo-upload-text">{t('GinzaV2.Button.AddPhoto')}</p>
          </button>
        </div>
        {uploadedPhotos.length > 0 && (
          <PhotoPreviewer
            photos={uploadedPhotos}
            deletePhoto={deletePhoto}
            readOnly={false}
          />
        )}
      </div>
    </>
  );
};

export default PhotoUpload;

PhotoUpload.propTypes = {
  uploadParams: PropTypes.shape({
    itemId: PropTypes.string,
    objectExceptionId: PropTypes.string,
    uploadToZoomApi: PropTypes.bool,
    type: PropTypes.string,
  }).isRequired,
  onFailure: PropTypes.func.isRequired,
};
