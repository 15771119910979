import { makeStyles } from '@material-ui/core';
import { COLORS } from '@codeboxxtechschool/ginza_shared_components/dist/theme';

const useStyles = makeStyles({
  container: {
    marginTop: '5px',
  },
  predefinedNoteTextArea: {
    marginTop: '24px',
    borderBottom: `1px ${COLORS.blue500} solid`,
  },
  textArea: {
    resize: 'both',
  },
  buttonsContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    columnGap: '24px',
    marginTop: '24px',
  },
});

export default useStyles;
