import React, {
  createContext,
  useMemo,
  useState,
} from 'react';

import { ThemeProvider } from '@codeboxxtechschool/ginza_shared_components';
import {
  post2faOtpCreate,
  post2faOtpEnable,
  post2faOtpVerify,
} from '../components/twoFactorAuthentication/rest/2FA';

export const MFAContext = createContext({});

// eslint-disable-next-line react/prop-types
const MFAProvider = ({ children }) => {
  const [isEnabled2faOpt, setIsEnabled2faOpt] = useState(false);
  const [properties2faOtp, setProperties2faOtp] = useState(null);

  const create2faOtp = async () => {
    const response = await post2faOtpCreate();
    const { status, data } = response;

    switch (status) {
      case 201:
      case 200:
        setProperties2faOtp({
          id: data?.message?.id,
          otpSecret: data?.message?.otp_secret,
          provisioningUri: data?.message?.provisioning_uri,
          user: {
            email: data?.message?.user?.email,
          },
          isEnabled: false,
        });
        break;
      default:
      // TODO handle error notification
    }
  };

  const enable2faOtp = async (enable2faBody) => {
    setIsEnabled2faOpt(false);
    const response = await post2faOtpEnable(enable2faBody);
    const { status, data } = response;

    switch (status) {
      case 200:
        setIsEnabled2faOpt(true);
        return data;
      default:
        return null;
      // TODO handle error notification
    }
  };

  const verify2faOtp = async (verify2faBody) => {
    const response = await post2faOtpVerify(verify2faBody);
    const { status, data } = response;

    switch (status) {
      case 200:
        return data;
      default:
        return null;
      // TODO handle error notification
    }
  };

  const value = useMemo(
    () => ({
      isEnabled2faOpt,
      properties2faOtp,
      create2faOtp,
      enable2faOtp,
      verify2faOtp,
    }),
    [isEnabled2faOpt, properties2faOtp]
  );

  return <MFAContext.Provider value={value}>{children}</MFAContext.Provider>;
};

function useMFAContext() {
  const context = React.useContext(MFAContext);
  if (context === undefined) {
    throw new Error('useMFAContext must be used within a AppProvider');
  }
  return context;
}

function withMFAContext(Component) {
  return () => {
    return (
      <ThemeProvider>
        <MFAProvider>
          <Component />
        </MFAProvider>
      </ThemeProvider>
    );
  };
}

export { MFAProvider, useMFAContext, withMFAContext };
