import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import StepModel from '../stepModel';
import CenteredContainer from '../../../reusables/centeredContainer';
import { QRCodeContainer } from './index.styles';
import SecretCodeContainer from './SecretCodeContainer';
import { useMFAContext } from '../../../../context/MFAContext';

const AddAccount = () => {
  const { t } = useTranslation();
  const { create2faOtp, properties2faOtp } = useMFAContext();

  useEffect(() => {
    async function fetchData() {
      await create2faOtp();
    }
    fetchData();
  }, []);

  console.log(properties2faOtp);

  return (
    <div>
      <StepModel
        stepNumber="2"
        title={t('account.security.2fa.step2.title')}
        content={t('account.security.2fa.step2.description')}
      >
        <div>
          <CenteredContainer>
            {properties2faOtp?.otpSecret && (
              <Box mb={2}>
                <QRCodeContainer>
                  <QRCode
                    size={200}
                    value={properties2faOtp?.provisioningUri}
                  />
                </QRCodeContainer>
                <SecretCodeContainer
                  title={t('account.security.2fa.step2.secretCode')}
                  value={properties2faOtp?.otpSecret}
                />
              </Box>
            )}
          </CenteredContainer>
        </div>
      </StepModel>
    </div>
  );
};

export default AddAccount;
