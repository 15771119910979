import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  Container,
  IconGrid,
  StationNameGrid,
  StyledGrid,
} from './index.styles';
import Popper from '../../../../reusables/popper';
import { COLORS, EXCEPTION_STATUS } from '../../../../../constants';
import { timeZoneFormat } from '../../../../../utils/timeZoneFormat';
import collectAllExceptions from '../../../../../utils/collectAllExceptions';

const StationStatus = ({
  stationItem,
  item,
  stationId,
  className,
  backgroundColor,
  icon,
  bottomLabel,
  name,
  topLabel,
  middleLabel,
  isExceptionStation,
  resolvedIcon,
  exceptionLabel,
}) => {
  const { t } = useTranslation();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const onMouseEnter = (event) => {
    if (!anchorEl) setAnchorEl(event.currentTarget);
    setPopoverOpen(true);
  };

  const onMouseLeave = () => {
    setPopoverOpen(false);
  };

  const isExceptionStatusHasValue = !!(
    isExceptionStation && topLabel !== 'N/A'
  );

  const allObjectExceptions = collectAllExceptions(item);

  const objectExceptions = allObjectExceptions
    ?.filter(
      (objectException) =>
        objectException.exceptionStatus === EXCEPTION_STATUS.Resolved &&
        objectException.stationId === stationId
    )
    .reverse();

  return (
    <Container
      id={`station-progress-${name.replace(' ', '-').toLowerCase()}`}
      data-testid={`station-progress-${name.replace(' ', '-').toLowerCase()}`}
      className={className}
      bgcolor={backgroundColor}
    >
      <StyledGrid
        container
        alignItems="center"
        justifyContent="center"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Grid container item xs={7} justifyContent="space-between">
          <Box pl={2}>
            <StationNameGrid item xs={12}>
              {name}
            </StationNameGrid>
          </Box>
        </Grid>
        {resolvedIcon ? (
          <IconGrid item xs>
            {resolvedIcon}
          </IconGrid>
        ) : null}
        <IconGrid item xs>
          {icon}
        </IconGrid>
      </StyledGrid>
      {(stationItem || isExceptionStatusHasValue) && (
        <Popper
          anchorEl={anchorEl}
          backgroundColor={COLORS.black}
          id={`popper${name.replace(' ', '')}`}
          open={popoverOpen}
        >
          <>
            <Box
              id={`popper-created-at-${name.replace(' ', '')}`}
              fontSize={11}
              data-testid="popper-top-label"
            >
              <Box component="span" pr={1}>
                {t('History.TimelineLabels.CreatedAt')}
              </Box>
              {topLabel}
            </Box>
            <Box
              id={`popper-updated-at-${name.replace(' ', '')}`}
              fontSize={11}
              sx={{ mt: 1 }}
              data-testid="popper-middle-label"
            >
              <Box component="span" pr={1}>
                {t('History.TimelineLabels.UpdatedAt')}
              </Box>
              {middleLabel}
            </Box>
            {exceptionLabel && (
              <Box fontSize={11} sx={{ mt: 1 }}>
                <Box component="span" pr={1}>
                  {t('History.TimelineLabels.ExceptionTimeIn')}
                </Box>
                {exceptionLabel}
              </Box>
            )}
            <Box
              id={`popper-name-${name.replace(' ', '')}`}
              fontSize={11}
              sx={{ mt: 1 }}
              data-testid="popper-name"
            >
              <Box component="span" pr={1}>
                {t('History.TimelineLabels.Name')}
              </Box>
              {bottomLabel}
            </Box>
            {objectExceptions &&
              objectExceptions.map((exception) => {
                const { createdAt, resolvedAt, resolvedBy } = exception;
                return (
                  <>
                    <hr />
                    <Box
                      fontSize={11}
                      sx={{ mt: 1 }}
                      data-testid="exception-time-in"
                    >
                      <Box component="span" pr={1}>
                        {t('History.TimelineLabels.ExceptionTimeIn')}
                      </Box>
                      {createdAt && timeZoneFormat(createdAt)}
                    </Box>
                    <Box
                      fontSize={11}
                      sx={{ mt: 1 }}
                      data-testid="exception-time-out"
                    >
                      <Box component="span" pr={1}>
                        {t('History.TimelineLabels.ExceptionTimeOut')}
                      </Box>
                      {resolvedAt && timeZoneFormat(resolvedAt)}
                    </Box>
                    <Box
                      fontSize={11}
                      sx={{ mt: 1 }}
                      data-testid="exception-resolved-by"
                    >
                      <Box component="span" pr={1}>
                        {t('History.TimelineLabels.Name')}
                      </Box>
                      {resolvedBy &&
                        `${resolvedBy.firstName} ${resolvedBy.lastName}`}
                    </Box>
                  </>
                );
              })}
          </>
        </Popper>
      )}
    </Container>
  );
};

StationStatus.defaultProps = {
  stationItem: null,
  backgroundColor: 'gray',
  bottomLabel: null,
  item: '',
  stationId: '',
  topLabel: null,
  middleLabel: null,
  isExceptionStation: false,
  exceptionLabel: null,
  resolvedIcon: null,
};
StationStatus.propTypes = {
  stationItem: PropTypes.shape(),
  backgroundColor: PropTypes.string,
  className: PropTypes.string.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    objectExceptions: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string })
    ),
    order: PropTypes.shape({
      id: PropTypes.string,
      objectExceptions: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.string })
      ),
    }),
  }),
  stationId: PropTypes.string,
  icon: PropTypes.node.isRequired,
  bottomLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  topLabel: PropTypes.string,
  middleLabel: PropTypes.string,
  isExceptionStation: PropTypes.bool,
  resolvedIcon: PropTypes.node,
  exceptionLabel: PropTypes.string,
};

export default StationStatus;
