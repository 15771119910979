import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React from 'react';
import SignIn from '../components/SignIn';
import ResetPassword from '../components/resetPassword/ResetPassword';

const PublicRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => <SignIn />} />
        <Route exact path="/resetPassword" render={() => <ResetPassword />} />
        <Route path="/*" render={() => <SignIn />} />
      </Switch>
    </Router>
  );
};

export default PublicRoutes;
