import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { ModalCardContent } from '../../../reusables/StyledComponent';
import ExceptionItem from './ExceptionItem';

const useExceptionNoteStyles = makeStyles(() => ({
  root: {
    width: 'fit-content',
    margin: '5em',
    minWidth: '450px',
    maxHeight: '90vh',
    overflow: 'auto',
  },
  titleContainer: {
    height: 'fit-content',
    backgroundColor: '#e53238',
    padding: '0.5em 1em',
  },
  title: {
    margin: 0,
  },
  container: {
    padding: '0.5em 0.5em 0',
    color: '#000',
    backgroundColor: '#fff',
  },
}));

const ExceptionNotesPopup = (props) => {
  const { t } = useTranslation();
  const classes = useExceptionNoteStyles();
  const { handleClose, orderExceptions, isOpened } = props;

  return (
    <ModalCardContent onClose={handleClose} open={isOpened}>
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <h1 className={classes.title}>
            {t('ExceptionNotesPopup.ExceptionNotes')}
          </h1>
        </div>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          className={classes.container}
        >
          {[...orderExceptions]
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((exception) => (
              <ExceptionItem key={exception.id} exception={exception} />
            ))}
        </Grid>
      </div>
    </ModalCardContent>
  );
};

export default ExceptionNotesPopup;

ExceptionNotesPopup.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  orderExceptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      notes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }).isRequired
      ),
    })
  ).isRequired,
};
