import { getRequest, putRequest } from './httpClient';
import {
  REMOVE_LOCATIONS_PATH,
  STORAGE_LOCATION_PATH,
  ApiParameters,
} from './routes';
import { buildSearchParameters } from '../utils/parameterUtils';
import mapStorageInformation from '../mapper/mapStorageInformation';
import { mapNewLocations } from '../mapper/mapNewLocations';
import getErrorResult from './errorHandling';

export async function getStorageLocation({ hubIdentifier, locationString, excludeEolStatuses=true }) {
  // If excludeEolStatuses is false, the function will include End of Life (EOL) statuses in the response.
  // If there's a need to include EOL statuses, pass excludeEolStatuses as false when calling this function.
  const response = await getRequest(
    `${STORAGE_LOCATION_PATH}${buildSearchParameters({
      hubIdentifier,
      locationString,
      excludeEolStatuses
    })}`
  );

  if (response.error) {
    return getErrorResult(response);
  }

  if (response.data) {
    const storageInformation = mapStorageInformation(response.data);

    return {
      data: { storageInformation },
    };
  }

  return { data: { storageInformation: null } };
}

export async function removeStorageLocation({ exceptionId }) {
  const response = await putRequest(
    `${REMOVE_LOCATIONS_PATH.replace(ApiParameters.exceptionId, exceptionId)}`
  );

  if (response.error) {
    return getErrorResult(response);
  }

  if (response.data) {
    return {
      data: { locations: mapNewLocations(response.data.locations) },
    };
  }

  return { data: { locations: [] } };
}
