import { formatInTimeZone } from 'date-fns-tz';

// eslint-disable-next-line import/prefer-default-export
export const timeZoneFormat = (date, item, formatString) => {
  const timeZone =
    item?.order?.partnerLocation.timeZone || item?.partnerLocation?.timeZone;
  const session = JSON.parse(sessionStorage.getItem('partnerLocation'));

  return formatInTimeZone(
    date,
    timeZone ?? session?.timeZone,
    formatString ?? 'yyyy-MM-dd hh:mm:ss a z'
  );
};
