import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './reusables/button';

const ReloadDialog = () => {
  const { t } = useTranslation();

  return (
    <Dialog open>
      <DialogTitle>{t('NewVersionDialog.title')}</DialogTitle>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button id="reload-page-button" onClick={() => document.location.reload()}>
          {t('NewVersionDialog.action')}
        </Button>
      </DialogActions>
      ;
    </Dialog>
  );
};

export default ReloadDialog;
