import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

export const Container = styled.div`
  margin-top: 0.5em;
  .previousButton,
  .nextButton {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }
  .previousButton svg,
  .nextButton svg {
    height: 24px;
  }
`;
Container.displayName = 'Container';

export const ImgThumbContainer = styled.div`
  border: 1px solid rgba(127, 127, 127, 0.4);
  margin: 20px 10px;
  width: 80px;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  overflow: hidden;
  .swiper-slide-thumb-active > div > & {
    border: 1px solid black;
  }
`;
ImgThumbContainer.displayName = 'ImgThumbContainer';

export const ThumbContainer = styled.div`
  position: relative;
  width: 80px;
`;
ThumbContainer.displayName = 'ThumbContainer';

export const StatusPillWrapper = styled.div`
  > div {
    position: absolute;
    top: -12px;
    right: -27px;
    height: 18px !important;
    font-size: 9px;
  }
`;
StatusPillWrapper.displayName = 'StatusPillWrapper';

export const FlexCenterContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  padding: 0px 10px;
`;
FlexCenterContainer.displayName = 'FlexCenterContainer';

export const MainImageContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-top: 20px;
`;
MainImageContainer.displayName = 'MainImageContainer';

export const WrapperHeaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 24px;
  background-color: rgba(0, 0, 0, 0.9);
`;
WrapperHeaderContainer.displayName = 'WrapperHeaderContainer';

export const WrapperBodyContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
WrapperBodyContainer.displayName = 'WrapperBodyContainer';

export const WrapperSlideContainer = styled.div`
  height: 70vh;
  width: 50vw;
  align-self: center;
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute !important;
  top: -3px;
  right: 0;
`;
StyledIconButton.displayName = 'StyledIconButton';
