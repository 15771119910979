import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Typography } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import StatusContainer from '../../../reusables/statusContainer';
import { Label } from '../../../reusables/statusContainer/index.styles';

const useExceptionNoteHeader = makeStyles(() => ({
  root: {
    gap: '1em',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  createdBy: {
    gap: '1em',
    minWidth: 'fit-content',
    width: '100%',
  },
  code: {
    minWidth: 'fit-content',
  },
  createdAt: {
    minWidth: 'fit-content',
    width: '100%',
  },
  verdictContainer: {
    marginLeft: '0.5em',
  },
}));

const ExceptionNoteHeader = ({ exception, setIsCollapsed, isCollapsed }) => {
  const { t } = useTranslation();
  const classes = useExceptionNoteHeader();

  return (
    <Grid
      container
      wrap="nowrap"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      className={classes.root}
      onClick={() => setIsCollapsed(!isCollapsed)}
    >
      <Grid item className={classes.createdBy}>
        <Typography variant="h6">
          <b>
            {t('ExceptionNotesPopup.ExceptionAuthor', {
              userIdentification: exception.createdBy.userIdentification,
            })}
          </b>
        </Typography>
      </Grid>
      <Grid item className={classes.code}>
        <StatusContainer backgroundColor="red">
          <Label>{exception.exceptionCode.displayName}</Label>
        </StatusContainer>
      </Grid>
      <Grid
        container
        item
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        className={classes.createdAt}
      >
        <Grid item>
          {t('date.CreatedAt', { date: new Date(exception.createdAt) })}
        </Grid>
        {exception.exceptionVerdict && (
          <Grid className={classes.verdictContainer}>
            <h4>
              {t('ExceptionNotesPopup.ExceptionVerdict', {
                exceptionVerdict: exception.exceptionVerdict,
              })}
            </h4>
          </Grid>
        )}
        <Grid item>
          <IconButton size="medium">
            {isCollapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ExceptionNoteHeader;

ExceptionNoteHeader.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  setIsCollapsed: PropTypes.func.isRequired,
  exception: PropTypes.shape({
    exceptionVerdict: PropTypes.string,
    createdBy: PropTypes.shape({
      userIdentification: PropTypes.string.isRequired,
    }).isRequired,
    exceptionCode: PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }).isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
};
