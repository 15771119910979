import { FormControl, MenuItem } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { ModifiedSelect } from './HubSwitch';
import { QUERY_ALL_LIVE_EVENTS } from '../graphql/queries';
import LoadingAnimation from './modals/LoadingAnimation';

const LiveEventSwitch = () => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(QUERY_ALL_LIVE_EVENTS);
  const [selectedLiveEvent, setSelectedLiveEvent] = useState('default');

  const handleChange = (e) => {
    if (e.target.id !== 'default') {
      setSelectedLiveEvent(e.target.id);

      const liveEvent = data?.allLiveEvents.find((le) => le.id === e.target.id);
      window.sessionStorage.setItem('liveEvent', JSON.stringify(liveEvent));
      window.dispatchEvent(new Event('liveEventChange'));
    }
  };

  useEffect(() => {
    const liveEvent = JSON.parse(window.sessionStorage.getItem('liveEvent'));
    setSelectedLiveEvent(liveEvent?.id || 'default');
  }, []);

  if (loading) return <LoadingAnimation />;

  return (
    <FormControl
      style={{
        width: 'fit-content',
        minWidth: '200px',
        height: '50px',
        marginLeft: '10px',
      }}
    >
      <ModifiedSelect
        displayEmpty
        id="select-live-event-location"
        data-testid="select-live-event-location"
        value={selectedLiveEvent}
        autoWidth
        variant="outlined"
      >
        <MenuItem id="default" value="default" disabled>
          {t('LiveEventShipping.SelectLiveEvent')}
        </MenuItem>
        {data?.allLiveEvents.map((item) => (
          <MenuItem
            id={item.id}
            data-testid={item.id}
            key={item.id}
            value={item.id}
            onClick={handleChange}
          >
            {item.name}
          </MenuItem>
        ))}
      </ModifiedSelect>
    </FormControl>
  );
};

export default LiveEventSwitch;
