import { NoteList } from '@codeboxxtechschool/ginza_shared_components';
import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { PhotoCamera } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Close from '@material-ui/icons/Close';
import { notePropTypes } from './notePropTypes';

const PopoverExceptionNotes = ({ notes, onClose }) => {
  const { t } = useTranslation();

  return (
    <Box data-testid="popOverNoteList">
      <Box
        data-testid="popover-exception-notes"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '0.5em',
          paddingLeft: '0.5em',
        }}
      >
        <Typography>
          <b>
            {t('History.ExceptionNotes')} ({notes.length})
          </b>
        </Typography>
        <Button id="popover-exception-notes-close-button" onClick={onClose}>
          <Close />
        </Button>
      </Box>
      <Box sx={{ width: '550px', margin: '0.5em' }}>
        <NoteList notes={notes} icon={<PhotoCamera />} />
      </Box>
    </Box>
  );
};

export default PopoverExceptionNotes;

PopoverExceptionNotes.propTypes = {
  onClose: PropTypes.func.isRequired,
  notes: PropTypes.arrayOf(notePropTypes).isRequired,
};
