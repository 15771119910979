const collectAllExceptions = (item) => {
  let exceptions = [];
  if (item?.objectExceptions?.length > 0) {
    exceptions = exceptions.concat(item?.objectExceptions);
  }
  if (item?.order?.objectExceptions?.length > 0) {
    exceptions = exceptions.concat(item?.order?.objectExceptions);
  }
  return exceptions;
};

export default collectAllExceptions;
