/* eslint-disable react/prop-types */
import { Button, COLORS } from '@codeboxxtechschool/ginza_shared_components';
import React from 'react';
import Box from '@material-ui/core/Box';

const defaultProps = {
  id: '',
  ariaLabel: '',
  backgroundColor: COLORS.blue,
  borderColor: '',
  textColor: COLORS.white,
  width: 'fit-content',
};

const FormButton = ({
  id,
  ariaLabel,
  backgroundColor,
  borderColor,
  textColor,
  children,
  disabled,
  onClick,
  width,
  divWith = '100%',
}) => {
  return (
    <div style={{ width: divWith }}>
      <Button
        id={id}
        ariaLabel={ariaLabel}
        outlined={borderColor !== ''}
        disabled={disabled}
        textColor={textColor}
        backgroundColor={backgroundColor}
        onClick={onClick}
        borderColor={borderColor}
        width={width}
        sx={{
          width: { width },
          fontSize: '1.2rem !important',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {children}
        </Box>
      </Button>
    </div>
  );
};

FormButton.defaultProps = defaultProps;

export default FormButton;
