import React from 'react';
import PropTypes from 'prop-types';
import { ChipText, ChipLabel, StyledChip } from './index.styles';

const getLabel = (label, text) => {
  return label ? (
    <>
      <ChipLabel>{label}</ChipLabel>
      <ChipText id="chip-text">{text}</ChipText>
    </>
  ) : (
    <div id="chip-text">{text}</div>
  );
};

const StatusPill = ({
  label,
  text,
  shadow,
  backgroundColor,
  textColor,
  bordercolor,
  fullWidth,
  texttransform,
  fontWeight,
  dataTestId,
}) => {
  return (
    <StyledChip
      id={`chip-${label}`}
      label={getLabel(label, text)}
      containlabel={(label !== '').toString()}
      shadow={shadow.toString()}
      backgroundcolor={backgroundColor}
      border={(bordercolor !== '').toString()}
      textcolor={textColor}
      bordercolor={bordercolor}
      fullwidth={fullWidth.toString()}
      texttransform={texttransform.toString()}
      fontWeight={fontWeight.toString()}
      data-testid={dataTestId}
    />
  );
};
export default StatusPill;

StatusPill.defaultProps = {
  label: '',
  shadow: false,
  backgroundColor: 'white',
  textColor: 'black',
  bordercolor: '',
  fullWidth: false,
  fontWeight: false,
  texttransform: false,
  dataTestId: undefined,
};

StatusPill.propTypes = {
  text: PropTypes.string.isRequired,
  label: PropTypes.string,
  shadow: PropTypes.bool,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  bordercolor: PropTypes.string,
  fullWidth: PropTypes.bool,
  fontWeight: PropTypes.bool,
  texttransform: PropTypes.bool,
  dataTestId: PropTypes.string,
};
