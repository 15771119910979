import { timeZoneFormat } from '../../../../utils/timeZoneFormat';

const getTimeInDate = (item, station, stationItem) => {
  const isException =
    station?.name.toLowerCase() === 'exception' &&
    item?.order?.status.toLowerCase() === 'exception';

  // eslint-disable-next-line no-nested-ternary
  return isException
    ? timeZoneFormat(item?.updatedAt, item)
    : stationItem
    ? timeZoneFormat(stationItem?.createdAt, item)
    : 'N/A';
};

export default getTimeInDate;
