import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField, Typography } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { COLORS } from '@codeboxxtechschool/ginza_shared_components';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PropTypes from 'prop-types';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import validator from 'validator/es';
import { useTranslation } from 'react-i18next';
import { UPDATE_GCX_TICKET_NUMBER } from '../../../../graphql/mutations';

const useStyles = makeStyles({
  readGcx: {
    height: '50px',
    backgroundColor: COLORS.gray100,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
    padding: '0 5px 0 15px',
  },
  deleteBtn: {
    marginLeft: 'auto',
    border: 'none',
    backgroundColor: 'transparent',
  },
  submitBtn: {
    textAlign: 'center',
    border: 'none',
    color: COLORS.white,
    backgroundColor: COLORS.blue500,
    height: '40px',
    borderRadius: '5px',
  },
  urlTextContainer: {
    width: '160px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  textField: {
    '& .MuiFormLabel-root': {
      color: COLORS.gray800,
    },
    '& .MuiInputLabel-formControl': {
      transform: 'translate(14px, 10px) scale(0.8)',
      backgroundColor: COLORS.white,
      zIndex: 100,
    },
  },
});

const GcxInput = ({ exceptionId, url, onError }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [newGcxUrl, setNewGcxUrl] = useState('');
  const [error, setError] = useState(false);
  const [submittedUrl, setSubmittedUrl] = useState(url || '');
  const [updateGcxTicketNumber] = useMutation(UPDATE_GCX_TICKET_NUMBER);

  const handleChange = (e) => {
    setNewGcxUrl(e.target.value);
  };

  const handleAction = async (actionType) => {
    // validate url on submit
    if (actionType === 'submit') {
      const isValid = validator.isURL(newGcxUrl);

      if (!isValid) return setError(!isValid);
    }

    try {
      const res = await updateGcxTicketNumber({
        variables: {
          id: exceptionId,
          gcxTicketNumber: '',
          gcxTicketUrl: actionType === 'delete' ? '' : newGcxUrl,
        },
      });
      const updatedUrl = res.data.updateGcxTicketNumber.gcxTicketUrl;
      setSubmittedUrl(updatedUrl);
    } catch (e) {
      const errorCode =
        e.graphQLErrors[0]?.extensions?.code?.error || 'UnableToUpdateGcxUrl';
      onError(errorCode);
    }
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(false), 3000);
    }
  }, [error]);

  return (
    <>
      {submittedUrl ? (
        <div className={classes.readGcx}>
          <Typography variant="body1">{t('PSNAD.QuickbaseInput')}:</Typography>

          <a href={submittedUrl}>
            <Typography variant="body1">
              <div className={classes.urlTextContainer}>{submittedUrl}</div>
            </Typography>
          </a>

          <button
            id="delete-gcx-url-button"
            data-testid="delete-gcx-url-button"
            type="button"
            className={classes.deleteBtn}
            onClick={() => handleAction('delete')}
          >
            <CancelIcon />
          </button>
        </div>
      ) : (
        <TextField
          className={classes.textField}
          error={error}
          helperText={error ? t('GinzaV2.Error.Validation.InvalidUrl') : ''}
          placeholder="https://ebayvalet.quickbase.com/..."
          label={t('PSNAD.QuickbaseInput')}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            style: {
              height: '50px',
              padding: '0 5px 0 15px',
              border: `1px solid ${COLORS.gray400}`,
              borderRadius: '5px',
              borderBottom: `2px solid ${COLORS.blue500}`,
            },
            disableUnderline: true,
            endAdornment: (
              <InputAdornment style={{ height: '50px' }} position="end">
                <button
                  id="add-gcx-url-button"
                  data-testid="add-gcx-url-button"
                  type="button"
                  className={classes.submitBtn}
                  onClick={() => handleAction('submit')}
                >
                  <ArrowForwardIosIcon />
                </button>
              </InputAdornment>
            ),
          }}
        />
      )}
    </>
  );
};

export default GcxInput;

GcxInput.defaultProps = {
  url: '',
  onError: null,
};

GcxInput.propTypes = {
  exceptionId: PropTypes.string.isRequired,
  url: PropTypes.string,
  onError: PropTypes.func,
};
