import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import usePredefinedNotesConnection from '../../../graphql/hooks/predefinedNotes/usePredefinedNotesConnection';
import { useStateValue } from '../../../providers/StateProvider';
import { getStationIdByName } from '../../../utils/stationsInfo';

export const PredefinedNotesContext = createContext({});

const rowsPerPage = 50;

const mergeNoteList = (notes, otherNotes) => {
  const mergedNotes = [...notes];
  otherNotes.forEach((note) => {
    const index = mergedNotes.findIndex(
      (mergedNote) => mergedNote.node.id === note.node.id
    );
    if (index === -1) {
      mergedNotes.push(note);
    }
  });
  return mergedNotes;
};

const PredefinedNotesProvider = ({ children }) => {
  const [fullNoteList, setFullNoteList] = useState([]);
  const [{ partnerLocation, roleList, stationList, stationId }] =
    useStateValue();
  const adminStationId = getStationIdByName(stationList, 'Admin');
  const isAdmin =
    stationId === adminStationId &&
    (roleList.includes('admin') || roleList.includes('super_admin'));

  const initialFilters = {
    categoryId: isAdmin ? '-1' : partnerLocation.categoryId,
    partnerLocationId: isAdmin ? '-1' : partnerLocation.id,
    search: '',
    rowsPerPage,
    first: rowsPerPage,
    last: null,
    after: null,
    before: null,
  };

  const [filters, setFilters] = useState(initialFilters);
  const { data, loading, fetch } = usePredefinedNotesConnection({ filters });

  const [categoryOptions, setCategoryOptions] = useState([
    { value: '-1', text: 'Select a category' },
  ]);

  const [partnerLocationOptions, setPartnerLocationOptions] = useState([
    { value: '-1', text: 'Select a hub' },
  ]);

  useEffect(() => {
    if (data?.categories) {
      setCategoryOptions([
        { value: '-1', text: 'Select a category' },
        ...data?.categories.map((c) => ({ value: c.id, text: c.name })),
      ]);
    }

    if (data?.partnerLocations) {
      setPartnerLocationOptions([
        { value: '-1', text: 'Select a hub' },
        ...data?.partnerLocations.map((pl) => ({
          value: pl.id,
          text: pl.name,
        })),
      ]);
    }

    if (data?.predefinedNotes?.edges?.length > 0) {
      const mergedNoteList = mergeNoteList(
        fullNoteList,
        data?.predefinedNotes?.edges
      );
      setFullNoteList(mergedNoteList);
    }
  }, [data]);

  useEffect(() => {
    setFilters({
      ...filters,
      first: filters.first ? filters.rowsPerPage : null,
      last: filters.last ? filters.rowsPerPage : null,
    });
  }, [filters.rowsPerPage]);

  const handleFilterChange = ({ target }) => {
    const { name, value } = target;
    setFilters((previousFilters) => ({
      ...previousFilters,
      [name]: value,
    }));
  };

  const handlePartnerLocationFilterChange = (e) => {
    // change category for partnerLocation's category
    const partnerLocationId = e.target.value;

    handleFilterChange(e);
    if (partnerLocationId !== '-1') {
      const partnerLocationCategoryId = data?.partnerLocations.find(
        ({ id }) => id === partnerLocationId
      )?.categoryId;
      handleFilterChange({
        target: { name: 'categoryId', value: partnerLocationCategoryId },
      });
    }
  };

  const handleCategoryFilterChange = (e) => {
    // change partnerLocation for -1 when we change the category
    handleFilterChange(e);

    handlePartnerLocationFilterChange({
      target: { name: 'partnerLocationId', value: '-1' },
    });
  };

  const handleBack = () => {
    setFilters({
      ...filters,
      last: filters.rowsPerPage,
      first: null,
      after: null,
      before: data?.predefinedNotes?.pageInfo.startCursor,
    });
  };

  const handleNext = () => {
    setFilters({
      ...filters,
      last: null,
      first: filters.rowsPerPage,
      before: null,
      after: data?.predefinedNotes?.pageInfo.endCursor,
    });
  };

  return (
    <PredefinedNotesContext.Provider
      value={{
        fullNoteList,
        categoryOptions,
        partnerLocationOptions,
        data,
        loading,
        filters,
        setFilters,
        fetch,
        handleFilterChange,
        handleCategoryFilterChange,
        handlePartnerLocationFilterChange,
        handleBack,
        handleNext,
      }}
    >
      {children}
    </PredefinedNotesContext.Provider>
  );
};

function usePredefinedNotes() {
  const context = React.useContext(PredefinedNotesContext);
  if (context === undefined) {
    throw new Error(
      'usePredefinedNotes must be used within a PredefinedNotesProvider'
    );
  }
  return context;
}

export { PredefinedNotesProvider, usePredefinedNotes };

PredefinedNotesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
