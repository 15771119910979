import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStateValue } from '../../../../providers/StateProvider';
import { PRINTERS_LIST } from '../../../../graphql/queries';
import makeError from '../../../../utils/makeError';
import { CanSelectPrinters } from '../../../../utils/accessManager';

const usePrinters = () => {
  const { t } = useTranslation();
  const [{ partnerLocation, roleList, currentStationId: stationId }, dispatch] =
    useStateValue();
  const [disabledPrinter] = useState(!CanSelectPrinters(roleList));

  const { loading, data, error, errors, refetch } = useQuery(PRINTERS_LIST, {
    variables: {
      stationId,
      partnerLocationId: partnerLocation.id,
    },
    fetchPolicy: 'network-only',
  });

  const retrieveStorageKey = (storageKey, type) => {
    return `hub${partnerLocation.id}station${stationId}-${storageKey}${
      type === 'regular' ? '' : `-${type}`
    }`;
  };

  const retrieveLabelStorageKey = (type) => retrieveStorageKey('label', type);
  const retrievePrinterStorageKey = (type) =>
    retrieveStorageKey('printer', type);

  const dispatchSelectedPrinter = (printer, type) => {
    dispatch({
      type: 'changeSelectedPrintersId',
      // this dispatch is done multiple times in a loop
      // and since the selectedPrintersId variable
      // coming from the useStateValue does not update it self between the iteration of the loop
      // we need to get the updated value as argument of the function used to change the state
      newSelectedPrintersId: (selectedPrintersId) => ({
        ...selectedPrintersId,
        [type]: printer,
      }),
    });
  };

  const dispatchSelectedLabel = (label, type) => {
    dispatch({
      type: 'changeSelectedLabels',
      // this dispatch is done multiple times in a loop
      // and since the selectedPrintersId variable
      // coming from the useStateValue does not update it self between the iteration of the loop
      // we need to get the updated value as argument of the function used to change the state
      newSelectedLabels: (selectedLabels) => ({
        ...selectedLabels,
        [type]: label,
      }),
    });
  };

  const retrievePrinterFromStorage = (type) => {
    const printerStorageKey = retrievePrinterStorageKey(type);
    if (printerStorageKey) {
      const retrievedPrinterStorage = localStorage.getItem(printerStorageKey);
      if (retrievedPrinterStorage) {
        const printerStorageArr = JSON.parse(retrievedPrinterStorage);
        printerStorageArr.ipAddress = printerStorageArr.printerIp;
        delete printerStorageArr.printerIp;
        return printerStorageArr;
      }
    }

    return null;
  };

  const retrieveLabelFromStorage = (type) => {
    const labelStorageKey = retrieveLabelStorageKey(type);
    if (labelStorageKey) {
      const retrievedLabelStorage = localStorage.getItem(labelStorageKey);
      if (retrievedLabelStorage) {
        return JSON.parse(retrievedLabelStorage);
      }
    }

    return null;
  };

  const addSelectedPrintersToStorage = (printer, type) => {
    const printerIp = printer.ipAddress;
    localStorage.setItem(
      retrievePrinterStorageKey(type),
      JSON.stringify({ ...printer, printerIp })
    );
  };

  const addSelectedLabelsToStorage = (label, type) => {
    localStorage.setItem(retrieveLabelStorageKey(type), JSON.stringify(label));
  };

  const handleSelectedPrinterChange = (printer, type) => {
    dispatchSelectedPrinter(printer, type);
    addSelectedPrintersToStorage(printer, type);
  };

  const handleSelectedLabelChange = (label, type) => {
    dispatchSelectedLabel(label, type);
    addSelectedLabelsToStorage(label, type);
  };

  useEffect(
    () => (error || errors) && makeError(error || errors, dispatch, t),
    [error, errors]
  );

  useEffect(() => {
    if (data) refetch();
  }, [stationId]);

  return {
    loading,
    data,
    refetch,
    dispatchSelectedPrinter,
    handleSelectedPrinterChange,
    handleSelectedLabelChange,
    retrievePrinterFromStorage,
    retrieveLabelFromStorage,
    retrievePrinterStorageKey,
    disabledPrinter,
  };
};

export default usePrinters;
