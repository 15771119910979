import { getErrorCode } from './errorsCode';

const getErrorResult = (response) => {
  return {
    data: null,
    error: { code: getErrorCode(response.error.statusCode) },
  };
};

export default getErrorResult;
