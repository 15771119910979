import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@codeboxxtechschool/ginza_shared_components';
import { useLoadingContext } from '../../context/LoadingContext';
import { useApolloContext } from './context/ApolloContext';

// eslint-disable-next-line react/prop-types
export const GlobalLoadingIndicator = ({ useApolloNetworkStatus }) => {
  const status = useApolloNetworkStatus();
  const { setLoading } = useLoadingContext();
  const { setError, setHasPendingCalls } = useApolloContext();

  // eslint-disable-next-line no-bitwise,no-self-compare
  const currentError = useRef(undefined);

  const hasPending =
    status.numPendingQueries > 0 || status.numPendingMutations > 0;

  useEffect(() => {
    if (currentError.current) {
      currentError.current.destroy();
      currentError.current = undefined;
    }

    setHasPendingCalls(hasPending);

    const error = status.mutationError || status.queryError;

    if (error) {
      setError(error);
    }

    setLoading((prev) => {
      if (hasPending !== prev) return hasPending;
      return prev;
    });
  }, [status]);

  if (hasPending) {
    // Let's re-evaluate how we trigger this spinner...
    // return <Loading />;
    return null;
  }
  return null;
};

GlobalLoadingIndicator.props = {
  useApolloNetworkStatus: PropTypes.func.isRequired,
};

export default GlobalLoadingIndicator;
