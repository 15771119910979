import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';

export const StyledModal = styled(Dialog)`
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiDialog-paper {
    border-radius: 0;
    box-shadow: none;
  }
  .MuiDialog-container {
    width: ${({ fullScreen }) => (fullScreen ? '100%' : 'fit-content')};
  }
  .MuiPaper-root {
    resize: ${({ resize }) => (resize ? 'both' : 'none')};
  }
`;
StyledModal.displayName = 'StyledModal';

export const ModalContainer = styled.div`
  max-width: ${({ fullscreen }) => (fullscreen ? '100%' : '900px')};
  align-items: center;
`;
ModalContainer.displayName = 'ModalContainer';

export const ModalContent = styled.div`
  ${(props) => (props.noPadding ? '' : 'padding: 16px;')}
  background-color: white;
`;
ModalContent.displayName = 'ModalContent';

export const ModalButtonWrapper = styled.div`
  padding: 1em 1em 0;
  text-align: center;
`;
ModalButtonWrapper.displayName = 'ModalButtonWrapper';

export const ModalSaveButton = styled.button`
  font-family: inherit !important;
  font-size: 1em !important;
  background-color: #0064d2;
  color: #efefef;
  border: unset;
  font-size: 1em !important;
  margin: 1em;
  width: ${({ width }) => width || '250px'};
  height: ${({ height }) => height || '40px'};
`;
ModalSaveButton.displayName = 'ModalSaveButton';

export const ModalCancelButton = styled.button`
  font-family: inherit !important;
  font-size: 1em !important;
  background-color: transparent;
  border: unset;
  text-decoration: underline !important;
`;
ModalCancelButton.displayName = 'ModalCancelButton';

export const ModalYesButton = styled.button`
  font-family: inherit !important;
  font-size: 1em !important;
  background-color: #86b817 !important;
  border: unset;
`;
ModalYesButton.displayName = 'ModalYesButton';

export const ModalCustomButton = styled.button`
  font-family: inherit !important;
  font-size: 1em !important;
  background-color: #ff0000;
  color: #efefef;
  border: unset;
  margin: 1em;
  width: ${({ width }) => width || '250px'};
  height: ${({ height }) => height || '40px'};
`;
ModalYesButton.displayName = 'ModalCustomButton';

export const ModalCancelWrapper = styled.div`
  padding: 0 1em 0;
  text-align: center;
`;
ModalCancelWrapper.displayName = 'ModalCancelWrapper';

export const ModalHeader = styled.div`
  position: relative;
  width: 100%;
  height: 24px;
  background-color: #a7ca64;
`;
ModalHeader.displayName = 'ModalHeader';

export const StyledTitle = styled.h2`
  margin-top: 3px;
  margin-left: 16px;
`;
StyledTitle.displayName = 'StyledTitle';

export const StyledIconButton = styled(IconButton)`
  position: absolute !important;
  top: ${({ top }) => top || '-3px'};
  right: ${({ right }) => right || '0'};
`;
StyledIconButton.displayName = 'StyledIconButton';
