export const NAVBAR_HEIGHT = '80px';

export const COLORS = {
  black: '#000000',
  white: '#ffffff',
  bgGray: '#F0F2F5',
  lightGray: '#dedede',
  midGray: '#979797',
  midDarkGray: '#7C858E',
  gray: '#ababab',
  darkGray: '#52535a',
  returnGray: '#333333',
  borderGray: '#d9d9d9',
  lightBlue: '#9fc6f1',
  midBlue: '#1fa0ee',
  blue: '#0063d2',
  darkBlue: '#0E103D',
  paleGreen: '#EAF3E1',
  lightGreen: '#edf5dc',
  midGreen: '#cee2a5',
  green: '#84c134',
  darkGreen: '#86b817',
  highlightGreen: '#4a7d07',
  paleYellow: '#FFF6E5',
  lightYellow: '#fff5cb',
  yellow: '#ffb404',
  darkYellow: '#ff9800',
  lightOrange: '#ffe09e',
  orange: '#f4ae13',
  highlightOrange: '#c08404',
  paleRed: '#FCEDEE',
  lightRed: '#ff8c90',
  red: '#e43237',
  redOrange: '#f44813',
  darkRed: '#bf0012',
  highlightRed: '#a50e1e',
  lightPink: '#fdefef',
  pink: '#fbe1e1',
  Alitheon: '#6B646C',
  Veracity: '#2E3F52',
};

export const STATUS_BY_COLOR = {
  MISCATEGORIZED: COLORS.orange,
  NOT_AS_DESCRIBED: COLORS.orange,
  NOT_AUTHENTIC_EXCEPTION: COLORS.red,
  NOT_AUTHENTIC: COLORS.red,
  CANCELLED: COLORS.red,
  CUSTOMIZED: COLORS.orange,
  AUTHENTIC: COLORS.green,
  NON_VERIFIED: COLORS.orange,
  DEFAULT: COLORS.green,
  DEFAULTSNAD: COLORS.green,
};

export const STATUS_NAMES = {
  MISCATEGORIZED: 'MISCATEGORIZED',
  NOT_AS_DESCRIBED: 'NOT_AS_DESCRIBED',
  NOT_AUTHENTIC_EXCEPTION: 'NOT_AUTHENTIC_EXCEPTION',
  NOT_AUTHENTIC: 'NOT AUTHENTIC',
  CANCELLED: 'CANCELLED',
  CUSTOMIZED: 'CUSTOMIZED',
  AUTHENTIC: 'AUTHENTIC',
  NON_VERIFIED: 'NON VERIFIED',
  DEFAULT: 'DEFAULT',
  DEFAULTSNAD: 'SNAD',
  MISSING_ITEM: 'NOT_RECEIVED',
};

export const AUTHENTICATION_RISK_LEVEL = {
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
};

export const LIVE_EVENT_INTAKE_STATUSES = {
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
  MISSING: 'NOT_RECEIVED',
  UNPROCESSED: 'UNPROCESSED',
};

export const LIVE_EVENT_INTAKE_GRADES = {
  LIVE_EVENT_INTAKE_CONFIRMED: 'Live Event Intake Confirmed',
};

export const ALITHEON_STATUS_BY_COLOR = {
  SUCCESSFUL: COLORS.green,
  SUCCESS: COLORS.green,
  UNSUCCESSFUL: COLORS.red,
  SKIPPED: COLORS.orange,
  DEFAULT: COLORS.green,
};

export const PARTNER_LOCATIONS = {
  TRADING_CARD: 'TRADING CARD',
  STREETWEAR: 'STREETWEAR',
};

export const qrTypeLabel = (partnerLocation, orderStatus) => {
  if (!partnerLocation) return '';

  switch (partnerLocation.categoryName) {
    case PARTNER_LOCATIONS.TRADING_CARD:
      return partnerLocation?.blueBiteAuthenticCardAllowedStatuses.includes(
        orderStatus
      )
        ? 'qrSticker'
        : 'qr';
    case PARTNER_LOCATIONS.STREETWEAR:
      if (orderStatus === 'pass') {
        return 'qrToPass';
      }
      if (orderStatus === 'fail') {
        return 'qrToFail';
      }
      return 'qr';

    default:
      return 'qr';
  }
};

export const SCAN_TYPES = {
  OUTBOUND_TRACKING: 'OutboundTracking',
  SUBMISSION_EVTN: 'SubmissionEvtn',
  TRACKING_EVTN: 'TrackingEvtn',
  SHIPPING: 'Shipping',
  LPN: 'LPN',
  EVTN: 'EVTN',
};

export const CATEGORIES = {
  tradingCard: 'TRADING CARD',
  handbags: 'HANDBAG',
  watches: 'WATCH',
  sneakers: 'SNEAKER',
  liveEvent: 'LIVE EVENT',
};

export const STATIONS = {
  liveEventIntake: 'Live Event Intake',
  liveEventShipping: 'Live Event Shipping',
  qualityControl: 'Quality Control',
  authentication: 'Authentication',
  packaging: 'Packaging',
};

export const USER_ROLES = {
  super_admin: 'super_admin',
  global_admin: 'global_admin',
  local_admin: 'local_admin',
  partner_admin: 'partner_admin',
  printer_user: 'printer_user',
  gcx_user: 'gcx_user',
  aspect_manager: 'aspect_manager',
  inventory_hub_setup: 'inventory_hub_setup',
  user: 'user',
};

export const REGULAR_ROLES = {
  hub_exceptions: 'hub_exceptions',
  hub_authenticator: 'hub_authenticator',
  watchmaker: 'watchmaker',
  hub_line: 'hub_line',
  gcx_exception_user: 'gcx_exception_user',
  report_user: 'report_user',
  user: 'user',
  gcx_user: 'gcx_user',
  ag_category_managers: 'ag_category_managers',
  ag_bu_other: 'ag_bu_other',
};

export const HIGHER_GROUP_ROLES = {
  ebay_leadership: 'ebay_leadership',
  ebay_eng_user: 'ebay_engineering_user',
  program_admin: 'program_admin',
  gcx_leadership: 'gcx_leadership',
};

export const ADMIN_ROLE = [
  USER_ROLES.super_admin,
  HIGHER_GROUP_ROLES.program_admin,
  USER_ROLES.global_admin,
  USER_ROLES.local_admin,
  USER_ROLES.partner_admin,
];

export const CATEGORY_MENU_AUTHORIZED_ROLE = [
  USER_ROLES.super_admin,
  HIGHER_GROUP_ROLES.program_admin,
  USER_ROLES.partner_admin,
];

export const REPRINT_NFC_AUTH_AUTHORIZED_ROLE = [
  USER_ROLES.super_admin,
  HIGHER_GROUP_ROLES.program_admin,
  USER_ROLES.partner_admin,
  USER_ROLES.local_admin,
];

export const USER_GROUP_ADMIN_ROLE = [
  USER_ROLES.super_admin,
  HIGHER_GROUP_ROLES.program_admin,
  USER_ROLES.partner_admin,
  USER_ROLES.local_admin,
  HIGHER_GROUP_ROLES.gcx_leadership,
];

export const HOLIDAY_AUTHORIZED_ROLES = [
  USER_ROLES.super_admin,
  HIGHER_GROUP_ROLES.program_admin,
  USER_ROLES.partner_admin,
];

export const LOCATION_CREATE_AUTHORIZED_ROLE = [
  USER_ROLES.super_admin,
  HIGHER_GROUP_ROLES.program_admin,
  USER_ROLES.partner_admin,
  USER_ROLES.local_admin,
  REGULAR_ROLES.hub_exceptions,
];

export const LOCATION_EDIT_AUTHORIZED_ROLE = [
  USER_ROLES.super_admin,
  HIGHER_GROUP_ROLES.program_admin,
  USER_ROLES.partner_admin,
  USER_ROLES.local_admin,
];

export const UPLOAD_ITEM_IMAGES_CATEGORIES = ['TRADING CARD'];

export const PREDEFINED_NOTE_AUTHORIZED_ROLES = [
  USER_ROLES.super_admin,
  HIGHER_GROUP_ROLES.program_admin,
  USER_ROLES.partner_admin,
  USER_ROLES.local_admin,
];

export const PREDEFINED_NOTE_DELETE_AUTHORIZED_ROLES = [
  USER_ROLES.super_admin,
  HIGHER_GROUP_ROLES.program_admin,
  USER_ROLES.partner_admin,
];

export const CAN_DELETE_USER_ROLE = [
  USER_ROLES.super_admin,
  USER_ROLES.global_admin,
];

/** Users who can view the address in "Outbound Shipment" section of history station  */
export const VIEW_OUTBOUND_ADDRESS_AUTHORIZED_ROLE = [
  USER_ROLES.super_admin,
  HIGHER_GROUP_ROLES.gcx_leadership,
  REGULAR_ROLES.gcx_exception_user,
  REGULAR_ROLES.gcx_user,
  REGULAR_ROLES.hub_exceptions,
];

export const EXCEPTION_MAXIMUM_CHARACTERS = '48';

export const handleMessage = (readOnlyObject, t) => {
  if (readOnlyObject.message && readOnlyObject.timestamp) {
    return t(`PopupContent.Error.${readOnlyObject.message}`, {
      timestamp: t('date.CreatedAt', {
        date: new Date(readOnlyObject.timestamp),
      }),
    });
  }
  if (readOnlyObject.message) {
    return t(`PopupContent.Error.${readOnlyObject.message}`);
  }
  return t('error.defaultReadonlyMessage');
};

export const STATION_TIMELINE_STATUSES = {
  NOTHING: '0',
  OPENED: '1',
  PAUSED: '2',
  COMPLETED: '3',
  LOCKED: '4',
};

export const REPORTS_STATUS_ENUM = {
  pending: 'pending',
  completed: 'completed',
  intransit: 'in_transit',
};

export const EXCEPTION_STATUS = {
  Resolved: 'Resolved',
  Unsolved: 'Unsolved',
};

export const BOX_STYLE_BULK_UPDATE = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 670,
  height: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
};

export const INPUT_VALIDATION = {
  specialChar: {
    floatingNote: ' -_,.?:',
    psnadSearch: ' -:',
  },
};

export const REGULAR_USER_ROLES = [
  USER_ROLES.user,
  USER_ROLES.gcx_user,
  USER_ROLES.aspect_manager,
  USER_ROLES.printer_user,
  REGULAR_ROLES.watchmaker,
  REGULAR_ROLES.report_user,
  REGULAR_ROLES.gcx_exception_user,
];

export const HIGHER_USER_ROLES = [
  USER_ROLES.super_admin,
  HIGHER_GROUP_ROLES.program_admin,
  USER_ROLES.partner_admin,
  USER_ROLES.local_admin,
  HIGHER_GROUP_ROLES.gcx_leadership,
];

/** Users who can perform only bulk update - Password change alert, deactivate user, lock/unlock users  */
export const USER_BULK_UPDATE_BASIC_ACCESS = [
  USER_ROLES.super_admin,
  HIGHER_GROUP_ROLES.program_admin,
  USER_ROLES.partner_admin,
  USER_ROLES.local_admin,
  HIGHER_GROUP_ROLES.gcx_leadership,
];

/** Users who can perform all bulk update - Add remove location/Add remove category/Add remove station/Password change alert/deactivate user/lock user/unlock users  */
export const USER_BULK_UPDATE_ADVANCED_ACCESS = [
  USER_ROLES.super_admin,
  HIGHER_GROUP_ROLES.program_admin,
];

/** Roles arrange in hierarchy. Do not change their hierarchy  */
export const ROLE_HIERARCHY_DESC = [
  USER_ROLES.super_admin,
  HIGHER_GROUP_ROLES.program_admin,
  USER_ROLES.partner_admin,
  USER_ROLES.local_admin,
  HIGHER_GROUP_ROLES.gcx_leadership,
  REGULAR_ROLES.hub_exceptions,
  REGULAR_ROLES.hub_authenticator,
  REGULAR_ROLES.watchmaker,
  REGULAR_ROLES.hub_line,
  REGULAR_ROLES.gcx_exception_user,
  REGULAR_ROLES.report_user,
  REGULAR_ROLES.user,
  REGULAR_ROLES.gcx_user,
  REGULAR_ROLES.ag_category_managers,
  REGULAR_ROLES.ag_bu_other,
];

export const LIVE_EVENT_COLOR_SCHEME = [
  {
    key: LIVE_EVENT_INTAKE_STATUSES.REJECTED,
    values: {
      border: COLORS.redOrange,
      card: COLORS.lightRed,
      icon: COLORS.redOrange,
    },
  },
  {
    key: LIVE_EVENT_INTAKE_STATUSES.ACCEPTED,
    values: {
      border: COLORS.highlightGreen,
      card: COLORS.lightGreen,
      icon: COLORS.green,
    },
  },
  {
    key: LIVE_EVENT_INTAKE_STATUSES.MISSING,
    values: {
      border: COLORS.darkYellow,
      card: COLORS.lightOrange,
      icon: COLORS.orange,
    },
  },
  {
    key: LIVE_EVENT_INTAKE_STATUSES.UNPROCESSED,
    values: {
      border: COLORS.red,
      card: COLORS.red,
      icon: null,
    },
  },
];

export const PSNAD_STATUSES = {
  cancelled: 'CANCELLED',
  accepted: 'ACCEPTED',
  rejected: 'REJECTED',
  notEligible: 'NOT_ELIGIBLE',
};

export const PSNAD_RESOLVE_TYPE = {
  cancelled: 'CANCELLED_PSNAD',
  accepted: 'ACCEPTED_PSNAD',
  rejected: 'REJECTED_PSNAD',
  noActionRequired: 'NO_ACTION_REQUIRED_PSNAD',
};

export const ITEM_VALUE_TITLE = {
  condition: 'Card Condition',
};

export const INPUT_TITLE = {
  condition: 'Condition',
};

export const INPUT_KEY = {
  condition: 'condition',
};

export const INPUT_TYPES = {
  itemSpecifics: 'item_specifics',
};

export const TC_CARD_PROTECTION = {
  raw: 'raw',
};

export const POLL_INTERVALS = {
  notes: 120000,
  reportsAuthenticityTicker: 300000,
  reportsCarrierManifests: 120000,
  testingOrders: 15000,
};

export const HUB_ID = {
  handbagsAustralia: 'HANDBAGS1_LIDCOMBE_NSW',
  handbagsNewJersey: 'wp-hub:hb:ewr:1ff02a9bb0782158',
};
