const stationNameConverter = (stationName, categoryName, roleList) => {
  const isWatchmaker = roleList.includes('watchmaker');

  let convertedStationName;
  if (categoryName === 'WATCH' && stationName === 'QualityControl') {
    convertedStationName = isWatchmaker
      ? 'WatchMakerPanel'
      : 'QualityControlAndAuthentication';
  } else {
    convertedStationName = stationName;
  }
  return convertedStationName;
};
export default stationNameConverter;
