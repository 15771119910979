import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { VerificationCodeModal } from '@codeboxxtechschool/ginza_shared_components';
import { useMFAContext } from '../../../../context/MFAContext';
import SubmitButton from '../../components/buttons/submit';
import CancelButton from '../../components/buttons/cancel';
import Popup from "../../../modals/Popup";
import { useMutation } from "@apollo/client";
import { RESET_2FA } from "../../../../graphql/mutations";

const codeDigitsLength = 6;

const CodeVerification = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [opened, setOpened] = useState(true);
  const [value, setValue] = useState('');
  const [responseMsgType, setResponseMsgType] = useState(false);
  const [submit2faReset, setSubmit2faReset] = useState(false);
  const [reset2fa] = useMutation( RESET_2FA );

  const { verify2faOtp } = useMFAContext();

  const handle2FAReset = async () => {
    await reset2fa();
    window.location.reload();
  };

  const handleSubmitReset2fa = () => {
    setSubmit2faReset(true);
  };

  const onVerify2faOptEvent = async () => {
    setResponseMsgType(0);

    const response = await verify2faOtp({
      otp: value,
    });

    if (response?.message?.message === 'success') {
      setResponseMsgType(1);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      setResponseMsgType(2);
    }
  };

  const onCloseModal = () => {
    setOpened(false);
    history.push('/');
  };


  return (<>
        {submit2faReset && (
            <Popup
                data-testid="warning-popup"
                type="warning"
                title={t('account.security.2fa.verificationModal.linkText')}
                message={t('account.security.2fa.verificationModal.message')}
                buttons={[
                  {
                    content: t('account.security.2fa.verificationModal.confirm'),
                    onClick: () => {
                      handle2FAReset();
                    },
                    type: 'white',
                    key: t('account.security.2fa.verificationModal.confirm'),
                  },
                  {
                    content: t('account.security.2fa.verificationModal.cancel'),
                    onClick: () => {
                      setSubmit2faReset(false);
                    },
                    type: 'white',
                    key:  t('account.security.2fa.verificationModal.cancel'),
                  }
                ]}
            />
        )}
    <VerificationCodeModal
      buttons={
        <>
          <CancelButton fullWidth onClick={onCloseModal} />
          <SubmitButton
            fullWidth
            onClick={onVerify2faOptEvent}
            disabled={!(codeDigitsLength === value.trim().length)}
          />
        </>
      }
      description={t('account.security.2fa.verificationModal.description')}
      linkText={t('account.security.2fa.verificationModal.linkText')}
      onClickLink={handleSubmitReset2fa}
      onClose={onCloseModal}
      onVerificationCodeChange={setValue}
      open={opened}
      title={t('account.security.2fa.verificationModal.title')}
      value={value}
      error={responseMsgType === 2}
      errorText={t('account.security.2fa.verificationModal.failureMessage')}
    />
    </>
  );
};

export default CodeVerification;
