import styled from 'styled-components';

export const StyledRow = styled('div')({
  display: 'inline-flex',
  width: '100%',
});

export const StyledColumn = styled('div')({
  float: 'left',
  width: '33.33%',
});

export const SetupPageContainer = styled('div')({
  marginTop: '60px',
});
