import gql from 'graphql-tag';

export const ORDER_STATUS_ON_ORDER = gql`
  fragment OrderStatus on Order {
    status
  }
`;

export const ITEM_QUANTITY_ON_ORDER = gql`
  fragment ItemQuantity on Order {
    itemQuantity
  }
`;

export const PACKAGING_COLLATERALS_ON_ITEM = gql`
  fragment PackagingCollaterals on Item {
    packagingCollaterals {
      value
      isRequired
    }
  }
`;

export const CARD_VALUES_ON_ITEM = gql`
  fragment CardValues on Item {
    itemBrand
    itemMaterial
    itemStyle
    itemModel
    itemReferenceNo
    itemSerialNo
    itemCaseMaterial
    itemMovementType
    itemBracelet
    itemAuthenticationDate
    authenticatedDate
    nfcTag {
      id
      tagId
      tagUrl
    }
  }
`;

export const AVAILABLE_LOCATIONS = gql`
  fragment AvailableLocations on Item {
    availableLocations {
      id
      identifier
    }
  }
`;

export const NOT_AUTHENTIC_LOCATION = gql`
  fragment NotAuthenticLocation on Item {
    notAuthenticLocation {
      id
      identifier
    }
  }
`;

export const BRAND_AND_MODEL_ON_ITEM = gql`
  fragment BrandAndModel on Item {
    itemBrand
    itemModel
  }
`;

export const FAILED_STATION_ITEMS_ON_ITEMS = gql`
  fragment FailedStationItems on Item {
    failedStationItems {
      id
      status
      item {
        id
        lpn {
          id
          lpn
        }
      }
    }
  }
`;

export const STATION_ITEM_FOR_TIMELINE_ON_ITEM = gql`
  fragment StationItemForTimeline on Item {
    nextStation {
      id
      name
    }
    station {
      id
      name
    }
    isShipped
    order {
      id
      status
    }
    stationItems {
      timelineStationStatus
      station {
        id
        name
      }
      status
      grade
      id
      createdAt
      updatedAt
      completedAt
      firstName
      lastName
      userIdentification
    }
  }
`;

export const QC_STATION_ITEM_ON_ITEM = gql`
  fragment QcStationItem on Item {
    qcStationItem {
      id
      firstName
      lastName
      userIdentification
      auditCreatedAt
      createdAt
      updatedAt
      grade
      stationId
      itemId
      station {
        id
        name
      }
      status
      notes
      stationItemAnswers {
        id
        grade
        text
        updatedAt
        input {
          id
          key
          title
          inputType
          stationId
          parentId
          variant
          isRequired
        }
        inputValueId
        inputValue {
          id
          value
          icon
          entrupyCode
        }
      }
    }
  }
`;

export const AUTH_STATION_ITEM_ON_ITEM = gql`
  fragment AuthStationItem on Item {
    authStationItem {
      id
      firstName
      lastName
      userIdentification
      auditCreatedAt
      stationId
      itemId
      grade
      station {
        id
        name
      }
      status
      notes
      stationItemAnswers {
        id
        grade
        text
        updatedAt
        input {
          id
          key
          title
          inputType
          stationId
          parentId
          variant
          isRequired
          inputValues {
            id
            value
            icon
            entrupyCode
          }
        }
        inputValueId
        inputValue {
          id
          value
          icon
          entrupyCode
        }
      }
    }
  }
`;

export const ITEM_VALUES_AND_PARAMETERS_ON_ORDER = gql`
  fragment OrderItemValuesAndParameters on Order {
    itemValues {
      id
      value
      valueType
      itemParameter {
        id
        name
      }
    }
  }
`;

export const ITEM_VALUES_AND_PARAMETERS_ON_ITEM = gql`
  fragment ItemValuesAndParameters on Item {
    itemValues {
      id
      value
      itemParameter {
        id
        name
      }
    }
  }
`;

export const DESCRIPTION_ON_EBAY_ORDER = gql`
  fragment EbayOrderDescription on EbayOrder {
    description
  }
`;

export const DESCRIPTION_ON_EBAY_ITEM = gql`
  fragment EbayItemDescription on EbayItem {
    description
  }
`;

export const RETURN_ON_EBAY_ITEM = gql`
  fragment EbayItemReturn on EbayItem {
    returnReason
    returnUserComments
  }
`;

export const TITLE_ON_EBAY_ORDER = gql`
  fragment EbayOrderTitle on EbayOrder {
    title
  }
`;

export const TITLE_ON_EBAY_ITEM = gql`
  fragment EbayItemTitle on EbayItem {
    title
  }
`;

export const WHITE_GLOVE_ON_EBAY_ORDER = gql`
  fragment WhiteGlove on EbayOrder {
    whiteGlove
  }
`;

export const SUPERVISED_AUTHENTICATION_ON_EBAY_ORDER = gql`
  fragment SupervisedAuthentication on EbayOrder {
    supervisedAuthentication
  }
`;

export const ITEM_CONDITION_ON_EBAY_ORDER = gql`
  fragment EbayOrderItemCondition on EbayOrder {
    itemCondition
  }
`;

export const ITEM_CONDITION_ON_EBAY_ITEM = gql`
  fragment EbayItemItemCondition on EbayItem {
    itemCondition
  }
`;

export const IMAGE_URLS_ON_EBAY_ORDER = gql`
  fragment EbayOrderImageUrls on EbayOrder {
    imageUrls
  }
`;

export const IMAGE_URLS_ON_EBAY_ITEM = gql`
  fragment EbayItemImageUrls on EbayItem {
    imageUrls
  }
`;

export const SHIPMENT_ON_ORDER = gql`
  ${CARD_VALUES_ON_ITEM}
  fragment Shipment on Order {
    shipment {
      id
      allShipped
      direction
      trackingNumber
      deliveryDate
      signatureRequired
      address {
        id
        streetLine1
        streetLine2
        city
        stateProvinceCode
        country
        postalCode
      }
      carrier {
        name
        id
        image
      }
      order {
        ebayOrder {
          id
          signatureRequired
        }
      }
      shipmentBoxes {
        id
        shipped
        trackingNumber
        box {
          id
          boxSize
          length
          width
          height
        }
        dustBag {
          id
        }
        items {
          id
          ebayStatus
          blueBiteCardPrinting
          blueBiteCardRescanAtShipping
          ...CardValues @include(if: $forPackStation)
          lpn {
            id
            lpn
          }
        }
        shippingLabels {
          id
          isPhysical
          zplString
          fileUrl
          fileName
          isMainLabel
          tracking
        }
      }
    }
  }
`;

export const SHIPMENT_ON_SHIPMENT_BOX = gql`
  ${CARD_VALUES_ON_ITEM}
  fragment ShipmentOnShipmentBox on ShipmentBox {
    shipment {
      id
      allShipped
      trackingNumber
      deliveryDate
      address {
        id
        streetLine1
        streetLine2
        city
        stateProvinceCode
        country
        postalCode
      }
      carrier {
        name
        id
        image
      }
      shipmentBoxes {
        id
        shipped
        trackingNumber
        box {
          id
          boxSize
          length
          width
          height
        }
        dustBag {
          id
        }
        items {
          id
          ebayStatus
          ...CardValues @include(if: $forPackStation)
          lpn {
            id
            lpn
          }
        }

        shippingLabels {
          id
          zplString
        }
      }
    }
  }
`;

export const BOXES_LIST_ON_STATION = gql`
  fragment BoxesList on Station {
    boxesList {
      id
      boxSize
      length
      width
      height
      displayOrder
    }
  }
`;

const INTERNATIONALIZATION_FRAGMENT = gql`
  fragment InternationalizationFragment on Internationalization {
    id
    translatableId
    countryCode
    value
    translatableType
    scopeSelector
  }
`;

const BASE_INPUT_FRAGMENT = gql`
  fragment BaseInputFragment on Input {
    id
    title
    key
    description
    inputType
    parentId
    hasInputValues
    variant
    isRequired
    isActive
    canAddValue
    categoryId
    inputValidation
    isPhotoRequired
    snadCode
    hasPsnadValue(partnerLocationId: $partnerLocationId)
    internationalizations {
      ...InternationalizationFragment
    }
    translatedTitles {
      ...InternationalizationFragment
    }
    translatedPublicNotes {
      ...InternationalizationFragment
    }
    parentInputValue {
      id
    }
    taxonomyCategoryInput {
      id
      name
    }
  }
`;

export const INPUT_VALUE_FRAGMENT = gql`
  fragment InputValueFragment on InputValue {
    id
    inputType
    inputId
    value
    icon
    entrupyCode
    parentId
  }
`;

export const INPUT_WITH_INPUT_VALUES_FRAGMENT = gql`
  ${INTERNATIONALIZATION_FRAGMENT}
  ${BASE_INPUT_FRAGMENT}
  ${INPUT_VALUE_FRAGMENT}
  fragment InputFragmentWithInputValues on Input {
    ...BaseInputFragment
    inputValues {
      ...InputValueFragment
    }
    childInputs(partnerLocationId: $partnerLocationId) {
      ...BaseInputFragment
      inputValues {
        ...InputValueFragment
      }
      childInputs(partnerLocationId: $partnerLocationId) {
        ...BaseInputFragment
        inputValues {
          ...InputValueFragment
        }
        childInputs(partnerLocationId: $partnerLocationId) {
          ...BaseInputFragment
          inputValues {
            ...InputValueFragment
          }
        }
      }
    }
  }
`;

export const INPUT_FRAGMENT = gql`
  ${INTERNATIONALIZATION_FRAGMENT}
  ${BASE_INPUT_FRAGMENT}
  fragment InputFragment on Input {
    ...BaseInputFragment
    childInputs(partnerLocationId: $partnerLocationId) {
      ...BaseInputFragment
      childInputs(partnerLocationId: $partnerLocationId) {
        ...BaseInputFragment
        childInputs(partnerLocationId: $partnerLocationId) {
          ...BaseInputFragment
        }
      }
    }
  }
`;

export const ORDER_LIST_FRAGMENT = gql`
  fragment OrderListFragment on Order {
    id
    isFulfillment
    gid
    createdAt
    updatedAt
    status
    evtn
    shipment {
      allShipped
    }
    partnerLocation {
      id
      name
    }
    ebayOrder {
      id
      itemUrl
      imageUrls
      authenticationLevel
      psaMode
    }
  }
`;

export const ITEM_LIST_FRAGMENT = gql`
  fragment ItemListFragment on Item {
    id
    gid
    createdAt
    updatedAt
    ebayItemUrlId
    ebayItem {
      id
      unitId
      imageUrls
      itemUrl
      authenticationLevel
      psaMode
      signatureRequired
    }
    authenticityStatus
    stationItems {
      station {
        id
        name
      }
      id
      timelineStationStatus
      createdAt
      completedAt
      updatedAt
    }
    category {
      name
    }
    station {
      id
      name
      displayOrder
    }
    nextStation {
      id
      name
    }
    lpn {
      id
      lpn
    }
    currentLocation {
      id
      identifier
    }
    shipmentBox {
      id
      shipment {
        id
        allShipped
      }
    }
    isNoShippingRequired
    order {
      createdAt
      updatedAt
      evtn
      evtnsOnRelatedOrder
      objectExceptions {
        id
        flagValue
        resolvedAt
      }
      shipment {
        allShipped
      }
      partnerLocation {
        name
        timeZone
      }
      ebayOrder {
        id
        itemUrl
        imageUrls
        authenticationLevel
        psaMode
      }
      status
    }
  }
`;

export const ORDER_HISTORY_FRAGMENT = `
fragment OrderHistoryFragment on Order {
  id
  createdAt
  updatedAt
  evtn
  evtnsOnRelatedOrder
  status
  ebayOrderState
  interceptionFlag
  partnerLocation {
        id
        isInterceptable
        category {
          name
        }
      }
  itemValues {
    id
    value
    itemParameter {
      id
      name
    }
  }
  trackings {
    carrier
    trackingNumber
    received
  }
  ebayOrder {
    id
    externalOrderId
    itemUrl
    imageUrls
    authenticationLevel
    psaMode
    whiteGlove
    supervisedAuthentication
  }
  notes {
    userIdentification
    description
  }
}
`;

export const HANDBAG_AUTH_DETAILS = gql`
  fragment HandbagAuthDetails on Item {
    itemBrand
    itemModel
    itemIdentifier
    itemStyle
    itemMaterial
  }
`;

export const ITEM_HISTORY_FRAGMENT = gql`
  fragment ItemHistoryFragment on Item {
    id
    isShipped
    isItemClosed
    isNoShippingRequired
    isSport
    isExport
    isPsnadItem
    psnadItemStatus
    exportCountry
    isInHandSubmission
    blueBiteCardPrinting
    blueBiteCardRescan
    blueBiteCardUpdating
    itemBranded
    itemCutGrade
    ebayItemUrlId
    isLiveEvent
    isVaultShipping
    itemValues {
      id
      value
      itemParameter {
        id
        name
      }
    }
    authenticatorResponses {
      id
      status
      photosInfo {
        filename
        url
      }
    }
    authenticityMatrix {
      authentication
      condition
      customized
      miscategorized
      description
    }
    watchAuthMatrixSiAnswers {
      id
      grade
      input {
        id
        title
      }
      inputValue {
        id
        value
        inputId
      }
    }
    nfcTag {
      tagId
      tagUrl
    }
    itemAuthenticationRiskLevel {
      id
      authenticationRiskLevel
      historicalPriorAuthentication {
        sku
        notAuthenticSum
        totalSum
      }
    }
    aiPrediction {
      id
      predictedStatus
      confidence
      riskScore
    }
    objectExceptions {
      id
      createdAt
      updatedAt
      resolvedAt
      gcxTicketNumber
      exceptionStatus
      stationId
      resolvedBy {
        username
        firstName
        lastName
      }
      notes {
        id
        description
      }
      createdBy {
        id
        userIdentification
      }
      exceptionCode {
        id
        name
      }
      assignedTo {
        id
        userIdentification
      }
    }
    createdAt
    lpnId
    lpn {
      id
      lpn
    }
    currentLocation {
      id
      identifier
    }
    order {
      id
      evtn
      evtnsOnRelatedOrder
      nfcTag {
        tagId
        tagUrl
      }
      objectExceptions {
        id
        createdAt
        updatedAt
        resolvedAt
        gcxTicketNumber
        exceptionStatus
        stationId
        resolvedBy {
          username
          firstName
          lastName
        }
        notes {
          id
          description
        }
        createdBy {
          id
          userIdentification
        }
        exceptionCode {
          id
          name
        }
        assignedTo {
          id
          userIdentification
        }
      }
      status
      ebayOrderState
      authenticationDetailStatus
      interceptionFlag
      isFulfillment
      isVaultBound
      isInHandSubmission
      isFailFast
      isReturn
      itemValues {
        id
        value
        itemParameter {
          id
          name
        }
      }
      trackings {
        id
        carrier
        trackingNumber
        received
      }
      ebayOrder {
        id
        externalOrderId
        itemUrl
        imageUrls
        authenticationLevel
        psaMode
        signatureRequired
        whiteGlove
        supervisedAuthentication
        returnCreationTimestamp
        parsedTrackingNumbers {
          carrier
          trackingNumber
        }
        sellerUserId
      }
      notes {
        id
        userIdentification
        description
      }
      orderExceptions {
        id
        createdAt
        updatedAt
        gcxTicketNumber
        exceptionStatus
        stationId
        resolvedBy {
          username
          firstName
          lastName
        }
        notes {
          id
          description
          createdAt
        }
        createdBy {
          id
          userIdentification
        }
        exceptionCode {
          id
          name
        }
        assignedTo {
          id
          userIdentification
        }
        resolvedAt
      }
      partnerLocation {
        id
        authenticationStrategy
        timeZone
        isInterceptable
        name
        hubId
      }
    }
    ebayItem {
      id
      unitId
      imageUrls
      itemUrl
      authenticationLevel
      psaMode
      itemPrice
      signatureRequired
      returnReason
      returnUserComments
    }
    allItemImages {
      isAlitheon
      isPsnad
      imagePill
      imageUrl
      title
    }
    category {
      id
      name
    }
    stationId
    station {
      id
      name
    }
    nextStation {
      id
      name
    }
  }
`;

export const PAGE_INFO_FRAGMENT = gql`
  fragment PageInfoFragment on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;
export const ITEMS_CONNECTION_FRAGMENT = gql`
  fragment ItemsConnectionFragment on ItemConnection {
    edges {
      cursor
      node {
        ...ItemListFragment
      }
    }
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
`;

export const STATION_RECEIVED_ITEMS = gql`
  fragment StationReceivedItems on StationTickerReport {
    receivedItems(last: $last, before: $before) {
      ...ItemsConnectionFragment
    }
  }
`;

export const STATION_COMPLETED_ITEMS = gql`
  fragment StationCompletedItems on StationTickerReport {
    completedItems(last: $last, before: $before) {
      ...ItemsConnectionFragment
    }
  }
`;

export const STATION_PENDING_ITEMS = gql`
  fragment StationPendingItems on StationTickerReport {
    pendingItems(last: $last, before: $before) {
      ...ItemsConnectionFragment
    }
  }
`;

export const STATION_ITEM_ANSWERS_HISTORY = gql`
  fragment StationItemAnswersHistory on StationItem {
    stationItemAnswers {
      id
      grade
      notes
      text
      inputValue {
        id
        value
        inputId
      }
      input {
        id
        title
        inputType
        parentId
        childInputs {
          id
        }
      }
    }
  }
`;

export const ORDER_EXCEPTION_LIST_FRAGMENT = gql`
  fragment OrderExceptionListFragment on OrderException {
    id
    createdAt
    gcxTicketNumber
    actualLocations {
      id
      identifier
    }
    station {
      id
      name
    }
    exceptionCode {
      id
      name
      displayName
    }
    flagType
    flagValue
    order {
      id
      evtn
      imageUrls
      isVaultBound
      isExport
      exportCountry
      isInHandSubmission
      trackings {
        id
        trackingNumber
      }
      ebayOrder {
        id
        imageUrls
        itemUrl
      }
      items {
        id
        currentLocation {
          id
          identifier
        }
        lpn {
          id
          lpn
        }
        allItemImages {
          imageUrl
          imagePill
          isAlitheon
          title
        }
      }
      partnerLocation {
        name
        timeZone
      }
    }
    createdBy {
      id
      userIdentification
    }
  }
`;

export const ORDER_EXCEPTIONS_CONNECTION_FRAGMENT = gql`
  fragment OrderExceptionsConnectionFragment on OrderExceptionConnection {
    edges {
      node {
        ...OrderExceptionListFragment
      }
      cursor
    }
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
`;

export const OBJECT_EXCEPTION_LIST_FRAGMENT = gql`
  fragment ObjectExceptionListFragment on ObjectException {
    id
    createdAt
    gcxTicketNumber
    relatedObjectId
    relatedObjectType
    actualLocations {
      id
      identifier
    }
    station {
      id
      name
    }
    exceptionCode {
      id
      name
      displayName
    }
    flagType
    flagValue
    item {
      id
      name
      alitheonStatus
      itemBranded
      itemCertificationNumber
      isExport
      exportCountry
      isInHandSubmission
      isConfirmReceived
      allItemImages {
        isAlitheon
        imageUrl
        title
      }
      ebayItem {
        id
        unitId
        imageUrls
        itemUrl
        authenticationLevel
        psaMode
        signatureRequired
      }
      lpn {
        id
        lpn
        createdAt
      }
    }
    order {
      id
      evtn
      imageUrls
      isVaultBound
      isFulfillment
      isExport
      exportCountry
      isInHandSubmission
      trackings {
        id
        trackingNumber
      }
      ebayOrder {
        id
        imageUrls
        itemUrl
      }
      items {
        id
        currentLocation {
          id
          identifier
        }
        lpn {
          id
          lpn
        }
        allItemImages {
          imageUrl
          imagePill
          isAlitheon
          title
        }
      }
      partnerLocation {
        name
        timeZone
      }
    }
    createdBy {
      id
      userIdentification
    }
  }
`;

export const OBJECT_EXCEPTIONS_CONNECTION_FRAGMENT = gql`
  fragment ObjectExceptionsConnectionFragment on ObjectExceptionConnection {
    edges {
      node {
        ...ObjectExceptionListFragment
      }
      cursor
    }
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
`;

export const ALITHEON_EXTENDED_DATA = gql`
  fragment AlitheonExtendedData on AlitheonItem {
    extendedData {
      lpn
      game
      cardName
      set
      setName
      stationId
      player
      registrationItemId
      registrationItemIdList
    }
  }
`;

export const ALITHEON_ATTACHMENTS = gql`
  fragment AlitheonAttachments on AlitheonItem {
    attachments {
      front {
        title
        url
      }
      back {
        title
        url
      }
    }
  }
`;

export const FiltersRequirementsQueryFragment = gql`
  fragment FiltersRequirementsQueryFragment on Query {
    partnerLocations(filter: $partnerLocationFilter) {
      id
      name
      hubId
      categoryId
    }
    categories {
      id
      name
    }
    queryStations {
      id
      name
      rack
      cart
      displayOrder
    }
    carriers {
      id
      name
    }
    serviceLevels(input: $input) @include(if: $withInput) {
      id
      carrierId
      name
    }
  }
`;

export const PARTNER_LOCATION_CONFIGURATIONS = gql`
  fragment PartnerLocationConfigurations on Order {
    partnerLocation {
      id
      conditionGuidelinesUrl
      hubId
    }
  }
`;

export const OptimalVaultShipmentBoxFragment = gql`
  fragment optimalVaultShipmentBoxFragment on ShipmentBox {
    id
    box {
      id
      valueThreshold
      quantityThreshold
    }
    remainingValueThreshold
    remainingQuantityThreshold
    lpn {
      id
      lpn
    }
    items {
      id
      lpn {
        id
        lpn
      }
    }
  }
`;

export const VaultShipmentBoxOnItem = gql`
  fragment vaultShipmentBoxOnItem on ShipmentBox {
    id
    shipped
    isReadyToShipVault
    trackingNumber
    box {
      id
      valueThreshold
      quantityThreshold
      isVaultOverpricedType
      boxSize
      height
      width
      length
    }
    remainingValueThreshold
    remainingQuantityThreshold
    lpn {
      id
      lpn
    }
    items {
      id
      itemPrice
      order {
        id
        itemPrice
        imageUrls
      }
      lpn {
        id
        lpn
      }
    }
    shippingLabels {
      id
      zplString
    }
    shipment {
      id
      signatureRequired
      carrier {
        id
        name
        image
      }
      deliveryDate
      address {
        id
        streetLine1
        streetLine2
        city
        stateProvinceCode
        country
      }
    }
  }
`;

export const SHIPMENT_BOX_SCAN_ITEM_BY_LPN = gql`
  fragment ShipmentBoxScanItemByLpn on Item {
    shipmentBox {
      ...vaultShipmentBoxOnItem
      id
      dustBagId
      boxId
      shippingLabels {
        isPhysical
      }
      items {
        id
        ebayStatus
        isExport
        exportCountry
        isInHandSubmission
        ...CardValues
        lpn {
          id
          lpn
        }
        order {
          id
          ebayStatus
          isReturn
        }
        category {
          id
          name
          hasCollaterals
        }
        blueBiteCardType
        blueBiteCardScanned
        blueBiteCardPrinting
        blueBiteCardRescanAtShipping
      }
      ...ShipmentOnShipmentBox
    }
  }
`;

export const RELATED_RETURN_ITEMS = gql`
  fragment RelatedReturnItems on Item {
    relatedReturnItems {
      id
      isReturnToSeller
      currentLocation {
        id
        identifier
      }
      lpn {
        id
        lpn
      }
    }
  }
`;

export const ORDER_ITEMS = gql`
  fragment OrderItems on Item {
    orderItems {
      id
      lpn {
        id
        lpn
      }
    }
  }
`;

export const PSNAD_EXCEPTION = gql`
  fragment PsnadException on PsnadException {
    id
    isEligible
    status
    daysSinceReported
    respondedAt
    respondedBy
    submittedAt
    submittedBy
    createdAt
    updatedAt
  }
`;

export const ALL_ITEM_IMAGES = gql`
  fragment AllItemImages on Item {
    allItemImages {
      itemImageId
      imageUrl
      imagePill
      isPsnad
      isAlitheon
      title
      objectExceptionId
    }
  }
`;
