import React, { createContext, useMemo, useState } from 'react';


export const LoadingContext = createContext({});

// eslint-disable-next-line react/prop-types
const LoadingProvider = ({ children }) => {
  const [globalLoading, setLoading] = useState(false);

  const value = useMemo(
    () => ({
      globalLoading,
      setLoading,
    }),
    [globalLoading]
  );

  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
};

function useLoadingContext() {
  const context = React.useContext(LoadingContext);
  if (context === undefined) {
    throw new Error('useLoadingContext must be used within a LoadingProvider');
  }
  return context;
}

export { LoadingProvider, useLoadingContext };
