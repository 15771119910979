import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useExceptionNoteItemStyles = makeStyles(() => ({
  paper: {
    padding: '0.6em',
    margin: '0.5em 0.5em',
  },
  note: {
    padding: '0 0.3em',
    width: 'fit-content',
  },
  line: {
    borderTop: '1px solid gray',
  },
  file: {
    display: 'block',
  },
}));

const ExceptionNoteItem = ({ note }) => {
  const { t } = useTranslation();
  const classes = useExceptionNoteItemStyles();

  return (
    <Paper square elevation={3} className={classes.paper}>
      <Grid container item direction="column">
        <Grid container item direction="row" justifyContent="space-between">
          <Grid item>
            <Typography variant="body2">
              {t('ExceptionNotesPopup.Author', {
                userIdentification: note.userIdentification,
              })}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {t('date.CreatedAt', {
                date: new Date(note.createdAt),
              })}
            </Typography>
          </Grid>
        </Grid>
        {note.description && (
          <Grid item className={classes.note}>
            <b>{note.description}</b>
          </Grid>
        )}
        {note.filesInfo?.length > 0 && (
          <Grid item>
            <hr className={classes.line} />
            {note.filesInfo.map(({ filename, url }) => (
              <a
                key={filename}
                href={url}
                className={classes.file}
                rel="noreferrer"
                target="_blank"
                download={filename}
              >
                {filename}
              </a>
            ))}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default ExceptionNoteItem;

ExceptionNoteItem.propTypes = {
  note: PropTypes.shape({
    id: PropTypes.string.isRequired,
    userIdentification: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    description: PropTypes.string,
    filesInfo: PropTypes.arrayOf(
      PropTypes.shape({
        filename: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};
