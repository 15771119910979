import { Grid, ListItem, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ItemConditionPill from '../../itemConditionPill';
import { COLORS } from '../../../../../constants';
import useStyles from '../useStyles';

const useConditionDefinitionModal = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const renderItemConditionListItem = (itemCondition, onOpenModal) => (
    <ListItem style={{ paddingLeft: 0 }}>
      <Grid container item xs={12} sm={12} direction="row">
        <Grid
          container
          item
          xs={4}
          sm={4}
          display="flex"
          direction="row"
          alignItems="center"
        >
          <div className={classes.flexRow}>
            <Typography variant="body1">
              <strong>{t('GinzaV2.ItemCondition')}</strong>
            </Typography>
            <button
              className={classes.infoButton}
              type="button"
              onClick={onOpenModal}
            >
              <InfoOutlined fontSize="small" />
            </button>
          </div>
        </Grid>
        <Grid item xs={8} sm={8}>
          <ItemConditionPill
            backgroundColor={COLORS.yellow}
            condition={itemCondition}
          />
        </Grid>
      </Grid>
    </ListItem>
  );

  return { renderItemConditionListItem };
};

export default useConditionDefinitionModal;
