import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './index.styles';
import { STATUS_COLORS } from './constants';
import { Label } from '../StyledComponent';

const BACKGROUND_COLOR = {
  [STATUS_COLORS.green]: '#edf5dc',
  [STATUS_COLORS.orange]: '#fef3dc',
  [STATUS_COLORS.red]: '#fbe1e1',
  [STATUS_COLORS.blue]: '#daeaf9',
  [STATUS_COLORS.white]: '#ffffff',
};

const BORDER_COLOR = {
  [STATUS_COLORS.green]: '#86b817',
  [STATUS_COLORS.orange]: '#f4ae13',
  [STATUS_COLORS.red]: '#e43237',
  [STATUS_COLORS.blue]: '#076ed4',
  [STATUS_COLORS.white]: '#cbcbcb',
};

const StatusContainer = ({ children, backgroundColor, label }) => {
  return (
    <Container
      backgroundColor={BACKGROUND_COLOR[backgroundColor]}
      borderColor={BORDER_COLOR[backgroundColor]}
    >
      {label && <Label>label</Label>}
      {children}
    </Container>
  );
};

StatusContainer.defaultProps = {
  label: undefined,
};

StatusContainer.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.oneOf([
    STATUS_COLORS.green,
    STATUS_COLORS.red,
    STATUS_COLORS.blue,
    STATUS_COLORS.orange,
    STATUS_COLORS.white,
  ]).isRequired,
  label: PropTypes.string,
};

export default StatusContainer;

// change for cherry pick
