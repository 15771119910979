import styled from 'styled-components';

export const StepContent = styled('span')({
  minWidth: '200px',
  maxWidth: '383px',
  minHeight: '120px',
  margin: '0 0 27.2px',
  textAlign: 'center',
  display: 'inline-block',
});

export const StepTitle = styled('span')({
  fontWeight: 'bold',
});
