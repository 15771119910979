import { postRequest } from '../httpClient';
import { getAPIUri } from '../../../../graphql/apollo_client';

const post2faOtpAction = async (actionURL, payload) => {
  return postRequest(actionURL, payload)
    .catch((error) => {
      return error.response;
    })
    .then((response) => {
      return response;
    });
};

export const post2faOtpCreate = async () => {
  return post2faOtpAction(
    (getAPIUri() || '') + (process.env.AUTH_OTP_CREATE_PATH || '')
  );
};

export const post2faOtpEnable = async (payload) => {
  return post2faOtpAction(
    (getAPIUri() || '') + (process.env.AUTH_OTP_ENABLE_PATH || ''),
    payload
  );
};

export const post2faOtpVerify = async (payload) => {
  return post2faOtpAction(
    (getAPIUri() || '') + (process.env.AUTH_OTP_VERIFY_PATH || ''),
    payload
  );
};
