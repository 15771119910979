import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import { getStorage } from '../../../../api/rest/storage';

export default function useStorage(exceptionIds, imsEnabled) {
  const [storageDetails, setStorageDetails] = useState([]);

  const { isLoading, isSuccess, data, refetch } = useQuery({
    queryKey: ['storage', exceptionIds],
    queryFn: () => getStorage({ exceptionIds }),
    cacheTime: 0,
    enabled: imsEnabled && !isEmpty(exceptionIds),
  });

  useEffect(() => {
    if (!isLoading && isSuccess) {
      if (data?.data?.storageDetails) {
        setStorageDetails(data.data.storageDetails);
      }
    }
  }, [isSuccess, isLoading, data]);

  return { storageDetails, refetch };
}
