import styled from 'styled-components';

export const ImageWrapper = styled('img')(
  ({ width, height, alt, margin, src, srcSet }) => ({
    width,
    height,
    alt,
    margin,
    src,
    srcSet,
    objectFit: 'contain',
  })
);

export default ImageWrapper;
