import styled from 'styled-components';
import { Chip } from '@material-ui/core';
import { COLORS } from '../../../constants';

export const StyledChip = styled(Chip)`
  position: relative;
  height: ${({ containlabel }) =>
    containlabel === 'true' ? '45px' : '20px'} !important;
  box-shadow: ${({ shadow }) =>
    shadow === 'true' ? '5px 5px 10px #c7c7c7' : 'none'};
  border: ${({ border }) =>
    border === 'true' ? '2px solid' : 'none'} !important;
  border-color: ${({ bordercolor }) => bordercolor || ''} !important;
  padding: 0.5em 0;
  width: ${({ fullwidth }) => (fullwidth === 'true' ? '100%' : '')};
  border-radius: 25px !important;
  background-color: ${({ backgroundcolor }) => backgroundcolor} !important;
  color: ${({ textcolor }) => textcolor} !important;
  text-transform: ${({ texttransform }) =>
    texttransform === 'true' ? 'uppercase' : 'none'} !important;
  font-weight: ${({ fontWeight }) =>
    fontWeight === 'true' ? 'bold' : '400'} !important;
`;

StyledChip.displayName = 'StyledChip ';

export const ChipLabel = styled.p`
  position: absolute;
  top: 1px;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 0.6rem;
  text-align: center;
`;
ChipLabel.displayName = 'ChipLabel ';

export const ChipText = styled.p`
  padding: 0;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  text-align: center;
`;

ChipText.displayName = 'ChipText ';

export const Status = styled.p`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '11px')};
  margin: 0;
  border-radius: 1em;
  text-align: center;
  padding: ${(props) => (props.padding ? props.padding : '3px 1em')} !important;
  margin: 0px 0px 2px 5px;
  color: white !important;
  font-weight: 600;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : COLORS.darkBlue} !important;
  display: flex;
  align-self: center;
  ${(props) =>
    props.isCard
      ? `
  position: absolute;
  top: -9px;
  right: ${props.right || 'auto'};
  left: ${props.left || '-9px'};`
      : null}
`;

export const StatusBranded = styled(Status)`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : COLORS.red} !important;
`;
StatusBranded.displayName = 'StatusBranded';

export const StatusVault = styled(Status)`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : COLORS.darkBlue} !important;
`;
StatusVault.displayName = 'StatusVault';

export const StatusExport = styled(Status)`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : COLORS.highlightRed} !important;
`;
StatusExport.displayName = 'StatusExport';
