import React, { useState } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { COLORS, Toggle } from '@codeboxxtechschool/ginza_shared_components';
import { translate } from 'react-admin';

const Accordion = styled((props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ bgcolor, theme }) => ({
  backgroundColor: bgcolor || COLORS.gray600,
  color: COLORS.white,
  borderRadius: '5px',
  marginBottom: theme.spacing(2),
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ExpandMoreIcon sx={{ fontSize: '0.9rem', marginLeft: '10px' }} />
    }
    {...props}
  />
))(() => ({
  flexDirection: 'row',
  paddingLeft: 0,
  '& .MuiAccordionSummary-expandIconWrapper': {
    marginLeft: '15px',
    color: 'white',
  },
  '& .MuiTypography-h6': {
    lineHeight: '2.5',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    marginLeft: '16px',
    borderRight: '1px solid white',
    height: '48px',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '0px 16px',
  backgroundColor: COLORS.white,
  color: 'black',
  border: 'none',
}));

const useStyles = makeStyles({
  titleWarning: {
    color: COLORS.white,
    fontStyle: 'italic',
    lineHeight: '48px',
    marginLeft: 'auto',
    marginRight: '20px',
  },
  count: {
    marginLeft: '5px',
  },
  accordionWrap: {
    display: 'flex',
  },
  accordionMain: {
    flex: 1,
  },
  toggleAndWarning: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
    marginRight: '10px',
  },
  spanMouseover: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
    transform: 'translateY(-70px) translateX(-15px)',
    fontSize: '10px',
    padding: '5px',
    minWidth: '117px',
  },
});

const CustomAccordion = ({
  title,
  checkedItemCount,
  displayTitleWarning,
  children,
  handleAllSelect,
  checkType,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const checked = checkType === 'fail' ? 'unchecked' : 'checked';
  const [isMouseOver, setIsMouseOver] = useState(false);
  return (
    <div className={classes.accordionWrap}>
      <Accordion
        bgcolor={checkedItemCount > 0 ? COLORS.red900 : null}
        className={classes.accordionMain}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="accordion-summary"
          id="accordion-summary"
        >
          <Typography variant="h6">{title}</Typography>
          {checkedItemCount > 0 && (
            <Typography variant="h6" style={{ marginLeft: '5px' }}>
              ({checkedItemCount})
            </Typography>
          )}
          <div className={classes.toggleAndWarning}>
            {displayTitleWarning && (
              <Typography
                className={classes.titleWarning}
                data-testid="accordion-title-warning"
                variant="body1"
              >
                {t('PSNAD.SubmissionForm.PhotoRequiredWarning')}
              </Typography>
            )}
            <div>
              <div
                onMouseEnter={() => setIsMouseOver(true)}
                onMouseLeave={() => setIsMouseOver(false)}
              >
                <Toggle
                  title={title}
                  onClick={(e) => {
                    handleAllSelect();
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  totalStates={3}
                  value={checkedItemCount > 0 ? checked : 'neutral'}
                />
                {isMouseOver && (
                  <span className={classes.spanMouseover}>
                    {t('PSNAD.SelectUnselectAll')}
                  </span>
                )}
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CustomAccordion;

CustomAccordion.defaultProps = {
  title: '',
  children: <></>,
  displayTitleWarning: false,
  checkedItemCount: 0,
};

CustomAccordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  displayTitleWarning: PropTypes.bool,
  checkedItemCount: PropTypes.number,
  handleAllSelect: PropTypes.func,
  checkType: PropTypes.string,
};
