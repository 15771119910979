import React from 'react';
import { Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DialogContent } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '@codeboxxtechschool/ginza_shared_components';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  closeBtn: {
    position: 'absolute',
    right: '8px',
    top: '8px',
    color: COLORS.gray800,
  },
  contentContainer: {
    margin: '0px',
    height: 'fit-content',
  },
});

const PopupModal = ({
  title,
  subtitle,
  openModal,
  onClose,
  children,
  dialogWidth,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={openModal}
      fullWidth
      maxWidth={dialogWidth}
      BackdropProps={{
        style: { backgroundColor: 'rgba(229, 226, 226, 0.6)' },
      }}
    >
      <div>
        <DialogTitle disableTypography>
          <Typography variant="h5" style={{ fontWeight: 800 }}>
            {title}
          </Typography>
          <Typography variant="body1">{subtitle}</Typography>
          {onClose ? (
            <IconButton
              className={classes.closeBtn}
              aria-label="close"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent className={classes.contentContainer}>
          {children}
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default PopupModal;

PopupModal.defaultProps = {
  dialogWidth: 'sm',
  title: '',
  subtitle: '',
  openModal: false,
  onClose: null,
  children: <></>,
};

PopupModal.propTypes = {
  dialogWidth: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  openModal: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
};
