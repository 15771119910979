import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Box, ButtonGroup } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Button from '@material-ui/core/Button';
import { useStateValue } from '../providers/StateProvider';
import InputButton from './reusables/inputs/InputButton';
import ExceptionPopup from './stations/Exception/ReportException/ExceptionPopup';
import { FLAG_EXCEPTION } from '../graphql/mutations';

import { getStationIdByName } from '../utils/stationsInfo';
import ExceptionNotesPopup from './stations/Exception/ExceptionNotes/ExceptionNotesPopup';
import { COLORS } from '../constants';
import Popper from './reusables/popper';

const ExceptionNav = (props) => {
  const { resetStation } = props;
  const { id } = useParams();
  const { t } = useTranslation();
  const [flagException] = useMutation(FLAG_EXCEPTION);
  const [showExceptionNotes, setShowExceptionNotes] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [exceptionPopupVisibility, setExceptionPopupVisibility] =
    useState(false);
  const [
    {
      alert,
      currentStationId,
      orderExceptions,
      partnerLocation,
      stationList,
      readOnlyException,
      scannedString,
    },
    dispatch,
  ] = useStateValue();

  const dispatchError = (err) =>
    dispatch({
      type: 'changePopup',
      newPopup: {
        type: 'error',
        title: t('PopupContent.Error.ErrorWhileSending'),
        message: `${err}`,
        btnMessage: t('ButtonContent.Close'),
      },
    });

  const handleSubmitException = async (prepValues) => {
    try {
      await flagException(prepValues);
      resetStation();
    } catch (err) {
      dispatchError(err);
    }
  };

  const resetOrderExceptionNotes = () => {
    setShowExceptionNotes(false);
    dispatch({
      type: 'changeOrderExceptions',
      newOrderExceptions: [],
    });
  };

  const openExceptionNotes = () => setShowExceptionNotes(true);

  const throwBack = () => {
    sessionStorage.setItem('stationId', null);
    resetOrderExceptionNotes();
    dispatch({ type: 'changeCurrentStationId', newCurrentStationId: null });
  };

  const onMouseEnter = (e) => {
    if (!anchorEl) setAnchorEl(e.currentTarget);
    setPopoverOpen(true);
  };

  const onMouseLeave = () => {
    setPopoverOpen(false);
  };

  useEffect(() => {
    if (!partnerLocation?.id) {
      throwBack();
    }
  }, [partnerLocation]);

  useEffect(() => {
    if (alert)
      setTimeout(
        () => dispatch({ type: 'changeAlert', newAlert: false }),
        6000
      );
  }, [alert]);

  useEffect(() => {
    if (scannedString) {
      setExceptionPopupVisibility(true);
    }
  }, [scannedString]);

  return (
    <>
      <ExceptionNotesPopup
        handleClose={() => setShowExceptionNotes(false)}
        orderExceptions={orderExceptions}
        isOpened={showExceptionNotes && orderExceptions?.length > 0}
      />
      {id !== getStationIdByName(stationList, 'Admin') && (
        <>
          <ExceptionButtonContainer>
            {readOnlyException?.exceptionAllowed ? null : (
              <Popper
                anchorEl={anchorEl}
                backgroundColor={COLORS.black}
                id="popperException"
                open={popoverOpen}
              >
                <Box fontSize={14}>
                  {t(`PopupContent.Error.${readOnlyException?.message}`)}
                </Box>
              </Popper>
            )}
            <ButtonGroup
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <InputButton
                width="inherit"
                type="alert"
                id="add-exception-button"
                dataTestId="add-exception-button"
                content={t('ButtonContent.ReportException')}
                onClick={() => setExceptionPopupVisibility(true)}
                disabled={!readOnlyException?.exceptionAllowed}
              />
              {orderExceptions.length > 0 && (
                <ExceptionNoteIcon onClick={openExceptionNotes}>
                  <PostAddIcon fontSize="large" />
                </ExceptionNoteIcon>
              )}
            </ButtonGroup>
          </ExceptionButtonContainer>
        </>
      )}
      {exceptionPopupVisibility && (
        <ExceptionPopup
          handleSubmit={handleSubmitException}
          handleClose={() => setExceptionPopupVisibility(false)}
          stationId={currentStationId}
          interceptedValue={scannedString}
        />
      )}
    </>
  );
};

ExceptionNav.propTypes = {
  resetStation: PropTypes.func.isRequired,
};

export default ExceptionNav;

const ExceptionButtonContainer = styled.div`
  margin: 0 1em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  @media only screen and (max-width: 1100px) {
    justify-content: center;
  }
  @media only screen and (max-width: 600px) {
    margin: 0;
    padding: 10px 0;
    justify-content: flex-end;
  }
`;

const ExceptionNoteIcon = styled(Button)`
  border: 2px solid #e53238 !important;
  background-color: #e53238 !important;
  color: #fff !important;
  border-left: 0 !important;
  border-radius: 0 !important;
`;
