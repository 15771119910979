import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, COLORS } from '@codeboxxtechschool/ginza_shared_components';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import PopupModal from '../popupModal';
import Dropzone from '../dropzone';
import NotificationBanner from '../notificationBanner';

const useStyle = makeStyles({
  photoRow: {
    borderBottom: `1px solid ${COLORS.gray400}`,
    padding: '8px',
    '&:last-child': {
      borderBottom: 'none',
    },
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridGap: '8px',
  },
  container: {
    backgroundColor: COLORS.gray100,
    padding: '16px',
  },
  photoName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

const arrayToTwoColumnList = (array) => {
  return array.reduce((output, photo, currentIndex) => {
    const lastIndex = output.length > 0 ? output.length - 1 : output.length;
    switch (currentIndex % 2) {
      case 0:
        output.push([photo]);
        break;
      case 1:
        output[lastIndex].push(photo);
        break;
      default:
        return output;
    }
    return output;
  }, []);
};

const MAX_PHOTO_COUNT = 10;

const UploadModal = ({ open, onClose, onUpload, uploadedPhotos }) => {
  const { t } = useTranslation();
  const classes = useStyle();

  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false);
  const [photosToUpload, setPhotosToUpload] = useState([]);
  const [photoNames, setPhotoNames] = useState([]);
  const hasPhotos = photoNames.length > 0;
  const subtitleText = hasPhotos
    ? t('PSNAD.PhotoUpload.AddPhotoPrompt')
    : t('PSNAD.PhotoUpload.Prompt');

  const totalPhotos = (newPhotoCount = 0) => {
    return uploadedPhotos.length + photosToUpload.length + newPhotoCount;
  };

  const handleDrop = (acceptedFiles) => {
    if (totalPhotos(acceptedFiles.length) > MAX_PHOTO_COUNT) return;

    const photoBlobs = acceptedFiles.map((file) => ({
      file,
      type: file.name,
    }));
    setPhotosToUpload(photoBlobs);

    const photoFileNames = acceptedFiles.map((blob) => blob.name);
    setPhotoNames(arrayToTwoColumnList(photoFileNames));
  };

  const reset = () => {
    setPhotosToUpload([]);
    setPhotoNames([]);
    setIsUploadingPhoto(false);
  };

  return (
    <PopupModal
      openModal={open}
      title={t('PSNAD.PhotoUpload.UploadPhotos')}
      subtitle={subtitleText}
      onClose={onClose}
      dialogWidth="md"
    >
      <Grid container direction="column" spacing={1}>
        {hasPhotos && (
          <Grid item xs={12} sm={12}>
            <div className={classes.container}>
              {photoNames.map((photoRow) => (
                <div
                  key={`photo-row-names-${photoRow[0]}-${photoRow[1]}`}
                  className={classes.photoRow}
                >
                  <Typography variant="body1" className={classes.photoName}>
                    {photoRow[0]}
                  </Typography>
                  <Typography variant="body1" className={classes.photoName}>
                    {photoRow[1]}
                  </Typography>
                </div>
              ))}
            </div>
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <Dropzone
            onDrop={handleDrop}
            isLoading={isUploadingPhoto}
            isDisabled={totalPhotos() > MAX_PHOTO_COUNT}
            disableNotice={
              <NotificationBanner
                type="error"
                message={t('PSNAD.PhotoUpload.MaxPhotoReached')}
              />
            }
            maxPhoto={MAX_PHOTO_COUNT - totalPhotos()}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          container
          direction="row"
          justifyContent="center"
        >
          <Grid
            item
            xs={6}
            sm={6}
            container
            display="flex"
            direction="row"
            justifyContent="space-around"
            spacing={5}
          >
            <Grid item xs={6} sm={6}>
              <Button
                width="200px"
                backgroundColor={
                  isUploadingPhoto || photosToUpload.length === 0
                    ? COLORS.gray400
                    : COLORS.blue500
                }
                disabled={isUploadingPhoto || photosToUpload.length === 0}
                onClick={() => {
                  setIsUploadingPhoto(true);
                  onUpload(photosToUpload, reset);
                }}
              >
                {t('GinzaV2.Button.AddPhotos')}
              </Button>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Button
                outlined
                width="200px"
                backgroundColor={COLORS.white}
                disabled={isUploadingPhoto}
                borderColor={COLORS.black}
                textColor={COLORS.black}
                onClick={onClose}
              >
                {t('GinzaV2.Button.Cancel')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PopupModal>
  );
};
export default UploadModal;

UploadModal.defaultProps = {
  open: false,
  onClose: null,
  onUpload: null,
  uploadedPhotos: [],
};

UploadModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUpload: PropTypes.func,
  uploadedPhotos: PropTypes.arrayOf(
    PropTypes.shape({
      itemImageId: PropTypes.string,
      previewUrl: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};
