import PropTypes from 'prop-types';

const lpnShape = {
  createdAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  lpn: PropTypes.string.isRequired,
};

const ebayOrderShape = {
  authenticationLevel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  imageUrls: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  itemCondition: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  whiteGlove: PropTypes.bool.isRequired,
  supervisedAuthentication: PropTypes.bool.isRequired,
};

const orderShape = {
  ebayOrder: PropTypes.shape(ebayOrderShape).isRequired,
  ebayStatus: PropTypes.string.isRequired,
  evtn: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  imageUrls: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  isReturn: PropTypes.bool.isRequired,
  itemQuantity: PropTypes.number.isRequired,
  itemUrl: PropTypes.string.isRequired,
  itemValues: PropTypes.arrayOf(PropTypes.any).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      lpn: lpnShape.isRequired,
    }).isRequired
  ).isRequired,
  orderExceptions: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export const stationItemAnswer = {
  grade: PropTypes.string,
  id: PropTypes.string.isRequired,
  notes: PropTypes.string,
  text: PropTypes.string,
  inputValue: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  input: PropTypes.shape({
    id: PropTypes.string.isRequired,
    inputType: PropTypes.string.isRequired,
    parentId: PropTypes.string,
    stationId: PropTypes.string,
    title: PropTypes.string.isRequired,
    inputValues: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        id: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export const stationItemsShape = {
  auditCreatedAt: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
  station: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  stationId: PropTypes.string.isRequired,
  stationItemAnswers: PropTypes.arrayOf(PropTypes.shape(stationItemAnswer))
    .isRequired,
  status: PropTypes.string.isRequired,
  userIdentification: PropTypes.string.isRequired,
};

export const itemShape = {
  authenticatorResponses: PropTypes.arrayOf(PropTypes.any).isRequired,
  collaterals: PropTypes.arrayOf(PropTypes.any).isRequired,
  failedStationItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
  isCustomized: PropTypes.bool.isRequired,
  isMiscategorized: PropTypes.bool.isRequired,
  isNoShippingRequired: PropTypes.bool.isRequired,
  isInHandSubmission: PropTypes.bool.isRequired,
  lpn: PropTypes.shape(lpnShape).isRequired,
  currentLocation: PropTypes.shape({
    identifier: PropTypes.string,
  }),
  order: PropTypes.shape(orderShape).isRequired,
  station: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  nextStation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  stationItems: PropTypes.arrayOf(PropTypes.shape(stationItemsShape).isRequired)
    .isRequired,
}.isRequired;

export default itemShape;
