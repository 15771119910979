import React, { useRef, useState } from 'react';
import ReactLoading from 'react-loading';
import { COLORS } from '@codeboxxtechschool/ginza_shared_components';
import CancelIcon from '@mui/icons-material/Cancel';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { useTranslation } from 'react-i18next';
import useStyles from './useStyles';
import ImageSliderModal from "../../../stations/PSNAD/PsnadDetailsPage/ImageSliderModal";

const PhotoPreviewer = ({ photos, deletePhoto, readOnly }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [imagesNotSupported, setImagesNotSupported] = useState([]);
  const [imageModal, setImageModal] = useState(false);
  const [selectImageIndex, setSelectImageIndex] = useState('')
  const classes = useStyles({ isLoading, readOnly });
  const counter = useRef(0);
  const imageLoaded = () => {
    counter.current += 1;
    if (counter.current >= photos.length) {
      setIsLoading(false);
    }
  };

  const handleImageError = (imgSrc) => {
    setImagesNotSupported((prev) => [...prev, imgSrc]);
    imageLoaded();
  };

  const isNotSupportedImage = (imgSrc) => {
    const imgUrl = imagesNotSupported.find((imageUrl) => imageUrl === imgSrc);
    // Boolean("") is false in JS, but we may have imageUrl: ""
    return imgUrl === '' ? true : Boolean(imgUrl);
  };

  const openCloseModal = () => { setImageModal(!imageModal) }

  const findImageIndex = (itemImageId, photos) => {
    const index = photos.findIndex(photo => photo.itemImageId == itemImageId)
    setSelectImageIndex(index)
  }

  return (

    <div className={classes.photoPreviewContainer}>
      {imageModal ? <ImageSliderModal photos={photos} onButtonClick={openCloseModal} selectImageIndex={selectImageIndex} /> : <></>}
      {photos.map((photo) => (
        <div
          key={`photo-${photo.itemImageId}`}
          className={classes.photoPreviewItem}
          data-testid={`image-div-${photo.itemImageId}`}
        >

          {isLoading && (
            <div
              data-testid="loading-spinner-container"
              className={classes.loadingContainer}
            >
              <ReactLoading
                height={30}
                width={30}
                type="spin"
                color={COLORS.blue500}
              />
            </div>
          )}
          <div className={classes.displayPhotoPreviewItem}>
            {!readOnly && (
              <div className={classes.deletePhotoIcon}>
                <CancelIcon
                  className={classes.deleteIcon}
                  onClick={() => deletePhoto(photo)}
                  data-testid="CancelIcon"
                />
              </div>
            )}
            {isNotSupportedImage(photo.imageUrl) ? (
              <Tooltip title={t('HistoryStation.ImageNotLoad')}>
                <ImageNotSupportedIcon
                  sx={{
                    height: '100px',
                    width: '100%',
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            ) : (
              <img
                alt="preview"
                className={classes.photoPreview}
                src={photo.imageUrl}
                onLoad={imageLoaded}
                data-testid={`psnadPreviewImage${photo.itemImageId}`}
                onError={() => handleImageError(photo.imageUrl)}
                onClick={() => {
                  openCloseModal();
                  findImageIndex(photo.itemImageId, photos);
                }}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PhotoPreviewer;

PhotoPreviewer.defaultProps = {
  readOnly: false,
  deletePhoto: null,
};

PhotoPreviewer.propTypes = {
  readOnly: PropTypes.bool,
  deletePhoto: PropTypes.func,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      itemImageId: PropTypes.string,
      title: PropTypes.string,
      imageUrl: PropTypes.string,
    })
  ).isRequired,
};
