import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import useStyles from './useStyles';

const ImageSliderModal = ({ photos, onButtonClick, selectImageIndex }) => {
    const classes = useStyles();
    const [mainImageIndex, setMainImageIndex] = useState(selectImageIndex);
    const [images, setImages] = ([photos])
    const [mainImageUrl, setMainImageUrl] = useState("");

    useEffect(() => {
        setMainImageUrl(images[mainImageIndex].imageUrl);
    }, [mainImageIndex]);

    const previousImage = () => {
        if (mainImageIndex > 0) {
            setMainImageIndex(mainImageIndex - 1)
            setMainImageUrl(images[mainImageIndex].imageUrl);
        }
        return;
    };

    const nextImage = () => {
        if (mainImageIndex < images.length - 1) {
            setMainImageIndex(mainImageIndex + 1)
            setMainImageUrl(images[mainImageIndex].imageUrl);
        }
        return;
    };

    const selectImage = (index) => {
        setMainImageIndex(index)
        setMainImageUrl(images[index].imageUrl);
    };

    return (
        <div className={classes.modalBackground}>
            <div className={classes.modalContainer} data-testid="imageModal">
                <div className={classes.modalTop}>
                    <CloseSharpIcon
                        onClick={onButtonClick}
                        data-testid="closeModalButton"
                    />
                </div>
                <div className={classes.modalCenter}>
                    <div className={classes.leftArrowContainer}>
                        <div className={classes.arrowContainer} onClick={previousImage}>
                            <ArrowBackIosIcon
                                className={classes.arrow}
                                style={{ marginLeft: '10px' }}
                                data-testid="leftModalArrow"
                            />
                        </div>
                    </div>
                    <div className={classes.centerContainer}>
                        <div className={classes.centerImageContainer}>
                            <div className={classes.psnadTag} data-testid="psnadTag">PSNAD</div>
                            <img
                                src={mainImageUrl}
                                className={classes.mainImage}
                                data-testid="mainImage"
                            />
                        </div>
                        <div className={classes.modalBottom} >
                            <div className={classes.bottomImageContainer} data-testid="imageThumbsSection">
                                {images.map((photo, index) => {
                                    return (
                                        <div className={mainImageIndex !== index ? classes.unselectImageContainer : classes.selectImageContainer} >
                                            <div className={classes.pTag} data-testid="pTag">P</div>
                                            <div key={index} onClick={() => selectImage(index)} style={{ display: 'flex', flexGrow: '1' }}>
                                                <img
                                                    src={photo.imageUrl}
                                                    className={classes.imageThumb}
                                                    data-testid={`modalThumbImage${photo.itemImageId}`}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    <div className={classes.rightArrowContainer} >
                        <div className={classes.arrowContainer} onClick={nextImage}>
                            <ArrowForwardIosIcon className={classes.arrow} />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ImageSliderModal;