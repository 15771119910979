import parseErrorMessage from './parseErrorMessage';
import dispatchErrorPopup from './dispatchErrorPopup';

export default (err, dispatch, t) => {
  const error = parseErrorMessage(err);

  let message;
  if (error?.errorCode?.error) {
    message = t(`PopupContent.Error.${error.errorCode.error}`, {
      object: error.errorCode.object,
    });
  } else {
    message = t(`PopupContent.Error.${error.errorCode}`, {
      fieldName: error.fieldName,
      typeName: error.typeName,
      trackingNumber: error.trackingNumber,
    });
  }

  let title;
  const errorCode = error?.errorCode?.error || error?.errorCode;
  switch (errorCode) {
    case 'NoAddressFromEbay':
      title = t(`PopupContent.Title.NoAddressFromEbay`);
      break;
    case 'AlitheonAuthFail' || 'AlitheonOriginalOrderNotFound':
      title = t('PopupContent.Title.AlitheonAuthFail');
      break;
    default:
      title = t('PopupContent.Error.ErrorWhileSending');
  }

  dispatchErrorPopup(title, message, dispatch);
};
