import React, { useEffect } from 'react';
import { Grid, ListItem, Typography } from '@material-ui/core';
import {
  COLORS,
  List,
  Toggle,
} from '@codeboxxtechschool/ginza_shared_components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomAccordion from '../customAccordion';
import useStyles from './useStyles';

const AccordionToggleList = ({
  title,
  listItems,
  onUpdateListItems,
  checkType,
  setShouldBeDisabled,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const checked = checkType === 'fail' ? 'unchecked' : 'checked';
  const checkedItemCount =
    listItems?.length > 0 ? listItems.filter((li) => li.isChecked).length : 0;

  const checkShouldBeDisabled = (list) => {
    const hasL1withChildSelected = list.some(
      (li) => li.isChecked === true && li.childInputs.length > 0
    );

    const hasEveryL2Selected = list.every((li) =>
      li.childInputs.every((li2) => !li2.isChecked)
    );

    setShouldBeDisabled(hasL1withChildSelected && hasEveryL2Selected);
  };

  const disableButtons = () => {
    setShouldBeDisabled(true);
    return null;
  };

  const handleToggleClick = (label) => {
    const updateIndex = listItems.findIndex((obj) => obj.label === label);

    if (updateIndex === -1) return;

    const updatedListItems = [...listItems];
    const updatedIsChecked = !updatedListItems[updateIndex].isChecked;

    updatedListItems[updateIndex].isChecked = updatedIsChecked;

    if (!updatedIsChecked) {
      updatedListItems[updateIndex].childInputs = updatedListItems[
        updateIndex
      ].childInputs.map((childInput) => ({
        ...childInput,
        isChecked: false,
      }));
    }

    onUpdateListItems(updatedListItems);
    checkShouldBeDisabled(updatedListItems);
  };

  const handleSecondLevelSelect = (childLabel, scndLevLabel) => {
    const updateIndex = listItems.findIndex((obj) => obj.label === childLabel);

    if (updateIndex === -1) return;
    const updatedParentInput = [...listItems][updateIndex];
    updatedParentInput.isChecked = true;

    updatedParentInput.childInputs = updatedParentInput.childInputs.map(
      (childInput) => {
        if (childInput.label === scndLevLabel) {
          return {
            ...childInput,
            isChecked: !childInput.isChecked,
          };
        }
        return childInput;
      }
    );

    const updatedGrandParentInput = [...listItems];
    updatedGrandParentInput[updateIndex] = updatedParentInput;
    onUpdateListItems(updatedGrandParentInput);
    checkShouldBeDisabled(updatedGrandParentInput);
  };

  const handleAllSelect = () => {
    const allChecked = listItems.every((obj) => obj.isChecked === true);
    const noneChecked = listItems.every((obj) => obj.isChecked === false);
    const someChecked = listItems.some((obj) => obj.isChecked === true);

    listItems.forEach((child) => {
      let updateIndex;

      if (allChecked || noneChecked) {
        updateIndex = listItems.findIndex((obj) => obj.label === child.label);
      } else if (someChecked) {
        updateIndex = listItems.findIndex((obj) => obj.isChecked === true);
      }

      if (updateIndex === -1) return;

      const updatedListItems = [...listItems];
      if (allChecked || noneChecked) {
        updatedListItems[updateIndex].isChecked = !child.isChecked;
        updatedListItems[updateIndex].childInputs = updatedListItems[
          updateIndex
        ].childInputs.map((childInput) => ({
          ...childInput,
          isChecked: false,
        }));
        onUpdateListItems(updatedListItems);
      } else if (someChecked) {
        updatedListItems[updateIndex].isChecked = false;
        updatedListItems[updateIndex].childInputs = updatedListItems[
          updateIndex
        ].childInputs.map((childInput) => ({
          ...childInput,
          isChecked: false,
        }));
        onUpdateListItems(updatedListItems);
      }

      checkShouldBeDisabled(updatedListItems);
    });
  };

  const hasPhotoRequiredSelected =
    listItems.filter((li) => li.isPhotoRequired && li.isChecked).length > 0;

  return (
    <>
      <CustomAccordion
        title={title}
        checkedItemCount={checkedItemCount}
        displayTitleWarning={hasPhotoRequiredSelected}
        handleAllSelect={handleAllSelect}
        checkType={checkType}
      >
        <List
          bottomDivider
          topDivider={false}
          dividerColor={COLORS.gray100}
          width="100%"
        >
          {listItems?.length > 0 &&
            listItems.map((li) => {
              const withSecondLevel = li.childInputs?.length > 0;
              const hasSelectedRadio = li.childInputs.some(
                (child) => child?.isChecked
              );

              const displayWarning =
                withSecondLevel && li.isChecked && !hasSelectedRadio;

              return (
                <ListItem key={`list-item-inputId-${li.inputId}`}>
                  <div className={classes.listContainer}>
                    <div
                      className={`${classes.flexRow} ${classes.accordionItem}`}
                    >
                      <Toggle
                        onClick={() => handleToggleClick(li.label)}
                        totalStates={3}
                        value={li.isChecked ? checked : 'neutral'}
                      />
                      <Typography
                        data-testid="accordion-item-label"
                        style={{ marginLeft: '6px' }}
                        variant="body1"
                      >
                        {li.label}
                      </Typography>
                      {withSecondLevel && displayWarning && (
                        <Typography
                          variant="body1"
                          className={classes.warning}
                          style={{ color: '#CC0011' }}
                        >
                          {t('PSNAD.SubmissionForm.SubReasonWarning')}
                          {disableButtons()}
                        </Typography>
                      )}
                    </div>
                    {li.isChecked && withSecondLevel && (
                      <Grid
                        container
                        className={classes.checkboxContainer}
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        {li.childInputs.map((li2) => {
                          return (
                            <div
                              style={{
                                width: '50%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: '5px 0',
                              }}
                              data-testid="label-level-two"
                            >
                              <Toggle
                                id={`toggle-switch-${li2.label}`}
                                totalStates={3}
                                value={li2.isChecked ? checked : 'neutral'}
                                onClick={() =>
                                  handleSecondLevelSelect(li.label, li2.label)
                                }
                              />

                              <Typography
                                data-testid="accordion-item-label"
                                variant="body1"
                                id={`sec-lvl-label-${li2.label}`}
                                style={{ flex: '1', marginLeft: '10px' }}
                              >
                                <text id={`sec-lvl-label-${li2.label}`}>
                                  {li2.label}
                                </text>
                              </Typography>
                            </div>
                          );
                        })}
                      </Grid>
                    )}
                  </div>
                </ListItem>
              );
            })}
        </List>
      </CustomAccordion>
    </>
  );
};

export default AccordionToggleList;

const Input = PropTypes.shape({
  inputId: PropTypes.string,
  parentId: PropTypes.string,
  hasPsnadValue: PropTypes.bool,
  snadCode: PropTypes.string,
  inputType: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  isPhotoRequired: PropTypes.bool,
  isChecked: PropTypes.bool,
});

Input.childInputs = PropTypes.arrayOf(Input);

AccordionToggleList.defaultProps = {
  title: '',
  checkType: 'success',
};

AccordionToggleList.propTypes = {
  title: PropTypes.string,
  listItems: PropTypes.arrayOf(Input).isRequired,
  onUpdateListItems: PropTypes.func.isRequired,
  checkType: PropTypes.string,
  setShouldBeDisabled: PropTypes.func.isRequired,
};
