import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, ImageListItemBar, Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import './customSwiperStyle.css';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import StatusPill from '../statusPill';
import { COLORS } from '../../../constants';
import {
  FlexCenterContainer,
  ImgThumbContainer,
  StatusPillWrapper,
  ThumbContainer,
  MainImageContainer,
} from './index.styles';
import { getStationById } from '../../../utils/stationsInfo';
import { useStateValue } from '../../../providers/StateProvider';

const imgHeight = { thumb: '80px', main: '400px', lightbox: '58vh' };

function getBackgroundColor(imagePill) {
  if (imagePill === 'VERACITY' || imagePill === 'MANUAL_UPLOAD') {
    return COLORS.Veracity;
  }
  if (imagePill === 'PSNAD') {
    return COLORS.darkRed;
  }
  return COLORS.black;
}

const ImageCarousel = ({
  images,
  onOpenLightbox,
  isLightbox,
  selectedImage,
  setSelectedImage,
}) => {
  const { t } = useTranslation();
  const [{ stationList }] = useStateValue();
  const sliderRef = useRef();
  const stationId = sessionStorage.getItem('stationId');
  const currentStation = getStationById(stationList, stationId);
  const isLiveEventIntake = currentStation?.value === 'LiveEventIntake';

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [forceNotSupportedImages, setForceNotSupportedImages] = useState([]);

  useEffect(() => {
    sliderRef.current?.swiper.slideTo(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    setForceNotSupportedImages([]);
    if (!isLightbox) sliderRef.current?.swiper.slideTo(0);
  }, [images]);

  const isNotSupportedImage = (img) => {
    const imgUrl = forceNotSupportedImages.find(
      (imageUrl) => imageUrl === img.src
    );
    // Boolean("") is false in JS, but we may have ebay_orders with no imageUrl: ""
    return imgUrl === '' ? true : Boolean(imgUrl);
  };

  return (
    <>
      <Swiper
        ref={sliderRef}
        onSlideChange={() => {
          setSelectedImage(sliderRef.current.swiper.realIndex);
        }}
        modules={[Navigation, Thumbs]}
        spaceBetween={100}
        slidesPerView="auto"
        navigation
        pagination={{ clickable: true }}
        thumbs={{ swiper: thumbsSwiper }}
        style={{
          '--swiper-navigation-color': isLightbox ? COLORS.white : COLORS.black,
          '--swiper-pagination-color': isLightbox ? COLORS.white : COLORS.black,
        }}
        data-testid="main-slide"
      >
        {images.map((img) => (
          <SwiperSlide key={`${img.src}-img-${img.id}`}>
            {isNotSupportedImage(img) ? (
              <FlexCenterContainer>
                <Tooltip title={t('HistoryStation.ImageNotLoad')}>
                  <ImageNotSupportedIcon
                    sx={{
                      height: isLightbox ? imgHeight.lightbox : imgHeight.main,
                      width: '100%',
                      cursor: 'pointer',
                    }}
                    data-testid="image-not-supported"
                    onClick={onOpenLightbox}
                  />
                </Tooltip>
              </FlexCenterContainer>
            ) : (
              <FlexCenterContainer>
                {img.imagePill && (
                  <Chip
                    label={t(`ImageChip.${img.imagePill}`)}
                    style={{
                      width: '125px',
                      alignSelf: 'center',
                      backgroundColor: getBackgroundColor(img.imagePill),
                      color: COLORS.white,
                    }}
                    data-testid={`${img.imagePill.toLowerCase()}-main-chip-${
                      img.src
                    }`}
                  />
                )}
                <MainImageContainer>
                  <img
                    aria-hidden
                    style={{
                      height: isLightbox ? imgHeight.lightbox : imgHeight.main,
                      maxHeight: isLightbox
                        ? imgHeight.lightbox
                        : imgHeight.main,
                    }}
                    src={img.src}
                    data-testid={`main-img-${img.src}`}
                    alt="carousel-img"
                    onError={() => {
                      setForceNotSupportedImages([
                        ...forceNotSupportedImages,
                        img.src,
                      ]);
                    }}
                    onClick={onOpenLightbox}
                  />
                </MainImageContainer>
                {img.title && (
                  <ImageListItemBar
                    title={img.title}
                    data-testid={`img-title-${img.src}`}
                  />
                )}
              </FlexCenterContainer>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      {isLightbox && <div style={{ marginTop: '5vh' }} />}
      {!isLiveEventIntake && (
        <Swiper
          modules={[Thumbs]}
          watchSlidesProgress
          slidesPerView={isLightbox ? 8 : 3}
          onSwiper={setThumbsSwiper}
          style={{ marginTop: '20px' }}
          date-testid="thumbnails"
        >
          {images.map((img) => (
            <SwiperSlide key={`${img.src}-img-${img.id}`}>
              <ThumbContainer>
                {isNotSupportedImage(img) ? (
                  <FlexCenterContainer>
                    <Tooltip title={t('HistoryStation.ImageNotLoad')}>
                      <ImageNotSupportedIcon
                        sx={{
                          height: imgHeight.thumb,
                          width: '100%',
                          cursor: 'pointer',
                        }}
                      />
                    </Tooltip>
                  </FlexCenterContainer>
                ) : (
                  <>
                    <ImgThumbContainer
                      id={img.src}
                      key={`${img.src}-img-${img.id}`}
                      aria-hidden
                    >
                      <img
                        src={img.src}
                        alt="thumb-img"
                        data-testid={`thumb-img-${img.src}`}
                        style={{ height: imgHeight.thumb }}
                        onError={() => {
                          setForceNotSupportedImages([
                            ...forceNotSupportedImages,
                            img.src,
                          ]);
                        }}
                      />
                    </ImgThumbContainer>
                    {img.imagePill && img.imagePill !== 'PSNAD' && (
                      <StatusPillWrapper
                        data-testid={`${img.imagePill.toLowerCase()}-thumb-chip-${
                          img.src
                        }`}
                      >
                        <StatusPill
                          text={t(`ImagePill.${img.imagePill}`)}
                          backgroundColor={getBackgroundColor(img.imagePill)}
                          textColor={COLORS.white}
                        />
                      </StatusPillWrapper>
                    )}
                  </>
                )}
              </ThumbContainer>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
};

ImageCarousel.defaultProps = {
  images: [{ id: '', src: '', title: '', isAlitheon: false }],
  isLightbox: false,
  onOpenLightbox: () => {},
  selectedImage: 0,
  setSelectedImage: () => {},
};

ImageCarousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      src: PropTypes.node.isRequired,
      title: PropTypes.string,
      isAlitheon: PropTypes.bool,
      imagePill: PropTypes.string,
    })
  ),
  isLightbox: PropTypes.bool,
  onOpenLightbox: PropTypes.func,
  selectedImage: PropTypes.number,
  setSelectedImage: PropTypes.func,
};

export default ImageCarousel;
