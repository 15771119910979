import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import Cookies from 'universal-cookie';
import localforage from 'localforage';
import {
  ErrorNotificationProvider,
  SuccessNotificationProvider,
} from '@codeboxxtechschool/ginza_shared_components';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import CustomApolloProvider from './apolloProvider';
import ContextReducer from '../reducers/ContextReducer';
import { StateProvider } from '../providers/StateProvider';
import Root from './Root';
import i18n from '../i18n/i18n';
import ReloadDialog from './ReloadDialog';
import QueryProvider from './queryProvider';
import WithRedirect from './WithRedirect';

const cookies = new Cookies();
const savedLanguage = cookies.get('i18nLanguage');

if (savedLanguage) {
  i18n.changeLanguage(savedLanguage);
}
const partnerLocation = JSON.parse(sessionStorage.getItem('partnerLocation'));
const partnerLocationConfigs = JSON.parse(
  sessionStorage.getItem('partnerLocationConfigs')
);

const initialState = {
  currentUserId: null,
  alert: false,
  error: false,
  authToken: sessionStorage.getItem('authToken'),
  refreshToken: sessionStorage.getItem('refreshToken'),
  tokenExpiryTime: sessionStorage.getItem('tokenExpiryTime'),
  roleList: null,
  adminCategoriesList: null,
  currentStationId: null,
  userStationList: null,
  stationList: null,
  APIDomain: process.env.AppAPIDomain,
  AUTH_OTP_CREATE_PATH: process.env.AUTH_OTP_CREATE_PATH,
  AUTH_OTP_ENABLE_PATH: process.env.AUTH_OTP_ENABLE_PATH,
  AUTH_OTP_VERIFY_PATH: process.env.AUTH_OTP_VERIFY_PATH,
  isRMA: false,
  orderExceptions: [],
  flagTypeAndValue: {
    type: null,
    value: null,
  },
  loading: false,
  miscategorized: false,
  currentItemInfo: { itemId: null, lpn: null, isMiscategorized: null },
  orderId: null,
  partnerLocation: {
    id: partnerLocation?.id,
    name: partnerLocation?.name,
    timeZone: partnerLocation?.timeZone,
    categoryName: partnerLocation?.categoryName,
    hubId: partnerLocation?.hubId,
    categoryId: partnerLocation?.categoryId,
    lpnType: partnerLocation?.lpnType,
    internationalizationCountryCode:
      partnerLocation?.internationalizationCountryCode,
    qcDefaultValues: partnerLocation?.qcDefaultValues,
    userPartnerLocationStations: partnerLocation?.userPartnerLocationStations,
    hasPsnadStation: partnerLocation?.hasPsnadStation,
    defaultCaseTypeToRaw: partnerLocation?.defaultCaseTypeToRaw,
    blueBiteAuthenticCardAllowedStatuses:
      partnerLocation?.blueBiteAuthenticCardAllowedStatuses,
  },
  userIdentification: '',
  selectedPrintersId: {},
  selectedLabels: {},
  isZebraPrintingInstalled: false,
  alitheonRegistrationStation: partnerLocation?.alitheonRegistrationStation,
  alitheonAuthenticationStation: partnerLocation?.alitheonAuthenticationStation,
  isLiveEventShippingMain: false,
  scannedString: '',
  currentPartnerLocationConfigs: partnerLocationConfigs,
};

const EBayAuthApp = () => (
  <StateProvider initialState={initialState} reducer={ContextReducer()}>
    <CustomApolloProvider>
      <ErrorNotificationProvider>
        <SuccessNotificationProvider>
          <QueryProvider>
            <I18nextProvider i18n={i18n}>
              <WithRedirect>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Root />
                </LocalizationProvider>
              </WithRedirect>
            </I18nextProvider>
          </QueryProvider>
        </SuccessNotificationProvider>
      </ErrorNotificationProvider>
    </CustomApolloProvider>
  </StateProvider>
);

// create a localStorage like store to be used to store bigger cache
// DOCS: https://www.npmjs.com/package/localforage
window.localForage = localforage.createInstance({ name: 'Ginza Auth Store' });

window.addEventListener('message', (event) => {
  if (
    event.origin !== window.location.origin ||
    !event.data.ZebraPrintingVersion
  )
    return;
  initialState.isZebraPrintingInstalled = true;
});

window.addEventListener('error', (e) => {
  if (/Loading chunk [\d]+ failed/.test(e.message)) {
    const popup = document.getElementById('refresh-popup');
    if (popup) {
      ReactDOM.render(
        <I18nextProvider i18n={i18n}>
          <ReloadDialog />
        </I18nextProvider>,
        popup
      );
    }
  }
});

document.addEventListener('turbolinks:load', () => {
  const app = document.getElementById('react-app');
  if (app) {
    ReactDOM.render(<EBayAuthApp />, app);
  }
});
