import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import {
  COLORS,
  Button,
  PredefinedNotes as PredefinedNotesSelection,
} from '@codeboxxtechschool/ginza_shared_components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import './styles.css';

const PredefinedNotes = ({ notes, onNoteChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [note, setNote] = useState('');

  const handleChange = (e, type) => {
    if (type === 'predefined') {
      setNote(e);
    } else {
      setNote(e.target.value);
    }
  };

  useEffect(() => {
    onNoteChange(note);
  }, [note]);

  return (
    <div className={classes.container}>
      <PredefinedNotesSelection
        label={t('GinzaV2.PredefinedNotes.Title')}
        notes={notes}
        height="fit-content"
        onClickNote={(e) => handleChange(e, 'predefined')}
      />
      <TextField
        id="note-textarea"
        name="note-textarea"
        label={t('GinzaV2.PredefinedNotes.TextAreaLabel')}
        className={classes.predefinedNoteTextArea}
        variant="outlined"
        multiline
        fullWidth
        minRows={8}
        onChange={(e) => handleChange(e, 'note')}
        inputProps={{ className: classes.textArea }}
        value={note}
      />
    </div>
  );
};

export default PredefinedNotes;

PredefinedNotes.defaultProps = {
  notes: [''],
  onNoteChange: null,
};

PredefinedNotes.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.string),
  onNoteChange: PropTypes.func,
};
