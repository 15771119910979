import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '@codeboxxtechschool/ginza_shared_components';

const useStyles = makeStyles({
  deleteIcon: {
    backgroundColor: COLORS.white,
    color: COLORS.red500,
    borderRadius: '100%',
  },
  deletePhotoIcon: {
    position: 'absolute',
    right: '-10px',
    top: '-10px',
  },
  displayPhotoPreviewItem: {
    display: (props) => (props.isLoading ? 'none' : 'block'),
  },
  photoPreviewContainer: {
    display: 'grid',
    marginBottom: '24px',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gridGap: '14px 10px',
    backgroundColor: (props) => (props.readOnly ? COLORS.gray100 : '#ffffff'),
    padding: '10px',
  },
  photoPreviewItem: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    border: '1px solid rgba(0, 0, 0, 0.3)',
    textAlign: 'center',
  },
  loadingContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px',
    height: '100px',
  },
  photoPreview: {
    width: '100px',
    objectFit: 'contain',
  },
});

export default useStyles;
