import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../../constants';
import { StyledButton } from './index.styles';

const Button = ({
  backgroundColor,
  children,
  color,
  fullWidth,
  onClick,
  disabled,
  dataTestId,
  id,
  startIcon,
}) => {
  return (
    <StyledButton
      id={id}
      data-testid={dataTestId}
      variant="contained"
      disableElevation
      fullWidth={fullWidth}
      backgroundcolor={backgroundColor}
      textcolor={color}
      startIcon={startIcon}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  );
};

Button.defaultProps = {
  id: 'generic-button',
  dataTestId: '',
  backgroundColor: COLORS.blue,
  color: 'white',
  startIcon: null,
  fullWidth: false,
  disabled: false,
  onClick: () => {},
};

Button.propTypes = {
  dataTestId: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  startIcon: PropTypes.node,
  onClick: PropTypes.func,
  id: PropTypes.string,
};

export default Button;
