import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import AlertBadge from '../reusables/alertBadge/AlertBadge';

const IconCard = (props) => {
  const { item, onClick, iconSize, noShadow, selected } = props;
  const { id } = item;

  return (
    <Stack direction="row" id={`icon-card-${item.id}`}>
      <IconItem
        id={`station-button-${item.name}`}
        selected={selected}
        noShadow={noShadow}
        size={iconSize}
        tabIndex={0}
        role="button"
        onClick={() => onClick(item)}
      >
        {item?.icon && (
          <IconItemImg maxSpace={!item.name} src={item.icon} alt={id} />
        )}
        {item.name && (
          <IconItemLabel size={iconSize}>{item.name}</IconItemLabel>
        )}
      </IconItem>
      {item?.alert && <AlertBadge />}
    </Stack>
  );
};

export default IconCard;

IconCard.defaultProps = {
  noShadow: false,
  iconSize: 6,
  onClick: null,
  selected: false,
};

IconCard.propTypes = {
  noShadow: PropTypes.bool,
  iconSize: PropTypes.number,
  item: PropTypes.shape({
    icon: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    alert: PropTypes.bool,
  }).isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

// Style
const IconItem = styled.div`
  ${({ size }) => `
    width: ${size}em;
    height: ${size}em;
    margin: calc(${size}em / 5);
  `}
  text-align: center;
  padding: 0.7em;
  transform: scale(1, 1);
  transition: transform 0.5s;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 1px solid darkgray;
  ${(props) =>
    props.noShadow ? '' : 'box-shadow: rgba(0, 0, 0, 0.35) 0 8px 12px -8px;'}
  color: #333;
  background: #fff;
  ${(props) =>
    props.selected
      ? `
      background: #414141;
      & > img {
          filter: invert(1);
      }
      & > p {
        color: #fff;
      }
    `
      : `
      &:hover {
        cursor: pointer;
        background: #414141;
        & > img {
          filter: invert(1);
        }
        & > p {
          color: #fff;
        }
      } 
  `}
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.55) 0 4px 5px -2px;
    border: 1px solid #414141;
    transform: scale(0.95, 0.95);
    transition: transform 0.5s;
    transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
  }
  &:focus {
    outline: 0;
    cursor: gab;
  }
  @media only screen and (min-width: 1000px) {
    ${({ size }) => `
      width: calc(${size}em * 1.3);
      height: calc(${size}em * 1.3);
    `}
  }
  @media only screen and (max-width: 992px) {
    ${({ size }) => `
      width: calc(${size}em * 1.2 );
      height: calc(${size}em *1.2 );
    `}
  }
  @media only screen and (max-width: 750px) {
    ${({ size }) => `
      width: calc(${size}em / 1.2 );
      height: calc(${size}em / 1.2 );
    `}
  }
`;

export const IconItemLabel = styled.p`
  ${(props) => (props.hide ? 'none' : 'inherit')}
  text-align: center;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: calc(${({ size }) => size} * 0.15em);
  line-height: 1em;
  @media only screen and (max-width: 1000px) {
    font-size: calc(${({ size }) => size} * 0.2em) !important;
  }
  @media only screen and (max-width: 750px) {
    display: none;
  }
`;

const IconItemImg = styled.img`
  width: fit-content;
  max-width: ${(props) => (props.maxSpace ? '100%' : '50%')};
  height: auto;
  @media only screen and (max-width: 750px) {
    max-width: 90%;
  }
`;
