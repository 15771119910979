import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import { useStateValue } from '../../../providers/StateProvider';
import ginzaLogo from '../../../../assets/images/ginza_logo.svg';
import InputButton from '../inputs/InputButton';
import removeSpace from '../../../utils/removeSpace';
import { COLORS } from '../../../constants';

const PartnerLocationSelectionContainer = (props) => {
  const { partnerLocations, fetchCurrentPartnerLocationConfigs } = props;
  const dispatch = useStateValue()[1];
  const [selectedPartnerLocation, setSelectedPartnerLocation] = useState({
    id: '',
    name: '',
    lpnType: '',
  });
  const handleChange = (event) => {
    const {
      name,
      category,
      hubId,
      lpnType,
      shippingService,
      timeZone,
      manifestableCarriers,
      internationalizationCountryCode,
      userPartnerLocationStations,
      hasPsnadStation,
      alitheonRegistrationStation,
      alitheonAuthenticationStation,
      defaultCaseTypeToRaw,
      blueBiteAuthenticCardAllowedStatuses,
      qcDefaultValues,
      isAllowedToReprintShippingLabel,
      inputMaxLength,
    } = partnerLocations.find((x) => x.id === event.target.value);
    setSelectedPartnerLocation({
      id: event.target.value,
      name,
      category,
      hubId,
      timeZone,
      hasManifests: shippingService.hasManifests,
      manifestableCarriers,
      lpnType,
      internationalizationCountryCode,
      userPartnerLocationStations,
      hasPsnadStation,
      alitheonRegistrationStation,
      alitheonAuthenticationStation,
      defaultCaseTypeToRaw,
      blueBiteAuthenticCardAllowedStatuses,
      qcDefaultValues,
      isAllowedToReprintShippingLabel,
      inputMaxLength,
    });
  };

  const { t } = useTranslation();

  const setPartnerLocation = () => {
    const newPartnerLocation = {
      id: selectedPartnerLocation.id,
      name: selectedPartnerLocation.name,
      timeZone: selectedPartnerLocation.timeZone,
      categoryName: selectedPartnerLocation?.category?.name,
      categoryId: selectedPartnerLocation?.category?.id,
      hubId: selectedPartnerLocation.hubId,
      hasManifests: selectedPartnerLocation.hasManifests,
      manifestableCarriers: selectedPartnerLocation.manifestableCarriers,
      lpnType: selectedPartnerLocation.lpnType,
      userPartnerLocationStations:
        selectedPartnerLocation.userPartnerLocationStations,
      hasPsnadStation: selectedPartnerLocation.hasPsnadStation,
      internationalizationCountryCode:
        selectedPartnerLocation.internationalizationCountryCode,
      alitheonRegistrationStation:
        selectedPartnerLocation.alitheonRegistrationStation,
      alitheonAuthenticationStation:
        selectedPartnerLocation.alitheonAuthenticationStation,
      defaultCaseTypeToRaw: selectedPartnerLocation.defaultCaseTypeToRaw,
      qcDefaultValues: selectedPartnerLocation.qcDefaultValues,
      isAllowedToReprintShippingLabel:
        selectedPartnerLocation.isAllowedToReprintShippingLabel,
      blueBiteAuthenticCardAllowedStatuses:
        selectedPartnerLocation.blueBiteAuthenticCardAllowedStatuses,
      inputMaxLength: selectedPartnerLocation.inputMaxLength,
    };

    fetchCurrentPartnerLocationConfigs({
      variables: { partnerLocationId: newPartnerLocation.id },
    });

    sessionStorage.setItem(
      'partnerLocation',
      JSON.stringify(newPartnerLocation)
    );

    dispatch({
      type: 'changePartnerLocation',
      newPartnerLocation,
    });

    // TODO: Put this section in a reusable

    const userPartnerStations =
      newPartnerLocation.userPartnerLocationStations || [];

    dispatch({
      type: 'changeUserStationList',
      newUserStationList: userPartnerStations.map((station) => ({
        id: station.id,
        name: removeSpace(station.name),
        customEndpoint: station.customEndpoint,
        isExternalUrl: station.isExternalUrl,
      })),
    });
  };

  return (
    <PartnerLocationSelectionCardContainer>
      <PartnerLocationSelectionContainerStyled>
        <GinzaAuthLogo
          src={ginzaLogo}
          alt="ginza logo"
          data-testid="ginzaLogoPartner"
        />
        <TitleHeader>
          {t('PartnerLocationSelectionContainer.SelectHubHeader')}
        </TitleHeader>
        <FormControl
          style={{
            width: 'fit-content',
            minWidth: '200px',
            height: '50px',
            marginTop: '20px',
          }}
        >
          <Select
            displayEmpty
            id="select-partner-location"
            value={selectedPartnerLocation.id}
            onChange={handleChange}
            autoWidth
          >
            <MenuItem value="" disabled>
              {t('PartnerLocationSelectionContainer.SelectHub')}
            </MenuItem>
            {[...partnerLocations]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((item) => (
                <MenuItem
                  id={`partner-location-option-${item.id}`}
                  key={item.id}
                  value={item.id}
                >
                  {item.name}
                </MenuItem>
              ))}
          </Select>
          <InputButton
            id="partner-location-select-button"
            content={t('ButtonContent.Select')}
            type="select"
            selected
            maxHeight="65px !important"
            height="30px"
            fontSize="10px"
            margin="1em"
            onClick={() => setPartnerLocation()}
            style={{
              minWidth: 'auto',
              maxWidth: '100% !important',
              width: 'auto !important',
            }}
          />
        </FormControl>
      </PartnerLocationSelectionContainerStyled>
    </PartnerLocationSelectionCardContainer>
  );
};

export default PartnerLocationSelectionContainer;

PartnerLocationSelectionContainer.propTypes = {
  partnerLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      category: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    }).isRequired
  ).isRequired,
  fetchCurrentPartnerLocationConfigs: PropTypes.func.isRequired,
};

// Style
const PartnerLocationSelectionCardContainer = styled.div`
  position: relative;
  align-self: start;
  width: fit-content;
  background-color: ${COLORS.white};
  padding: 20px;
  z-index: 999;
  margin: auto;
  max-height: calc(100% - 100px);
  top: 100px;
`;

const PartnerLocationSelectionContainerStyled = styled.div`
  height: fit-content;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GinzaAuthLogo = styled.img`
  margin: 1em;
  margin-top: 2em;
  width: 280px;
  height: auto;
`;

export const TitleHeader = styled.p`
  text-align: center;
  margin-top: 0.5em;
  margin-bottom: 0;
  font-size: 1.7em;
  @media only screen and (max-width: 442px) {
    font-size: 1.25em;
  }
`;
