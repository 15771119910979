import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Button, COLORS } from '@codeboxxtechschool/ginza_shared_components';
import PropTypes from 'prop-types';
import { MenuItem } from '@mui/material';
import useStyles from './useStyles';
import PredefinedNotes from '../predefinedNotes';

const btnColor = {
  Resolve: COLORS.blue500,
  SendToQc: COLORS.red500,
  Submit: COLORS.blue500,
};

const ExceptionResolverModal = ({
  onResolve,
  onClose,
  resolutionOption,
  resolveBtnText,
  usePredefinedNotes,
  disableClose,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [data, setData] = useState({
    note: '',
    resolution: 'SELECT',
  });
  const [resolutionOptions, setResolutionOptions] = useState([
    { key: 'SELECT', value: 'Select' },
  ]);
  const [inlineError, setInlineError] = useState({
    isShown: false,
    errorMessage: '',
  });

  useEffect(() => {
    if (resolutionOption) {
      setResolutionOptions((prev) => [...prev, resolutionOption]);
      const newData = { ...data };
      newData['resolution'] = resolutionOption.key;
      setData(newData);
    }
  }, [resolutionOption]);

  useEffect(() => {
    if (inlineError.isShown)
      setTimeout(
        () => setInlineError({ isShown: false, errorMessage: '' }),
        5000
      );
  }, [inlineError]);

  const handleFormChange = (e, type) => {
    const newData = { ...data };
    if (type === 'predefined') {
      newData.note = e;
    } else {
      newData[type] = e.target.value;
    }
    setData(newData);
  };
  return (
    <div
      id="exception-resolver-container"
      data-testid="exception-resolver-container"
      className={classes.container}
    >
      <div style={{ borderRadius: '4px', position: 'relative' }}>
        <FormControl fullWidth>
          <InputLabel className={classes.dropdownLabel}>
            {t('GinzaV2.ExceptionResolver.ResolutionAction')}
          </InputLabel>
          <Select
            id="exception-resolution-action"
            className={classes.dropdownSelect}
            value={data.resolution}
            onChange={(e) => handleFormChange(e, 'resolution')}
          >
            {resolutionOptions.map((action) => (
              <MenuItem key={action.key} value={action.key}>
                {t(
                  `GinzaV2.ExceptionResolver.ResolutionOption.${action.value}`
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {usePredefinedNotes.length > 0 ? (
        <PredefinedNotes
          onNoteChange={(note) => handleFormChange(note, 'predefined')}
          notes={usePredefinedNotes}
        />
      ) : (
        <>
          <TextField
            id="note-textarea"
            name="note-textarea"
            label={t('GinzaV2.ExceptionResolver.EnterANote')}
            className={classes.predefinedNoteTextArea}
            variant="outlined"
            multiline
            fullWidth
            minRows={8}
            onChange={(e) => handleFormChange(e, 'note')}
            inputProps={{ className: classes.textArea }}
            value={data.note}
          />
        </>
      )}
      {inlineError.isShown && (
        <Typography
          variant="body1"
          style={{
            color: COLORS.red400,
          }}
        >
          {inlineError.errorMessage}
        </Typography>
      )}
      <div className={classes.buttonsContainer}>
        <Button
          fullWidth
          disabled={!data.note || data.resolution === 'SELECT'}
          backgroundColor={
            !data.note || data.resolution === 'SELECT'
              ? COLORS.gray400
              : btnColor[resolveBtnText]
          }
          textColor={COLORS.white}
          onClick={() => onResolve(data.note, data.resolution)}
        >
          {t(`GinzaV2.Button.${resolveBtnText}`)}
        </Button>
        <Button
          fullWidth
          outlined
          borderColor={COLORS.black}
          backgroundColor={COLORS.white}
          textColor={COLORS.black}
          onClick={() => {
            if (disableClose) {
              setInlineError({
                isShown: true,
                errorMessage: t('PSNAD.Notification.Error.ResolveBeforeExit'),
              });
            } else {
              onClose();
            }
          }}
        >
          {t('GinzaV2.Button.Cancel')}
        </Button>
      </div>
    </div>
  );
};

export default ExceptionResolverModal;

ExceptionResolverModal.defaultProps = {
  onClose: null,
  onResolve: null,
  resolutionOption: null,
  resolveBtnText: 'Resolve',
  usePredefinedNotes: [],
  disableClose: false,
};

ExceptionResolverModal.propTypes = {
  onClose: PropTypes.func,
  onResolve: PropTypes.func,
  resolutionOption: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  resolveBtnText: PropTypes.string,
  usePredefinedNotes: PropTypes.arrayOf(PropTypes.string),
  disableClose: PropTypes.bool,
};
