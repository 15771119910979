import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import BackupIcon from '@material-ui/icons/Backup';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import { Checkbox, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DivStyled, FlexContainerRow } from '../StyledComponent';

const UploadFile = ({ onDelete, changeSelected, isFileSelected, fileName }) => (
  <FlexContainerRow
    className="single-upload-file"
    padding="0em .5em"
    justCont="space-between"
    alignItems="center"
  >
    <Checkbox
      id={`upload-file-checkbox-name-${fileName}`}
      checked={isFileSelected}
      onChange={() => changeSelected(fileName)}
    />
    <h2 style={{ fontWeight: 400, fontSize: '1.2em' }}>{fileName}</h2>
    <DeleteForeverIcon id={`upload-file-delete-name-${fileName}`} className="pointer" onClick={() => onDelete(fileName)} />
  </FlexContainerRow>
);

UploadFile.propTypes = {
  onDelete: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
  changeSelected: PropTypes.func.isRequired,
  isFileSelected: PropTypes.bool.isRequired,
};

// TODO testing
const UploadSelector = ({ uploadType, files, setFiles }) => {
  const { t } = useTranslation();
  const hiddenUploadInput = useRef(null);

  const findFileIndexByName = (fileName) =>
    files.findIndex((file) => file.file.name === fileName);

  const handleChangeSelected = (filename) => {
    const fileIndex = findFileIndexByName(filename);
    const newFiles = [...files];
    newFiles[fileIndex].selected = !newFiles[fileIndex].selected;
    setFiles(newFiles);
  };

  const handleUploadClick = () => hiddenUploadInput.current.click();

  const handleUploadChange = (e) => {
    const fileArray = Array.from(e.target.files);
    const newFiles = [];
    fileArray.forEach((file) => {
      if (!files.find((_file) => _file.file.name === file.name))
        newFiles.push({ file, selected: false });
    });
    setFiles([...files, ...newFiles]);
  };

  const handleSelectAll = () => {
    const newFiles = files.map((file) => ({ ...file, selected: true }));
    setFiles(newFiles);
  };

  const handleDelete = (fileName) => {
    const fileIndex = findFileIndexByName(fileName);
    const newFiles = [...files];
    newFiles.splice(fileIndex, 1);
    setFiles(newFiles);
  };

  const handleDeleteSelected = () => {
    const newFiles = [...files];
    for (let i = files.length - 1; i >= 0; i -= 1) {
      if (files[i].selected) newFiles.splice(i, 1);
    }
    setFiles(newFiles);
  };

  const areAllSelected = () => !files.some((file) => !file.selected);

  const hasFileSelected = () => files.some((file) => file.selected);

  return (
    <DivStyled
      id="uploadFileId"
      className="upload-container"
      border="#bbbbbb 1px solid"
      borderRadius="4px"
      textCenter
      width="-webkit-fill-available"
      margin="1em 0 0"
    >
      <FlexContainerRow
        id="upload-file-div"
        className="upload-nav-selector"
        alignItems="center"
        justCont="space-between"
        padding="0"
        style={{ borderBottom: '#bbbbbb 1px solid' }}
        onClick={files.length === 0 ? handleUploadClick : null}
        cursor={files.length === 0 ? 'pointer' : null}
      >
        <FlexContainerRow minWidth="fit-content" margin="0 0 0 0.5em">
          {files.length === 0 && t('FileUploader.NoFileSelected')}
        </FlexContainerRow>
        <FlexContainerRow minWidth="fit-content">
          {!areAllSelected() && files.length > 0 && (
            <Button
              id="upload-file-select-all-button"
              startIcon={<SelectAllIcon fontSize="large" />}
              onClick={handleSelectAll}
            >
              {t('FileUploader.SelectAll')}
            </Button>
          )}
          {hasFileSelected() && (
            <Button
              id="upload-file-delete-selected-button"
              startIcon={<DeleteOutlineIcon fontSize="large" />}
              onClick={handleDeleteSelected}
            >
              {t('FileUploader.DeleteSelected')}
            </Button>
          )}
          <Button
            id="upload-file-handle-button"
            startIcon={<BackupIcon fontSize="large" />}
            onClick={files.length === 0 ? null : handleUploadClick}
          >
            {uploadType === 'guide'
              ? t('FileUploader.UploadFile')
              : t('FileUploader.UploadFiles')}
          </Button>
        </FlexContainerRow>
      </FlexContainerRow>
      <div>
        {files.map((file) => (
          <UploadFile
            key={file.file.name}
            fileName={file.file.name}
            onDelete={handleDelete}
            isFileSelected={file.selected}
            changeSelected={handleChangeSelected}
          />
        ))}
      </div>
      <input
        style={{ display: 'none' }}
        onChange={handleUploadChange}
        ref={hiddenUploadInput}
        type="file"
        name="input-file-uploader"
        id="input-file-uploader"
        multiple
        data-testid="input-file-uploader-test"
      />
    </DivStyled>
  );
};

export default UploadSelector;

UploadSelector.defaultProps = {
  uploadType: null,
};

UploadSelector.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      selected: PropTypes.bool.isRequired,
      file: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  setFiles: PropTypes.func.isRequired,
  uploadType: PropTypes.string,
};
