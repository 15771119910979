/* eslint-disable react/prop-types */
import React from 'react';
import StepHeader from './StepHeader';
import StepContainer from './StepContainer';

const StepModel = ({ stepNumber, title, content, children }) => {
  return (
    <div spacing={2}>
      <StepContainer stepNumber={stepNumber}>
        <StepHeader title={title} content={content} />
        {children}
      </StepContainer>
    </div>
  );
};

export default StepModel;
