import React from 'react';
import PropTypes from 'prop-types';
import { PredefinedNotesProvider } from './PredefinedNotesContext';
import PredefinedNotesList from './PredefinedNotesList';

const PredefinedNotes = ({ onAdd, disabled, autofocus }) => {
  return (
    <PredefinedNotesProvider>
      <PredefinedNotesList
        onAdd={onAdd}
        disabled={disabled}
        autofocus={autofocus}
      />
    </PredefinedNotesProvider>
  );
};

PredefinedNotes.propTypes = {
  onAdd: PropTypes.func.isRequired,
  autofocus: PropTypes.bool,
  disabled: PropTypes.bool,
};

PredefinedNotes.defaultProps = {
  autofocus: true,
  disabled: false,
};
export default PredefinedNotes;
