const mergeSameParentName = (parsedList) => {
  // Since the exception reason(code) association to stations is based on L1 but L2 may differs from station to station
  // we may end up with more that 1 L1 with the same name at one station. This function merge the child of L1 with the
  // same name.
  const output = [];
  parsedList.forEach((parentCode) => {
    const existingIndex = output.findIndex(
      (parentOutputCode) => parentOutputCode.value === parentCode.value
    );
    if (existingIndex !== -1) {
      output[existingIndex].childOptions = output[
        existingIndex
      ].childOptions.concat(parentCode.childOptions);
    } else {
      output.push(parentCode);
    }
  });
  return output;
};

const associateChildrenCodes = (codeList) => {
  const fetchChildren = (parentId) =>
    codeList
      .filter((code) => code.parentCodeId === parentId)
      .map((code) => ({
        ...code,
        childOptions: fetchChildren(code.id),
      }));
  const parseCode = (code) => ({
    ...code,
    childOptions: fetchChildren(code.id),
  });
  return mergeSameParentName(
    codeList.map(parseCode).filter((code) => code.parentCodeId === null)
  );
};

export default associateChildrenCodes;
