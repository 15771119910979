export const DEFAULT_PRINTER_LABEL = {
  label: '2x4',
  value: 'default',
  currentPrinterModel: null,
  printerModels: [],
};

export const PRINTER_LABELS = {
  rfid: [
    { ...DEFAULT_PRINTER_LABEL },
    {
      label: '1x3',
      value: 'small',
      currentPrinterModel: null,
      printerModels: [
        { label: 'T410', value: 'T410' },
        { label: 'T411', value: 'T411' },
      ],
    },
  ],
};

// ZPL COMMAND REFERENCE
// https://gist.github.com/metafloor/773bc61480d1d05a976184d45099ef56
//
// DETAILED VERSION
// https://www.zebra.com/content/dam/zebra_new_ia/en-us/manuals/printers/common/programming/zpl-zbi2-pm-en.pdf
//
// RFID ZPL Guide 3
// https://www.zebra.com/content/dam/zebra_new_ia/en-us/manuals/printers/common/programming/rfid3-pg-en.pdf

const DEFAULT_LABEL_OPTIONS = {
  QR_CODE_ITEM_LPN: {
    QR_CODE: {
      size: '10', // 2 to 10
      top: '310',
      left: '315',
    },
    EVTN: {
      w: '400', // width of text block line (in dots)
      fontSize: '32',
      top: '0',
      left: '555',
      orientation: 'A0R',
    },
    LPN: {
      w: '400', // width of text block line (in dots)
      fontSize: '36',
      top: '340',
      left: '235',
      orientation: 'A0N',
    },
    MULTI: {
      w: '400', // width of text block line (in dots)
      fontSize: '32',
      top: '400',
      left: '285',
    },
  },
  BAR_CODE_BOX_LPN: {
    BAR_CODE: {
      w: '3', // w = module width (in dots)
      r: '3', // r = ratio
      h: '220', // h = height of bars (in dots)
      top: '200',
      left: '325',
    },
    LPN: {
      top: '400',
      left: '280',
    },
  },
  BAR_CODE_ITEM_LPN: {
    BAR_CODE: {
      w: '3', // w = module width (in dots)
      r: '3', // r = ratio
      h: '220', // h = height of bars (in dots)
      top: '260',
      left: '80',
    },
    LPN: {
      top: '300',
      left: '220',
    },
    EVTN: {
      top: '330',
      left: '220',
    },
    RMA: {
      top: '360',
      left: '220',
    },
  },
};

const LABEL_OPTIONS = {
  default: {
    ...DEFAULT_LABEL_OPTIONS,
  },
  small: {
    ...DEFAULT_LABEL_OPTIONS,
    QR_CODE_ITEM_LPN: {
      QR_CODE: {
        size: '6', // 2 to 10
        top: '165',
        left: '50',
      },
      EVTN: {
        w: '250', // width of text block line (in dots)
        fontSize: '40',
        top: '75',
        left: '225',
        orientation: 'A0N',
      },
      LPN: {
        w: '450', // width of text block line (in dots)
        fontSize: '40',
        top: '150',
        left: '175',
        orientation: 'A0N',
      },
      MULTI: {
        w: '200', // width of text block line (in dots)
        fontSize: '16',
        top: '190',
        left: '30',
      },
    },
  },
};

const LABELS = {
  QR_CODE_ITEM_LPN: {
    QR_CODE: (
      value,
      options = DEFAULT_LABEL_OPTIONS.QR_CODE_ITEM_LPN.QR_CODE
    ) =>
      `^BQN,2,${options.size}^FT${options.left},${options.top}^FDQA,${value}^FS`,
    EVTN: (value, options = DEFAULT_LABEL_OPTIONS.QR_CODE_ITEM_LPN.EVTN) =>
      `^FB${options.w},1,0,C^FT${options.left},${options.top}^${options.orientation},${options.fontSize},${options.fontSize}^FD${value}\\&^FS`,
    LPN: (value, options = DEFAULT_LABEL_OPTIONS.QR_CODE_ITEM_LPN.LPN) =>
      `^FB${options.w},1,0,C^FT${options.left},${options.top}^${options.orientation},${options.fontSize},${options.fontSize}^FD${value}\\&^FS`,
    MULTI: (value, options = DEFAULT_LABEL_OPTIONS.QR_CODE_ITEM_LPN.MULTI) =>
      `^FB${options.w},1,0,C^FT${options.left},${options.top}^A0B,${options.fontSize},${options.fontSize}^FD${value}\\&^FS`,
  },
  BAR_CODE_BOX_LPN: {
    BAR_CODE: (
      value,
      options = DEFAULT_LABEL_OPTIONS.BAR_CODE_BOX_LPN.BAR_CODE
    ) =>
      `^BY${options.w},${options.r},${options.h}^FT${options.left},${options.top}^BCR,,N,N^FD>:${value}^FS`,
    LPN: (value, options = DEFAULT_LABEL_OPTIONS.BAR_CODE_BOX_LPN.LPN) =>
      `^FT${options.left},${options.top}^A0R,36,36^FD${value}^FS`,
  },
  BAR_CODE_ITEM_LPN: {
    BAR_CODE: (
      value,
      options = DEFAULT_LABEL_OPTIONS.BAR_CODE_ITEM_LPN.BAR_CODE
    ) =>
      `^BY${options.w},${options.r},${options.h}^FT${options.left},${options.top}^BCN,,N,N^FD>:${value}^FS`,
    LPN: (value, options = DEFAULT_LABEL_OPTIONS.BAR_CODE_ITEM_LPN.LPN) =>
      `^FB400,1,0,C^FT${options.left},${options.top}^A0N,36,36^FD${value}\\&^FS`,
    EVTN: (value, options = DEFAULT_LABEL_OPTIONS.BAR_CODE_ITEM_LPN.EVTN) =>
      `^FB400,1,0,C^FT${options.left},${options.top}^A0N,36,36^FD${value}\\&^FS`,
    RMA: (value, options = DEFAULT_LABEL_OPTIONS.BAR_CODE_ITEM_LPN.RMA) =>
      `^FB400,1,0,C^FT${options.left},${options.top}^A0N,36,36^FD${value}\\&^FS`,
  },
  /**
   * @param {PrinterModels} printerModel
   * @param hexValue
   * @param lockValue
   * @returns {string}
   * @constructor
   */
  RFID: (printerModel, hexValue, lockValue) => {
    const rfidCommand = `^RFW,H^FD${hexValue}^FS`;
    switch (printerModel) {
      case null:
        return `${rfidCommand}^RFW,H,P^FD${lockValue}^FS^RLM,L,L^FS`;
      case 'T410':
        return `${rfidCommand}^RLM,P,P,P,P,P^FS`;
      case 'T411':
        return `${rfidCommand}^RLM,P,P,P,P,P^FS`;
      default:
        return rfidCommand;
    }
  }
};

const GEN_LABEL_OPTIONS = {
  labelSize: 'default',
  rma: false,
  multi: false,
};

/**
 * @typedef {'default' | 'small'} LabelSizes
 */

/**
 * @typedef {'T410' | 'T411' | null} PrinterModels
 */

/**
 * @param {string} lpn
 * @param {string} evtn
 * @param {{multi: boolean | undefined, labelSize: LabelSizes | undefined} | undefined} options
 * @returns {string}
 */
export function genQrCodeItemLpn(lpn, evtn, options = undefined) {
  const { multi, labelSize } = { ...GEN_LABEL_OPTIONS, ...(options || {}) };
  const zplOptions = LABEL_OPTIONS[labelSize].QR_CODE_ITEM_LPN;
  const multiSection = multi
    ? LABELS.QR_CODE_ITEM_LPN.MULTI('MULTI', zplOptions.MULTI)
    : '';
  return (
    LABELS.QR_CODE_ITEM_LPN.QR_CODE(lpn, zplOptions.QR_CODE) +
    LABELS.QR_CODE_ITEM_LPN.LPN(lpn, zplOptions.LPN) +
    LABELS.QR_CODE_ITEM_LPN.EVTN(evtn, zplOptions.EVTN) +
    multiSection
  );
}

export function genSneakerLpn(lpn) {
  return `~TA000~JSN^LT0 ^MNW^MTT^PON^PMN^LH0,0^JMA^PR4,4~SD15^JUS^LRN^CI0^XZ ^XA ^MMT ^PW400 ^LL100 ^LS0 ^BY1,3,100^FT85,125^BCN,,N,N ^FD>:${lpn}^FS ^FT15,170^A0N,40,40^FH^FD${lpn}^FS ^PQ1,0,1,Y`;
}

/**
 * @param {string} lpn
 * @param {{labelSize: LabelSizes | undefined} | undefined} options
 * @returns {string}
 */
export function genBarCodeBoxLpn(lpn, options = undefined) {
  const { labelSize } = { ...GEN_LABEL_OPTIONS, ...(options || {}) };
  const zplOptions = LABEL_OPTIONS[labelSize].BAR_CODE_BOX_LPN;
  return (
    LABELS.BAR_CODE_BOX_LPN.BAR_CODE(lpn, zplOptions.BAR_CODE) +
    LABELS.BAR_CODE_BOX_LPN.LPN(lpn, zplOptions.LPN)
  );
}

/**
 * @param {string} lpn
 * @param {string} evtn
 * @param {{rma: boolean | undefined, labelSize: LabelSizes | undefined} | undefined} options
 * @returns {string}
 */
export function genBarCodeItemLpn(lpn, evtn, options = undefined) {
  const { rma, labelSize } = { ...GEN_LABEL_OPTIONS, ...(options || {}) };
  const zplOptions = LABEL_OPTIONS[labelSize].BAR_CODE_ITEM_LPN;
  const rmaSection = rma
    ? LABELS.BAR_CODE_ITEM_LPN.RMA('RMA', zplOptions.RMA)
    : '';
  return (
    LABELS.BAR_CODE_ITEM_LPN.BAR_CODE(lpn, zplOptions.BAR_CODE) +
    LABELS.BAR_CODE_ITEM_LPN.LPN(lpn, zplOptions.LPN) +
    LABELS.BAR_CODE_ITEM_LPN.EVTN(evtn, zplOptions.EVTN) +
    rmaSection
  );
}

/**
 * @param {PrinterModels} printerModel
 * @param hexValue
 * @param lockValue
 * @returns {string}
 */
export function genRfidEncoding(printerModel, hexValue, lockValue) {
  return LABELS.RFID(printerModel, hexValue, lockValue);
}
