import styled from 'styled-components';
import { COLORS } from '@codeboxxtechschool/ginza_shared_components';

export const StyledRow = styled('div')({
  display: 'inline-flex',
});

export const ImageBox = styled('div')({
  minHeight: '284px',
  margin: '0 0 25px',
  padding: '50px 350px 0px 350px',
  borderRadius: '3.8px',
  backgroundColor: COLORS.lightGreen,
});

export const ButtonContainer = styled('div')({
  display: 'inline-flex',
  alignSelf: 'center',
});
