import { USER_ROLES, ADMIN_ROLE } from '../constants';

export const IsAdmin = (userRoles) => {
  return (
    // every admins currently have access to printer selection
    userRoles.filter((element) => ADMIN_ROLE.includes(element)).length > 0
  );
};

export const IsPrinterUser = (userRoles) => {
  return (
    userRoles.filter((element) => USER_ROLES.printer_user === element).length >
    0
  );
};

/**
 * @param {Array<string>} userRoles
 * @returns {boolean}
 */
export const CanEditItemMetadata = (userRoles) => {
  return userRoles.some((userRole) =>
    [
      USER_ROLES.super_admin,
      USER_ROLES.global_admin,
      USER_ROLES.aspect_manager,
    ].includes(userRole)
  );
};

export const CanSelectPrinters = (userRoles) => {
  return IsAdmin(userRoles) || IsPrinterUser(userRoles);
};

export const CanDiscardException = (userRoles) => {
  // every admins currently have access to discard
  return IsAdmin(userRoles);
};

export const CanReprintShippingLabel = (userRoles) => {
  return IsAdmin(userRoles) || IsPrinterUser(userRoles);
};
