import styled from 'styled-components';
import { COLORS } from '@codeboxxtechschool/ginza_shared_components';

export const SecretCodeWrapper = styled('div')({
  maxWidth: '403px',
  maxHeight: '94px',
  margin: '23px 0 0',
  padding: '5px 0px 5px',
  borderRadius: '4px',
  backgroundColor: COLORS.mediumGray,
});

export const SecretCodeValue = styled('h4')({
  maxHeight: '50px',
  textAlign: 'center',
  color: COLORS.black,
});

export const SecretCodeTitle = styled('div')({
  fontWeight: 'normal',
});
