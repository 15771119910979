import { useStateValue } from '../../../../providers/StateProvider';

const usePsnadAutomation = () => {
  const dispatch = useStateValue()[1];

  const updateSelectedSnadIdsContext = (topLvlInputs) => {
    const selectedSecondLvlSnadIds = topLvlInputs
      .flatMap((topLevelInput) => topLevelInput.children)
      .filter((secondLvlSnad) => !secondLvlSnad.answer)
      .map((secondLvlSnad) => secondLvlSnad.id);

    dispatch({
      type: 'changeSelectedSnadIds',
      newSelectedSnadIds: selectedSecondLvlSnadIds,
    });
  };

  const isPsnadAutomatedHub = () => {
    const currentHub = JSON.parse(sessionStorage.getItem('partnerLocation'));
    return currentHub?.hasPsnadStation;
  };

  return {
    updateSelectedSnadIdsContext,
    isPsnadAutomatedHub,
  };
};

export default usePsnadAutomation;
