import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  border-radius: 0 !important;
  background-color: ${({ backgroundcolor }) => backgroundcolor} !important;
  color: ${({ textcolor }) => textcolor} !important;
  text-transform: none !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
`;

export default StyledButton;
