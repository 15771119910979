import React, { createContext, useState, useContext } from 'react';
import { Box, ButtonGroup, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LinkIcon from '@material-ui/icons/Link';
import { MonetizationOn, StarOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Icon } from '@codeboxxtechschool/ginza_shared_components';
import { itemShape } from '../../../utils/propTypesShape/itemShape';
import { FooterProvider } from './footer/context/FooterContext';
import StationWrapperTimeline from '../../reusables/timeline/StationWrapperTimeline';
import InputButton from '../../reusables/inputs/InputButton';
import { StyledIconButton } from './Handbag/AttributesSelection.styles';
import { COLORS, HUB_ID } from '../../../constants';
import {
  StatusExport,
  StatusVault,
} from '../../reusables/statusPill/index.styles';
import itemDetails from '../../../utils/itemDetails';

export const QualityControlContext = createContext({});
export const useQualityControlContext = () => useContext(QualityControlContext);
export const QualityControlProvider = ({
  children,
  miscategorized,
  reinitialize,
  scannedItem,
  setItem,
  setMiscategorized,
  stationItemResult,
  orderStatus,
  readOnlyObject,
}) => {
  const { t } = useTranslation();
  const [showPrivateNote, setShowPrivateNote] = useState(true);
  const [inputs, setInputs] = useState();
  const [refetchUserNote, setRefetchUserNote] = useState(null);
  const [userNoteParams, setUserNoteParams] = useState({});

  const details = itemDetails(scannedItem);

  const guidelinesUrl =
    scannedItem?.order?.partnerLocation?.conditionGuidelinesUrl || null;

  const lpnWithCollaterals =
    scannedItem?.collaterals?.length > 0
      ? `${scannedItem.lpn.lpn}, ${scannedItem.collaterals
          .map((c) => c.lpn.lpn)
          .join(', ')}`
      : scannedItem.lpn.lpn;

  // This is a hub PSA Mode and Price test that applies to HB New Jersey and Australia, should be refactored for all or deleted if conclusive
  const ebayItem = scannedItem && scannedItem.ebayItem;
  const { psaMode = '', itemPrice = '' } = ebayItem || {};
  const partnerLocation =
    scannedItem?.order && scannedItem?.order?.partnerLocation;
  const hubId = (partnerLocation && partnerLocation?.hubId) || '';

  return (
    <StationWrapperTimeline
      leftComponent={
        <>
          <Box
            sx={{
              border: 1,
              borderColor: 'rgba(0, 0, 0, 0.2)',
              borderStyle: 'solid',
            }}
            marginRight="10px"
            alignItems="center"
            paddingLeft="10px"
            display="flex"
            justifyContent="space-between"
          >
            <Box alignItems="center" sx={{ width: '-webkit-fill-available' }}>
              <Tooltip
                title={t('TradingCards.LPN', { lpn: lpnWithCollaterals })}
              >
                <Typography noWrap>
                  {t('TradingCards.LPN', { lpn: lpnWithCollaterals })}
                </Typography>
              </Tooltip>
              <Typography>
                {t('TradingCards.EVTN', { evtn: scannedItem.order.evtn })}
              </Typography>
              <Typography>
                {t('TradingCards.Title', {
                  title: details.title,
                })}
              </Typography>

              {/* Temporary Hub test / Handbags New Jersey and Australia: PSA Optional and Price should be refactored to all hubs after conclusion */}
              {hubId === HUB_ID.handbagsAustralia ||
              hubId === HUB_ID.handbagsNewJersey ? (
                <>
                  {psaMode === 'OPTIONAL' && (
                    <div style={{ display: 'flex' }} name="details-item-price">
                      <Typography>{t('QualityControl.AG.PsaMode')}</Typography>
                      <div style={{ padding: '0.2rem 0.5rem' }}>
                        <Icon name="check" size="xxSmall" />
                      </div>
                    </div>
                  )}
                  <Typography>
                    {t('QualityControl.AG.ItemPrice') + itemPrice}
                  </Typography>
                </>
              ) : (
                <></>
              )}
              {scannedItem.category.name === 'TRADING CARD' &&
              scannedItem.alitheonStatus ? (
                <Typography>
                  {t('TradingCards.AlitheonStatus', {
                    alitheonStatus: scannedItem.alitheonStatus,
                  })}
                </Typography>
              ) : null}
              {scannedItem.category.name === 'STREETWEAR' ? (
                <Typography>
                  {t('Streetwear.Condition', {
                    condition:
                      scannedItem?.order?.ebayOrder?.itemCondition?.length > 0
                        ? scannedItem.order.ebayOrder.itemCondition
                        : scannedItem.ebayItem.itemCondition,
                  })}
                </Typography>
              ) : null}
            </Box>
            <Box
              sx={{ mr: 1 }}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              {!scannedItem.isInHandSubmission && scannedItem.isVaultBound ? (
                <Box>
                  <StatusVault
                    sx={{ ml: 1 }}
                    fontSize="13px"
                    padding="5px 1.5em"
                  >
                    {t('ScanHistory.VaultBound')}
                  </StatusVault>
                </Box>
              ) : null}
              {scannedItem.isInHandSubmission ? (
                <Box>
                  <StatusVault
                    sx={{ ml: 1 }}
                    fontSize="13px"
                    padding="5px 1.5em"
                  >
                    {t('ScanHistory.InHandSubmission')}
                  </StatusVault>
                </Box>
              ) : null}
              {scannedItem?.isExport ? (
                <Box>
                  <StatusExport
                    sx={{ ml: 1 }}
                    fontSize="13px"
                    padding="5px 1.5em"
                  >
                    {scannedItem?.exportCountry
                      ? t('ScanHistory.ExportWithCountry', {
                          country: scannedItem?.exportCountry,
                        })
                      : t('ScanHistory.Export')}
                  </StatusExport>
                </Box>
              ) : null}
              {details.supervisedAuthentication ? (
                <Box sx={{ ml: 1 }} title={t('QualityControl.Title.HighValue')}>
                  <MonetizationOn
                    fontSize="large"
                    htmlColor={COLORS.darkYellow}
                  />
                </Box>
              ) : null}
              {details.whiteGlove ? (
                <Box sx={{ ml: 1 }} title={t('QualityControl.Title.TopStar')}>
                  <StarOutlined
                    fontSize="large"
                    htmlColor={COLORS.darkYellow}
                  />
                </Box>
              ) : null}
              {guidelinesUrl ? (
                <Box sx={{ m: 1 }}>
                  <ButtonGroup>
                    <InputButton
                      id="auth-guide-url-button"
                      renderAs="button"
                      type="white&border"
                      href={guidelinesUrl}
                      target="_blank"
                      content={t('QualityControl.Title.AuthGuide')}
                    />
                    <StyledIconButton
                      id="auth-guide-url-button-icon"
                      href={guidelinesUrl}
                      target="_blank"
                    >
                      <LinkIcon fontSize="medium" />
                    </StyledIconButton>
                  </ButtonGroup>
                </Box>
              ) : null}
            </Box>
          </Box>
        </>
      }
      item={scannedItem}
    >
      <QualityControlContext.Provider
        value={{
          miscategorized,
          reinitialize,
          scannedItem,
          scannedItemDetails: details,
          setItem,
          setMiscategorized,
          setShowPrivateNote,
          showPrivateNote,
          stationItemResult,
          inputs,
          setInputs,
          refetchUserNote,
          setRefetchUserNote,
          userNoteParams,
          setUserNoteParams,
          orderStatus,
          readOnlyObject,
        }}
      >
        <FooterProvider>{children}</FooterProvider>
      </QualityControlContext.Provider>
    </StationWrapperTimeline>
  );
};

QualityControlProvider.defaultProps = {
  readOnlyObject: {},
};

QualityControlProvider.propTypes = {
  children: PropTypes.node.isRequired,
  reinitialize: PropTypes.func.isRequired,
  scannedItem: PropTypes.shape(itemShape).isRequired,
  setItem: PropTypes.func.isRequired,
  miscategorized: PropTypes.shape({
    checked: PropTypes.bool.isRequired,
  }).isRequired,
  setMiscategorized: PropTypes.func.isRequired,
  stationItemResult: PropTypes.func.isRequired,
  orderStatus: PropTypes.string.isRequired,
  readOnlyObject: PropTypes.shape({
    readOnly: PropTypes.bool,
    message: PropTypes.string,
    timestamp: PropTypes.oneOfType([PropTypes.instanceOf(Date)]),
  }),
};
