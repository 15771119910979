/* eslint-disable react/prop-types */
import React from 'react';
import Box from '@material-ui/core/Box';

const defaultProps = {
  children: <></>,
  vertically: true,
};

const CenteredContainer = ({ children, vertically }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: vertically ? 'center' : undefined,
        alignItems: 'center',
        height: '100%',
      }}
    >
      {children}
    </Box>
  );
};

CenteredContainer.defaultProps = defaultProps;

export default CenteredContainer;
