import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { useLazyQuery } from '@apollo/client';
import StationsStatusSection from '../../stations/History/sections/StationsStatusSection';
import { useStateValue } from '../../../providers/StateProvider';
import { StationWrapper } from '../StyledComponent';
import { itemShape } from '../../../utils/propTypesShape/itemShape';
import { FETCH_ITEM_TIMELINE_STATION_STATUSES } from '../../../graphql/queries';
import makeError from '../../../utils/makeError';

const StationWrapperTimeline = ({ children, item, leftComponent }) => {
  const [{ stationList }, dispatch] = useStateValue();
  const [itemTimeline, setItemTimeline] = useState(null);
  const [fetchItemTimelineStationStatuses, { data, loading }] = useLazyQuery(
    FETCH_ITEM_TIMELINE_STATION_STATUSES
  );

  const [stationId] = useState(sessionStorage.getItem('stationId'));

  useEffect(async () => {
    if (item) {
      try {
        await fetchItemTimelineStationStatuses({
          variables: { itemId: item?.id, stationId, isHistory: false },
          fetchPolicy: 'no-cache',
        });
      } catch (err) {
        makeError(err);
      }
    }
  }, [item]);

  useEffect(() => {
    if (data) {
      setItemTimeline(data.fetchItemTimelineStationStatuses);
    }
  }, [data]);

  useEffect(() => {
    if (loading) dispatch({ type: 'changeLoading', newLoading: true });
    else dispatch({ type: 'changeLoading', newLoading: false });
  }, [loading]);

  return (
    <>
      {item && itemTimeline && stationList && (
        <RelativeGrid container direction="row">
          <Grid item xs={12} md={12} lg={4}>
            {leftComponent}
          </Grid>
          <Grid item xs={12} md={12} lg={8}>
            <StationsStatusSection
              item={itemTimeline}
              stations={stationList.map((station) => ({
                id: station.id,
                name: station.value,
                displayOrder: station.displayOrder,
              }))}
            />
          </Grid>
        </RelativeGrid>
      )}
      <StationWrapper>{children}</StationWrapper>
    </>
  );
};

export default StationWrapperTimeline;

StationWrapperTimeline.defaultProps = {
  leftComponent: null,
  children: null,
  item: null,
};

StationWrapperTimeline.propTypes = {
  leftComponent: PropTypes.node,
  children: PropTypes.node,
  item: PropTypes.shape(itemShape),
};

export const RelativeGrid = styled(Grid)`
  padding: 0.5em;
  z-index: 2;
`;
