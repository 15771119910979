import { Badge } from '@codeboxxtechschool/ginza_shared_components';
import React from 'react';
import { BadgeWrapper } from './AlertBadge.styles';
import { COLORS } from '../../../constants';

const AlertBadge = () => {
  return (
    <BadgeWrapper data-testid="alert-badge">
      <Badge color={COLORS.red} value={<></>} />
    </BadgeWrapper>
  );
};

export default AlertBadge;
