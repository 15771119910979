import { useEffect, useState } from 'react';

function getHeaderValue(headers, name) {
  return headers.get(name);
}

export default function useApolloResponse(useApolloNetworkStatus) {
  const status = useApolloNetworkStatus();
  const [response, setResponse] = useState();

  useEffect(() => {
    if (status) {
      const { queryError } = status;
      if (queryError) {
        const { networkError } = queryError;
        if (networkError) {
          const { response: networkResponse } = networkError;
          if (networkResponse) {
            const statusCode = networkResponse.status;
            const headers = [];

            const accessDeniedMessage = getHeaderValue(
              networkResponse.headers,
              'Access-Denied-Message'
            );

            if (accessDeniedMessage)
              headers.push({
                name: 'accessDeniedMessage',
                value: accessDeniedMessage,
              });

            setResponse({ statusCode, headers });
          }
        }
      }
    }
  }, [status]);

  return { response, setResponse };
}
