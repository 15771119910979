import React from 'react';
import { Button } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useStateValue } from '../providers/StateProvider';

const LogOut = () => {
  // const [{ authToken, currentStationId, userStationList }, dispatch] = useStateValue()
  const dispatch = useStateValue()[1];
  const { t } = useTranslation();
  const history = useHistory();

  const onLogout = () => {
    dispatch({
      type: 'changeAuthToken',
      newAuthToken: null,
    });
    dispatch({
      type: 'changeUserStationList',
      newUserStationList: null,
    });
    dispatch({
      type: 'changeStationList',
      newStationList: null,
    });
    dispatch({
      type: 'changeCurrentStationId',
      newCurrentStationId: null,
    });
    dispatch({
      type: 'changeCurrentPartnerLocationConfigs',
      currentPartnerLocationConfigs: {},
    });
    dispatch({
      type: 'changePartnerLocation',
      newPartnerLocation: {
        id: null,
        name: null,
        lpnType: null,
      },
    });
    dispatch({
      type: 'changeUserIdentification',
      newUserIdentification: '',
    });

    sessionStorage.clear();
    history.push('/');
  };

  return (
    <Button
      id="logout-button"
      startIcon={<ExitToAppIcon />}
      onClick={onLogout}
      style={{ textTransform: 'capitalize' }}
    >
      {t('LogOut')}
    </Button>
  );
};

LogOut.defaultProps = {
  padding: 'auto',
};

LogOut.propTypes = {
  padding: PropTypes.string,
};

export default LogOut;
