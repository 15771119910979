import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const FloatingNotesContainer = styled.div`
  position: absolute;
  ${(props) => `right: ${props.right};`}
  ${(props) => `display: ${props.isShown ? 'block' : 'none'};`}
  top: 0px;
  height: 100%;
  width: 0px;
  ${(props) => `width: ${props.width};`}
  max-width: 75%;
  z-index: 10;
`;

export const FloatingNotesButton = styled.div`
  position: sticky;
  ${(props) => `margin-top: ${props.marginTop};`}
  ${(props) => `top: ${props.top};`}
  height: 50px;
  width: 50px;
  ${(props) => `width: ${props.width};`}
  ${(props) => `background-color: ${props.isShown ? 'white' : '#134074'};`}
  ${(props) =>
    `border-radius: ${props.isShown ? '0px 0px 0px 0px' : '5px 0px 0px 5px'};`}
  cursor: pointer;
`;

export const FloatingNotesComponent = styled.div`
  position: sticky;
  ${(props) => `display: ${props.isShown ? 'block' : 'none'};`}
  ${(props) => `margin-top: ${props.marginTop};`}
  ${(props) => `top: ${props.top};`}
  ${(props) => `height: calc(100vh - ${props.marginTop});`}
  ${(props) => `width: ${props.width};`}
  background-color: white;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 5px black;
`;

export const IconContainter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & svg {
    width: 35px;
    height: 35px;
    color: white;
    ${(props) => `color: ${props.isShown ? 'black' : 'white'};`}
  }
`;

export const NotesContainer = styled.div`
  padding: 15px 15px 30px 15px;
`;

export const BoldTypography = styled(Typography)`
  font-weight: bolder;
`;

export default FloatingNotesContainer;
