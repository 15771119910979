import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export const LocationInputLabel = styled(Typography)`
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  color: rgba(0, 0, 0, 0.6);
  background-color: white;
  transform: scale(0.85);
  transform-origin: 0px -40px;
  position: absolute;
  z-index: 1;
  padding: 2px 4px;
  margin-left: 10px;
`;

export const LocationInputWrapper = styled(Box)`
  position: relative;
  margin-top: 8px;
`;
