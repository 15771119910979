import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Collapse, Grid, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import ExceptionNoteItem from './ExceptionNoteItem';
import ExceptionNoteHeader from './ExceptionNoteHeader';

const useExceptionItemStyles = makeStyles(() => ({
  root: {
    margin: '0 0 0.5em 0',
  },
  container: {
    padding: '0.5em 1em 0.5em',
    margin: '0.5em 1em',
    backgroundColor: '#f7f7f7',
  },
}));

const ExceptionItem = ({ exception }) => {
  const classes = useExceptionItemStyles();
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Grid container item direction="column" className={classes.root}>
      <Paper square elevation={24} className={classes.container}>
        <ExceptionNoteHeader
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          exception={exception}
        />
        <Collapse in={!isCollapsed}>
          <Grid container item direction="column">
            {exception.notes?.length > 0 &&
              [...exception.notes]
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map((note) => <ExceptionNoteItem key={note.id} note={note} />)}
          </Grid>
        </Collapse>
      </Paper>
    </Grid>
  );
};

export default ExceptionItem;

ExceptionItem.propTypes = {
  exception: PropTypes.shape({
    id: PropTypes.string.isRequired,
    notes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};
