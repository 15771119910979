import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../../../constants';

const conditionDefinitions = (category) => {
  const { t } = useTranslation();

  const handbagConditions = [
    {
      color: COLORS.blue,
      title: t(
        'GinzaV2.ConditionInfoModal.Handbag.ConditionTitles.NewWithTags'
      ),
      definition: t(
        'GinzaV2.ConditionInfoModal.Handbag.ConditionDefinitions.NewWithTags'
      ),
    },
    {
      color: COLORS.blue,
      title: t(
        'GinzaV2.ConditionInfoModal.Handbag.ConditionTitles.NewWithoutTags'
      ),
      definition: t(
        'GinzaV2.ConditionInfoModal.Handbag.ConditionDefinitions.NewWithoutTags'
      ),
    },
    {
      color: COLORS.yellow,
      title: t(
        'GinzaV2.ConditionInfoModal.Handbag.ConditionTitles.NewWithDefects'
      ),
      definition: t(
        'GinzaV2.ConditionInfoModal.Handbag.ConditionDefinitions.NewWithDefects'
      ),
    },
    {
      color: COLORS.yellow,
      title: t('GinzaV2.ConditionInfoModal.Handbag.ConditionTitles.PreOwned'),
      definition: t(
        'GinzaV2.ConditionInfoModal.Handbag.ConditionDefinitions.PreOwned'
      ),
    },
  ];

  switch (category) {
    case 'handbag':
      return handbagConditions;
    default:
      return null;
  }
};

export default conditionDefinitions;
