import { Fab, Button, Modal, Paper, Grid, Chip } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import styled from 'styled-components';
import MuiAlert from '@material-ui/lab/Alert';
import { COLORS as SHARED_COLORS } from '@codeboxxtechschool/ginza_shared_components';
import { COLORS } from '../../constants';

const StationWrapper = styled.div`
  overflow: auto;
  overflow-x: hidden;
  height: 100%;
`;

const StyledGrid = styled(Grid)`
  ${(props) => props.maxheight && `max-height: ${props.maxheight};`}
  ${(props) => props.minheight && `min-height: ${props.minheight};`}
  ${(props) => props.maxwidth && `max-width: ${props.maxwidth};`}
  ${(props) => props.minwidth && `min-width: ${props.minwidth};`}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.height && `height: ${props.height};`}
  ${(props) => props.alignself && `align-self: ${props.alignself};`}
  ${(props) => props.paddingleft && `padding-left: ${props.paddingleft};`}
  ${(props) => props.paddingTop && `padding-top: ${props.paddingTop};`}
  ${(props) => props.padding && `padding: ${props.padding};`}
  ${(props) => props.scrolly && `overflow-y: ${props.scrolly};`}
  ${(props) => props.overflowX && `overflow-x: ${props.overflowX};`}
    ${(props) =>
    props.justifyContent && `justify-content: ${props.justifyContent};`}
`;

const DivStyled = styled.div`
  align-self: ${(props) => (props.align ? props.align : 'auto')};
  overflow-y: ${(props) => props.scrolly || 'auto'};
  overflow-x: ${(props) => props.scrollX || 'auto'};
  overflow: ${(props) => props.overflow || 'auto'};
  min-width: ${(props) => props.minWidth || 'auto'};
  max-width: ${(props) => props.maxWidth || 'unset'};
  min-height: ${(props) => props.minHeight || 'auto'};
  max-height: ${(props) => props.maxHeight || 'unset'};
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  margin: ${(props) => props.margin || '0'};
  padding: ${(props) => props.padding || '0'};
  float: ${(props) => props.float || 'none'};
  ${(props) => (props.textCenter ? 'text-align: center;' : null)};
  background-color: ${(props) => props.bgColor || 'unset'};
  ${(props) => props.color && `color: ${props.color};`}
  border-radius: ${(props) => props.borderRadius || 'unset'};
  border: ${(props) => props.border || '0'};
  border-top: ${(props) => props.borderY || null};
  border-bottom: ${(props) => props.borderY || null};
  border-right: ${(props) => props.borderX || null};
  border-left: ${(props) => props.borderX || null};
  flex: ${(props) => props.flex || 'unset'};
  justify-content: ${(props) => (props.justCont ? props.justCont : 'inherit')};
  position: ${(props) => props.position || 'initial'};

  &.newOldItem {
    position: absolute;
    padding: 10px 17px;
    background-color: white;
    left: 0;
    z-index: 5;
    top: 2px;
  }
`;

const DividerLeftMiddleRight = styled.div`
  display: grid;
  ${(props) => (props.false ? '' : 'grid-template-columns: 27em 1fr 27em;')};
  padding-bottom: 1rem;
  height: ${(props) => (props.fullHeight ? '100%' : 'auto')};
  @media only screen and (max-width: 1100px) {
    grid-template-columns: none;
  }
`;

const DividerLeftRight = styled.div`
  display: grid;
  ${(props) => (props.false ? '' : 'grid-template-columns: 50% 50%;')}
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  min-height: ${(props) => props.minHeight || 'auto'};
  background-color: ${(props) => props.bgColor || 'unset'};
  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media only screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;
const FlexContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) => props.reverse && 'flex-direction: row-reverse;'}
  flex: ${(props) => (props.flex ? props.flex : '0')};
  justify-content: ${(props) => (props.justCont ? props.justCont : 'inherit')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'inherit')};
  flex-shrink: ${(props) => (props.flexShrink ? props.flexShrink : 'unset')};
  flex-wrap: ${(props) => (props.wrap ? props.wrap : 'unset')};
  flex-grow: ${(props) => (props.flexGrow ? props.flexGrow : 'unset')};
  gap: ${(props) => props.gap || 'unset'};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : '100%')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : 'auto')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'unset')};
  min-height: ${(props) => (props.minHeight ? props.minHeight : 'auto')};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : 'unset')};
  overflow: ${(props) => (props.scroll ? props.scroll : 'unset')};
  border: ${(props) => (props.border ? props.border : '0')};
  z-index: 0;
  cursor: ${(props) => props.cursor || 'default'};
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'transparent'};

  &.ebayDesc {
    flex-wrap: wrap;
    overflow-x: scroll;
    & * {
      flex-wrap: wrap;
    }
  }
`;

const FlexContainerColumn = styled.div`
  display: flex;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1em')};
  justify-content: ${(props) => (props.justCont ? props.justCont : 'center')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  gap: ${(props) => props.gap || 'unset'};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  min-width: ${(props) => (props.minwidth ? props.minwidth : 'unset')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'unset')};
  min-height: ${(props) => (props.minHeight ? props.minHeight : 'auto')};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : 'unset')};
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  flex: ${(props) => (props.flex ? props.flex : '1')};
  flex-grow: ${(props) => (props.grow ? props.grow : '1')};
  flex-direction: column;
  color: ${(props) => (props.font ? props.font : 'inherit')};
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'transparent'};
  border: ${(props) => (props.border ? props.border : '0')};
  border-top: ${(props) => (props.borderY ? props.borderY : null)};
  border-bottom: ${(props) => (props.borderY ? props.borderY : null)};
  border-radius: ${(props) => (props.rounded ? props.rounded : null)};
  ${(props) => props.cursor && `cursor: ${props.cursor};`}
  ${(props) => props.wordWrap && 'word-break: break-word;'}
`;

const ScanInputContainer = styled.div`
  height: 100%;
  flex: 1;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: auto;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const ConditionSectionContainer = styled.div`
  margin: 0.2em 0;
  border: ${(props) => (props.passing ? '#86B817' : '#E53238')} 2px solid;
  position: relative;

  &:first-child {
    margin: 0 0 0.2em 0;
  }

  .fullWidthButton {
    width: 100% !important;
    position: absolute;
    height: 100% !important;
    left: 0;
    top: 0;
    padding-left: 68px;
    max-height: 58px;
  }
`;

const OpenIconContainer = styled.div`
  flex-grow: 2;
  height: min-content !important;
  border-radius: 0 !important;
  justify-content: flex-end !important;
  text-align: end;
  background-color: transparent !important;
  margin: 0 1em 0 0 !important;
  color: inherit !important;
  box-shadow: none !important;
  display: flex;
`;

const ClickableFab = styled(Fab)`
  justify-content: ${(props) => props.aligny || 'unset'} !important;
  border-radius: 0 !important;
  flex-shrink: 2;
  box-shadow: unset !important;
  background: transparent !important;
  height: min-content !important;
  width: -webkit-fill-available !important;
  color: #fff !important;
`;

const CheckIcon = styled(Check)`
  color: #3e3e3e !important;
`;

const CloseIcon = styled(Close)`
  color: #3e3e3e !important;
`;

const Header = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-size: ${(props) => props.fontSize || '1em'};
  ${(props) => (props.child === 'true' ? 'font-weight: bold;' : null)}
  margin: 0;
`;

const SideNavText = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: capitalize;
`;

const ModalSubmitContent = styled(Button)`
  margin-top: 20px !important;
  font-weight: 900 !important;
  background-color: #fff !important;
  width: 50%;
  align-self: center !important;
  &:hover {
    color: #333;
  }
`;

const ModalCardContent = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

const ItemCardContentPaper = styled(Paper)`
  margin: 0.3rem;
  border: 1px solid ${(props) => props.border};
  border-color: ${(props) => (props.bcolor ? props.bcolor : '#000')};
  background-color: ${(props) => props.background} !important;
  color: ${(props) => props.font} !important;
  border-radius: 0.55em !important;
`;

const ItemCardImage = styled.div`
  width: ${(props) => (props.width ? props.width : '100px')};
  max-width: ${(props) => (props.Mwidth ? props.Mwidth : '102px')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '3px')};
  padding-top: 3%;
  justify-content: flex-start;
  display: flex;
  align-items: center;
`;
ItemCardImage.displayName = 'ItemCardImage';

const ItemCardImg = styled.img`
  margin: ${(props) => (props.margin ? props.margin : 'auto')};
  display: block;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '75%')};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '25%')};
`;

const FloatButtonStyled = styled.div`
  color: #fafafa;
  text-align: center;
  padding: 0 5px;
  background: ${(props) => props.bgColor};
  width: 8em;
  height: 1.3em;
  border-radius: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -0.5em;
  margin-top: -0.55em;
`;

const TextCenter = styled.h1`
  text-align: center;
`;

const PBiggerFont = styled.p`
  font-size: 1.25em;
  font-family: 'Montserrat', sans-serif;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'unset')};
  ${(props) => props.wordWrap && 'word-break: break-word;'}
  padding: ${(props) => (props.padding ? props.padding : 0)};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '120%')};
`;

const Title = styled.h2`
  ${(props) => props.italic && 'font-style: italic;'}
  font-size: ${(props) => (props.size ? props.size : '1.2em')};
  font-family: 'Montserrat', sans-serif;
  ${(props) => (props.noBold ? 'font-weight: 100;' : null)}
  ${(props) => (props.regular ? 'font-weight: 400;' : null)}
  color: ${(props) => props.color || '#000'};
  background: ${(props) => props.bgColor};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  margin-right: ${(props) => (props.mRight ? props.mRight : null)};
  margin-left: ${(props) => (props.mLeft ? props.mLeft : null)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'unset')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'unset'};
  padding: ${(props) => (props.padding ? props.padding : 0)};
  display: ${(props) => (props.display ? props.display : 'unset')};
  overflow: ${(props) => (props.overflow ? props.overflow : 'unset')};
  text-overflow: ${(props) =>
    props.textOverflow ? props.textOverflow : 'unset'};
  ${(props) => props.wordWrap && 'word-break: break-word;'}
  &:hover {
    ${(props) => props.wordWrapHover && 'word-break: break-word;'}
  }
`;
Title.displayName = 'Title';

const SubTitle = styled.p`
  ${(props) => props.italic && 'font-style: italic;'}
  font-size: ${(props) => (props.size ? props.size : '0.9em')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
  line-height: 1.3em;
  font-family: 'Montserrat', sans-serif;
  ${(props) => (props.noBold ? 'font-weight: 100;' : null)}
  color: ${(props) => props.color || '#000'};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  margin-right: ${(props) => (props.mRight ? props.mRight : '8px')};
  margin-left: ${(props) => (props.mLeft ? props.mLeft : null)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'unset')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  ${(props) => props.wordWrap && 'word-break: break-word;'}
`;
SubTitle.displayName = 'SubTitle';

const AuthResultPanelSection = styled.div`
  flex-grow: ${(props) => (props.grow ? props.grow : 0)};
  margin-right: ${(props) => (props.mRight ? props.mRight : 0)};
  padding: 1em 1em 0;
  border: 1.5px solid lightgray;
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  overflow: auto;
`;

const ListTitle = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-size: ${(props) => props.fontSize || '0.88em'};
  ${(props) => (props.child === 'true' ? 'font-weight: bold;' : null)}
  margin: 0;
`;

const ListInnerTitle = styled.span`
  ${(props) => (props.floatRight ? 'float: right;' : null)}
  font-size: ${(props) => (props.size ? props.size : '0.93em')};
  font-weight: 300;
  ${(props) => (props.noPadding ? null : 'padding-left: 1em;')}
  ${(props) =>
    props.paddingRight ? 'padding-right: 1em; padding-left: 0;' : null}
  min-width: fit-content;
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'unset')};
`;

const InnerTitle = styled.span`
  ${(props) => (props.floatRight ? 'float: right;' : null)}
  font-size: ${(props) => (props.size ? props.size : '1em')};
  font-weight: 300;
  ${(props) => (props.noPadding ? null : 'padding-left: 1em;')}
  ${(props) =>
    props.paddingRight ? 'padding-right: 1em; padding-left: 0;' : null}
  ${(props) => (props.paddingLeft ? 'padding-left: 0.25em;' : null)}
  min-width: fit-content;
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'unset')};
`;

const BorderText = styled.span`
  margin-top: -4.8em;
  padding: 0 4px;
  width: fit-content;
  font-size: 11px;
  font-family: 'Montserrat', sans-serif !important;
  background-color: #fff;
  z-index: auto;
  position: absolute;
`;

const HideAt900 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0.5em;
  @media only screen and (max-width: 1100px) {
    display: none;
  }
`;

const AlertBox = styled(MuiAlert)`
  width: -webkit-fill-available;
  margin: 0.5em 1em;
  justify-content: center;
  ${(props) =>
    props.backgroundcolor === 'success'
      ? `background-color: ${COLORS.green} !important;`
      : ''}
`;

const AlertBoxV2 = styled(MuiAlert)`
  width: -webkit-fill-available;
  margin: 0.5em 1em;
  justify-content: flex-start;
  border: 1px solid ${SHARED_COLORS.green500};
  color: ${COLORS.black};
  ${(props) =>
    props.severity === 'success'
      ? `background-color: ${SHARED_COLORS.green100} !important;`
      : ''}
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0.5em;
  height: -webkit-fill-available;
  padding: 0.5em;
  border: 1px solid #d9d9d9;
  justify-content: flex-start;
  align-items: center;
  width: -webkit-fill-available;
  flex-wrap: wrap;
  position: relative;
  max-height: 180px;
`;

const SelectStyled = styled.div`
  height: min-content;
  width: min-content;
  flex: 1;
  margin: 12px 8px 12px 8px !important;
`;

const LeftPanel = styled.div`
  background-color: #fff;
  box-shadow: #000 1px 0px 20px 3px;
  z-index: 500;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 500px;
  overflow: auto;
`;

const H1NoGutter = styled.h1`
  font-family: 'Montserrat', sans-serif !important;
  margin: ${(props) => (props.margin ? props.margin : 0)} !important;
  padding: ${(props) => (props.padding ? props.padding : 0)} !important;
  font-size: ${(props) => (props.size ? props.size : 'auto')} !important;
`;

const ImgContainer = styled.div`
  margin: 0 0.5em;
  height: ${(props) => (props.height ? props.height : 'auto')};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '')};
  max-height: ${(props) =>
    !props.maxHeight && props.height ? props.height : ''};
  min-width: ${(props) => (props.minWidth ? props.minWidth : '')};
  text-align: ${(props) => props.textAlign || 'center'};
  border: ${(props) => props.border || '0.5px solid rgba(0, 0, 0, 0.2)'};
  &:hover {
    cursor: pointer;
  }
`;

const DescriptionSectionStyledContainer = styled.div`
  overflow: auto;
  flex-wrap: wrap;
  justify-content: center;
  border: 1px solid #d9d9d9;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  height: -webkit-fill-available;
  min-width: ${(props) => (props.minWidth ? props.minWidth : '')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '')};
  width: ${(props) => (props.width ? props.width : 'unset')};
  padding: ${(props) => props.padding || '2em 1em'};

  aside {
    padding-bottom: 30px;
  }

  .product_dec,
  aside {
    h2 {
      font-size: 1em;
    }
    ul {
      padding-left: 0;
    }
  }

  h1 {
    font-size: 1.2em;
    margin-top: 0.2em;
  }

  h3 {
    font-size: 0.88em;
  }
`;

const EntrupySectionStyledContainer = styled.div`
  overflow: auto;
  justify-content: flex-start;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  height: -webkit-fill-available;
  min-width: ${(props) => (props.minWidth ? props.minWidth : '')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '')};
  width: ${(props) => (props.width ? props.width : 'unset')};
  padding: ${(props) => props.padding || '2em 1em'};
  display: flex;
  align-items: center;
`;

const H3NoMargin = styled.h3`
  margin: 0 !important;
`;

const DescriptionSectionStyled = styled.div`
  padding: 0.5em;
  margin: 1em 0;
  flex: 2;

  big {
    font-size: 1.05em;
    white-space: nowrap;
  }
`;

const SellerDescriptionStyled = styled.div`
  padding: 0.5em;
  margin: 1em 0;
  flex: 2;

  big {
    font-size: 1.05em;
    white-space: nowrap;
  }
`;

const ProductAttributesSection = styled(DescriptionSectionStyled)`
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const ImgHandBag = styled.img`
  margin-right: ${(props) => (props.marginright ? props.marginright : 0)};
  width: 75px;
  height: auto;
  max-height: 75px;
  max-width: 100px;
  cursor: pointer;
`;

const TabSelector = styled.div`
  display: flex;
  position: absolute;
  left: -32px;
  top: 0;
  z-index: 10;
  width: 150px;
  text-align: left;

  button {
    color: white;
    text-align: left;
    background-image: linear-gradient(
      180deg,
      #89a1e0,
      #6c8bda,
      #7390d8,
      #6d8ad4,
      #597bd0,
      #4e6dbb
    );
    margin: 1px 0;

    span {
      font-size: 0.8rem;
      line-height: 1.25em;
    }

    &.Mui-selected {
      background-image: linear-gradient(
        180deg,
        #7793da,
        #4e73d0,
        #3a64cb,
        #3a64cb,
        #3a64cb,
        #244cb1
      );
    }

    &:hover {
      background-image: linear-gradient(
        180deg,
        #6c8ad6,
        #4e73d0,
        #3a64cb,
        #3a64cb,
        #3a64cb,
        #3a62ca
      );
      transition: all 1s;
    }
  }
`;

const AlertChip = styled(Chip)`
  background-color: ${COLORS.yellow} !important;
  color: ${COLORS.white} !important;
  height: ${({ height }) => height || '75%'} !important;
  ${({ width }) => width && `width:${width}!important;`};

  .MuiChip-label {
    font-size: 1.5rem !important;
    font-weight: bold;
  }
`;

export const Label = styled.label`
  font-weight: bold;
`;
Label.displayName = 'Label';

export const ScrollableSection = styled.div`
  width: 100%;
  max-height: ${({ maxheight }) => maxheight} !important;
  overflow-x: hidden;
  overflow-y: auto;
`;
ScrollableSection.displayName = 'ScrollableSection';

export {
  H3NoMargin,
  DividerLeftRight,
  DescriptionSectionStyled,
  SellerDescriptionStyled,
  ProductAttributesSection,
  ImgContainer,
  H1NoGutter,
  LeftPanel,
  CheckIcon,
  CloseIcon,
  SelectStyled,
  SelectContainer,
  AlertBox,
  AlertBoxV2,
  HideAt900,
  BorderText,
  InnerTitle,
  ListInnerTitle,
  Title,
  ListTitle,
  SubTitle,
  AuthResultPanelSection,
  TextCenter,
  PBiggerFont,
  FlexContainerRow,
  StationWrapper,
  DividerLeftMiddleRight,
  FlexContainerColumn,
  ScanInputContainer,
  Header,
  SideNavText,
  ConditionSectionContainer,
  ModalSubmitContent,
  ModalCardContent,
  OpenIconContainer,
  ItemCardContentPaper,
  ItemCardImage,
  ItemCardImg,
  DivStyled,
  FloatButtonStyled,
  ClickableFab,
  DescriptionSectionStyledContainer,
  EntrupySectionStyledContainer,
  ImgHandBag,
  TabSelector,
  StyledGrid,
  AlertChip,
};
