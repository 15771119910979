const itemDetails = (item = null, order = null) => {
  const objectToCheck = item || order;

  let itemUrl = '';
  let imageUrls = [];
  let itemValues = [];
  let itemCondition = '';
  let title = '';
  let description = '';
  let authenticationLevel = '';
  let psaMode = '';
  let whiteGlove = '';
  let supervisedAuthentication = '';
  let nfcTagId = '';
  let nfcTagUrl = '';

  itemUrl =
    objectToCheck?.ebayItem?.itemUrl ||
    objectToCheck?.order?.ebayOrder?.itemUrl ||
    objectToCheck?.ebayOrder?.itemUrl ||
    (objectToCheck?.items?.length > 0 &&
      objectToCheck?.items[0].ebayItem?.itemUrl);
  imageUrls =
    objectToCheck?.ebayItem?.imageUrls ||
    objectToCheck?.order?.imageUrls ||
    objectToCheck?.order?.ebayOrder?.imageUrls ||
    objectToCheck?.ebayOrder?.imageUrls ||
    (objectToCheck?.items?.length > 0 &&
      objectToCheck?.items[0].ebayItem?.imageUrls);
  itemValues = objectToCheck?.itemValues?.length
    ? objectToCheck?.itemValues
    : objectToCheck?.order?.itemValues;
  itemCondition =
    objectToCheck?.ebayItem?.itemCondition ||
    objectToCheck?.order?.ebayOrder?.itemCondition ||
    objectToCheck?.ebayOrder?.itemCondition;
  title =
    objectToCheck?.ebayItem?.title ||
    objectToCheck?.order?.ebayOrder?.title ||
    objectToCheck?.ebayOrder?.title;
  description =
    objectToCheck?.ebayItem?.description ||
    objectToCheck?.order?.ebayOrder?.description ||
    objectToCheck?.ebayOrder?.description;
  authenticationLevel =
    objectToCheck?.ebayItem?.authenticationLevel ||
    objectToCheck?.order?.ebayOrder?.authenticationLevel ||
    objectToCheck?.ebayOrder?.authenticationLevel;
  psaMode =
    objectToCheck?.ebayItem?.psaMode ||
    objectToCheck?.order?.ebayOrder?.psaMode ||
    objectToCheck?.ebayOrder?.psaMode;
  whiteGlove =
    objectToCheck?.order?.ebayOrder?.whiteGlove ||
    objectToCheck?.ebayOrder?.whiteGlove;
  supervisedAuthentication =
    objectToCheck?.order?.ebayOrder?.supervisedAuthentication ||
    objectToCheck?.ebayOrder?.supervisedAuthentication;
  nfcTagId =
    objectToCheck?.nfcTag?.tagId || objectToCheck?.order?.nfcTag?.tagId;
  nfcTagUrl =
    objectToCheck?.nfcTag?.tagUrl || objectToCheck?.order?.nfcTag?.tagUrl;

  const imageUrlsMapped = imageUrls
    ? imageUrls?.map((imageUrl, index) => ({
        id: `image${index + 1}Id`,
        src: imageUrl,
      }))
    : [];

  return {
    itemUrl,
    imageUrls,
    itemValues,
    itemCondition,
    title,
    description,
    authenticationLevel,
    psaMode,
    whiteGlove,
    supervisedAuthentication,
    nfcTagId,
    nfcTagUrl,
    imageUrlsMapped,
  };
};

export default itemDetails;
