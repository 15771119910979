import gql from 'graphql-tag';

const predefinedNotesConnectionGql = gql`
  query predefinedNotes(
    $filters: PredefinedNotesFilters!
    $stationId: ID
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    categories {
      id
      name
    }
    partnerLocations {
      id
      name
      categoryId
    }
    predefinedNotes(
      filters: $filters
      stationId: $stationId
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      edges {
        cursor
        node {
          id
          gid
          createdAt
          description
          categoryId
          category {
            id
            name
          }
          partnerLocationId
          partnerLocation {
            id
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export default predefinedNotesConnectionGql;
