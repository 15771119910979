import styled from 'styled-components';
import { TextField } from '@material-ui/core';

export const TextFieldFillHeight = styled(TextField)`
  > div {
    border-radius: 0;
  }
  & > div {
    height: -webkit-fill-available;
  }
  & textarea {
    height: 100%;
  }
`;

export default TextFieldFillHeight;
