import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputSelect from '../../../reusables/inputs/InputSelect';

const RecursiveSelect = (props) => {
  const {
    options,
    setLastSelected,
    subOptionIndex,
    name,
    label,
    isIntercepted,
  } = props;

  const getDefaultOptions = () => {
    if (options?.length > 0) {
      if (isIntercepted)
      {
        return (
            options.find((elem) => elem.value === 'Intercepted Order') || options[0]
        );
      }
      return options.find((elem) => elem.value === 'PSNAD') || options[0] ;
    }
    return options[0];
  };

  const [selectedOption, setSelectedOption] = useState(getDefaultOptions());

  const handleChange = (id) => {
    const choice = options.find((opt) => opt.id === id);
    setLastSelected(choice);
    setSelectedOption(choice);
  };

  const hasChildren = () => selectedOption?.childOptions?.length > 0;

  if (options?.length > 0)
    return (
      <div>
        <InputSelect
          margin="normal"
          options={options}
          label={
            label && hasChildren() ? `${label} - L${subOptionIndex}` : label
          }
          name={hasChildren() ? `${name} - L${subOptionIndex}` : name}
          value={selectedOption?.id}
          defaultValue={selectedOption?.id}
          setValue={handleChange}
          fullWidth
        />
        {hasChildren() && (
          <RecursiveSelect
            name={label || name}
            options={selectedOption.childOptions}
            setLastSelected={setLastSelected}
            subOptionIndex={subOptionIndex + 1}
          />
        )}
      </div>
    );
  return null;
};

const optionType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  childOptions: PropTypes.arrayOf(PropTypes.shape({})),
});

RecursiveSelect.defaultProps = {
  options: [],
  subOptionIndex: 1,
  label: null,
  isIntercepted: false,
};

RecursiveSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  subOptionIndex: PropTypes.number,
  options: PropTypes.arrayOf(optionType),
  setLastSelected: PropTypes.func.isRequired,
  isIntercepted: PropTypes.bool,
};

export default RecursiveSelect;
