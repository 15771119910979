import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import {
  Button,
  COLORS as SHARED_COLORS,
  PhotoCarousel,
} from '@codeboxxtechschool/ginza_shared_components';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { QUERY_EXCEPTIONS } from '../../../../graphql/queries';
import useStyles from './useStyles';
import DescriptionList from '../../../ginzaV2/reusables/descriptionList';
import PopupModal from '../../../ginzaV2/reusables/popupModal';
import Station from '../../../ginzaV2/reusables/station';
import LoadingAnimation from '../../../modals/LoadingAnimation';
import useQueryStation from '../../QualityControl/hooks/useQueryStation';
import { getStationIdByName } from '../../../../utils/stationsInfo';
import { useStateValue } from '../../../../providers/StateProvider';
import {
  PSNAD_STATION_ITEM_RESULT,
  RESOLVE_PSNAD,
  SUBMIT_PSNAD,
} from '../../../../graphql/mutations';
import ConditionDefinitionModal from '../../../ginzaV2/reusables/conditionDefinitionModal';
import useConditionDefinitionModal from '../../../ginzaV2/reusables/conditionDefinitionModal/hooks/useConditionDefinitionModal';
import ExceptionResolverModal from '../../../ginzaV2/reusables/exceptionResolverModal';
import {
  initItemData,
  PsnadAutomationService,
} from '../services/PsnadAutomationService';
import PsnadReadForm from './PsnadReadForm';
import PsnadSubmissionForm from './PsnadSubmissionForm';
import FloatingNotes from '../../../reusables/floatingNotes';
import GcxInput from './GcxInput';
import { PSNAD_STATUSES } from '../../../../constants';
import useStorage from '../../Exception/hooks/useGetStorage';
import useImsLocations from '../../Exception/hooks/useImsLocations';
import {NoImageViewer } from '../../../reusables/imageViewerCarousel';

const zoomOptions = {
  height: 380,
  offset: {
    horizontal: -20,
    vertical: 40,
  },
  scale: 1,
  width: 512,
  zoomWidth: 440,
};

const PsnadDetailsPage = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  const history = useHistory();
  const { objectExceptionId } = useParams();

  const { renderItemConditionListItem } = useConditionDefinitionModal();
  const { data: stationData, loading: loadingStationData } = useQueryStation({
    withInputValues: false,
  });

  const currentPartnerLocation = JSON.parse(
    sessionStorage.getItem('partnerLocation')
  );
  const { data, loading } = useQuery(QUERY_EXCEPTIONS, {
    variables: {
      id: objectExceptionId,
      relatedObjectType: 'Order',
      partnerLocationIds: [currentPartnerLocation?.id],
      forPackStation: false,
      withId: false,
      openExceptionOnly: true,
    },
    fetchPolicy: 'no-cache',
  });
  const [psnadStationItemResult, { loading: loadingStationItemResult }] =
    useMutation(PSNAD_STATION_ITEM_RESULT);
  const [submitPsnad, { loading: loadingSubmit }] = useMutation(SUBMIT_PSNAD);
  const [resolvePsnad, { loading: loadingResolve }] =
    useMutation(RESOLVE_PSNAD);

  const [{ stationList, userStationList }, dispatch] = useStateValue();
  const psnadStationId = getStationIdByName(stationList, 'PSNAD');

  const [exception, setException] = useState(null);
  const [itemMetadata, setItemMetadata] = useState(initItemData);
  const [psnadMetadata, setPsnadMetadata] = useState([]);
  const [openModal, setOpenModal] = useState({
    isOpen: false,
    type: '',
  });
  const [psnadForm, setPsnadForm] = useState([]);
  const [notification, setNotification] = useState(null);
  const [resolver, setResolver] = useState({
    type: '',
    title: '',
    resolverBtnTxt: '',
    resolutionOption: { key: '', value: '' },
    predefinedNotes: [],
  });

  const [shouldBeDisabled, setShouldBeDisabled] = useState(false);
  useEffect(() => {}, [shouldBeDisabled]);

  const { imsEnabled } = useImsLocations();
  const { storageDetails } = useStorage([objectExceptionId], imsEnabled);

  const hasStationAccess = () =>
    !!userStationList.find((station) => station.name === 'PSNAD');

  const isActionablePsnad = (_exception) =>
    [
      PSNAD_STATUSES.accepted,
      PSNAD_STATUSES.rejected,
      PSNAD_STATUSES.notEligible,
    ].includes(_exception?.psnadException.status);

  const handleUpdateListItems = (topLevelTitle) => (updatedSecondLevel) => {
    const updatedPsnadForm = [...psnadForm];
    const topLevelIndex = psnadForm.findIndex(
      (topLevel) => topLevel.title === topLevelTitle
    );

    if (topLevelIndex !== -1) {
      updatedPsnadForm[topLevelIndex].childInputs = updatedSecondLevel;
      setPsnadForm(updatedPsnadForm);
    }
  };

  const showModal = (type) => () => setOpenModal({ isOpen: true, type });

  const closeModal = () => setOpenModal({ isOpen: false, type: '' });

  const showPsnadResolver = (resolverType) => {
    const service = PsnadAutomationService();
    setResolver({
      type: resolverType,
      ...service.resolver(resolverType),
    });

    showModal('resolvePsnad')();
  };

  const createNotificationBanner = (notif) => setNotification(notif);

  const goToPsnadList = () => {
    history.push(`/stations/${psnadStationId}`);
  };

  const resetStation = () => {
    closeModal();
    goToPsnadList();
  };

  const handleSavePsnadAnswer = () => {
    const services = PsnadAutomationService();
    const checkedPsnad = services.form.getSelectedInputIds(psnadForm);
    return psnadStationItemResult({
      variables: {
        objectExceptionId,
        psnadInputIds: checkedPsnad,
      },
    })
      .then((res) => ({
        success: true,
        data: res.data.psnadStationItemResult,
        errorCode: null,
      }))
      .catch((e) => ({
        success: false,
        errorCode: e.graphQLErrors[0].extensions.code,
      }));
  };

  const handlePsnadSubmission = () => {
    return submitPsnad({
      variables: { objectExceptionId },
    })
      .then((res) => ({
        success: true,
        data: res.data.submitPsnad,
        errorCode: null,
      }))
      .catch((e) => ({
        success: false,
        errorCode: e.graphQLErrors[0].extensions.code,
      }));
  };

  const handleResolvePsnad = (resolveType, note) => {
    return resolvePsnad({
      variables: {
        objectExceptionId,
        resolveType,
        note,
      },
    })
      .then((_res) => ({
        success: true,
        data: _res.data.resolvePsnad,
        errorCode: null,
      }))
      .catch((e) => ({
        success: false,
        data: null,
        errorCode: e.graphQLErrors[0].extensions.code,
      }));
  };

  const dispatchError = (errorCode) =>
    setNotification({
      type: 'error',
      message: t(`PSNAD.Notification.Error.${errorCode}`),
    });

  const onExit = async () => {
    const psnadAnswerSaved = await handleSavePsnadAnswer();
    if (!psnadAnswerSaved.success)
      return dispatchError('UnableToSavePsnadAnswer');

    return goToPsnadList();
  };

  const onSubmit = async () => {
    const psnadAnswerSaved = await handleSavePsnadAnswer();
    if (!psnadAnswerSaved.success)
      return dispatchError('UnableToSavePsnadAnswer');

    const submissionResponse = await handlePsnadSubmission();
    if (!submissionResponse.success)
      return dispatchError(submissionResponse.errorCode);

    const { isEligible } = submissionResponse.data;
    if (isEligible) {
      dispatch({
        type: 'changeAlert',
        newAlert: {
          type: 'success',
          message: t('PSNAD.Notification.Success.PsnadSubmitted', {
            lpn: itemMetadata?.lpn,
          }),
        },
      });
      return goToPsnadList();
    }

    // display PSNAD resolver for cancelled order (Not eligible);
    return showPsnadResolver(PSNAD_STATUSES.notEligible);
  };

  const onResolvePsnad = async (note, resolutionAction) => {
    const res = await handleResolvePsnad(resolutionAction, note);

    if (res.success) {
      return resetStation();
    }

    closeModal();
    return dispatchError('UnableToResolvePsnad');
  };

  useEffect(() => {
    if (!hasStationAccess()) history.push('/');
  }, []);

  useEffect(() => {
    /* Logic to parse queryException response for item metadata, exception metadata,
     and pre-fill of submission form based on QC answers */
    if (loading) return;

    if (data && stationData?.queryStation) {
      const { queryStation } = stationData;
      const exceptionData = data.queryExceptions[0];
      setException(exceptionData);
      setResolver((prev) => ({
        ...prev,
        type: exceptionData.psnadException.status,
      }));

      if (!exceptionData) history.push('/');

      const services = PsnadAutomationService(
        exceptionData,
        queryStation,
        storageDetails?.[0]?.item?.location?.identifier
      );

      setItemMetadata(services.metadata.item());
      setPsnadMetadata(services.metadata.psnad());

      const form = exceptionData.psnadException.submittedAt
        ? services.form.getSelectedPsnadList()
        : services.form.getPsnadList();

      setPsnadForm(form);
    }
  }, [data, loading, stationData]);

  useEffect(() => {
    const isLoading =
      loadingSubmit || loadingResolve || loadingStationItemResult;

    dispatch({
      type: 'changeLoading',
      newLoading: !!isLoading,
    });
  }, [loadingSubmit || loadingResolve || loadingStationItemResult]);

  if (loading || loadingStationData) return <LoadingAnimation />;

  return (
    <>
      <FloatingNotes
        top={90}
        overWriteItemId={itemMetadata.itemId}
        overWriteOrderId={itemMetadata.orderId}
      />
      <Station.TwoColumns
        header={
          <Typography variant="h5">
            LPN: <strong>{itemMetadata.lpn}</strong>
            {' | '}
            EVTN: <strong>{itemMetadata.evtn}</strong>
          </Typography>
        }
        leftColumn={
          <div data-testid="left-column-content">
            <div className={classes.carouselContainer}>
              <div className={classes.linkContainer}>
                <Typography variant="body1">
                  <a target="_blank" href={itemMetadata.url} rel="noreferrer">
                    {t('GinzaV2.SeeItemOnEbay')}
                  </a>
                </Typography>
              </div>
              {(itemMetadata.imageUrls?.length > 0 && (
                <PhotoCarousel
                  data-testId="photo-carousel"
                  id="photo-carousel"
                  hoverText="Hover to Zoom"
                  images={itemMetadata.imageUrls}
                  zoomOptions={zoomOptions}
                />
              )) || <NoImageViewer />}
            </div>
            <Typography
              data-testid="item-title"
              style={{ marginBottom: '12px' }}
              variant="h5"
            >
              {itemMetadata.title}
            </Typography>
            <DescriptionList
              data-testid="item-specifics-section"
              title={t('PSNAD.ItemSpecifics')}
              withTitleContainer={false}
              list={itemMetadata.attributes}
              customListItem={renderItemConditionListItem(
                itemMetadata.condition,
                showModal('conditionModal')
              )}
            />
          </div>
        }
        rightColumn={
          <div
            data-testid="right-column-content"
            className={classes.rightContainer}
          >
            <div className={classes.gridContainer}>
              <GcxInput
                exceptionId={objectExceptionId}
                url={data?.queryExceptions[0]?.gcxTicketUrl}
                onError={dispatchError}
                data-testid="gcx-input"
              />
              <div />
            </div>
            <DescriptionList
              title={t('PSNAD.PsnadDetails.Title')}
              list={psnadMetadata}
            />
            {exception && exception.psnadException.submittedAt ? (
              <PsnadReadForm
                psnadForm={psnadForm}
                itemMetadata={itemMetadata}
                exception={exception}
                objectExceptionId={objectExceptionId}
              />
            ) : (
              <PsnadSubmissionForm
                psnadForm={psnadForm}
                itemMetadata={itemMetadata}
                handleUpdateListItems={handleUpdateListItems}
                createNotificationBanner={createNotificationBanner}
                setShouldBeDisabled={setShouldBeDisabled}
              />
            )}
          </div>
        }
        footerButtons={
          <div
            data-testid="footer-main-container"
            className={classes.rightFooterButtonsContainer}
          >
            {exception && exception.psnadException.submittedAt ? (
              <>
                <div />
                <Button
                  fullWidth
                  outlined
                  backgroundColor={SHARED_COLORS.white}
                  borderColor={SHARED_COLORS.black}
                  textColor={SHARED_COLORS.black}
                  onClick={goToPsnadList}
                  data-testid="exit-button"
                >
                  <span>{t('GinzaV2.Button.Exit')}</span>
                </Button>
                <Button
                  fullWidth
                  disabled={!isActionablePsnad(exception)}
                  backgroundColor={
                    !isActionablePsnad(exception)
                      ? SHARED_COLORS.gray400
                      : SHARED_COLORS.blue500
                  }
                  onClick={() => showPsnadResolver(resolver.type)}
                  data-testid="exit-button"
                >
                  <span>{t('GinzaV2.Button.Resolve')}</span>
                </Button>
              </>
            ) : (
              <>
                <Button
                  fullWidth
                  outlined
                  backgroundColor={SHARED_COLORS.white}
                  borderColor={SHARED_COLORS.black}
                  textColor={SHARED_COLORS.black}
                  onClick={onExit}
                  data-testid="save-and-exit-button"
                  disabled={shouldBeDisabled}
                >
                  <span>{t('GinzaV2.Button.SaveExit')}</span>
                </Button>
                <Button
                  fullWidth
                  backgroundColor={SHARED_COLORS.blue500}
                  onClick={onSubmit}
                  data-testid="submit-button"
                  disabled={shouldBeDisabled}
                >
                  <span>{t('GinzaV2.Button.Submit')}</span>
                </Button>
                <Button
                  fullWidth
                  backgroundColor={SHARED_COLORS.red500}
                  onClick={() => showPsnadResolver('none')}
                  data-testid="send-to-QC-button"
                  disabled={shouldBeDisabled}
                >
                  <span>{t('GinzaV2.Button.SendToQc')}</span>
                </Button>
              </>
            )}
          </div>
        }
        notification={notification}
        setNotification={setNotification}
      />
      {openModal.type === 'conditionModal' && (
        <ConditionDefinitionModal
          category="handbag"
          onClose={closeModal}
          isOpen={openModal.isOpen}
        />
      )}
      {resolver.type && openModal.type === 'resolvePsnad' && (
        <PopupModal
          title={t(`PSNAD.${resolver.title}.Title`)}
          subtitle={t(`PSNAD.${resolver.title}.Subtitle`)}
          openModal={openModal.isOpen}
          onClose={
            resolver.type === PSNAD_STATUSES.notEligible &&
            !exception.psnadException.submittedAt
              ? null
              : closeModal
          }
        >
          <ExceptionResolverModal
            resolveBtnText={resolver.resolverBtnTxt}
            usePredefinedNotes={resolver.predefinedNotes}
            resolutionOption={resolver.resolutionOption}
            exceptionId={objectExceptionId}
            onResolve={onResolvePsnad}
            onClose={closeModal}
            disableClose={
              resolver.type === PSNAD_STATUSES.notEligible &&
              !exception.psnadException.submittedAt
            }
          />
        </PopupModal>
      )}
    </>
  );
};

export default PsnadDetailsPage;
