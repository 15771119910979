import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import { COLORS } from '../../../../constants';

export const StyledAttributesTextField = styled(TextField)`
  margin: 12px 8px 12px 8px !important;
`;

export const StyledIconButton = styled(Button)`
  border: 2px solid black !important;
  background-color: black !important;
  color: #fff !important;
  border-left: 0 !important;
  border-radius: 0 !important;
`;

export const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: -3,
    'font-weight': '800',
  },
  '& .MuiBadge-colorPrimary': {
    'background-color': COLORS.red,
  },
}));
