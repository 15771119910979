import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { ButtonProvider } from './ButtonContext';

export const FooterContext = createContext({});

export const FooterProvider = ({ children }) => {
  return (
    <FooterContext.Provider value={{}}>
      <ButtonProvider>{children}</ButtonProvider>
    </FooterContext.Provider>
  );
};

FooterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
