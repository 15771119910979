import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';

const InputText = React.forwardRef((props, parentRef) => {
  const {
    control,
    fieldsErrors,
    label,
    name,
    rows,
    placeholder,
    margin,
    type,
    validation,
    required,
    onReset,
    readOnly,
    setCapsLock,
    capsLock,
    dataTestId,
    pattern,
  } = props;
  const { t } = useTranslation();
  const isCapsLock = () => capsLock.isCapsLock && capsLock.isEnabled;

  const isRequired = () => {
    if (required)
      return { required: (val) => val !== '' || t('Error.EmptyField') };
    return {};
  };

  const handleOnReset = () => {
    onReset();
  };

  const handleCapsLockToggle = (e) => {
    setCapsLock({ ...capsLock, isCapsLock: e.getModifierState('CapsLock') });
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={{
        pattern: {
          ...pattern,
        },
        validate: {
          ...validation,
          ...isRequired(),
        },
      }}
      render={({ onChange, ref, value }) => (
        <TextField
          data-testid={dataTestId}
          onKeyUp={(e) => handleCapsLockToggle(e)}
          onKeyDown={(e) => handleCapsLockToggle(e)}
          type={type}
          margin={margin}
          autoComplete="on"
          autoFocus
          label={label}
          id={`${name}-input-text`}
          placeholder={placeholder === '' ? placeholder : null}
          value={value}
          inputRef={(ref, parentRef)}
          onChange={(e) => {
            if (isCapsLock()) return;
            onChange(e.target.value);
          }}
          error={!!fieldsErrors[name] || isCapsLock()}
          helperText={
            (fieldsErrors[name] ? fieldsErrors[name].message : null) ||
            (isCapsLock() ? t('Error.CapsLockUsage') : null)
          }
          fullWidth
          multiline={!!rows}
          minRows={rows}
          variant="outlined"
          disabled={readOnly}
          InputProps={{
            endAdornment: onReset ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="reset text field"
                  onClick={handleOnReset}
                  edge="end"
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        />
      )}
    />
  );
});

export default InputText;

InputText.defaultProps = {
  rows: 0,
  placeholder: '',
  type: 'text',
  validation: {},
  required: false,
  margin: 'normal',
  readOnly: false,
  onReset: null,
  setCapsLock: () => {},
  capsLock: { isCapsLock: false, isEnabled: false },
  dataTestId: '',
  pattern: {},
};

InputText.propTypes = {
  required: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  margin: PropTypes.string,
  /* eslint-disable react/forbid-prop-types */
  control: PropTypes.object.isRequired,
  fieldsErrors: PropTypes.object.isRequired,
  validation: PropTypes.object,
  onReset: PropTypes.func,
  readOnly: PropTypes.bool,
  setCapsLock: PropTypes.func,
  capsLock: PropTypes.shape({
    isCapsLock: PropTypes.bool,
    isEnabled: PropTypes.bool,
  }),
  dataTestId: PropTypes.string,
  pattern: PropTypes.object,
};
