const ContextReducer = () => {
  return (state, action) => {
    switch (action.type) {
      case 'changeCurrentUserId':
        return {
          ...state,
          currentUserId: action.newCurrentUserId,
        };
      case 'changeUserEmail':
        return {
          ...state,
          userEmail: action.newUserEmail,
        };
      case 'changeAuthToken':
        return {
          ...state,
          authToken: action.newAuthToken,
        };
      case 'changeRefreshToken':
        return {
          ...state,
          refreshToken: action.newRefreshToken,
        };
      case 'changeTokenExpiryTime':
        return {
          ...state,
          tokenExpiryTime: action.newTokenExpiryTime,
        };
      case 'changeCurrentStationId':
        return {
          ...state,
          currentStationId: action.newCurrentStationId,
        };
      case 'changeRoleList':
        return {
          ...state,
          roleList: action.newRoleList,
        };
      case 'changeAdminCategoriesList':
        return {
          ...state,
          adminCategoriesList: action.newAdminCategoriesList,
        };
      case 'changeUserStationList':
        return {
          ...state,
          userStationList: action.newUserStationList,
        };
      case 'changeStationList':
        return {
          ...state,
          stationList: action.newStationList,
        };
      case 'changePopup':
        return {
          ...state,
          popup: action.newPopup,
        };
      case 'changeAlert':
        return {
          ...state,
          alert: action.newAlert,
        };
      case 'changeIsRMA':
        return {
          ...state,
          isRMA: action.newIsRMA,
        };
      case 'changeFlagTypeAndValue':
        return {
          ...state,
          flagTypeAndValue: action.newFlagTypeAndValue,
        };
      case 'changeLoading':
        return {
          ...state,
          loading: action.newLoading,
        };
      case 'changeCurrentItemInfo':
        return {
          ...state,
          currentItemInfo: action.newCurrentItemInfo,
        };
      case 'changeMiscategorized':
        return {
          ...state,
          miscategorized: action.newMiscategorized,
        };
      case 'changeOrderId':
        return {
          ...state,
          orderId: action.newOrderId,
        };
      case 'changeItemId':
        return {
          ...state,
          orderId: action.newOrderId,
          itemId: action.newItemId,
        };
      case 'changeOrderExceptions':
        return {
          ...state,
          orderExceptions: action.newOrderExceptions,
        };
      case 'changePartnerLocation':
        return {
          ...state,
          partnerLocation: action.newPartnerLocation,
          alitheonRegistrationStation:
            action.newPartnerLocation.alitheonRegistrationStation,
          alitheonAuthenticationStation:
            action.newPartnerLocation.alitheonAuthenticationStation,
        };
      case 'changePartnerLocations':
        return {
          ...state,
          partnerLocations: action.newPartnerLocations,
        };
      case 'changeCurrentPartnerLocationConfigs':
        return {
          ...state,
          currentPartnerLocationConfigs: action.currentPartnerLocationConfigs,
        };
      case 'changeCategories':
        return {
          ...state,
          categories: action.newCategories,
        };
      case 'changeSelectedPrintersId':
        return {
          ...state,
          selectedPrintersId:
            typeof action.newSelectedPrintersId === 'function'
              ? action.newSelectedPrintersId(state.selectedPrintersId)
              : action.newSelectedPrintersId,
        };
      case 'changeSelectedLabels':
        return {
          ...state,
          selectedLabels:
            typeof action.newSelectedLabels === 'function'
              ? action.newSelectedLabels(state.selectedPrintersId)
              : action.newSelectedLabels,
        };
      case 'openException': {
        return {
          ...state,
          scannedString: action.lpnOrTrackingEvtn || action.scannedString,
        };
      }
      case 'changeUserIdentification':
        return {
          ...state,
          userIdentification: action.newUserIdentification,
        };
      case 'changeReadOnlyException':
        return {
          ...state,
          readOnlyException: action.newReadOnlyException,
        };
      case 'changeIsLiveEventShippingMain':
        return {
          ...state,
          isLiveEventShippingMain: action.newIsLiveEventShippingMain,
        };
      case 'changeSelectedSnadIds':
        return {
          ...state,
          selectedSnadIds: action.newSelectedSnadIds,
        };
      default:
        return state;
    }
  };
};

export default ContextReducer;
