export default function mapStorageInformation({ zoneCategory, zoneStatuses }) {
  return {
    zoneCategory: {
      id: zoneCategory.id,
      name: zoneCategory.name,
      handle: zoneCategory.handle,
    },
    zoneStatuses: zoneStatuses.map(({ id, name, handle }) => ({
      id,
      name,
      handle,
    })),
  };
}
