import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { QUERY_EXCEPTION_FLAG_VALUES } from '../../../../graphql/queries';
import Loading from '../../../reusables/loading';
import RecursiveSelect from './RecursiveSelect';
import associateChildrenCodes from './utils/associateChildrenCodes';
import { COLORS } from '../../../../constants';

const ExceptionCodeSelector = (props) => {
  const { setSelectedCodeId, setExceptionReasonsConfigured, isIntercepted } =
    props;
  const { t } = useTranslation();
  const [exceptionCodeOptions, setExceptionCodeOptions] = useState([]);
  const { loading, data } = useQuery(QUERY_EXCEPTION_FLAG_VALUES, {
    variables: {
      stationId: sessionStorage.getItem('stationId'),
      categoryId: JSON.parse(sessionStorage.getItem('partnerLocation'))
        ?.categoryId,
    },
  });

  const parseExceptionCode = () => {
    const parsedExceptionCodeSelect = associateChildrenCodes(
      data.queryExceptionCodes.map((exceptionCode) => ({
        value: exceptionCode.name,
        id: exceptionCode.id,
        parentCodeId: exceptionCode.parentCodeId,
      }))
    );

    const parseExceptionCodeOptions = parsedExceptionCodeSelect.filter(
      (elem) => elem.value !== 'Miscategorized'
    );
    setExceptionCodeOptions(parseExceptionCodeOptions);
  };

  useEffect(() => {
    if (data?.queryExceptionCodes) {
      parseExceptionCode();
    }
  }, [data]);

  if (exceptionCodeOptions?.length === 0) {
    return (
      <Typography variant="h5" align="center">
        {t('ExceptionPopup.Error.NoReasonsConfigured')}
      </Typography>
    );
  }
  if (loading) {
    return <Loading color={COLORS.white} />;
  }
  if (exceptionCodeOptions?.length > 0) {
    return (
      <RecursiveSelect
        name="selectExceptionCode"
        label={t('ExceptionPopup.SelectCode')}
        options={exceptionCodeOptions}
        setLastSelected={setSelectedCodeId}
        isIntercepted={isIntercepted}
        setExceptionReasonsConfigured={setExceptionReasonsConfigured(true)}
      />
    );
  }
  return null;
};

ExceptionCodeSelector.defaultProps = {
  isIntercepted: false,
};

ExceptionCodeSelector.propTypes = {
  setSelectedCodeId: PropTypes.func.isRequired,
  setExceptionReasonsConfigured: PropTypes.func.isRequired,
  isIntercepted: PropTypes.bool,
};

export default ExceptionCodeSelector;
