import { LocationInputs } from '@codeboxxtechschool/ginza_shared_components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  LocationInputLabel,
  LocationInputWrapper,
} from './LabelledLocationInput.styles';

const LabelledLocationInput = ({ onChange, error }) => {
  const { t } = useTranslation();

  return (
    <LocationInputWrapper>
      <LocationInputLabel>
        {t('StorageLocationInput.StorageLocation')}
      </LocationInputLabel>
      <LocationInputs onChange={onChange} />
      {error}
    </LocationInputWrapper>
  );
};

export default LabelledLocationInput;

LabelledLocationInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  error: PropTypes.elementType.isRequired,
};
