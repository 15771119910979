import styled from 'styled-components';
import { Popper } from '@material-ui/core';

export const StyledPopper = styled(Popper)`
  padding: 8px;
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  color: ${({ color }) => color};
  opacity: 0.7;
  width: fit-content;
  z-index: 1301;
`;
StyledPopper.displayName = 'StyledPopper';

export default StyledPopper;
