import styled from 'styled-components';
import { COLORS } from '@codeboxxtechschool/ginza_shared_components';

export const InputVerificationCodeContainer = styled('div')`
  --ReactInputVerificationCode-itemWidth: 2.5rem;
  --ReactInputVerificationCode-itemHeight: 3.5rem;
  .ReactInputVerificationCode__item {
    position: relative;
    color: ${COLORS.black};
    font-weight: 500;
  }
  .ReactInputVerificationCode__item,
  .ReactInputVerificationCode__item.is-active {
    padding: 4px;
  }
  .ReactInputVerificationCode__item:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 2px;
    background-color: #6bafff;
    transition: background-color 0.2s ease-out;
  }
  .ReactInputVerificationCode__item.is-active:after {
    background-color: ${COLORS.blue};
  }
  margin-bottom: 20px;
`;

export const PasswordContainer = styled('div')({
  width: '325px',
  height: '85px',
});

export const VerificationCodeWrapper = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const EnterCodeText = styled('span')({
  alignSelf: 'baseline',
  top: -5,
  position: 'relative',
});

export const ErrorMessage = styled('div')({
  color: COLORS.red,
  alignSelf: 'center',
  width: 'auto',
  padding: '20px',
  fontWeight: 'bold',
});

export const SuccessMessage = styled('div')({
  color: COLORS.green,
  alignSelf: 'center',
  width: 'auto',
  padding: '20px',
  fontWeight: 'bold',
});
