export const NOT_FOUND = 1;
export const UNAUTHORIZED = 2;

export const getErrorCode = (statusCode) => {
  switch (statusCode) {
    case 404:
      return NOT_FOUND;
    case 401:
      return UNAUTHORIZED;
    default:
      return 0;
  }
};
