import { useIsFetching } from '@tanstack/react-query';

export default function GlobalLoadingIndicator() {
  const isFetching = useIsFetching();

  if (isFetching) {
    return null;
  }
  return null;
}
