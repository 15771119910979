import React, { lazy, Suspense, useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useStateValue } from '../providers/StateProvider';
import {
  AlertBox,
  AlertBoxV2,
  DividerLeftMiddleRight,
  DivStyled,
  HideAt900,
} from './reusables/StyledComponent';
import { getStationIdByName } from '../utils/stationsInfo';
import StatusPill from './reusables/statusPill';
import { COLORS } from '../constants';

const BuildStation = ({ resetStation, setResetStation }) => {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [showRMA, setShowRMA] = useState(false);

  const [
    { userStationList, alert, isRMA, partnerLocation, stationList },
    dispatch,
  ] = useStateValue();

  const getStationComponent = (name) =>
    lazy(() => import(`./stations/${name}/${name}.jsx`));

  const getStationInfo = () => {
    let currentStation = userStationList.find((sta) => id === sta.id) || false;
    if (currentStation)
      currentStation = {
        ...currentStation,
        component: getStationComponent(currentStation.name),
      };
    return currentStation;
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const previousPartnerId = usePrevious(partnerLocation?.id);

  const [validStation, setValidStation] = useState(getStationInfo());

  const resetIsLiveEventShippingMain = () => {
    dispatch({
      type: 'changeIsLiveEventShippingMain',
      newIsLiveEventShippingMain: false,
    });
  };

  const throwBack = () => {
    sessionStorage.setItem('stationId', null);
    dispatch({ type: 'changeCurrentStationId', newCurrentStationId: null });
    resetIsLiveEventShippingMain();
    history.push('/');
  };

  const setStation = () => {
    sessionStorage.setItem('stationId', id);
    dispatch({ type: 'changeCurrentStationId', newCurrentStationId: id });
    resetIsLiveEventShippingMain();
  };

  useEffect(() => {
    dispatch({ type: 'changeIsRMA', newIsRMA: false });
    setValidStation(getStationInfo());
  }, [id]);

  useEffect(() => {
    if (!partnerLocation?.id) {
      throwBack();
      return;
    }

    if (previousPartnerId && partnerLocation.id !== previousPartnerId) {
      resetStation();
    }
    if (validStation) setStation();
    if (!validStation) throwBack();
  }, [validStation, partnerLocation]);

  useEffect(() => {
    setShowRMA(isRMA);
  }, [isRMA]);

  useEffect(() => {
    if (alert)
      setTimeout(
        () => dispatch({ type: 'changeAlert', newAlert: false }),
        6000
      );
  }, [alert]);

  if (!validStation || !partnerLocation?.id) return null;

  const isHistoryStation = id === getStationIdByName(stationList, 'History');
  const isAdminStation = id === getStationIdByName(stationList, 'Admin');
  const isPsnadStation = id === getStationIdByName(stationList, 'PSNAD');
  const isQCStation = id === getStationIdByName(stationList, 'QualityControl');

  return (
    <Suspense fallback={<LinearProgress />}>
      <StationDivider footer={!isHistoryStation}>
        {!isPsnadStation && alert && (
          <AlertBox variant="filled" severity={alert.type}>
            {alert.message}
          </AlertBox>
        )}

        {isPsnadStation && alert && (
          <AlertBoxV2 variant="filled" severity={alert.type}>
            {alert.message}
          </AlertBoxV2>
        )}

        {!isAdminStation &&
          !isHistoryStation &&
          !isPsnadStation &&
          !isQCStation && (
            <>
              <DividerLeftMiddleRight>
                <HideAt900>
                  {showRMA && (
                    <DivStyled>
                      <StatusPill
                        text={t('BuildStation.RMAFlag')}
                        backgroundColor={COLORS.red}
                        textColor={COLORS.white}
                      />
                    </DivStyled>
                  )}
                </HideAt900>
              </DividerLeftMiddleRight>
            </>
          )}

        <validStation.component setReset={setResetStation} />
      </StationDivider>
    </Suspense>
  );
};

BuildStation.propTypes = {
  resetStation: PropTypes.func.isRequired,
  setResetStation: PropTypes.func.isRequired,
};

export default BuildStation;

const StationDivider = styled.div`
  overflow: inherit;
  position: relative;
  top: 80px;
  height: ${(props) => (props.footer ? 'calc(100% - 175px)' : 'initial')};
  display: flex;
  flex-direction: column;
`;
