import { getErrorCode } from './errorsCode';
import { putRequest, postRequest } from './httpClient';
import { STORAGE_LOCATION_PATH, STORAGE_PATH } from './routes';
import mapStorage from '../mapper/mapStorage';
import getErrorResult from './errorHandling';

export async function getStorage({ exceptionIds }) {
  const response = await postRequest(`${STORAGE_PATH}`, exceptionIds);

  if (response.error) {
    return getErrorResult(response);
  }

  if (response.data) {
    const storageDetails = mapStorage(response.data);

    return {
      data: { storageDetails },
    };
  }

  return { data: { storageDetails: null } };
}

export async function putStorage({
  exceptionId,
  hubIdentifier,
  locationString,
  zoneStatusHandle,
}) {
  const response = await putRequest(STORAGE_LOCATION_PATH, {
    exceptionId,
    fullLocation: { hubIdentifier, locationString },
    zoneStatusHandle,
  });

  if (response.error) {
    return {
      data: { success: false },
      error: { code: getErrorCode(response.error.statusCode) },
    };
  }

  if (response.data) {
    return {
      data: {
        success: true,
        data: response.data,
      },
    };
  }

  return { data: { success: false } };
}
