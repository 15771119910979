export const STATUS_COLORS = {
  green: 'green',
  orange: 'orange',
  red: 'red',
  blue: 'blue',
  white: 'white',
};

export default STATUS_COLORS;

// change for cherry pick
