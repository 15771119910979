const parseErrorMessage = (err) => {
  let errorCode = 'DefaultError';
  let fieldName = '';
  let typeName = '';
  let extractedValue = '';
  let trackingNumber = '';
  if (err.toString() === 'Error: noItem') errorCode = 'NoItem';
  else if (err?.graphQLErrors?.length > 0) {
    if (err.graphQLErrors[0].extensions) {
      errorCode = err.graphQLErrors[0].extensions.code || 'DefaultError';
      fieldName = err.graphQLErrors[0].extensions?.fieldName || '';
      typeName = err.graphQLErrors[0].extensions?.typeName || '';
      extractedValue = err.graphQLErrors[0].extensions.extractedValue || '';
      trackingNumber = err.graphQLErrors[0].extensions.trackingNumber || '';
    }
  }
  return { errorCode, fieldName, typeName, extractedValue, trackingNumber };
};

export default parseErrorMessage;
