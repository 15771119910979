/* eslint-disable no-unused-vars */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactInputVerificationCode from 'react-input-verification-code';
import { TextField, COLORS } from '@codeboxxtechschool/ginza_shared_components';
import VerifyButton from '../../components/buttons/verify';
import StepModel from '../stepModel';
// import BackupCodes from '../../BackupCodes';

import {
  InputVerificationCodeContainer,
  VerificationCodeWrapper,
  EnterCodeText,
  PasswordContainer,
  ErrorMessage,
  SuccessMessage,
} from './index.styles';
import { useMFAContext } from '../../../../context/MFAContext';
import { ROUTES, AUTHENTICATION_ROUTES } from '../../constants';

const codeDigitsLength = 6;

const DeviceVerification = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [value, setValue] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [responseMsgType, setResponseMsgType] = React.useState(0);

  const { enable2faOtp, properties2faOtp, isEnabled2faOpt } = useMFAContext();

  const onEnable2faOptEvent = async () => {
    setResponseMsgType(0);

    const response = await enable2faOtp({
      password,
      two_factor_id: properties2faOtp?.id,
      otp: value,
    });

    if (response?.message?.message === 'success') {
      setResponseMsgType(1);
      setTimeout(() => {
        history.push(
          `${ROUTES.authentication}/${AUTHENTICATION_ROUTES.security}/?redirectUrl=pSuccess`
        );
      }, 1000);
    } else {
      setResponseMsgType(2);
    }
  };

  return (
    <>
      <div spacing={2}>
        <StepModel
          stepNumber="3"
          title={t('account.security.2fa.step3.title')}
          content={t('account.security.2fa.step3.description')}
        >
          <VerificationCodeWrapper>
            <PasswordContainer>
              <TextField
                fullWidth
                label={`${t('account.security.2fa.step3.password')}*`}
                name="password"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                sx={{
                  background: COLORS.white,
                }}
                value={password}
                type="password"
              />
            </PasswordContainer>
            <InputVerificationCodeContainer>
              <EnterCodeText>
                {t('account.security.2fa.step3.enterCode')}*
              </EnterCodeText>
              <ReactInputVerificationCode
                autoFocus
                length={codeDigitsLength}
                placeholder=""
                onChange={setValue}
                // onCompleted={onEnable2faOptEvent}
                value={value}
              />
            </InputVerificationCodeContainer>

            <VerifyButton
              onClick={onEnable2faOptEvent}
              disabled={
                !(
                  codeDigitsLength === value.trim().length &&
                  password.trim().length > 0
                )
              }
            />
            {responseMsgType === 1 && (
              <SuccessMessage>
                {t('account.security.2fa.step3.successMessage')}
              </SuccessMessage>
            )}
            {responseMsgType === 2 && (
              <ErrorMessage>
                {t('account.security.2fa.step3.failureMessage')}
              </ErrorMessage>
            )}
          </VerificationCodeWrapper>
        </StepModel>
      </div>
      {/* {isEnabled2faOpt && <BackupCodes />} */}
    </>
  );
};

export default DeviceVerification;
