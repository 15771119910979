import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { StyledErrorText, StyledInputBase } from '../inputs.styles';

const InputTextBase = ({
  value,
  name,
  fullWidth,
  dataTestId,
  multiline,
  error,
  disabled,
  onChange,
  rows,
  onBlur,
  autofocus,
  rounded,
  placeholder,
  endAdornment,
  id,
  type,
  replaceValue,
  className,
  label,
  inputStyled,
  marginBottom,
}) => {
  const textInput = useRef(null);

  const handleChange = (newValue) => {
    onChange({ target: { value: newValue } });
  };

  useEffect(() => {
    if (replaceValue || replaceValue === '') {
      textInput.current.value = replaceValue;
      handleChange(replaceValue);
    }
  }, [replaceValue]);

  useEffect(() => {
    if (type === 'alphanumeric' || type === 'number') {
      handleChange(value);
    }
  }, [value]);

  return (
    <>
      {inputStyled === 'underline' ? (
        <div style={{ width: '100%', marginBottom }}>
          <TextField
            id={`text-input-${name}`}
            variant="filled"
            label={label}
            fullWidth={fullWidth}
            name={name}
            data-testid={dataTestId}
            value={value}
            defaultValue={value}
            multiline={multiline}
            error={!!error}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            rows={rows}
            autoFocus={autofocus}
            placeholder={placeholder}
            rounded={rounded.toString()}
            endAdornment={endAdornment}
            className={className}
          />
        </div>
      ) : (
        <StyledInputBase
          id={`text-input-${name}`}
          fullWidth={fullWidth}
          name={name}
          data-testid={dataTestId}
          value={value}
          multiline={multiline}
          error={!!error}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          rows={rows}
          autoFocus={autofocus}
          placeholder={placeholder}
          rounded={rounded.toString()}
          endAdornment={endAdornment}
          className={className}
        inputRef={textInput}/>
      )}
      {error && <StyledErrorText>{error}</StyledErrorText>}
    </>
  );
};

InputTextBase.defaultProps = {
  dataTestId: '',
  value: undefined,
  type: '',
  fullWidth: false,
  autofocus: false,
  multiline: false,
  error: null,
  disabled: false,
  rows: 1,
  onChange: () => {},
  onBlur: () => {},
  rounded: false,
  placeholder: '',
  endAdornment: null,
  replaceValue: null,
  id: '',
  className: '',
  label: null,
  inputStyled: '',
  marginBottom: '0px',
};

InputTextBase.propTypes = {
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  autofocus: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func,
  rows: PropTypes.number,
  onBlur: PropTypes.func,
  rounded: PropTypes.bool,
  placeholder: PropTypes.string,
  endAdornment: PropTypes.node,
  replaceValue: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  inputStyled: PropTypes.string,
  marginBottom: PropTypes.string,
};

export default InputTextBase;
