export const buildSearchParameters = (searchParameters) => {
  let baseSearch = '';

  if (searchParameters) {
    const searchParametesList = Object.entries(searchParameters);
    if (searchParametesList.length > 0) {
      baseSearch += '?';

      searchParametesList.forEach((parameter, index) => {
        baseSearch += `${parameter[0]}=${parameter[1]}`;
        if (
          searchParametesList.length > 0 &&
          index !== searchParametesList.length - 1
        )
          baseSearch += '&';
      });
    }
  }

  return baseSearch;
};

export default buildSearchParameters;
