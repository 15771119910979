import React, { useState } from 'react';
import { Container } from '@material-ui/core';
import { TextField } from '@codeboxxtechschool/ginza_shared_components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import ginzaLogo from '../../../assets/images/ginza_logo.svg';
import { FlexContainerColumn } from '../reusables/StyledComponent';
import InputButton from '../reusables/inputs/InputButton';
import CenterForm from '../reusables/centerForm';
import { SEND_RESET_PW_EMAILS } from '../../graphql/mutations';
import Popup from '../modals/Popup';

const ResetPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [emailInput, setEmailInput] = useState('');
  const [submitEmail, setSubmitEmail] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [sendResetPwEmails] = useMutation(SEND_RESET_PW_EMAILS);

  const handlePasswordReset = async () => {
    await sendResetPwEmails({
      variables: { email: emailInput },
    });
  };

  const handleSubmitEmail = () => {
    if (emailInput) {
      setSubmitEmail(true);
      handlePasswordReset();
    } else {
      setDisplayError(true);
    }
  };

  const handleSignIn = () => {
    history.push('/');
  };

  return (
    <>
      {submitEmail && (
        <Popup
          data-testid="success-popup"
          type="success"
          title={t('PopupContent.Title.Success')}
          message={t('PasswordReset.EmailSentMessage')}
          buttons={[
            {
              content: t('PasswordReset.SignIn'),
              onClick: () => {
                handleSignIn();
              },
              type: 'white',
              key: 'success',
            },
          ]}
        />
      )}
      <Container component="main" maxWidth="xs">
        <FlexContainerColumn padding="4em 0 0 0">
          <img
            src={ginzaLogo}
            alt="ginza logo"
            data-testid="ginzaLogoSignIn"
            style={{ width: '280px' }}
          />
          <br />
          <br />
          <CenterForm
            title={t('PasswordReset.EnterEmailTitle')}
            description={t('PasswordReset.EnterEmailDescription')}
          >
            <br />
            <TextField
              data-testid="email-input"
              label={t('PasswordReset.EmailAddress')}
              placeholder={t('PasswordReset.EmailAddress')}
              margin="dense"
              value={emailInput}
              onChange={(event) => setEmailInput(event.target.value)}
              onKeyPress={() => setDisplayError(false)}
              error={displayError}
              helperText={
                displayError ? t('PasswordReset.InlineErrorMessage') : ' '
              }
              fullWidth
              type="email"
              required
            />
            <br />
            <InputButton
              data-testid="enter-button"
              dataTestId="enter-button"
              fullWidth
              content={t('PasswordReset.Enter')}
              onClick={handleSubmitEmail}
            />
            <br />
            <br />
            <div style={{ textAlign: 'center' }}>
              <InputButton
                data-testid="signIn-btn"
                content={t('PasswordReset.SignIn')}
                type="cancel"
                onClick={handleSignIn}
              />
            </div>
          </CenterForm>
        </FlexContainerColumn>
      </Container>
    </>
  );
};

export default ResetPassword;
